import React from 'react';

import { Box } from '@src/common/components';
import { NumberProviderType } from '@shared/bff';

import { NumberProviderLabelTag } from '../NumberProviderLabelTag';

interface IPhoneNumberProps {
  phoneNumber: string;
  providerType: NumberProviderType;
}

export const PhoneNumber = ({
  phoneNumber,
  providerType,
}: IPhoneNumberProps) => {
  return (
    <span>
      <span id="phone-number">{phoneNumber}</span>
      <Box display="inline" ml="x-small" />
      <NumberProviderLabelTag type={providerType}></NumberProviderLabelTag>
    </span>
  );
};
