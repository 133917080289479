import { gql } from '@apollo/client';

import { IWhatsAppAspClientsResponse } from '@shared/bff';

import { ICrFragment, WA_CR_FRAGMENT } from '../../fragments';

export interface ICrQueryResponse {
  whatsAppCr: ICrFragment;
  whatsAppAspClients: Pick<IWhatsAppAspClientsResponse, 'onboarded'>;
}

export const CR_QUERY = gql`
  query WhatsAppCr($botCrId: String!) {
    whatsAppCr(botCrId: $botCrId) {
      ...CrFragment
    }
  }
  ${WA_CR_FRAGMENT}
`;
