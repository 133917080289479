import React from 'react';
import { t } from 'messages';

import {
  Box,
  CheckboxField,
  Grid,
  GridItem,
  InputField,
  SingleSelectField,
  Text,
} from '@src/common/components';

export const DisabledAuthenticationMessageContent = () => {
  const defaultOtpTypeValue = {
    value: 'copy-code',
    label:
      t.whatsapp.messageTemplates.authenticationTemplateButtonLabels.COPY_CODE(),
  };
  return (
    <Box pl="medium" pr="medium" mt="large">
      <Text variant="heading_small" weight="bold" mt="large">
        {t.whatsapp.messageTemplates.register.sections.messageContent()}
      </Text>
      <Grid flow="vertical">
        <GridItem cols={10} pr="small" pt="small">
          <Text variant="body">
            {t.whatsapp.messageTemplates.fields.messageBody()}
          </Text>
          <CheckboxField
            name="securityRecommendation"
            labels={{
              label: `${t.whatsapp.messageTemplates.fields.securityRecommendation()} ${t.common.optional()}`,
            }}
            disabled
            id="security-recommendation-input"
          />
        </GridItem>
      </Grid>
      <Grid flow="vertical">
        <GridItem cols={8} pr="small" pt="small">
          <Text variant="body">
            {t.whatsapp.messageTemplates.fields.footer()}
          </Text>
          <InputField
            name="codeExpirationMinutes"
            label={`${t.whatsapp.messageTemplates.fields.codeExpirationMinutes()} ${t.common.optional()}`}
            disabled
            type="number"
            tooltip={t.whatsapp.messageTemplates.tooltips.codeExpirationMinutes()}
            id="code-expiration-minutes-input"
          />
        </GridItem>
      </Grid>
      <Grid flow="vertical">
        <GridItem cols={6} mt="small">
          <Text variant="body">
            {t.whatsapp.messageTemplates.fields.button()}
          </Text>
          <SingleSelectField
            name="otpType"
            labels={{
              label: t.whatsapp.messageTemplates.fields.buttonType(),
            }}
            options={[]}
            selection={[defaultOtpTypeValue]}
            singleInputDisabled
            variant="readonly"
            required
            id="otp-type-input"
          />
        </GridItem>
      </Grid>
      <Box mt="small" />
      <InputField
        name="buttonText"
        label={t.whatsapp.messageTemplates.fields.copyCodeButtonText()}
        disabled
        type="number"
        tooltip={t.whatsapp.messageTemplates.tooltips.copyCodeButtonText()}
        required
        id="copy-code-button-text-input"
      />
    </Box>
  );
};
