import React from 'react';
import styled from 'styled-components';

import {
  Grid,
  GridItem,
  StickyGridItem,
  Box,
  Card,
  ProfileSettingsPreviewer,
} from '@src/common/components';
import { t } from '@src/messages';
import { IWhatsAppBotInput, CrState } from '@shared/bff';

import { prepareDataForBusinessProfilePreviewer } from '../../utils';
import { WhatsAppChannelProgress } from '../WhatsAppChannelProgress';

import { Configuration } from './Configuration';
import { Design } from './Design';
import { CompanyInfo } from './CompanyInfo';
import { Contact } from './Contact';

interface IBotFormProps {
  isEditing?: boolean;
  disablePhoneNumber?: boolean;
  values: IWhatsAppBotInput;
}

const CardWrapper = styled(Card)({
  overflowX: 'auto',
});

export const BotForm = ({
  isEditing = false,
  disablePhoneNumber = false,
  values: {
    name,
    description,
    countryCode,
    phone,
    photoUrl,
    address,
    vertical,
    email,
    websiteOne,
    websiteTwo,
    about,
  },
}: IBotFormProps) => (
  <>
    <WhatsAppChannelProgress state={CrState.DRAFT} />
    <Box mt="small" />
    <Grid>
      <GridItem cols={8} pr="small">
        <Grid flow="vertical">
          <GridItem mb="small">
            <Configuration
              isEditing={isEditing}
              disablePhoneNumber={disablePhoneNumber}
            />
          </GridItem>
        </Grid>
        <GridItem mb="small">
          <Design />
        </GridItem>
        <GridItem mb="small">
          <CompanyInfo />
        </GridItem>
        <GridItem mb="small">
          <Contact />
        </GridItem>
      </GridItem>
      <StickyGridItem cols={4}>
        <CardWrapper
          heading={t.common.components.businessProfilePreviewer.heading()}
        >
          <ProfileSettingsPreviewer
            whatsappData={prepareDataForBusinessProfilePreviewer({
              name,
              description,
              countryCode,
              phone,
              photoUrl,
              address,
              vertical,
              email,
              websiteOne,
              websiteTwo,
              about,
            })}
          />
        </CardWrapper>
      </StickyGridItem>
    </Grid>
  </>
);
