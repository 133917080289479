import { gql } from '@apollo/client';

import { IWhatsAppCr } from '@shared/bff';

import { ICrBotDataFragment, CR_BOT_DATA_FRAGMENT } from './CrBotDataFragment';

export type ICrFragment = Pick<
  IWhatsAppCr,
  'id' | 'botId' | 'changes' | 'state' | 'created' | 'updated'
> & {
  bot: ICrBotDataFragment;
};

export const WA_CR_FRAGMENT = gql`
  fragment CrFragment on WhatsAppCr {
    id
    botId
    bot {
      ...CrBotDataFragment
    }
    changes
    state
    created
    updated
  }
  ${CR_BOT_DATA_FRAGMENT}
`;
