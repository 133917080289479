import {
  IWhatsAppMtCrContentInput,
  WhatsAppMtHeaderType,
  WhatsAppMtMediaType,
  WhatsAppMtButtonType,
  IWhatsAppAuthenticationMtCrContentInput,
  WhatsAppMtOtpType,
} from '@shared/bff';

import { CallToActionButton, ContentPayload } from '../../types';

import { mapCallToActionButtons } from './mapCallToActionButtons';

export const mapContents = (contents: ContentPayload[]) =>
  contents.map((content) => {
    const {
      headerType,
      mediaType,
      buttonType,
      callToActionButtons,
      footer,
      ...rest
    } = content as IWhatsAppMtCrContentInput;

    return {
      headerType: headerType || WhatsAppMtHeaderType.NONE,
      mediaType: mediaType || WhatsAppMtMediaType.NONE,
      buttonType: buttonType || WhatsAppMtButtonType.NONE,
      callToActionButtons: callToActionButtons
        ? mapCallToActionButtons(callToActionButtons as CallToActionButton[])
        : null,
      footer: footer || null,
      ...rest,
    };
  });

export const mapAuthenticationContents = (
  contents: IWhatsAppAuthenticationMtCrContentInput[]
) =>
  contents.map((content) => {
    const { otpType, ...rest } =
      content as IWhatsAppAuthenticationMtCrContentInput;

    return {
      otpType: otpType || WhatsAppMtOtpType.COPY_CODE,
      ...rest,
    };
  });
