import { gql } from '@apollo/client';

import { IWhatsAppCr } from '@shared/bff';

export type IUpdateCrMutationResponse = {
  whatsAppUpdateCr: IWhatsAppCr;
};

export const UPDATE_CR_MUTATION = gql`
  mutation WhatsAppUpdateCr($whatsAppCr: WhatsAppCrUpdateInput!) {
    whatsAppUpdateCr(whatsAppCr: $whatsAppCr) {
      id
    }
  }
`;
