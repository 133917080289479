import * as Yup from 'yup';
import type { Except } from 'type-fest';
import { isEqual } from 'lodash-es';
import { t } from 'messages';

import {
  getHttpHttpsSchema,
  isPhoneNumberE164,
  TEST_INVALID_PHONE_NUMBER,
  TEST_INVALID_URL_VARIABLE,
  TEST_WHATSAPP_DOMAIN,
} from '@src/common/utils';
import {
  WhatsAppMtCategory,
  WhatsAppMtHeaderType,
  WhatsAppMtMediaType,
  WhatsAppMtButtonType,
  WhatsAppMtUrlType,
  IWhatsAppMtCrContentInput,
  CrState,
  IWhatsAppMtPhoneActionInput,
  IWhatsAppMtWebsiteActionInput,
  IWhatsAppAuthenticationMtCrContentInput,
  WhatsAppMtOtpType,
} from '@shared/bff';

import {
  CallToActionType,
  TemplateValues,
  EditMode,
  CallToActionButton,
  EditContentPayload,
  EditAuthenticationContentPayload,
} from '../../types';
import {
  CALL_TO_ACTION_TYPE_FIELD_NAME,
  HEADER_FIELD_NAME,
  BUTTONS_TYPE_FIELD_NAME,
  BODY_TEXT_FIELD_NAME,
  MEDIA_TYPE_FIELD_NAME,
  HEADER_TEXT_FIELD_NAME,
  CALL_TO_ACTION_BUTTONS_FIELD_NAME,
  OTP_TYPE_FIELD_NAME,
} from '../form';

import { templateFieldsMaxLength } from './templateFieldsMaxLength';
import { contentFieldsMaxLength } from './contentFieldsMaxLength';
import { getTemplateNameSchema } from './validation';
import {
  authenticationContentFieldsMaxLength,
  authenticationContentFieldsMinLength,
} from './authenticationContentFieldsLength';

export const validCategoryTypes: WhatsAppMtCategory[] = [
  WhatsAppMtCategory.ACCOUNT_UPDATE,
  WhatsAppMtCategory.PAYMENT_UPDATE,
  WhatsAppMtCategory.PERSONAL_FINANCE_UPDATE,
  WhatsAppMtCategory.SHIPPING_UPDATE,
  WhatsAppMtCategory.RESERVATION_UPDATE,
  WhatsAppMtCategory.ISSUE_RESOLUTION,
  WhatsAppMtCategory.APPOINTMENT_UPDATE,
  WhatsAppMtCategory.TRANSPORTATION_UPDATE,
  WhatsAppMtCategory.TICKET_UPDATE,
  WhatsAppMtCategory.ALERT_UPDATE,
  WhatsAppMtCategory.AUTO_REPLY,
  WhatsAppMtCategory.TRANSACTIONAL,
  WhatsAppMtCategory.MARKETING,
  WhatsAppMtCategory.ONE_TIME_PASSWORDS,
  WhatsAppMtCategory.UTILITY,
  WhatsAppMtCategory.AUTHENTICATION,
];

const validHeaderTypes: WhatsAppMtHeaderType[] = [
  WhatsAppMtHeaderType.NONE,
  WhatsAppMtHeaderType.TEXT,
  WhatsAppMtHeaderType.MEDIA,
];

const validMediaTypes: WhatsAppMtMediaType[] = [
  WhatsAppMtMediaType.IMAGE,
  WhatsAppMtMediaType.VIDEO,
  WhatsAppMtMediaType.DOCUMENT,
];

const validButtonTypes: WhatsAppMtButtonType[] = [
  WhatsAppMtButtonType.NONE,
  WhatsAppMtButtonType.QUICK_REPLY,
  WhatsAppMtButtonType.CALL_TO_ACTION,
];

export const validCallToActionTypes: CallToActionType[] = [
  CallToActionType.CALL_PHONE_NUMBER,
  CallToActionType.VISIT_WEBSITE,
];

const validUrlTypes: WhatsAppMtUrlType[] = [
  WhatsAppMtUrlType.STATIC,
  WhatsAppMtUrlType.DYNAMIC,
];

const validOtpTypes: WhatsAppMtOtpType[] = [
  WhatsAppMtOtpType.COPY_CODE,
  WhatsAppMtOtpType.ONE_TAP,
];

export const validLanguageSchema = () =>
  Yup.string().oneOf(
    Object.keys(t.whatsapp.messageTemplates.templateLanguageLabels)
  );
export const validWebsiteUrlSchema = () =>
  getHttpHttpsSchema()
    .test(TEST_WHATSAPP_DOMAIN, t.common.validation.url.whatsapp(), (value) => {
      if (value) {
        return !value.includes('whatsapp.com');
      }
      return true;
    })
    .url();
export const validVariableUrlSchema = () =>
  Yup.string()
    .test(
      TEST_WHATSAPP_DOMAIN,
      t.common.validation.url.whatsapp(),
      function (urlVariable) {
        const websiteButton: IWhatsAppMtWebsiteActionInput =
          this.parent.callToActionButtons.find(
            (
              button:
                | IWhatsAppMtWebsiteActionInput
                | IWhatsAppMtPhoneActionInput
            ) => 'websiteUrl' in button
          );

        if (!websiteButton) {
          return true;
        }

        const urlWithVariable = websiteButton.websiteUrl + urlVariable;

        if (!urlWithVariable) {
          return true;
        }

        return !urlWithVariable.includes('whatsapp.com');
      }
    )
    .test(
      TEST_INVALID_URL_VARIABLE,
      t.common.validation.url.variable(),
      function (urlVariable) {
        if (!urlVariable) {
          return true;
        }

        const websiteButton: IWhatsAppMtWebsiteActionInput =
          this.parent.callToActionButtons.find(
            (
              button:
                | IWhatsAppMtWebsiteActionInput
                | IWhatsAppMtPhoneActionInput
            ) => 'websiteUrl' in button
          );

        try {
          Yup.string()
            .url()
            .validateSync(websiteButton.websiteUrl + urlVariable);
        } catch (_error) {
          return false;
        }

        return true;
      }
    );

const validCallToActionSchema = () => {
  const buttonTextSchema = () =>
    Yup.string().max(contentFieldsMaxLength.callToActionButtons);
  return Yup.object().shape({
    type: Yup.string().oneOf(validCallToActionTypes).required(),
    buttonText: buttonTextSchema().required(),
    phoneNumber: Yup.string().when(CALL_TO_ACTION_TYPE_FIELD_NAME, {
      is: CallToActionType.CALL_PHONE_NUMBER,
      then: Yup.string()
        .required()
        .test(
          TEST_INVALID_PHONE_NUMBER,
          t.common.validation.phoneNumber.e164(),
          (value) => isPhoneNumberE164(value)
        ),
    }),
    urlType: Yup.string().when(CALL_TO_ACTION_TYPE_FIELD_NAME, {
      is: CallToActionType.VISIT_WEBSITE,
      then: Yup.string().oneOf(validUrlTypes).required(),
    }),
    websiteUrl: Yup.string().when(CALL_TO_ACTION_TYPE_FIELD_NAME, {
      is: CallToActionType.VISIT_WEBSITE,
      then: validWebsiteUrlSchema().required(),
    }),
  });
};

const getNameSchema = () =>
  getTemplateNameSchema().max(templateFieldsMaxLength.name);
const getCategorySchema = () => Yup.string().oneOf(validCategoryTypes);
const getLanguagesSchema = () => Yup.array().of(validLanguageSchema());
const getHeaderSchema = () => Yup.string().oneOf(validHeaderTypes);
const getHeaderTextSchema = () =>
  Yup.string().when(HEADER_FIELD_NAME, {
    is: WhatsAppMtHeaderType.TEXT,
    then: Yup.string().max(contentFieldsMaxLength.headerText).required(),
  });
const getMediaTypeSchema = () =>
  Yup.string().when(HEADER_FIELD_NAME, {
    is: WhatsAppMtHeaderType.MEDIA,
    then: Yup.string().oneOf(validMediaTypes).required(),
  });
export const getBodyTextSchema = () =>
  Yup.string().max(contentFieldsMaxLength.bodyText);
export const getFooterSchema = () =>
  Yup.string().max(contentFieldsMaxLength.footer);
const getButtonsTypeSchema = () => Yup.string().oneOf(validButtonTypes);
const getQuickReplyButtonsSchema = () =>
  Yup.array().when(BUTTONS_TYPE_FIELD_NAME, {
    is: WhatsAppMtButtonType.QUICK_REPLY,
    then: Yup.array()
      .of(
        Yup.string()
          .max(contentFieldsMaxLength.quickReplyButtons)
          .required()
          .test('Unique', t.common.validation.test.unique(), function (value) {
            const quickReplyButtons = this.parent;
            if (!quickReplyButtons || !value) {
              return true;
            }

            return (
              quickReplyButtons.filter(
                (buttonText: string) => buttonText === value
              ).length < 2
            );
          })
      )
      .max(3),
  });
const getCallToActionSchema = () =>
  Yup.array().when(BUTTONS_TYPE_FIELD_NAME, {
    is: WhatsAppMtButtonType.CALL_TO_ACTION,
    then: Yup.array()
      .of(validCallToActionSchema())
      .max(2)
      .test(
        'Buttons has unique text',
        t.common.validation.test.unique(),
        function (callToActionButtons: CallToActionButton[]) {
          if (!callToActionButtons || callToActionButtons.length <= 1) {
            return true;
          }

          if (
            callToActionButtons[0].buttonText ===
            callToActionButtons[1].buttonText
          ) {
            return this.createError({
              path: `${this.path}[1].buttonText`,
              message: t.common.validation.test.unique(),
            });
          }

          return true;
        }
      ),
  });
const getMediaVariableSchema = () =>
  Yup.object().when(MEDIA_TYPE_FIELD_NAME, {
    is: (mediaType) =>
      mediaType === WhatsAppMtMediaType.IMAGE ||
      mediaType === WhatsAppMtMediaType.VIDEO ||
      mediaType === WhatsAppMtMediaType.DOCUMENT,
    then: Yup.object()
      .shape({
        url: Yup.string().required(),
        type: Yup.string(),
      })
      .required(),
  });
export const getMediaVariableDraftSchema = () =>
  Yup.object().shape({
    url: Yup.string(),
    type: Yup.string(),
  });
const getHeaderVariableSchema = (whatsAppSamples?: boolean) =>
  Yup.string().when(HEADER_TEXT_FIELD_NAME, {
    is: (headerText) => headerText && headerText.includes('{{1}}'),
    then: whatsAppSamples ? Yup.string().required() : Yup.string(),
  });
const getBodyVariablesSchema = (whatsAppSamples?: boolean) =>
  Yup.array().when(BODY_TEXT_FIELD_NAME, {
    is: (bodyText) => bodyText && bodyText.includes('{{1}}'),
    then: whatsAppSamples
      ? Yup.array().required().of(Yup.string().required())
      : Yup.array().of(Yup.string()),
  });
const getButtonUrlVariable = (whatsAppSamples?: boolean) =>
  Yup.string().when(CALL_TO_ACTION_BUTTONS_FIELD_NAME, {
    is: (callToActionButtons) =>
      callToActionButtons &&
      callToActionButtons.find(
        (button: CallToActionButton) =>
          'urlType' in button && button.urlType === WhatsAppMtUrlType.DYNAMIC
      ),
    then: whatsAppSamples ? validVariableUrlSchema().required() : Yup.string(),
  });

const getMessageContentFieldRequirements = (
  whatsAppSamples?: boolean
): Record<keyof IWhatsAppMtCrContentInput, Yup.Schema<unknown>> => ({
  language: validLanguageSchema().required(),
  headerType: getHeaderSchema().required(),
  headerText: getHeaderTextSchema(),
  mediaType: getMediaTypeSchema(),
  bodyText: getBodyTextSchema().required(),
  footer: getFooterSchema(),
  buttonType: getButtonsTypeSchema().required(),
  quickReplyButtons: getQuickReplyButtonsSchema(),
  callToActionButtons: getCallToActionSchema(),
  mediaVariable: whatsAppSamples
    ? getMediaVariableSchema()
    : getMediaVariableDraftSchema(),
  headerVariable: getHeaderVariableSchema(whatsAppSamples),
  bodyVariables: getBodyVariablesSchema(whatsAppSamples),
  buttonUrlVariable: getButtonUrlVariable(whatsAppSamples),
});

export const getSecurityRecommendationSchema = () => Yup.boolean().nullable();
export const getCodeExpirationMinutesSchema = () =>
  Yup.number()
    .integer()
    .min(authenticationContentFieldsMinLength.codeExpirationMinutes)
    .max(authenticationContentFieldsMaxLength.codeExpirationMinutes)
    .nullable();
const getOtpTypeSchema = () => Yup.string().oneOf(validOtpTypes);
export const getButtonTextSchema = () =>
  Yup.string().max(authenticationContentFieldsMaxLength.buttonText);
export const getAutofillSchema = () =>
  Yup.string()
    .max(authenticationContentFieldsMaxLength.autofillText)
    .nullable();
export const getPackageNameSchema = () => Yup.string().nullable();
export const getSignatureHashSchema = () => Yup.string().nullable();

const getAuthenticationMessageContentFieldRequirements = (): Record<
  keyof IWhatsAppAuthenticationMtCrContentInput,
  Yup.Schema<unknown>
> => ({
  language: validLanguageSchema().required(),
  securityRecommendation: getSecurityRecommendationSchema(),
  codeExpirationMinutes: getCodeExpirationMinutesSchema(),
  otpType: getOtpTypeSchema().required(),
  buttonText: getButtonTextSchema().required(),
  autofillText: getAutofillSchema(),
  packageName: Yup.string().when(OTP_TYPE_FIELD_NAME, {
    is: WhatsAppMtOtpType.ONE_TAP,
    then: getPackageNameSchema().required(),
    otherwise: getPackageNameSchema(),
  }),
  signatureHash: Yup.string().when(OTP_TYPE_FIELD_NAME, {
    is: WhatsAppMtOtpType.ONE_TAP,
    then: getSignatureHashSchema().required(),
    otherwise: getSignatureHashSchema(),
  }),
});

const getAuthenticationMessageContentsSchema = (
  initialContent?: EditAuthenticationContentPayload[]
) =>
  Yup.array().of(
    getAuthenticationMessageContentPayloadSchema(initialContent)
      .shape({ ...getAuthenticationMessageContentFieldRequirements() })
      .required()
  );

export const getAuthenticationMessageContentPayloadSchema = (
  initialContent?: EditAuthenticationContentPayload[]
) =>
  Yup.object().test(
    'changes-expected',
    t.common.validation.test.change(),
    (value: EditAuthenticationContentPayload) => {
      if (value.crStatus !== CrState.REJECTED) {
        return true;
      }
      const initialValue = initialContent?.find(({ id }) => id === value.id);
      return !isEqual(value, initialValue);
    }
  );

const getMessageContentsSchema = (
  whatsAppSamples?: boolean,
  initialContent?: EditContentPayload[]
) =>
  Yup.array().of(
    getMessageContentPayloadSchema(initialContent)
      .shape({ ...getMessageContentFieldRequirements(whatsAppSamples) })
      .required()
  );

export const getMessageContentPayloadSchema = (
  initialContent?: EditContentPayload[]
) =>
  Yup.object().test(
    'changes-expected',
    t.common.validation.test.change(),
    (value: EditContentPayload) => {
      if (value.crStatus !== CrState.REJECTED) {
        return true;
      }
      const initialValue = initialContent?.find(({ id }) => id === value.id);
      return !isEqual({ ...value, footer: value.footer ?? '' }, initialValue);
    }
  );

export const getTemplateFieldRequirements = (
  mode?: EditMode
): Record<
  keyof Except<TemplateValues, 'contents' | 'authenticationContents'>,
  Yup.Schema<unknown>
> => ({
  aspClientId: Yup.string().required(),
  name: getNameSchema().required(),
  category: getCategorySchema().required(),
  languages:
    mode === 'edit' ? getLanguagesSchema() : getLanguagesSchema().required(),
});

export const getTemplateRequestValidationSchema = (
  whatsAppSamples?: boolean,
  mode?: EditMode,
  initialContent?: EditContentPayload[],
  initialAuthenticationContent?: EditAuthenticationContentPayload[]
) =>
  Yup.object().shape({
    ...getTemplateFieldRequirements(mode),
    contents: getMessageContentsSchema(whatsAppSamples, initialContent),
    authenticationContents: getAuthenticationMessageContentsSchema(
      initialAuthenticationContent
    ),
  });
