import React, { useMemo, ReactNode } from 'react';
import { useFormikContext } from 'formik';
import { t } from 'messages';

import {
  InputField,
  GridItem,
  Grid,
  SingleSelectField,
  PhoneNumberField,
} from '@src/common/components';
import { useFeatureFlags } from '@src/common/utils/hooks';
import { WhatsAppMtUrlType } from '@shared/bff';

import {
  prepareOptionsForCallToActionType,
  ITemplateCallToActionTypeOption,
  IUrlTypeOption,
  prepareOptionsForUrlType,
  contentFieldsMaxLength,
  CALL_TO_ACTION_TYPE_FIELD_NAME,
  templateButtonRegexConstraint,
} from '../../../utils';
import { CallToActionType, TemplateValues } from '../../../types';
import { RemoveButtonIcon } from '../RemoveButtonIcon';

interface ICallToActionProps {
  fieldPath: string;
  type: CallToActionType;
  urlType: WhatsAppMtUrlType;
  typeSelectDisabled: boolean;
  index: number;
  selectedTabIndex: number;
  isRtlLanguage?: boolean;
  canRemoveAction: boolean;
  handleRemoveAction: (index: number) => void;
}

export const CallToActionFields = ({
  fieldPath,
  type,
  urlType,
  typeSelectDisabled,
  index,
  selectedTabIndex,
  isRtlLanguage,
  canRemoveAction,
  handleRemoveAction,
}: ICallToActionProps) => {
  const { whatsAppSamples } = useFeatureFlags();

  const { setFieldValue } = useFormikContext<TemplateValues>();

  const handleActionTypeChanged = (selectedType: CallToActionType) => {
    const currentAction =
      selectedType === CallToActionType.CALL_PHONE_NUMBER
        ? {
            type: CallToActionType.CALL_PHONE_NUMBER,
            buttonText: '',
            phoneNumber: '',
          }
        : {
            type: CallToActionType.VISIT_WEBSITE,
            buttonText: '',
            urlType: WhatsAppMtUrlType.STATIC,
            websiteUrl: '',
          };

    setFieldValue(fieldPath, currentAction);
    if (whatsAppSamples) {
      setFieldValue(
        `contents[${selectedTabIndex}].buttonUrlVariable`,
        undefined
      );
    }
  };

  const handleUrlTypeChanged = (selectedUrlType: WhatsAppMtUrlType) => {
    if (whatsAppSamples) {
      if (selectedUrlType === WhatsAppMtUrlType.STATIC) {
        setFieldValue(
          `contents[${selectedTabIndex}].buttonUrlVariable`,
          undefined
        );
      } else {
        setFieldValue(`contents[${selectedTabIndex}].buttonUrlVariable`, '');
      }
    }
  };

  const typeOptions = useMemo<ITemplateCallToActionTypeOption[]>(
    prepareOptionsForCallToActionType,
    []
  );
  const urlTypeOptions = useMemo<IUrlTypeOption[]>(
    prepareOptionsForUrlType,
    []
  );
  const CallToActionMapper: Record<CallToActionType, ReactNode> = {
    [CallToActionType.CALL_PHONE_NUMBER]: (
      <>
        <GridItem cols={9}>
          <PhoneNumberField
            name={`${fieldPath}.phoneNumber`}
            label={t.whatsapp.messageTemplates.fields.phoneNumber()}
            placeholder={t.whatsapp.messageTemplates.placeholders.phoneNumber()}
            tooltip={t.whatsapp.messageTemplates.tooltips.phoneNumber()}
            id={`phone-number-${index + 1}-input`}
            required
          />
        </GridItem>
      </>
    ),
    [CallToActionType.VISIT_WEBSITE]: (
      <>
        <GridItem cols={3} pr="small">
          <SingleSelectField
            name={`${fieldPath}.urlType`}
            labels={{
              label: t.whatsapp.messageTemplates.fields.urlType(),
              placeholderReadOnly: '',
            }}
            options={urlTypeOptions}
            tooltip={t.whatsapp.messageTemplates.tooltips.urlType()}
            id={`url-type-${index + 1}-input`}
            required
            variant="readonly"
            onSelect={(selectedUrlType) =>
              handleUrlTypeChanged(selectedUrlType as WhatsAppMtUrlType)
            }
          />
        </GridItem>
        <GridItem cols={6}>
          <InputField
            name={`${fieldPath}.websiteUrl`}
            label={t.whatsapp.messageTemplates.fields.websiteUrl()}
            placeholder="https://www.example.com"
            required
            rightAdornment={urlType === WhatsAppMtUrlType.DYNAMIC && '{{1}}'}
            maxLength="2000"
            id={`website-url-${index + 1}-input`}
          />
        </GridItem>
      </>
    ),
  };

  return (
    <>
      <Grid>
        <GridItem cols={6} pr="small">
          <SingleSelectField
            name={`${fieldPath}.${CALL_TO_ACTION_TYPE_FIELD_NAME}`}
            labels={{
              label: t.whatsapp.messageTemplates.fields.typeOfAction(),
            }}
            options={typeOptions}
            required
            singleInputDisabled={typeSelectDisabled}
            variant="readonly"
            onSelect={(selectedType) =>
              handleActionTypeChanged(selectedType as CallToActionType)
            }
            id={`type-of-action-${index + 1}-input`}
          />
        </GridItem>
        <GridItem cols={3}>
          {canRemoveAction && (
            <RemoveButtonIcon handleRemove={() => handleRemoveAction(index)} />
          )}
        </GridItem>
      </Grid>
      <Grid>
        {type && (
          <GridItem cols={3} pr="small">
            <InputField
              name={`${fieldPath}.buttonText`}
              label={t.whatsapp.messageTemplates.fields.buttonText()}
              maxLength={`${contentFieldsMaxLength.callToActionButtons}`}
              id={`button-text-${index + 1}-input`}
              regexConstraint={templateButtonRegexConstraint}
              excludeEmojis
              className={isRtlLanguage ? 'rtl' : ''}
              required
            />
          </GridItem>
        )}
        {CallToActionMapper[type]}
      </Grid>
    </>
  );
};
