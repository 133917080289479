import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonGroup, Modal } from '@salesforce/design-system-react';
import { useMutation } from '@apollo/client';

import { Channel, IBotIdMatchParams } from '@src/common/types';
import {
  ActivityHistorySection,
  Button,
  Grid,
  GridItem,
  IQueryHandlerResult,
  PageHeader,
  QueryHandler,
  ToastContext,
  ScopedNotification,
  UserContext,
  Box,
} from '@src/common/components';
import { t } from '@src/messages';
import {
  channelBotCompareCrBotPath,
  channelBotEditDetailsPath,
  channelListPath,
} from '@src/common/utils';
import {
  ACTIVITIES_QUERY,
  CREATE_COMMENT_MUTATION,
  IActivitiesQueryResponse,
  ICreateCommentResponse,
} from '@src/common/graphql';
import {
  IMutationCreateCommentArgs,
  ActivityTargetType,
  ViberSenderState,
  ViberSenderAction,
  IMutationViberSenderActionArgs,
} from '@shared/bff';

import { SenderDetails } from '../components';
import {
  CR_QUERY,
  ICrQueryResponse,
  ISenderActionMutationResponse,
  ISenderQueryResponse,
  SENDER_ACTION_MUTATION,
  SENDER_QUERY,
} from '../graphql';

export const SenderDetailsView = () => {
  const { toast } = useContext(ToastContext);
  const { botId } = useParams<IBotIdMatchParams>();
  const history = useHistory();
  const [createCommentRequest] = useMutation<
    ICreateCommentResponse,
    IMutationCreateCommentArgs
  >(CREATE_COMMENT_MUTATION);
  const [senderActionRequest, { loading: actionRequestLoading }] = useMutation<
    ISenderActionMutationResponse,
    IMutationViberSenderActionArgs
  >(SENDER_ACTION_MUTATION);
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;

  const [showCurrent, setShowCurrent] = useState(false);
  const [showSenderActionModal, setShowSenderActionModal] = useState(false);

  const handleCurrentClick = () => setShowCurrent(!showCurrent);

  const handleEditChannelClick = () =>
    history.push(channelBotEditDetailsPath(Channel.ViberBusiness, botId));

  const handleGoToRequest = (crId: string) => {
    history.push(channelBotCompareCrBotPath(Channel.ViberBusiness, crId));
  };

  const handleSenderActionModalConfirm = () => {
    setShowSenderActionModal(true);
  };

  const onCancel = () => {
    setShowSenderActionModal(false);
  };

  const handleSenderAction = async (action: ViberSenderAction) => {
    try {
      await senderActionRequest({
        variables: {
          senderId: botId,
          action,
        },
        refetchQueries: [
          {
            query: SENDER_QUERY,
            variables: {
              senderId: botId,
            },
          },
        ],
      });
      setShowSenderActionModal(false);
    } catch (e) {
      toast('error', { heading: t.common.toasts.somethingWentWrong() });
    }
  };
  return (
    <>
      <QueryHandler
        query={SENDER_QUERY}
        variables={{
          senderId: botId,
        }}
        levitate
      >
        {({
          data: {
            viberSender,
            viberAspClients: { onboarded },
          },
        }: IQueryHandlerResult<ISenderQueryResponse>) => {
          const aspClient = onboarded.find(
            (client) => client.id === viberSender.aspClientId
          );
          return (
            <Grid>
              <GridItem mb="small">
                <PageHeader
                  channelType={Channel.ViberBusiness}
                  sectionType="channel"
                  title={viberSender.name}
                  id="sender-details-header"
                  breadcrumbs={[
                    {
                      header: t.common.channels(),
                      to: channelListPath(Channel.ViberBusiness),
                    },
                  ]}
                  actions={
                    <ButtonGroup>
                      {!viberSender.cr ? (
                        <Button
                          id="edit-channel-details-button"
                          key="edit-channel-details"
                          label={t.common.actions.editChannelDetails()}
                          onClick={handleEditChannelClick}
                          disabled={readOnlyAccess}
                        />
                      ) : (
                        <>
                          <Button
                            id="show-changes-button"
                            label={
                              showCurrent
                                ? t.common.actions.hideChanges()
                                : t.common.actions.showChanges()
                            }
                            onClick={handleCurrentClick}
                          />
                          <Button
                            id="go-to-request-button"
                            label={t.common.actions.goToRequest()}
                            onClick={() =>
                              handleGoToRequest(viberSender.cr!.id)
                            }
                          />
                        </>
                      )}
                      {viberSender.state === ViberSenderState.ACTIVE ? (
                        <Button
                          id="deactivate-channel-button"
                          label={t.viberBusiness.channels.deactivate()}
                          onClick={handleSenderActionModalConfirm}
                          disabled={readOnlyAccess}
                        />
                      ) : (
                        <></>
                      )}
                      {viberSender.state === ViberSenderState.INACTIVE ? (
                        <Button
                          id="activate-channel-button"
                          label={t.viberBusiness.channels.activate()}
                          onClick={handleSenderActionModalConfirm}
                          disabled={readOnlyAccess}
                        />
                      ) : (
                        <></>
                      )}
                    </ButtonGroup>
                  }
                />
              </GridItem>
              {readOnlyAccess && (
                <GridItem mb="small">
                  <ScopedNotification variant="info">
                    {t.common.readOnlyAccessNotification()}
                  </ScopedNotification>
                </GridItem>
              )}
              {viberSender.cr ? (
                <QueryHandler
                  query={CR_QUERY}
                  variables={{
                    senderCrId: viberSender.cr.id,
                  }}
                  levitate
                >
                  {({
                    data: { viberCr },
                  }: IQueryHandlerResult<ICrQueryResponse>) => {
                    return (
                      <SenderDetails
                        aspClient={aspClient!}
                        sender={viberSender}
                        cr={viberCr}
                        showCurrent={showCurrent}
                      />
                    );
                  }}
                </QueryHandler>
              ) : (
                <SenderDetails
                  aspClient={aspClient!}
                  sender={viberSender}
                  showCurrent={showCurrent}
                />
              )}
              <Modal
                heading={t.viberBusiness.channels.confirmation()}
                footer={[
                  <Button onClick={onCancel} key="cancel">
                    {t.common.actions.cancel()}
                  </Button>,
                  <Button
                    id="modal-submit-button"
                    variant="brand"
                    type="submit"
                    onClick={() =>
                      handleSenderAction(
                        viberSender.state === ViberSenderState.ACTIVE
                          ? ViberSenderAction.DEACTIVATE
                          : ViberSenderAction.ACTIVATE
                      )
                    }
                    loading={actionRequestLoading}
                    key="submit"
                  >
                    {viberSender.state === ViberSenderState.ACTIVE
                      ? t.viberBusiness.channels.deactivate()
                      : t.viberBusiness.channels.activate()}
                  </Button>,
                ]}
                isOpen={showSenderActionModal}
                ariaHideApp={false}
                dismissOnClickOutside={false}
                onRequestClose={onCancel}
              >
                <Box pl="medium" pr="medium" pb="medium" pt="medium">
                  {viberSender.state === ViberSenderState.ACTIVE
                    ? t.viberBusiness.channels.deactivateModal.description()
                    : t.viberBusiness.channels.activateModal.description()}
                </Box>
              </Modal>

              <Box mt="small">
                <QueryHandler
                  query={ACTIVITIES_QUERY}
                  variables={{
                    targetType: ActivityTargetType.VIBER_SENDER,
                    targetId: botId,
                  }}
                  levitate
                >
                  {({
                    data: { activities },
                  }: IQueryHandlerResult<IActivitiesQueryResponse>) => {
                    return (
                      <ActivityHistorySection
                        onSubmit={async (values) => {
                          try {
                            await createCommentRequest({
                              variables: {
                                comment: {
                                  targetId: botId,
                                  targetType: ActivityTargetType.VIBER_SENDER,
                                  comment: values.comment,
                                },
                              },

                              refetchQueries: [
                                {
                                  query: ACTIVITIES_QUERY,
                                  variables: {
                                    targetType: ActivityTargetType.VIBER_SENDER,
                                    targetId: botId,
                                  },
                                },
                              ],
                            });
                            toast('success', {
                              heading: t.common.toasts.commentCreatedSuccess(),
                            });
                          } catch (e) {
                            toast('error', {
                              heading: t.common.toasts.somethingWentWrong(),
                            });
                          }
                        }}
                        data={activities.map((activity) => ({
                          id: activity.activityId,
                          timestamp: activity.updated,
                          comment: activity.comment,
                          event: activity.notificationTemplate,
                        }))}
                      />
                    );
                  }}
                </QueryHandler>
              </Box>
            </Grid>
          );
        }}
      </QueryHandler>
    </>
  );
};
