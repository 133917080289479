import React from 'react';

import {
  Section,
  Card,
  InputField,
  Grid,
  GridItem,
  DocumentationLink,
  Box,
} from '@src/common/components';
import { t } from '@src/messages';
import { IWhatsAppAspClientInput } from '@shared/bff';

import { aspClientFieldsMaxLength } from '../../utils';

export const AspClientRegistrationForm = () => {
  return (
    <Grid>
      <GridItem mb="small">
        <Card
          heading={t.whatsapp.aspClients.register.heading()}
          description={
            <>
              <span>{t.whatsapp.aspClients.register.descriptionOne()}</span>
              <Box mb="xx-small" />
              <span>
                {t.whatsapp.aspClients.register.descriptionTwo()}{' '}
                <DocumentationLink>
                  {t.whatsapp.aspClients.register.documentationLink()}
                </DocumentationLink>
                .
              </span>
            </>
          }
          fullHeight
        >
          <Section>
            <Grid flow="vertical">
              <GridItem cols={5} mb="small">
                <InputField<IWhatsAppAspClientInput>
                  name="name"
                  label={t.whatsapp.aspClients.fields.name()}
                  tooltip={t.whatsapp.aspClients.fields.nameTooltip()}
                  id="account-name-input"
                  maxLength={`${aspClientFieldsMaxLength.name}`}
                  required
                />
              </GridItem>
              <GridItem cols={5} mb="small">
                <InputField<IWhatsAppAspClientInput>
                  name="companyLegalName"
                  label={t.whatsapp.aspClients.fields.businessLegalName()}
                  tooltip={t.whatsapp.aspClients.fields.businessLegalNameTooltip()}
                  id="company-legal-name-input"
                  maxLength={`${aspClientFieldsMaxLength.companyLegalName}`}
                  required
                />
              </GridItem>
              <GridItem cols={5}>
                <InputField<IWhatsAppAspClientInput>
                  name="facebookBusinessManagerId"
                  label={t.whatsapp.aspClients.fields.facebookBusinessManagerID()}
                  tooltip={t.whatsapp.aspClients.fields.facebookBusinessManagerIDTooltip()}
                  id="business-manager-id-input"
                  maxLength={`${aspClientFieldsMaxLength.facebookBusinessManagerId}`}
                  required
                />
              </GridItem>
            </Grid>
          </Section>
        </Card>
      </GridItem>
    </Grid>
  );
};
