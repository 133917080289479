import React from 'react';
import styled from 'styled-components';
import { PhoneFrame, ViberBusinessPreview } from '@sinch/viber-bot-preview';

import {
  Card,
  ChangeRequestInfoSection,
  Grid,
  GridItem,
  StickyGridItem,
} from '@src/common/components';
import { t } from '@src/messages';
import { IExtraDoc, IViberAspClient } from '@shared/bff';
import { useFeatureFlags } from '@src/common/utils/hooks';

import { AccountInformationSection } from '../AccountInformationSection';
import {
  ICrFragment,
  ICrSenderDataFragment,
  IViberSenderFragment,
} from '../../graphql';
import { getLogoToPreview } from '../../utils';

import { ContactInformationSection } from './ContactInformationSection';
import { ChannelDesignSection } from './ChannelDesignSection';
import { ExtraDocumentsSection } from './ExtraDocumentsSection';
import { SampleMessagesSection } from './SampleMessagesSection';
import { ChannelConfigurationSection } from './ChannelConfigurationSection';

interface ISenderDetailsProps {
  aspClient: IViberAspClient;
  sender: IViberSenderFragment;
  cr?: ICrFragment;
  showCurrent: boolean;
}

const CardWrapper = styled(Card)({
  overflowX: 'auto',
});

export const SenderDetails = ({
  aspClient,
  sender,
  cr,
  showCurrent,
}: ISenderDetailsProps) => {
  const { extraDocuments } = useFeatureFlags();

  const hasCrValues = cr !== undefined;
  let senderCr: ICrSenderDataFragment;

  if (hasCrValues) {
    senderCr = cr!.sender;
  }

  return (
    <>
      <GridItem>
        <Grid>
          <GridItem cols={8} pr="small">
            <Grid>
              {cr && (
                <GridItem mb="small">
                  <ChangeRequestInfoSection cr={cr} />
                </GridItem>
              )}
              <GridItem mb="small">
                <AccountInformationSection aspClient={aspClient} />
              </GridItem>
              <GridItem mb="small">
                <ChannelConfigurationSection
                  values={{
                    name: sender.name,
                    destinationCountries: sender.destinationCountries,
                    businessLaunchDate: sender.businessLaunchDate,
                    state: sender.state,
                  }}
                  crValues={
                    hasCrValues
                      ? {
                          name: senderCr!.name,
                          destinationCountries: senderCr!.destinationCountries,
                          businessLaunchDate: senderCr!.businessLaunchDate,
                        }
                      : undefined
                  }
                  showCurrent={showCurrent}
                />
              </GridItem>
              <GridItem mb="small">
                <ChannelDesignSection
                  values={{
                    logoUrls: sender.logoUrls,
                  }}
                  crValues={
                    hasCrValues
                      ? {
                          logoUrls: senderCr!.logoUrls,
                        }
                      : undefined
                  }
                  showCurrent={showCurrent}
                />
              </GridItem>
              <GridItem mb="small">
                <ContactInformationSection
                  values={{
                    description: sender.description,
                    address: sender.address,
                    phoneNumber: sender.phoneNumber,
                    website: sender.website,
                  }}
                  crValues={
                    hasCrValues
                      ? {
                          description: senderCr!.description,
                          address: senderCr!.address,
                          phoneNumber: senderCr!.phoneNumber,
                          website: senderCr!.website,
                        }
                      : undefined
                  }
                  showCurrent={showCurrent}
                />
              </GridItem>
              <GridItem mb="small">
                <SampleMessagesSection
                  values={{
                    sampleMessage: sender.sampleMessage,
                  }}
                  crValues={
                    hasCrValues
                      ? {
                          sampleMessage: senderCr!.sampleMessage,
                        }
                      : undefined
                  }
                  showCurrent={showCurrent}
                />
              </GridItem>
              {extraDocuments && (
                <GridItem>
                  <ExtraDocumentsSection
                    values={{
                      extraDocs: sender.extraDocs ?? [],
                    }}
                    crValues={
                      hasCrValues
                        ? {
                            extraDocs: senderCr!.extraDocs as IExtraDoc[],
                          }
                        : undefined
                    }
                    showCurrent={showCurrent}
                  />
                </GridItem>
              )}
            </Grid>
          </GridItem>
          <StickyGridItem cols={4}>
            <CardWrapper
              heading={t.common.components.businessProfilePreviewer.heading()}
            >
              <PhoneFrame>
                <ViberBusinessPreview
                  input={{
                    name: sender.name,
                    about: sender.description,
                    address: sender.address,
                    phoneNumber: sender.phoneNumber,
                    website: sender.website,
                    photoUrl: getLogoToPreview(sender.logoUrls, 360),
                  }}
                />
              </PhoneFrame>
            </CardWrapper>
          </StickyGridItem>
        </Grid>
      </GridItem>
    </>
  );
};
