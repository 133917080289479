import { gql } from '@apollo/client';

import { IViberSender } from '@shared/bff';

import {
  ISenderListItemCrFragment,
  SENDER_LIST_ITEM_CR_FRAGMENT,
} from './SenderListItemCrFragment';

export type IViberSenderFragment = Pick<
  IViberSender,
  | 'id'
  | 'aspClientId'
  | 'name'
  | 'destinationCountries'
  | 'businessLaunchDate'
  | 'logoUrls'
  | 'description'
  | 'address'
  | 'phoneNumber'
  | 'website'
  | 'sampleMessage'
  | 'state'
  | 'extraDocs'
> & { cr: ISenderListItemCrFragment | null };

export const VIBER_SENDER_FRAGMENT = gql`
  fragment ViberSenderFragment on ViberSender {
    id
    aspClientId
    name
    destinationCountries
    businessLaunchDate
    logoUrls {
      url
      name
      size {
        width
        height
      }
    }
    description
    address
    phoneNumber
    website
    sampleMessage
    state
    extraDocs {
      url
      name
      type
      permanent
      contentLength
    }
    cr {
      ...SenderListItemCrFragment
    }
  }
  ${SENDER_LIST_ITEM_CR_FRAGMENT}
`;
