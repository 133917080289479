import React from 'react';
import styled from 'styled-components';
import { Button } from '@salesforce/design-system-react';

import { SFIcon } from '@src/common/components';

interface IRemoveButtonIconProps {
  handleRemove: () => void;
  marginTop?: number;
}

export const RemoveButtonIcon = ({
  handleRemove,
  marginTop = 24,
}: IRemoveButtonIconProps) => {
  const ButtonWrapper = styled(Button)({
    width: 25,
    marginTop,
  });

  return (
    <ButtonWrapper variant="neutral" onClick={handleRemove}>
      <SFIcon name="close" size="x-small" />
    </ButtonWrapper>
  );
};
