import React, { useMemo } from 'react';
import { t } from 'messages';

import {
  Card,
  Box,
  DescriptionList,
  IDescriptionListItem,
  ComparisonValues,
} from 'common/components';

import { ISectionProps } from '../types';

interface IContactInfoSectionData {
  address: string;
  email: string;
  website: string;
  additionalWebsite: string;
}

interface IContactInfoSectionProps extends ISectionProps {
  values: IContactInfoSectionData;
  comparisonValues?: IContactInfoSectionData;
}

export const ContactInfoSection = ({
  values,
  comparisonValues,
  showDiff = false,
}: IContactInfoSectionProps) => {
  const items = useMemo<IDescriptionListItem[]>(
    () => [
      {
        label: t.whatsapp.fieldLabels.address(),
        detail: (
          <ComparisonValues
            id="address"
            value={values.address}
            comparisonValue={comparisonValues?.address}
            showDiff={showDiff && values.address !== comparisonValues?.address}
          />
        ),
      },
      {
        label: t.whatsapp.fieldLabels.email(),
        detail: (
          <ComparisonValues
            id="email"
            value={values.email}
            comparisonValue={comparisonValues?.email}
            showDiff={showDiff && values.email !== comparisonValues?.email}
          />
        ),
      },
      {
        label: t.whatsapp.fieldLabels.websiteOne(),
        detail: (
          <ComparisonValues
            id="website"
            value={values.website}
            comparisonValue={comparisonValues?.website}
            showDiff={showDiff && values.website !== comparisonValues?.website}
          />
        ),
      },
      {
        label: t.whatsapp.fieldLabels.websiteTwo(),
        detail: (
          <ComparisonValues
            id="additional-website"
            value={values.additionalWebsite}
            comparisonValue={comparisonValues?.additionalWebsite}
            showDiff={
              showDiff &&
              values.additionalWebsite !== comparisonValues?.additionalWebsite
            }
          />
        ),
      },
    ],
    [showDiff]
  );
  return (
    <Card heading={t.common.contactInformation()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
