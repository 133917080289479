import React from 'react';

import { Card, Box, ImageUploader } from '@src/common/components';
import { t } from '@src/messages';

const maxImageSize = 5000000; //5MB
const FIELD_PHOTO_URL = 'photoUrl';

export const Design = () => {
  return (
    <>
      <Card heading={t.common.channelDesign()}>
        <Box pl="small" pr="small">
          <ImageUploader
            label={t.whatsapp.fieldLabels.brandLogo()}
            fieldName={FIELD_PHOTO_URL}
            tooltip={{
              label: t.whatsapp.tooltips.brandLogo(),
              id: 'brand-logo-image-tooltip',
            }}
            modalLabels={{
              title: t.whatsapp.botForm.upload.logoModalTitle(),
              description:
                t.common.components.imageResolver.resolverDescription.bot(),
            }}
            maxImageSize={maxImageSize}
          />
        </Box>
      </Card>
    </>
  );
};
