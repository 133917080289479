import React from 'react';

import { GridItem, Card, InputField } from '@src/common/components';
import { t } from '@src/messages';
import { IWhatsAppBotInput } from '@shared/bff';
import { useFeatureFlags } from '@src/common/utils/hooks';

import { botFieldsMaxLength } from '../../../utils';

export const Contact = () => {
  const { whatsAppChannelMakeCompanyDetailsOptional } = useFeatureFlags();

  return (
    <Card heading={t.common.contactInformation()}>
      <GridItem cols={8} pl="small" pr="small">
        <GridItem mb="small">
          <InputField<IWhatsAppBotInput>
            id="address-input"
            name="address"
            label={t.whatsapp.fieldLabels.address()}
            tooltip={t.whatsapp.tooltips.contactInfoAddress()}
            maxLength={`${botFieldsMaxLength.address}`}
            required={!whatsAppChannelMakeCompanyDetailsOptional}
          />
        </GridItem>
        <GridItem mb="small">
          <InputField<IWhatsAppBotInput>
            id="email-input"
            name="email"
            label={t.whatsapp.fieldLabels.email()}
            maxLength={`${botFieldsMaxLength.email}`}
            required={!whatsAppChannelMakeCompanyDetailsOptional}
          />
        </GridItem>
        <GridItem mb="small">
          <InputField<IWhatsAppBotInput>
            id="website-one-input"
            name="websiteOne"
            label={t.whatsapp.fieldLabels.websiteOne()}
            maxLength={`${botFieldsMaxLength.websiteOne}`}
            required={!whatsAppChannelMakeCompanyDetailsOptional}
          />
        </GridItem>
        <GridItem mb="small">
          <InputField<IWhatsAppBotInput>
            id="website-two-input"
            name="websiteTwo"
            label={t.whatsapp.fieldLabels.websiteTwoOptional()}
            maxLength={`${botFieldsMaxLength.websiteTwo}`}
          />
        </GridItem>
      </GridItem>
    </Card>
  );
};
