import { gql } from '@apollo/client';

import { IEmailNotificationsSettingsResponse } from '@shared/bff';

export interface IEmailNotificationsSettingsQueryResponse {
  emailNotificationsSettings: IEmailNotificationsSettingsResponse;
}

export const EMAIL_NOTIFICATIONS_SETTINGS_QUERY = gql`
  query EmailNotificationsSettings {
    emailNotificationsSettings {
      email
    }
  }
`;
