import { gql } from '@apollo/client';

import { IAspClientsQueryResponse } from '@src/modules/whatsapp/aspClients/graphql';
import { IWhatsAppCr, IWhatsAppBot } from '@shared/bff';

import {
  LIST_CR_BOT_DATA_FRAGMENT,
  BOT_LIST_ITEM_CR_FRAGMENT,
} from '../../fragments';

export interface IBotCrsQueryResponse {
  whatsAppCrs: IBotCrListItemFragment[];
}

export type IBotCrListItemFragment = Pick<
  IWhatsAppCr,
  'id' | 'state' | 'botId' | 'updated'
> & {
  bot: Pick<IWhatsAppBot, 'name'>;
};

export interface IBotsQueryResponse {
  whatsAppBots: IBotListItemFragment[];
}

export type IBotListItemFragment = Pick<
  IWhatsAppBot,
  'id' | 'name' | 'state' | 'quality' | 'dailyLimit'
>;

export type IChannelsQueryResponse = IBotsQueryResponse &
  IBotCrsQueryResponse &
  IAspClientsQueryResponse;

export const CHANNELS_QUERY = gql`
  query ChannelsQuery($whatsAppBotsSort: BotSort, $whatsAppCrsSort: BotCrSort) {
    whatsAppBots(filter: null, sort: $whatsAppBotsSort) {
      id
      name
      state
      updated
      botCr {
        ...BotListItemCrFragment
      }
      quality
      dailyLimit
    }
    whatsAppCrs(filter: null, sort: $whatsAppCrsSort) {
      id
      botId
      state
      updated
      bot {
        ...ListCrBotDataFragment
      }
    }
    whatsAppAspClients {
      onboarded {
        id
      }
    }
  }
  ${LIST_CR_BOT_DATA_FRAGMENT}
  ${BOT_LIST_ITEM_CR_FRAGMENT}
`;
