import React from 'react';

import {
  QueryHandler,
  IQueryHandlerResult,
  ChannelsList,
} from '@src/common/components';
import { Channel } from '@src/common/types';
import { BotSortField, SortOrder } from '@shared/bff';

import { CHANNELS_QUERY, IChannelsQueryResponse } from '../graphql';

export const ChannelHome = () => {
  return (
    <QueryHandler
      query={CHANNELS_QUERY}
      variables={{
        whatsAppBotsSort: {
          sortField: BotSortField.BY_MODIFIED_DATE,
          sortOrder: SortOrder.DESCENDING,
        },
        whatsAppCrsSort: {
          sortField: BotSortField.BY_MODIFIED_DATE,
          sortOrder: SortOrder.DESCENDING,
        },
      }}
      levitate
    >
      {({
        data: {
          whatsAppAspClients: { onboarded },
          whatsAppBots,
          whatsAppCrs,
        },
      }: IQueryHandlerResult<IChannelsQueryResponse>) => {
        return (
          <ChannelsList
            channelType={Channel.Whatsapp}
            needToRegisterAccount={onboarded.length === 0}
            pendingChannels={whatsAppCrs}
            onboardedChannels={whatsAppBots}
          />
        );
      }}
    </QueryHandler>
  );
};
