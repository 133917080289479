import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Card, Text, Box } from '@src/common/components';

const Wrapper = styled(Card)({
  padding: 0,
  '& .slds-card__body': {
    padding: 0,
    margin: 0,
  },
});

const IllustrationWrapper = styled(Box)({
  background: '#f2f2f2',
});

const PromoContent = styled.div({
  padding: '0.75rem 1rem 0',
  margin: '0 0 0.75rem',
});

interface IPromoCardProps {
  image?: string;
  illustration?: ReactNode;
  heading?: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
}

export const PromoCard = ({
  image,
  illustration,
  heading,
  description,
  actions,
}: IPromoCardProps) => (
  <Wrapper hasNoHeader>
    <>
      {image && <img src={image} alt="Card promo image" />}
      {illustration && (
        <IllustrationWrapper pl="medium" pr="medium" pt="medium">
          {illustration}
        </IllustrationWrapper>
      )}
      <PromoContent>
        <Text
          variant="heading_small"
          weight="bold"
          align="center"
          mt="xx-small"
          mb="x-small"
        >
          {heading}
        </Text>
        <Box mb="medium">
          <Text variant="body_regular" align="center" color="weak">
            {description}
          </Text>
        </Box>
        <Box display="flex" justifyContent="center" mb="medium">
          {actions}
        </Box>
      </PromoContent>
    </>
  </Wrapper>
);
