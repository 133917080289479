import { t } from 'messages';

import { uuid } from '@src/common/utils';
import { WhatsAppMtLanguage } from '@shared/bff';

export interface ITemplateLanguageOption {
  value: WhatsAppMtLanguage;
  label: string;
}

const languages = t.whatsapp.messageTemplates.templateLanguageLabels;

export const prepareOptionsForTemplateLanguages =
  (): ITemplateLanguageOption[] => {
    const languageKeys = Object.keys(languages);

    return languageKeys
      .map((key) => ({
        id: uuid(),
        value: key as WhatsAppMtLanguage,
        label: languages[key as WhatsAppMtLanguage](),
      }))
      .filter(({ value }) => value !== WhatsAppMtLanguage.NO_LANGUAGE);
  };
