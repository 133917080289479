import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { Box, DescriptionList, Image, SFIcon } from '@src/common/components';
import { t } from '@src/messages';
import { FileTypeMapper, getFileName } from '@src/common/utils';
import { IWhatsAppMtMediaVariable, WhatsAppMtMediaType } from '@shared/bff';

interface ISamplesDetailsProps {
  mediaVariable?: IWhatsAppMtMediaVariable | null;
  mediaType?: WhatsAppMtMediaType;
  headerVariable?: string | null;
  bodyVariables?: string[] | null;
  buttonUrlVariable?: string | null;
}

interface IDescriptionField {
  label: ReactNode;
  detail: ReactNode;
  id?: string;
}

const FileName = styled.a({
  maxWidth: '300px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const SamplesDetails = ({
  mediaVariable,
  mediaType,
  headerVariable,
  bodyVariables,
  buttonUrlVariable,
}: ISamplesDetailsProps) => {
  const fields: IDescriptionField[] = useMemo(
    () => [
      ...(mediaVariable && mediaVariable.type
        ? [
            {
              label: t.whatsapp.messageTemplates.fields.headerMediaSample(),
              detail: (
                <Box display="inline-flex" alignItems="center">
                  {mediaType === WhatsAppMtMediaType.IMAGE ? (
                    <>
                      <Image
                        src={mediaVariable.url}
                        width={`${(5 / 3) * 100}px`}
                        height="100px"
                      />
                    </>
                  ) : (
                    <>
                      {FileTypeMapper[mediaVariable.type] && (
                        <SFIcon name={FileTypeMapper[mediaVariable.type]} />
                      )}
                      <Box display="inline" ml="x-small" />
                      <FileName href={mediaVariable.url}>
                        {getFileName(mediaVariable.url)}
                      </FileName>
                    </>
                  )}
                </Box>
              ),
            },
          ]
        : []),
      ...(mediaVariable && !mediaVariable.type
        ? [
            {
              label: t.whatsapp.messageTemplates.fields.headerMediaSample(),
              detail: mediaVariable.type ? (
                ''
              ) : (
                <Box display="inline-flex" alignItems="center">
                  {mediaType === WhatsAppMtMediaType.IMAGE ? (
                    <>
                      <Image
                        src={mediaVariable.url}
                        width="100px"
                        height="100px"
                      />
                    </>
                  ) : (
                    ''
                  )}
                </Box>
              ),
            },
          ]
        : []),
      ...(headerVariable || headerVariable === ''
        ? [
            {
              label: `${t.whatsapp.messageTemplates.fields.headerVariable()} {{1}}`,
              detail: headerVariable,
              id: 'header-variable',
            },
          ]
        : []),
      ...(bodyVariables
        ? bodyVariables.map((value, index) => ({
            label: `${t.whatsapp.messageTemplates.fields.bodyVariable()} {{${
              index + 1
            }}}`,
            detail: value,
            id: `body-variable-${index}`,
          }))
        : []),
      ...(buttonUrlVariable || buttonUrlVariable === ''
        ? [
            {
              label: `${t.whatsapp.messageTemplates.fields.buttonDynamicUrlVariable()} {{1}}`,
              detail: buttonUrlVariable,
              id: 'button-url-variable',
            },
          ]
        : []),
    ],
    [headerVariable, bodyVariables, buttonUrlVariable]
  );
  return (
    <Box pl="medium" pr="medium" mt="large">
      <DescriptionList items={fields} />
    </Box>
  );
};
