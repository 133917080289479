import React from 'react';
import styled from 'styled-components';
import { orderBy } from 'lodash-es';

import { Box, Grid, GridItem } from '@src/common/components';
import { t } from '@src/messages';
import { ILogoUrl, ISize } from '@shared/bff';

interface ILogoDisplayerProps {
  logoUrls: ILogoUrl[];
  highlighted?: boolean;
}

const LogoImage = styled.img(({ width, height }: ISize) => ({
  width,
  height,
  marginRight: '1rem',
}));

const HighlightedLink = styled.div({
  backgroundColor: '#F6FFBB',
});

const UrlLink = styled.a({
  color: '#0376B0',
  display: 'inline-block',
  overflow: 'hidden',
});

const SizeInfo = styled.div({
  color: '#858C91',
});

const SCALE_FACTOR = 0.4;

export const LogoDisplayer = ({
  logoUrls,
  highlighted,
}: ILogoDisplayerProps) => {
  const sortedLogoUrls = orderBy(logoUrls, (item) => item.size.width, ['asc']);
  return (
    <Grid>
      {sortedLogoUrls.map(({ url, size, name }) => {
        const shouldScale = size.width === 360;
        const height = shouldScale ? size.height * SCALE_FACTOR : size.height;
        const width = shouldScale ? size.width * SCALE_FACTOR : size.width;

        const urlLink = (
          <UrlLink href={url} target="_blank">
            {name}
          </UrlLink>
        );

        const scaleText = shouldScale
          ? ` ${t.viberBusiness.channels.fields.scaled()}`
          : '';

        return (
          <GridItem mb="x-small">
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <LogoImage src={url} height={height} width={width} />
              <div>
                {highlighted ? (
                  <HighlightedLink>{urlLink}</HighlightedLink>
                ) : (
                  urlLink
                )}
                <SizeInfo>{`${size.width}x${size.height}px${scaleText}`}</SizeInfo>
              </div>
            </Box>
          </GridItem>
        );
      })}
    </Grid>
  );
};
