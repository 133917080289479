import React, { useState } from 'react';
import { Tabs, TabsPanel } from '@salesforce/design-system-react';
import styled from 'styled-components';

import {
  Box,
  Button,
  Card,
  DocumentationLink,
  Grid,
  GridItem,
  Illustration,
  PageHeader,
  ScopedNotification,
  Text,
} from '@src/common/components';
import { useFeatureFlags } from '@src/common/utils/hooks';
import { CONTACT_EMAIL } from '@src/common/utils';
import { t } from '@src/messages';
import { ViberLogo, WhatsAppLogo } from '@src/assets';

import {
  EnterpriseDetails,
  PromoCard,
  SetupNotificationsCard,
  ViberSetup,
  WhatsAppSetup,
} from '../components';

const Footer = styled(Card)({
  padding: '0 0 0.25rem 0.25rem',
  marginTop: '0.75rem !important',
});

const CustomTabsCard = styled(Card)({
  '& .slds-card__body': {
    marginTop: 0,
  },
});

const CustomTabs = styled(Tabs)({
  '& .slds-show, & .slds-tabs_default__content, & .slds-tabs--default__content':
    {
      padding: '0',
    },
});

const handleContactClick = () => {
  // Not a file operation
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  window.open(`mailto: ${CONTACT_EMAIL}`);
};

export const HomeView = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const { viberBusiness, emailNotifications } = useFeatureFlags();

  return (
    <Grid>
      <GridItem mb="small">
        <PageHeader title={t.common.home()} />
      </GridItem>

      <GridItem mb="small">
        <Card
          heading={t.home.heading()}
          description={
            <GridItem mb="xx-small">{t.home.description()}</GridItem>
          }
          type="darken"
        />
      </GridItem>
      <GridItem cols={9}>
        {new Date() < new Date('2023-05-01') && (
          <Box pb="medium">
            <ScopedNotification variant="warning">
              {t.home.newCategories.description()}
              <br />
              {t.home.newCategories.secondDescription()}
              <br />
              {t.home.newCategories.thirdDescription()}
            </ScopedNotification>
          </Box>
        )}

        {emailNotifications && <SetupNotificationsCard />}
        <CustomTabsCard hasNoHeader>
          <CustomTabs
            selectedIndex={selectedTabIndex}
            onSelect={(index) => {
              setSelectedTabIndex(index);
            }}
          >
            {viberBusiness && (
              <TabsPanel
                label={
                  <Box display="inline-flex" alignItems="center">
                    <ViberLogo />
                    <Text ml="x-small">
                      {t.common.channelNames.ViberBusiness()}
                    </Text>
                  </Box>
                }
              >
                <ViberSetup></ViberSetup>
              </TabsPanel>
            )}
            <TabsPanel
              label={
                <Box display="inline-flex" alignItems="center">
                  <WhatsAppLogo />
                  <Text ml="x-small">{t.common.channelNames.Whatsapp()}</Text>
                </Box>
              }
            >
              <WhatsAppSetup></WhatsAppSetup>
            </TabsPanel>
          </CustomTabs>
        </CustomTabsCard>
        <Footer
          type="darken"
          hasNoHeader
          description={
            <span>
              {t.home.suffix.heading()}
              <Box display="inline" ml="x-small" />
              {/* temporary link */}
              <a
                target="_blank"
                href="https://help.salesforce.com/articleView?id=mc_overview_marketing_cloud.htm"
              >
                {t.home.suffix.link()}
              </a>
            </span>
          }
        />
      </GridItem>
      <GridItem cols={3} pl="small">
        <Card
          heading={t.home.enterpriseDetails.heading()}
          description={<EnterpriseDetails />}
        />
        <PromoCard
          illustration={<Illustration type="hero" size="small" />}
          heading={t.home.promoCards.baloon.heading()}
          description={t.home.promoCards.baloon.description()}
          actions={
            <Button id="wa-documentation">
              <DocumentationLink>
                {t.home.promoCards.baloon.link()}
              </DocumentationLink>
            </Button>
          }
        />
        <PromoCard
          illustration={<Illustration type="contact" size="small" />}
          heading={t.home.promoCards.fruit.heading()}
          description={t.home.promoCards.fruit.description()}
          actions={
            <Button onClick={handleContactClick}>
              {t.home.promoCards.fruit.link()}
            </Button>
          }
        />
      </GridItem>
    </Grid>
  );
};
