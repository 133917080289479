import React from 'react';
import { Input } from '@salesforce/design-system-react';

import { Box, EmptySection, Section, Text } from '@src/common/components';
import { t } from '@src/messages';

import { EditContentPayload } from '../../types';

import { ExistingMediaVariable } from './ExistingMediaVariable';

interface ISamplesProps {
  content: EditContentPayload;
}

export const ExistingSamples = ({ content }: ISamplesProps) => {
  const hasHeaderVariable = content.headerVariable;
  const hasMessageBodyVariable = content.bodyVariables;
  const hasMediaHeader = content.mediaVariable;
  const hasButtonUrlVariable = content.buttonUrlVariable;
  const hasVariables =
    hasHeaderVariable ||
    hasMessageBodyVariable ||
    hasMediaHeader ||
    hasButtonUrlVariable;
  return (
    <Section>
      <Text variant="body" mb="medium">
        {t.whatsapp.messageTemplates.register.helpTexts.samples()}
      </Text>
      {!hasVariables && (
        <EmptySection
          type="no-templates"
          title={t.whatsapp.messageTemplates.noVariables()}
        />
      )}
      {content.mediaVariable && (
        <ExistingMediaVariable
          mediaType={content.mediaType}
          mediaVariable={content.mediaVariable}
        />
      )}
      {content.headerVariable && (
        <Box mb="small">
          <Input
            label={`${t.whatsapp.messageTemplates.fields.headerVariable()} {{1}}`}
            required
            disabled
            value={content.headerVariable}
          />
        </Box>
      )}
      {content.bodyVariables &&
        content.bodyVariables.map((value, index) => (
          <Box mb="small">
            <Input
              label={`${t.whatsapp.messageTemplates.fields.bodyVariable()} ${
                index + 1
              }`}
              required
              disabled
              value={value}
            />
          </Box>
        ))}
      {content.buttonUrlVariable && (
        <Box mb="small">
          <Input
            label={`${t.whatsapp.messageTemplates.fields.buttonDynamicUrlVariable()} {{1}}`}
            required
            disabled
            value={content.buttonUrlVariable}
          />
        </Box>
      )}
    </Section>
  );
};
