import React from 'react';
import { Checkbox, Combobox, Input } from '@salesforce/design-system-react';

import { Box, Grid, GridItem, Text } from '@src/common/components';
import { t } from '@src/messages';
import { WhatsAppMtOtpType } from '@shared/bff';
import { languageDirection } from '@src/common/utils';

import { EditAuthenticationContentPayload } from '../../types';

interface IExistingAuthenticationContentProps {
  content: EditAuthenticationContentPayload;
}

export const ExistingAuthenticationContent = ({
  content,
}: IExistingAuthenticationContentProps) => {
  const {
    language,
    securityRecommendation,
    codeExpirationMinutes,
    otpType,
    buttonText,
    autofillText,
    packageName,
    signatureHash,
  } = content;

  const isRtlLanguage = languageDirection(language) === 'rtl';

  return (
    <Box pl="medium" pr="medium" mt="large">
      <Text variant="heading_small" weight="bold" mt="large">
        {t.whatsapp.messageTemplates.templateLanguageLabels[content.language]()}{' '}
        {t.whatsapp.messageTemplates.register.sections.messageContent()}
      </Text>
      <Grid flow="vertical">
        <GridItem cols={10} pr="small" pt="small">
          <Text variant="body">
            {t.whatsapp.messageTemplates.fields.messageBody()}
          </Text>
          <Checkbox
            labels={{
              label: `${t.whatsapp.messageTemplates.fields.securityRecommendation()} ${t.common.optional()}`,
            }}
            checked={securityRecommendation ?? false}
            disabled
          />
        </GridItem>
      </Grid>
      <Grid flow="vertical">
        <GridItem cols={6} pr="small" pt="small">
          <Text variant="body">
            {t.whatsapp.messageTemplates.fields.footer()}
          </Text>
          <Input
            label={`${t.whatsapp.messageTemplates.fields.codeExpirationMinutes()} ${t.common.optional()}`}
            type="number"
            variant="counter"
            value={codeExpirationMinutes || ''}
            disabled
          />
        </GridItem>
      </Grid>
      <Grid flow="vertical">
        <GridItem cols={6} mt="small">
          <Text variant="body">
            {t.whatsapp.messageTemplates.fields.button()}
          </Text>
          <Combobox
            labels={{
              label: t.whatsapp.messageTemplates.fields.buttonType(),
            }}
            variant="readonly"
            required
            singleInputDisabled
            selection={[
              {
                label:
                  t.whatsapp.messageTemplates.authenticationTemplateButtonLabels[
                    otpType
                  ](),
                value: otpType,
                id: otpType,
              },
            ]}
          />
        </GridItem>
      </Grid>
      {content.otpType === WhatsAppMtOtpType.ONE_TAP && (
        <Box>
          <Box mt="small" />
          <Input
            label={t.whatsapp.messageTemplates.fields.autofillText()}
            value={autofillText || ''}
            className={isRtlLanguage ? 'rtl' : ''}
            disabled
          />
          <Box mt="small" />
          <Input
            label={t.whatsapp.messageTemplates.fields.packageName()}
            value={packageName || ''}
            className={isRtlLanguage ? 'rtl' : ''}
            disabled
          />
          <Box mt="small" />
          <Input
            label={t.whatsapp.messageTemplates.fields.signatureHash()}
            value={signatureHash || ''}
            className={isRtlLanguage ? 'rtl' : ''}
            disabled
          />
        </Box>
      )}
      <Box mt="small" />
      <Input
        label={t.whatsapp.messageTemplates.fields.copyCodeButtonText()}
        value={buttonText || ''}
        className={isRtlLanguage ? 'rtl' : ''}
        disabled
      />
    </Box>
  );
};
