import { pullAt } from 'lodash-es';

export const extractIndex = (variable: string) =>
  parseInt(variable.replace(/[^-\d]/g, ''), 10);

export const createRegex = (index: number) => `{\\{${index}}\\}`;

export const createVariable = (count?: number) => {
  count = count ?? 1;
  return `{{${count}}}`;
};

const VARIABLE_REGEX = /{{(-?\d+)\}}/g;

export const correctTextWithVariables = (
  text: string,
  origBodyVariables: string[] | null | undefined
) => {
  let bodyVariables: string[] | undefined = [...(origBodyVariables ?? [])];
  const variables = text.match(VARIABLE_REGEX) || [];
  const indexes = variables
    .map((variable) => extractIndex(variable))
    .sort((a, b) => a - b)
    .filter((item, index, array) => array.indexOf(item) === index);
  const shouldReindex =
    Math.max(...indexes) > indexes.length ||
    indexes.some((index) => index <= 0);
  const variablesCount = indexes.length;

  if (!shouldReindex) {
    if (!bodyVariables) {
      bodyVariables = [];
      for (let i = 0; i < variablesCount; i++) {
        if (!bodyVariables[i]) {
          bodyVariables[i] = '';
        }
      }
    } else {
      if (bodyVariables.length > variablesCount) {
        bodyVariables.forEach((_, index) => {
          if (!indexes[index]) {
            pullAt(bodyVariables!, [index]);
          }
        });
      } else if (bodyVariables.length < variablesCount) {
        for (let i = 0; i < variablesCount; i++) {
          if (!bodyVariables[i]) {
            bodyVariables[i] = '';
          }
        }
      }
    }

    if (bodyVariables.length === 0) {
      bodyVariables = undefined;
    }

    return {
      correctedText: text.trimRight(),
      variablesCount,
      newBodyVariables: bodyVariables,
    };
  }

  const bodyVariablesMap: { [key: number]: string } = {};
  bodyVariables?.forEach((value, index) => {
    bodyVariablesMap[index + 1] = value;
  });

  const newBodyVariables: string[] = [];
  const variablesMap = variables.reduce(
    (acc: Record<string, string>, variable) => {
      const variableIndex = extractIndex(variable);
      const correctIndex = indexes.indexOf(variableIndex) + 1;

      acc[`{{${variableIndex}}}`] = `{{${correctIndex}}}`;

      if (bodyVariablesMap) {
        if (bodyVariablesMap[variableIndex]) {
          newBodyVariables[correctIndex - 1] = bodyVariablesMap[variableIndex];
        } else {
          newBodyVariables[correctIndex - 1] = '';
        }
      }

      return acc;
    },
    {}
  );
  const variablesToFix = Object.keys(variablesMap);
  const fixedVariables = Object.values(variablesMap);
  const variableRegexes = variablesToFix
    .map((variable) => {
      const variableNumber = extractIndex(variable);

      return createRegex(variableNumber);
    })
    .join('|');
  const correctedText = text.trimRight().replace(
    // There is no alternative RegExp syntax for this particular case, not a security concern tho.
    // eslint-disable-next-line security/detect-non-literal-regexp
    new RegExp(variableRegexes, 'g'),
    (variable: string) => fixedVariables[variablesToFix.indexOf(variable)]
  );

  return {
    correctedText,
    variablesCount,
    newBodyVariables,
  };
};
