import React from 'react';

import {
  Box,
  Card,
  ComparisonValues,
  DescriptionList,
  IDescriptionListItem,
} from '@src/common/components';
import { t } from '@src/messages';
import { IViberSender } from '@shared/bff';

interface IContactInformationSectionProps {
  crValues: Pick<
    IViberSender,
    'description' | 'address' | 'phoneNumber' | 'website'
  >;
  currentValues?: Pick<
    IViberSender,
    'description' | 'address' | 'phoneNumber' | 'website'
  >;
  showCurrent: boolean;
}

export const ContactInformationSection = ({
  crValues,
  currentValues,
  showCurrent,
}: IContactInformationSectionProps) => {
  const items: IDescriptionListItem[] = [
    {
      label: t.viberBusiness.channels.fields.description(),
      detail: (
        <ComparisonValues
          id="description"
          value={crValues.description}
          comparisonValue={currentValues?.description}
          showDiff={
            showCurrent && crValues.description !== currentValues?.description
          }
        />
      ),
    },
    {
      label: t.viberBusiness.channels.fields.address(),
      detail: (
        <ComparisonValues
          id="address"
          value={crValues.address}
          comparisonValue={currentValues?.address}
          showDiff={showCurrent && crValues.address !== currentValues?.address}
        />
      ),
    },
    {
      label: t.viberBusiness.channels.fields.phoneNumber(),
      detail: (
        <ComparisonValues
          id="phone-number"
          value={crValues.phoneNumber}
          comparisonValue={currentValues?.phoneNumber}
          showDiff={
            showCurrent && crValues.phoneNumber !== currentValues?.phoneNumber
          }
        />
      ),
    },
    {
      label: t.viberBusiness.channels.fields.website(),
      detail: (
        <ComparisonValues
          id="website"
          value={crValues.website}
          comparisonValue={currentValues?.website}
          showDiff={showCurrent && crValues.website !== currentValues?.website}
        />
      ),
    },
  ];
  return (
    <Card heading={t.common.contactInformation()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
