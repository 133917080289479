import { gql } from '@apollo/client';

import { IViberSender } from '@shared/bff';

export type ICrSenderDataFragment = Pick<
  IViberSender,
  | 'id'
  | 'name'
  | 'destinationCountries'
  | 'businessLaunchDate'
  | 'aspClientId'
  | 'logoUrls'
  | 'description'
  | 'address'
  | 'phoneNumber'
  | 'website'
  | 'sampleMessage'
  | 'extraDocs'
>;

export const CR_SENDER_DATA_FRAGMENT = gql`
  fragment CrSenderDataFragment on ViberSender {
    name
    destinationCountries
    businessLaunchDate
    aspClientId
    logoUrls {
      url
      name
      size {
        width
        height
      }
    }
    description
    address
    phoneNumber
    website
    sampleMessage
    extraDocs {
      url
      name
      type
      permanent
      contentLength
    }
  }
`;
