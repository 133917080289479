import * as Yup from 'yup';

export const getRequestValidationSchema = () =>
  Yup.object().shape({
    profileId: Yup.string().required(),
    channelName: Yup.string().required(),
    phoneNumber: Yup.string().required(),
    topLevelCategory: Yup.string().required(),
    midLevelCategory: Yup.string().required(),
    subLevelCategory: Yup.string().required(),
  });

export const getDraftValidationSchema = () =>
  Yup.object().shape({
    profileId: Yup.string().required(),
  });
