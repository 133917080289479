import { gql } from '@apollo/client';

import { IWhatsAppCr } from '@shared/bff';

export type ITransitionBotCrFromDraftToReviewMutationResponse = {
  whatsAppTransitionBotCrFromDraftToReview: Pick<IWhatsAppCr, 'id'>;
};

export const TRANSITION_BOT_CR_FROM_DRAFT_TO_REVIEW_MUTATION = gql`
  mutation WhatsAppTransitionBotCrFromDraftToReview($crId: String!) {
    whatsAppTransitionBotCrFromDraftToReview(crId: $crId) {
      id
    }
  }
`;
