import * as Yup from 'yup';

import {
  getPhoneNumberE164CountryCodeSchema,
  getHttpsSchema,
  getHttpHttpsSchema,
  phoneNumberE164CountryCodeRegex,
  subscriberPhoneNumberMaxLength,
  TEST_INVALID_SUBSCRIBER_NUMBER,
  isPhoneNumberE164,
} from '@src/common/utils';
import {
  IWhatsAppBotInput,
  NumberProviderType,
  WhatsAppVertical,
} from '@shared/bff';
import { t } from '@src/messages';
import { useFeatureFlags } from '@src/common/utils/hooks';

import { FIELD_NUMBER_PROVIDER_TYPE } from '../../components/form/PhoneInput';

import { botFieldsMaxLength } from './botFieldsMaxLength';

export const validVerticalTypes: WhatsAppVertical[] = [
  WhatsAppVertical.AUTOMOTIVE,
  WhatsAppVertical.BEAUTY_SPA_SALON,
  WhatsAppVertical.CLOTHING_AND_APPAREL,
  WhatsAppVertical.EDUCATION,
  WhatsAppVertical.ENTERTAINMENT,
  WhatsAppVertical.EVENT_PLANNING_AND_SERVICE,
  WhatsAppVertical.FINANCE_AND_BANKING,
  WhatsAppVertical.FOOD_AND_GROCERY,
  WhatsAppVertical.HOTEL_AND_LODGING,
  WhatsAppVertical.MEDICAL_AND_HEALTH,
  WhatsAppVertical.NON_PROFIT,
  WhatsAppVertical.OTHER,
  WhatsAppVertical.PROFESSIONAL_SERVICES,
  WhatsAppVertical.PUBLIC_SERVICE,
  WhatsAppVertical.RESTAURANT,
  WhatsAppVertical.SHOPPING_AND_RETAIL,
  WhatsAppVertical.TRAVEL_AND_TRANSPORTATION,
];

export const validNumberProviderTypes: NumberProviderType[] = [
  NumberProviderType.CUSTOMER,
  NumberProviderType.SINCH,
];

const getIgnoredPropertySchema = () => Yup.mixed().notRequired();
export const getNameSchema = () => Yup.string().max(botFieldsMaxLength.name);
const getDescriptionSchema = () =>
  Yup.string().max(botFieldsMaxLength.description);
const getAboutSchema = () => Yup.string().max(botFieldsMaxLength.about);
export const getVerticalSchema = () => Yup.string().oneOf(validVerticalTypes);
const getAddressSchema = () => Yup.string().max(botFieldsMaxLength.address);
export const getEmailSchema = () =>
  Yup.string().max(botFieldsMaxLength.email).email();
const getNumberProviderSchema = () =>
  Yup.string().oneOf(validNumberProviderTypes);
const testPhoneNumber = (countryCode: string, value: string) => {
  const isValidCountryCode =
    countryCode && phoneNumberE164CountryCodeRegex.test(countryCode);

  if (isValidCountryCode) {
    return isPhoneNumberE164(`${countryCode}${value}`);
  } else {
    return value.length >= 3 && value.length <= subscriberPhoneNumberMaxLength;
  }
};
export const getPhoneNumberSchema = () =>
  Yup.string()
    .required()
    .test(
      TEST_INVALID_SUBSCRIBER_NUMBER,
      t.common.validation.phoneNumber.e164(),
      function (value) {
        if (!value) {
          return false;
        }

        const countryCode = this.parent.countryCode;
        return testPhoneNumber(countryCode, value);
      }
    );
export const getOptionalPhoneNumberSchema = () =>
  Yup.string().test(
    TEST_INVALID_SUBSCRIBER_NUMBER,
    t.common.validation.phoneNumber.e164(),
    function (value) {
      if (!value) {
        return true;
      }

      const countryCode = this.parent.countryCode;
      if (!countryCode) {
        return true;
      }

      return testPhoneNumber(countryCode, value);
    }
  );
export const getWebsiteSchema = () =>
  getHttpsSchema().max(botFieldsMaxLength.websiteOne).url();

const getGeneralFieldRequirements = (): Record<
  keyof IWhatsAppBotInput,
  Yup.Schema<unknown>
> => {
  const { whatsAppChannelMakeCompanyDetailsOptional } = useFeatureFlags();

  return {
    id: Yup.string(),
    aspClientId: Yup.string().required(),
    name: getNameSchema().required(),
    description: whatsAppChannelMakeCompanyDetailsOptional
      ? getDescriptionSchema()
      : getDescriptionSchema().required(),
    photoUrl: getHttpHttpsSchema().required(),
    about: getAboutSchema().required(),
    vertical: getVerticalSchema().required(),
    address: whatsAppChannelMakeCompanyDetailsOptional
      ? getAddressSchema()
      : getAddressSchema().required(),
    email: whatsAppChannelMakeCompanyDetailsOptional
      ? getEmailSchema()
      : getEmailSchema().required(),
    websiteOne: whatsAppChannelMakeCompanyDetailsOptional
      ? getWebsiteSchema().nullable()
      : getWebsiteSchema().required(),
    websiteTwo: getWebsiteSchema().nullable(),
    state: getIgnoredPropertySchema(),
    countryCode: getPhoneNumberE164CountryCodeSchema().required(),
    phone: Yup.string().when(FIELD_NUMBER_PROVIDER_TYPE, {
      is: NumberProviderType.CUSTOMER,
      then: getPhoneNumberSchema(),
    }),
    numberProviderType: getNumberProviderSchema(),
  };
};

export const getRequestValidationSchema = () =>
  Yup.object().shape({
    ...getGeneralFieldRequirements(),
  });
