export const CALL_TO_ACTION_TYPE_FIELD_NAME = 'type';
export const HEADER_FIELD_NAME = 'headerType';
export const BUTTONS_TYPE_FIELD_NAME = 'buttonType';
export const BODY_TEXT_FIELD_NAME = 'bodyText';
export const HEADER_TEXT_FIELD_NAME = 'headerText';
export const MEDIA_TYPE_FIELD_NAME = 'mediaType';
export const CALL_TO_ACTION_BUTTONS_FIELD_NAME = 'callToActionButtons';
export const SECURITY_RECOMMENDATION_FIELD_NAME = 'securityRecommendation';
export const CODE_EXPIRATION_MINUTES_FIELD_NAME = 'codeExpirationMinutes';
export const OTP_TYPE_FIELD_NAME = 'otpType';
export const COPY_CODE_BUTTON_TEXT_FIELD_NAME = 'buttonText';
export const AUTOFILL_TEXT_FIELD_NAME = 'autofillText';
export const PACKAGE_NAME_FIELD_NAME = 'packageName';
export const SIGNATURE_HASH_FIELD_NAME = 'signatureHash';
