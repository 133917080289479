import { TemplateValues } from '../../types';

type templateFieldsMaxLengthKeys = keyof Pick<TemplateValues, 'name'>;

export const templateFieldsMaxLength: Record<
  templateFieldsMaxLengthKeys,
  number
> = {
  name: 512,
};
