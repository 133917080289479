import React, { useContext } from 'react';
import { Button } from '@salesforce/design-system-react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  Card,
  PageHeader,
  Grid,
  GridItem,
  NeedAspClientRegister,
  QueryHandler,
  IQueryHandlerResult,
  UserContext,
  ScopedNotification,
} from '@src/common/components';
import { Channel } from '@src/common/types';
import { templateRegisterPath } from '@src/common/utils';
import { t } from '@src/messages';

import { TEMPLATE_LIST_QUERY, ITemplateListQueryResponse } from '../graphql';
import {
  PendingMessageTemplates,
  ApprovedMessageTemplates,
} from '../components';

const CardWrapper = styled(Card)({
  minHeight: '40vh',
});

export const TemplateListView = () => {
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const history = useHistory();
  const actions = (disabled: boolean) => (
    <Button
      label={t.common.actions.newTemplate()}
      variant="brand"
      onClick={() => {
        history.push(templateRegisterPath(Channel.Whatsapp));
      }}
      disabled={disabled}
    />
  );
  return (
    <QueryHandler query={TEMPLATE_LIST_QUERY} levitate>
      {({
        data: {
          whatsAppAspClientsList: onboardedAspClientsWithActiveChannels,
          whatsAppMessageTemplates: { pending, approved },
        },
      }: IQueryHandlerResult<ITemplateListQueryResponse>) => (
        <Grid>
          <GridItem mb="small">
            <PageHeader
              channelType={Channel.Whatsapp}
              sectionType="messageTemplate"
              title={t.common.messageTemplates()}
              actions={actions(
                readOnlyAccess ||
                  onboardedAspClientsWithActiveChannels.length === 0
              )}
            />
          </GridItem>
          {readOnlyAccess && (
            <GridItem mb="small">
              <ScopedNotification variant="info">
                {t.common.readOnlyAccessNotification()}
              </ScopedNotification>
            </GridItem>
          )}
          <GridItem>
            {onboardedAspClientsWithActiveChannels.length === 0 ? (
              <NeedAspClientRegister
                title={t.common.components.needAccountWithActiveChannel.title()}
                description={t.common.components.needAccountWithActiveChannel.description()}
              />
            ) : (
              <>
                <GridItem mb="small">
                  <CardWrapper
                    heading={t.whatsapp.messageTemplates.list.pendingMessageTemplates()}
                    fullHeight
                  >
                    <PendingMessageTemplates
                      items={pending}
                      id="pending-message-templates"
                    />
                  </CardWrapper>
                </GridItem>
                <GridItem>
                  <CardWrapper
                    heading={t.whatsapp.messageTemplates.list.approvedMessageTemplates()}
                    fullHeight
                  >
                    <ApprovedMessageTemplates
                      items={approved}
                      id="approved-message-templates"
                    />
                  </CardWrapper>
                </GridItem>
              </>
            )}
          </GridItem>
        </Grid>
      )}
    </QueryHandler>
  );
};
