import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { BaseEmoji, Picker } from 'emoji-mart';
import styled from 'styled-components';

import { t } from '@src/messages';

import { initializeTranslations } from '../../../utils';

interface EmojiPickerProps {
  onAddEmoji?: (emoji: BaseEmoji) => void;
}

const PickerWrapper = styled.div`
  position: absolute;
  top: 43px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 15px;
    margin-left: -15px;
  }

  &:before {
    border-color: rgba(217, 217, 217, 0);
    border-bottom-color: #d9d9d9;
    border-width: 16px;
    margin-left: -16px;
  }

  & .emoji-mart {
    border: none;
  }

  & .emoji-mart-search-icon {
    top: 4px;
  }
`;

const emojiPickerLabels = t.whatsapp.messageTemplates.emojiPickerLabels;

export const EmojiPicker = ({ onAddEmoji }: EmojiPickerProps) => {
  const labels = initializeTranslations(emojiPickerLabels);

  return (
    <PickerWrapper>
      <Picker
        onSelect={onAddEmoji}
        emoji="point_up"
        title={emojiPickerLabels.pickYourEmoji()}
        i18n={labels}
      />
    </PickerWrapper>
  );
};
