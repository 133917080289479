import React from 'react';
import { useFormikContext } from 'formik';
import { pullAt } from 'lodash-es';
import { t } from 'messages';

import { GridItem, Grid, Box, Text } from '@src/common/components';
import { WhatsAppMtUrlType } from '@shared/bff';

import {
  TemplateValues,
  CallToActionType,
  ContentPayload,
  WebsiteButton,
} from '../../../types';
import {
  CALL_TO_ACTION_BUTTONS_FIELD_NAME,
  getMessageContentButtonPath,
} from '../../../utils';
import { ButtonsSection } from '../ButtonsSection';

import { CallToActionFields } from './CallToActionFields';

interface ICallToActionButtonsProps {
  selectedTabIndex: number;
  content: ContentPayload;
  isRtlLanguage: boolean;
}

export const CallToActionButtons = ({
  selectedTabIndex,
  content,
  isRtlLanguage,
}: ICallToActionButtonsProps) => {
  const { setFieldValue } = useFormikContext<TemplateValues>();
  const callToActionButtons = content.callToActionButtons || [];
  const disabled = callToActionButtons.length > 1;

  const handleAddAction = () => {
    const firstAction = callToActionButtons[0];
    const remainingAction =
      firstAction.type === CallToActionType.VISIT_WEBSITE
        ? {
            type: CallToActionType.CALL_PHONE_NUMBER,
            buttonText: '',
            phoneNumber: '',
          }
        : {
            type: CallToActionType.VISIT_WEBSITE,
            buttonText: '',
            urlType: WhatsAppMtUrlType.STATIC,
            websiteUrl: '',
          };
    const newAction = remainingAction;
    setFieldValue(
      getMessageContentButtonPath(
        selectedTabIndex,
        CALL_TO_ACTION_BUTTONS_FIELD_NAME,
        1
      ),
      newAction
    );
  };

  const handleRemoveAction = (index: number) => {
    const actionIndexToKeep = index === 0 ? 1 : 0;
    const remainingActions = pullAt(callToActionButtons, [actionIndexToKeep]);
    setFieldValue(
      getMessageContentButtonPath(
        selectedTabIndex,
        CALL_TO_ACTION_BUTTONS_FIELD_NAME
      ),
      remainingActions
    );
  };

  const buttons = callToActionButtons.map((button, index: number) => {
    const { type, urlType } = button as WebsiteButton;
    const canRemoveAction = index !== 0 || callToActionButtons.length > 1;

    return (
      <Box mb="small" ml="medium" key={index}>
        <Grid>
          <GridItem>
            <Text
              variant="body_small"
              color="weak"
            >{`${t.whatsapp.messageTemplates.fields.button()} ${
              index + 1
            }`}</Text>
            <CallToActionFields
              fieldPath={getMessageContentButtonPath(
                selectedTabIndex,
                CALL_TO_ACTION_BUTTONS_FIELD_NAME,
                index
              )}
              type={type}
              urlType={urlType}
              typeSelectDisabled={disabled}
              index={index}
              selectedTabIndex={selectedTabIndex}
              isRtlLanguage={isRtlLanguage}
              canRemoveAction={canRemoveAction}
              handleRemoveAction={handleRemoveAction}
            />
          </GridItem>
        </Grid>
      </Box>
    );
  });

  return (
    <ButtonsSection
      buttons={buttons}
      disabled={disabled}
      helpText={t.whatsapp.messageTemplates.register.helpTexts.callToActionButtons()}
      handleAdd={handleAddAction}
    />
  );
};
