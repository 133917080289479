import React from 'react';

import {
  Card,
  Box,
  DescriptionList,
  IDescriptionListItem,
  LabelTag,
} from '@src/common/components';
import { t } from '@src/messages';
import { mapWhatsAppQualityToType } from '@src/common/utils';
import { WhatsAppQuality, WhatsAppDailyLimit } from '@shared/bff';

import { ISectionProps } from '../types';

interface PropertiesSectionData {
  quality: WhatsAppQuality;
  dailyLimit: WhatsAppDailyLimit;
}

interface PropertiesSectionProps extends ISectionProps {
  values: PropertiesSectionData;
}

export const PropertiesSection = ({ values }: PropertiesSectionProps) => {
  const items: IDescriptionListItem[] = [
    {
      label: t.common.listings.columns.quality(),
      tooltip: t.whatsapp.tooltips.quality(),
      id: 'channel-quality',
      detail:
        values.quality !== WhatsAppQuality.UNKNOWN ? (
          <LabelTag type={mapWhatsAppQualityToType(values.quality)}>
            {t.whatsapp.qualityLabels[values.quality]()}
          </LabelTag>
        ) : (
          t.whatsapp.qualityLabels[values.quality]()
        ),
    },
    {
      label: t.common.listings.columns.dailyLimit(),
      tooltip: t.whatsapp.tooltips.dailyLimits(),
      id: 'channel-daily-limit',
      detail: t.whatsapp.dailyLimitLabels[values.dailyLimit](),
    },
  ];
  return (
    <Card heading={t.common.channelProperties()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
