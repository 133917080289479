import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { ButtonGroup, Modal } from '@salesforce/design-system-react';

import { IBotIdMatchParams, Channel } from '@src/common/types';
import {
  QueryHandler,
  IQueryHandlerResult,
  PageHeader,
  Box,
  ActivityHistorySection,
  ToastContext,
  ScopedNotification,
  DocumentationLink,
  Button,
  ChannelLimitsContext,
  UserContext,
} from '@src/common/components';
import { t } from '@src/messages';
import {
  channelListPath,
  channelBotEditDetailsPath,
  channelBotCompareCrBotPath,
} from '@src/common/utils';
import {
  CREATE_COMMENT_MUTATION,
  ICreateCommentResponse,
  ACTIVITIES_QUERY,
  IActivitiesQueryResponse,
} from '@src/common/graphql';
import {
  ActivityTargetType,
  BotState,
  IMutationCreateCommentArgs,
  IMutationWhatsAppBotActionArgs,
  NumberProviderType,
  WhatsAppBotAction,
} from '@shared/bff';

import {
  BOT_QUERY,
  IBotQueryResponse,
  BOT_ACTION_MUTATION,
  IBotActionMutationResponse,
  CR_QUERY,
  ICrQueryResponse,
} from '../graphql';
import { BotDetails } from '../components/BotDetails';

export const BotDetailsView = () => {
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const { toast } = useContext(ToastContext);
  const { botId } = useParams<IBotIdMatchParams>();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { updateChannelLimits } = useContext(ChannelLimitsContext);
  const [createCommentRequest] = useMutation<
    ICreateCommentResponse,
    IMutationCreateCommentArgs
  >(CREATE_COMMENT_MUTATION);
  const [botActionRequest, { loading: deleteLoading }] = useMutation<
    IBotActionMutationResponse,
    IMutationWhatsAppBotActionArgs
  >(BOT_ACTION_MUTATION);

  const [showCurrent, setShowCurrent] = useState(false);
  const handleCurrentClick = () => setShowCurrent(!showCurrent);

  const handleGoToRequest = (crId: string) => {
    history.push(channelBotCompareCrBotPath(Channel.Whatsapp, crId));
  };

  const onCancel = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    try {
      await botActionRequest({
        variables: {
          action: WhatsAppBotAction.DEACTIVATE,
          botId,
        },
        refetchQueries: [
          {
            query: BOT_QUERY,
            variables: {
              botId: botId,
            },
          },
        ],
      });
      updateChannelLimits();
      setShowDeleteModal(false);
    } catch (e) {
      toast('error', {
        heading: t.common.toasts.somethingWentWrong(),
      });
    }
  };

  return (
    <>
      <QueryHandler
        query={BOT_QUERY}
        variables={{
          botId: botId,
        }}
        levitate
      >
        {({
          data: { whatsAppBot },
        }: IQueryHandlerResult<IBotQueryResponse>) => {
          const handleEditing = () =>
            history.push(
              channelBotEditDetailsPath(Channel.Whatsapp, whatsAppBot.id)
            );

          const handleDeleteConfirm = () => {
            setShowDeleteModal(true);
          };

          return (
            <>
              <PageHeader
                channelType={Channel.Whatsapp}
                sectionType="channel"
                title={whatsAppBot.name}
                id="bot-details-header"
                breadcrumbs={[
                  {
                    header: t.common.channels(),
                    to: channelListPath(Channel.Whatsapp),
                  },
                ]}
                actions={
                  <ButtonGroup>
                    {!whatsAppBot.botCr ? (
                      <Button
                        id="edit-channel-details-button"
                        label={t.common.actions.editChannelDetails()}
                        onClick={handleEditing}
                        disabled={readOnlyAccess}
                      />
                    ) : (
                      <>
                        <Button
                          id="show-changes-button"
                          label={
                            showCurrent
                              ? t.common.actions.hideChanges()
                              : t.common.actions.showChanges()
                          }
                          onClick={handleCurrentClick}
                        />
                        <Button
                          id="go-to-request-button"
                          label={t.common.actions.goToRequest()}
                          onClick={() =>
                            handleGoToRequest(whatsAppBot.botCr!.id)
                          }
                        />
                      </>
                    )}
                    {whatsAppBot.state !== BotState.INACTIVE &&
                    whatsAppBot.state !== BotState.NEW ? (
                      <Button
                        id="delete-channel-button"
                        label={t.whatsapp.channels.deleteChannel()}
                        onClick={handleDeleteConfirm}
                        disabled={readOnlyAccess}
                      />
                    ) : (
                      <></>
                    )}
                  </ButtonGroup>
                }
              />
              {readOnlyAccess && (
                <>
                  <Box mt="small" />
                  <ScopedNotification variant="info">
                    {t.common.readOnlyAccessNotification()}
                  </ScopedNotification>
                </>
              )}
              {whatsAppBot.state === BotState.NEW &&
                whatsAppBot.numberProviderType ===
                  NumberProviderType.CUSTOMER && (
                  <>
                    <Box mt="small" />
                    <ScopedNotification variant="warning">
                      <>
                        {t.whatsapp.channels.scopedNotification.verificationNeeded()}
                        <Box display="inline" ml="xx-small" />
                        <DocumentationLink>
                          {t.whatsapp.channels.scopedNotification.link()}
                        </DocumentationLink>
                      </>
                    </ScopedNotification>
                  </>
                )}
              {whatsAppBot.state === BotState.NEW &&
                whatsAppBot.numberProviderType === NumberProviderType.SINCH && (
                  <>
                    <Box mt="small" />
                    <ScopedNotification variant="info">
                      {t.whatsapp.channels.scopedNotification.verificationBySinch()}
                    </ScopedNotification>
                  </>
                )}
              {whatsAppBot.state === BotState.INACTIVE && (
                <>
                  <Box mt="small" />
                  <ScopedNotification variant="warning">
                    {t.whatsapp.channels.scopedNotification.inactiveChannel()}
                    <Box display="inline" ml="xx-small" />
                    <DocumentationLink>
                      {t.whatsapp.channels.scopedNotification.link()}
                    </DocumentationLink>
                  </ScopedNotification>
                </>
              )}
              <Box mt="small" />

              {whatsAppBot.botCr ? (
                <QueryHandler
                  query={CR_QUERY}
                  variables={{
                    botCrId: whatsAppBot.botCr.id,
                  }}
                  levitate
                >
                  {({
                    data: { whatsAppCr },
                  }: IQueryHandlerResult<ICrQueryResponse>) => {
                    return (
                      <BotDetails
                        accountName={whatsAppBot.aspClientName}
                        bot={whatsAppBot}
                        whatsAppCr={whatsAppCr}
                        showCurrent={showCurrent}
                      />
                    );
                  }}
                </QueryHandler>
              ) : (
                <BotDetails
                  accountName={whatsAppBot.aspClientName}
                  bot={whatsAppBot}
                  showCurrent={showCurrent}
                />
              )}

              <Box mt="small" />
              <QueryHandler
                query={ACTIVITIES_QUERY}
                variables={{
                  targetType: ActivityTargetType.WA_BOT,
                  targetId: botId,
                }}
                levitate
              >
                {({
                  data: { activities },
                }: IQueryHandlerResult<IActivitiesQueryResponse>) => {
                  return (
                    <ActivityHistorySection
                      onSubmit={async (values) => {
                        try {
                          await createCommentRequest({
                            variables: {
                              comment: {
                                targetId: botId,
                                targetType: ActivityTargetType.WA_BOT,
                                comment: values.comment,
                              },
                            },

                            refetchQueries: [
                              {
                                query: ACTIVITIES_QUERY,
                                variables: {
                                  targetType: ActivityTargetType.WA_BOT,
                                  targetId: botId,
                                },
                              },
                            ],
                          });
                          toast('success', {
                            heading: t.common.toasts.commentCreatedSuccess(),
                          });
                        } catch (e) {
                          toast('error', {
                            heading: t.common.toasts.somethingWentWrong(),
                          });
                        }
                      }}
                      data={activities.map((activity) => ({
                        id: activity.activityId,
                        timestamp: activity.created,
                        comment: activity.comment,
                        event: activity.notificationTemplate,
                      }))}
                    />
                  );
                }}
              </QueryHandler>
            </>
          );
        }}
      </QueryHandler>
      <Modal
        heading={t.whatsapp.channels.deleteChannelModal.header()}
        footer={[
          <Button onClick={onCancel} key="cancel">
            {t.common.actions.cancel()}
          </Button>,
          <Button
            id="modal-delete-button"
            variant="brand"
            type="submit"
            onClick={handleDelete}
            loading={deleteLoading}
            key="submit"
          >
            {t.common.actions.delete()}
          </Button>,
        ]}
        isOpen={showDeleteModal}
        ariaHideApp={false}
        dismissOnClickOutside={false}
        onRequestClose={onCancel}
      >
        <Box pl="medium" pr="medium" pb="medium" pt="medium">
          {`${t.whatsapp.channels.deleteChannelModal.description()}`}
          <DocumentationLink>
            {`${t.whatsapp.channels.deleteChannelModal.documentPage()}`}
          </DocumentationLink>
        </Box>
      </Modal>
    </>
  );
};
