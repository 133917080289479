import React, { useMemo, useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import moment, { Moment } from 'moment';
import { useMutation } from '@apollo/client';

import {
  Card,
  Box,
  DescriptionList,
  IDescriptionListItem,
  SFIcon,
  Text,
  InputField,
  Grid,
  GridItem,
  ToastContext,
  Button,
  UserContext,
  ScopedNotification,
} from '@src/common/components';
import { t } from '@src/messages';
import { formatPhoneNumber, CONTACT_EMAIL } from '@src/common/utils';
import { IMutationWhatsAppVerifyBotArgs } from '@shared/bff';

import { getVerificationSectionSchema } from '../../utils';
import {
  VERIFY_BOT_MUTATION,
  IVerifyBotMutationResponse,
  IWhatsAppBotFragment,
  BOT_QUERY,
} from '../../graphql';

import { RegisterBotForm } from './RegisterBotForm';

interface IVerificationSectionProps {
  bot: IWhatsAppBotFragment;
}

const INTERVAL_IN_MILLISECONDS = 1000;
const EXPIRE_TIME_IN_MINUTES = 10;
let countDownDeadline: Moment;
let timeoutId = 0;

const initialValues = {
  code: '',
};

export const VerificationSection = ({ bot }: IVerificationSectionProps) => {
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const items = useMemo<IDescriptionListItem[]>(
    () => [
      {
        label: t.whatsapp.fieldLabels.phone(),
        detail: formatPhoneNumber({
          countryCode: bot.countryCode,
          phoneNumber: bot.phone,
        }),
      },
    ],
    []
  );

  const { toast } = useContext(ToastContext);
  const [sentCode, setSentCode] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<Moment | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [verifyError, setVerifyError] = useState<string | undefined>(undefined);
  const [verifyBot] = useMutation<
    IVerifyBotMutationResponse,
    IMutationWhatsAppVerifyBotArgs
  >(VERIFY_BOT_MUTATION);

  const calculateTimeLeft = (): Moment => {
    return moment.utc(countDownDeadline.diff(moment()));
  };

  const handleRegisterBotComplete = () => {
    setShowModal(false);
    setSentCode(true);
    countDownDeadline = moment().add(EXPIRE_TIME_IN_MINUTES, 'minutes');
    setTimeLeft(calculateTimeLeft());
  };

  const handleVerifyFailed = (errorCode: string) => {
    setVerifyError(errorCode);
    setShowModal(false);
    resetCountdown();
  };

  const resetCountdown = () => {
    clearTimeout(timeoutId);
    timeoutId = 0;
    setTimeLeft(null);
  };

  const handleStart = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (sentCode) {
      timeoutId = setTimeout(() => {
        if (countDownDeadline.isAfter(moment())) {
          setTimeLeft(calculateTimeLeft());
        } else {
          resetCountdown();
        }
      }, INTERVAL_IN_MILLISECONDS);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeLeft]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={getVerificationSectionSchema()}
        onSubmit={async (values) => {
          try {
            const { errors } = await verifyBot({
              variables: {
                botId: bot.id,
                code: values.code,
              },
              refetchQueries: [
                {
                  query: BOT_QUERY,
                  variables: {
                    botId: bot.id,
                  },
                },
              ],
            });

            const errorCode = errors?.[0]?.extensions?.code;

            if (
              typeof errorCode === 'string' &&
              errorCode.startsWith('WHATSAPP_VERIFY')
            ) {
              handleVerifyFailed(errorCode);
              return;
            }

            setSentCode(false);
            resetCountdown();
            toast('success', {
              heading: t.whatsapp.channels.toasts.verifyChannelSuccess(),
            });
          } catch (e) {
            toast('error', {
              heading: t.common.toasts.somethingWentWrong(),
            });
          }
        }}
      >
        {({ submitForm, isSubmitting }) => {
          const handleVerify = () => {
            submitForm();
          };
          return (
            <Form>
              <Card
                icon={
                  <SFIcon name="verification" size="small" type="warning" />
                }
                headerActions={
                  <>
                    {sentCode ? (
                      <Button
                        id="verification-button"
                        variant="brand"
                        loading={isSubmitting}
                        onClick={handleVerify}
                      >
                        {t.whatsapp.fieldLabels.verifyButton()}
                      </Button>
                    ) : (
                      <Button
                        id="verification-start-button"
                        variant="brand"
                        onClick={handleStart}
                        disabled={readOnlyAccess}
                      >
                        {t.whatsapp.fieldLabels.startButton()}
                      </Button>
                    )}
                  </>
                }
                heading={t.whatsapp.fieldLabels.verificationNeeded()}
              >
                <Box pl="medium" pr="medium">
                  {verifyError && (
                    <Box
                      mt="small"
                      mb="small"
                      id="verification-error-notification"
                    >
                      <ScopedNotification variant="error">
                        {verifyError ===
                        'WHATSAPP_REGISTRATION_INVALID_NUMBER_OR_VNAME'
                          ? t.whatsapp.verifyErrors.invalidNumberOrVName()
                          : verifyError === 'WHATSAPP_VERIFY_INCORRECT_CODE'
                          ? t.whatsapp.verifyErrors.incorrectCode()
                          : t.whatsapp.verifyErrors.genericError()}
                        {verifyError !== 'WHATSAPP_VERIFY_INCORRECT_CODE' && (
                          <Box pl="xx-small" display="inline">
                            <a
                              id="verification-contact-support-link"
                              href={`mailto:${CONTACT_EMAIL}`}
                            >
                              {t.common.actions.contactSinchSupport()}
                            </a>
                          </Box>
                        )}
                      </ScopedNotification>
                    </Box>
                  )}

                  <Grid>
                    <GridItem cols={10}>
                      <Text>
                        {t.whatsapp.fieldLabels.verificationDescription()}
                      </Text>
                    </GridItem>
                  </Grid>

                  <DescriptionList items={items} />
                  <Grid>
                    <GridItem cols={3}>
                      <InputField<{ code: string }>
                        id="verification-code-input"
                        name="code"
                        disabled={!sentCode || isSubmitting}
                        label={t.whatsapp.fieldLabels.verificationCode()}
                        tooltip={t.whatsapp.tooltips.verificationNeeded()}
                        required
                        regexConstraint={/[\D]/g}
                      />
                    </GridItem>
                    <GridItem cols={9} pl="medium">
                      <Text color="weak">
                        {t.whatsapp.fieldLabels.expireIn()}
                      </Text>
                      <Box mt="small" />
                      <Text variant="heading_medium">
                        {timeLeft ? timeLeft.format('mm:ss') : '10:00'}
                      </Text>
                    </GridItem>
                  </Grid>
                </Box>
              </Card>
            </Form>
          );
        }}
      </Formik>
      <RegisterBotForm
        show={showModal}
        bot={bot}
        onCancel={handleCancel}
        onSubmitComplete={handleRegisterBotComplete}
        onSubmitFailed={handleVerifyFailed}
      />
    </>
  );
};
