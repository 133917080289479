import { gql } from '@apollo/client';

import { IViberAspClientsResponse } from '@shared/bff';

export interface IAspClientsQueryResponse {
  viberAspClients: IViberAspClientsResponse;
}

export const ASP_CLIENTS_QUERY = gql`
  query ViberAspClients {
    viberAspClients {
      pending {
        id
        name
        state
        updated
      }
      onboarded {
        id
        name
        state
        updated
      }
    }
  }
`;
