import React from 'react';
import { Input } from '@salesforce/design-system-react';
import { t } from 'messages';

import { GridItem, Grid, Text, Box, Button } from '@src/common/components';

interface IQuickReplySectionProps {
  quickReplyButtons: string[];
  isRtlLanguage?: boolean;
}

export const QuickReplySection = ({
  quickReplyButtons,
  isRtlLanguage,
}: IQuickReplySectionProps) => {
  const buttons = quickReplyButtons.map((_: string, index: number) => (
    <Box mb="small" ml="medium" key={index}>
      <Grid>
        <GridItem cols={4}>
          <Text
            variant="body_small"
            color="weak"
          >{`${t.whatsapp.messageTemplates.fields.button()} ${
            index + 1
          }`}</Text>
          <Input
            label={t.whatsapp.messageTemplates.fields.buttonText()}
            required
            value={quickReplyButtons[index]}
            disabled
            className={isRtlLanguage ? 'rtl' : ''}
          />
        </GridItem>
      </Grid>
    </Box>
  ));

  return (
    <>
      <Text variant="body_small" color="weak" mt="small" mb="small">
        {t.whatsapp.messageTemplates.register.helpTexts.quickReplyButtons()}
      </Text>
      {buttons}
      <Box ml="medium">
        <Button disabled>
          {t.whatsapp.messageTemplates.register.buttonLabels.addButton()}
        </Button>
      </Box>
    </>
  );
};
