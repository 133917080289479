import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { ButtonGroup } from '@salesforce/design-system-react';
import { useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';

import {
  Channel,
  ErrorCode,
  errorCodes,
  senderErrorCodesExplainer,
} from '@src/common/types';
import { t } from '@src/messages';
import {
  channelChangeRequestDetailsPath,
  channelListPath,
  TEST_FACEBOOK_INSTAGRAM_DOMAIN,
  TEST_HTTP_HTTPS,
  TEST_INVALID_PHONE_NUMBER,
} from '@src/common/utils';
import {
  Box,
  Button,
  ChannelLimitsContext,
  ErrorModal,
  PageHeader,
  RouteLeavingGuard,
  ScopedNotification,
  Text,
  ToastContext,
  UserContext,
} from '@src/common/components';
import {
  IMutationViberCreateCrArgs,
  IMutationViberTransitionSenderCrFromDraftToReviewArgs,
  IViberSenderInput,
} from '@shared/bff';

import { ChannelFormModal, SenderForm } from '../components';
import {
  getDraftValidationSchema,
  getRequestValidationSchema,
  mapChannelValues,
} from '../utils';
import {
  CREATE_CR_MUTATION,
  ICreateCrMutationResponse,
  ITransitionSenderCrFromDraftToReviewMutationResponse,
  TRANSITION_SENDER_CR_FROM_DRAFT_TO_REVIEW_MUTATION,
} from '../graphql';

const initialValues: IViberSenderInput = {
  aspClientId: '',
  name: '',
  destinationCountries: [],
  businessLaunchDate: '',
  logoUrls: [],
  description: '',
  address: '',
  phoneNumber: '',
  // To get proper fallback usage for website in previewer
  // we need to use undefined as the initial input
  website: undefined,
  sampleMessage: null,
  extraDocs: null,
};

export const RequestSenderView = () => {
  const { toast } = useContext(ToastContext);
  const { viberChannels } = useContext(ChannelLimitsContext);
  const { user } = useContext(UserContext);

  const channelsLimitReached =
    viberChannels.consumed >= viberChannels.purchased &&
    viberChannels.purchased > 0;
  const noPurchasedChannels = viberChannels.purchased === 0;
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const history = useHistory();
  const [shouldSubmitRequest, setShouldSubmitRequest] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorExplanation, setErrorExplanation] = useState('');
  const [errorHeading, setErrorHeading] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [hasApprovedConditions, setHasApprovedConditions] = useState(false);

  const [createCrMutation] = useMutation<
    ICreateCrMutationResponse,
    IMutationViberCreateCrArgs
  >(CREATE_CR_MUTATION);
  const [transitionToReviewMutation] = useMutation<
    ITransitionSenderCrFromDraftToReviewMutationResponse,
    IMutationViberTransitionSenderCrFromDraftToReviewArgs
  >(TRANSITION_SENDER_CR_FROM_DRAFT_TO_REVIEW_MUTATION);

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const handleErrorModalClose = () => setShowErrorModal(false);

  const handleError = (e: { graphQLErrors?: readonly GraphQLError[] }) => {
    const errorCode: ErrorCode = e?.graphQLErrors?.[0]?.extensions?.code;
    const explanation = senderErrorCodesExplainer[errorCode];
    const isKnownError = errorCodes.includes(errorCode);
    setErrorHeading(
      shouldSubmitRequest
        ? t.viberBusiness.channels.request.errorModal.heading()
        : t.viberBusiness.channels.request.errorModal.saveErrorHeading()
    );

    if (isKnownError) {
      setErrorExplanation(explanation());
      setShowErrorModal(true);

      return;
    }

    toast('error', {
      heading: t.common.toasts.somethingWentWrong(),
    });
  };

  const handleCancelRequest = () =>
    history.push(channelListPath(Channel.ViberBusiness));
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getRequestValidationSchema()}
      onSubmit={async (values, actions) => {
        const newSenderValues = mapChannelValues(values);

        try {
          const { data, errors: createCrErrors } = await createCrMutation({
            variables: {
              viberCr: newSenderValues,
            },
          });

          if (createCrErrors) {
            actions.setSubmitting(false);
            return handleError({ graphQLErrors: createCrErrors });
          }

          const { errors: transitionToReviewErrors } =
            await transitionToReviewMutation({
              variables: {
                crId: data!.viberCreateCr.id,
              },
            });

          if (transitionToReviewErrors) {
            actions.setSubmitting(false);
            return handleError({
              graphQLErrors: transitionToReviewErrors,
            });
          }

          actions.resetForm();
          history.push(
            channelChangeRequestDetailsPath(
              Channel.ViberBusiness,
              data!.viberCreateCr.id
            )
          );
          toast('success', {
            heading: t.common.toasts.submitRequestSuccess(),
          });
        } catch (e) {
          actions.setSubmitting(false);
          handleError(e);
        }
      }}
    >
      {({
        values,
        dirty,
        isSubmitting,
        validateForm,
        submitForm,
        setSubmitting,
        resetForm,
      }) => {
        const shouldBlockExternalNavigation = () => dirty;

        const handlePreSaveDraft = () => {
          setShouldSubmitRequest(false);
          const draftSchema = getDraftValidationSchema();
          draftSchema
            .validate(values)
            .then(() => {
              handleModalOpen();
            })
            .catch((e) => {
              setSubmitting(false);
              if (e.type === TEST_INVALID_PHONE_NUMBER) {
                setErrorExplanation(
                  t.viberBusiness.channels.request.errorModal.invalidPhoneNumber()
                );
              } else if (e.type === TEST_HTTP_HTTPS) {
                setErrorExplanation(
                  t.viberBusiness.channels.request.errorModal.invalidWebsite()
                );
              } else if (e.type === TEST_FACEBOOK_INSTAGRAM_DOMAIN) {
                setErrorExplanation(
                  t.viberBusiness.channels.request.errorModal.forbiddenFacebookInstagramDomain()
                );
              } else {
                setErrorExplanation(
                  t.viberBusiness.channels.request.errorModal.requiredForNewDraft()
                );
              }
              setErrorHeading(
                t.viberBusiness.channels.request.errorModal.requiredHeading()
              );
              setShowErrorModal(true);
            });
        };

        const handlePreSubmit = async () => {
          setShouldSubmitRequest(true);

          const errorObj = await validateForm();
          const hasErrors = Object.entries(errorObj).length > 0;

          if (hasErrors) {
            submitForm();

            return;
          }

          handleModalOpen();
        };

        const handleSubmit = () => {
          handleModalClose();
          submitForm();
        };

        const handleSave = async () => {
          handleModalClose();
          setSubmitting(true);
          const newSenderValues = mapChannelValues(values);

          try {
            const { data, errors } = await createCrMutation({
              variables: {
                viberCr: newSenderValues,
              },
            });

            if (errors) {
              setSubmitting(false);
              return handleError({ graphQLErrors: errors });
            }

            resetForm();
            history.push(
              channelChangeRequestDetailsPath(
                Channel.ViberBusiness,
                data!.viberCreateCr.id
              )
            );
            toast('success', {
              heading: t.common.toasts.saveDraftSuccess(),
            });
          } catch (e) {
            setSubmitting(false);
            handleError(e);
          }
        };

        return (
          <>
            <Form>
              <PageHeader
                channelType={Channel.ViberBusiness}
                sectionType="channel"
                title={t.common.actions.newChannel()}
                breadcrumbs={[
                  {
                    header: t.common.channels(),
                    to: channelListPath(Channel.ViberBusiness),
                  },
                ]}
                actions={
                  <ButtonGroup variant="list">
                    <Button
                      id="cancel-button"
                      key="cancel"
                      label={t.common.actions.cancel()}
                      onClick={handleCancelRequest}
                    />
                    <Button
                      id="save-draft-button"
                      key="saveDraft"
                      label={t.common.actions.saveDraft()}
                      loading={isSubmitting && !shouldSubmitRequest}
                      onClick={handlePreSaveDraft}
                      disabled={
                        !dirty ||
                        isSubmitting ||
                        readOnlyAccess ||
                        noPurchasedChannels ||
                        channelsLimitReached
                      }
                    />
                    <Button
                      id="submit-request-button"
                      key="submitRequest"
                      label={t.common.actions.submitRequest()}
                      loading={isSubmitting && shouldSubmitRequest}
                      onClick={handlePreSubmit}
                      disabled={
                        !dirty ||
                        isSubmitting ||
                        readOnlyAccess ||
                        channelsLimitReached ||
                        noPurchasedChannels
                      }
                    />
                  </ButtonGroup>
                }
              />
              {readOnlyAccess && (
                <>
                  <Box mt="small" />
                  <ScopedNotification variant="info">
                    {t.common.readOnlyAccessNotification()}
                  </ScopedNotification>
                </>
              )}
              {channelsLimitReached && (
                <>
                  <Box mt="small" />
                  <ScopedNotification variant="warning">
                    {t.common.channelLimitNotification()}
                  </ScopedNotification>
                </>
              )}
              {noPurchasedChannels && (
                <>
                  <Box mt="small" />
                  <ScopedNotification variant="info">
                    {t.common.noPurchasedAppsNotification()}
                  </ScopedNotification>
                </>
              )}
              <Box mt="small" />
              <SenderForm />
              <ChannelFormModal
                handleCancel={handleModalClose}
                handleOnSubmit={shouldSubmitRequest ? handleSubmit : handleSave}
                isOpen={showModal}
                shouldSubmitRequest={shouldSubmitRequest}
                hasApprovedConditions={hasApprovedConditions}
                setHasApprovedConditions={setHasApprovedConditions}
                isSubmitting={isSubmitting}
              />
              <ErrorModal
                heading={errorHeading}
                onClose={handleErrorModalClose}
                isOpen={showErrorModal}
                label={t.common.actions.close()}
              >
                <Box pl="medium" pr="medium" pt="large" pb="large">
                  <Text id="viber-channel-error-modal-message">
                    {errorExplanation}
                  </Text>
                </Box>
              </ErrorModal>
            </Form>
            <RouteLeavingGuard
              whenBlockInternalNavigation={dirty}
              navigate={(path) => {
                history.push(path);
              }}
              shouldBlockExternalNavigation={shouldBlockExternalNavigation}
            />
          </>
        );
      }}
    </Formik>
  );
};
