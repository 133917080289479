import React from 'react';

import {
  Box,
  Card,
  DescriptionList,
  IDescriptionListItem,
} from '@src/common/components';
import { t } from '@src/messages';
import { IViberAspClient } from '@shared/bff';

import { getCountryIsoCodeLangFn } from '../../../utils';
import { prepareOptionsForBusinessCategory } from '../../../aspClients';

interface IAccountInformationSectionProps {
  aspClient: Pick<
    IViberAspClient,
    | 'name'
    | 'businessLegalName'
    | 'businessCategories'
    | 'businessRegisteredCountry'
  >;
}

export const AccountInformationSection = ({
  aspClient: {
    name,
    businessLegalName,
    businessCategories,
    businessRegisteredCountry,
  },
}: IAccountInformationSectionProps) => {
  const items: IDescriptionListItem[] = [
    {
      id: 'account-name',
      label: t.viberBusiness.aspClients.fields.name(),
      detail: name,
    },
    {
      id: 'business-legal-name',
      label: t.viberBusiness.aspClients.fields.businessLegalName(),
      detail: businessLegalName,
    },
    {
      id: 'business-categories',
      label: t.viberBusiness.aspClients.fields.businessCategories(),
      detail:
        prepareOptionsForBusinessCategory().find(
          (cat) => cat.value === businessCategories
        )?.label ?? businessCategories,
    },
    {
      id: 'business-registered-country',
      label: t.viberBusiness.aspClients.fields.businessRegisteredCountry(),
      detail: getCountryIsoCodeLangFn(businessRegisteredCountry)(),
    },
  ];

  return (
    <Card heading={t.common.accountInformation()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
