import React, { ReactNode } from 'react';
import { Combobox, Input, Textarea } from '@salesforce/design-system-react';

import { Grid, GridItem, Box, Text } from '@src/common/components';
import { t } from '@src/messages';
import { WhatsAppMtHeaderType, WhatsAppMtButtonType } from '@shared/bff';
import { languageDirection } from '@src/common/utils';

import { EditContentPayload } from '../../types';

import { MediaTypeSection } from './MediaTypeSection';
import { QuickReplySection } from './QuickReplySection';
import { CallToActionSection } from './CallToActionSection';

interface IExistingContentProps {
  content: EditContentPayload;
}

export const ExistingContent = ({ content }: IExistingContentProps) => {
  const {
    headerType,
    headerText,
    mediaType,
    bodyText,
    footer,
    buttonType,
    quickReplyButtons,
    callToActionButtons,
    language,
  } = content;

  const isRtlLanguage = languageDirection(language) === 'rtl';

  const HeaderTypeMapper: Record<WhatsAppMtHeaderType, ReactNode | null> = {
    [WhatsAppMtHeaderType.NONE]: null,
    [WhatsAppMtHeaderType.TEXT]: (
      <Box mb="small" ml="medium">
        <Input
          label={t.whatsapp.messageTemplates.fields.headerText()}
          required
          disabled
          value={headerText || ''}
          className={isRtlLanguage ? 'rtl' : ''}
        />
      </Box>
    ),
    [WhatsAppMtHeaderType.MEDIA]: <MediaTypeSection mediaType={mediaType} />,
  };

  const ButtonTypeMapper: Record<WhatsAppMtButtonType, ReactNode | null> = {
    [WhatsAppMtButtonType.NONE]: null,
    [WhatsAppMtButtonType.QUICK_REPLY]: (
      <QuickReplySection
        quickReplyButtons={quickReplyButtons || []}
        isRtlLanguage={isRtlLanguage}
      />
    ),
    [WhatsAppMtButtonType.CALL_TO_ACTION]: (
      <CallToActionSection
        callToActionButtons={callToActionButtons || []}
        isRtlLanguage={isRtlLanguage}
      />
    ),
  };

  return (
    <Box pl="medium" pr="medium" mt="large">
      <Text variant="heading_small" weight="bold" mt="large">
        {t.whatsapp.messageTemplates.templateLanguageLabels[content.language]()}{' '}
        {t.whatsapp.messageTemplates.register.sections.messageContent()}
      </Text>
      <Grid flow="vertical">
        <GridItem cols={9} mt="medium" mb="small">
          <Combobox
            labels={{
              label: `${t.whatsapp.messageTemplates.fields.header()} ${t.common.optional()}`,
            }}
            required
            variant="readonly"
            singleInputDisabled
            options={[]}
            selection={[
              {
                label:
                  t.whatsapp.messageTemplates.templateHeaderLabels[
                    headerType
                  ](),
                value: headerType,
                id: headerType,
              },
            ]}
          />
        </GridItem>
      </Grid>
      {HeaderTypeMapper[headerType]}
      <Textarea
        label={t.whatsapp.messageTemplates.fields.messageBody()}
        value={bodyText}
        required
        disabled
        className={isRtlLanguage ? 'rtl' : ''}
      />
      <Input
        label={`${t.whatsapp.messageTemplates.fields.footer()} ${t.common.optional()}`}
        disabled
        value={footer || ''}
        className={isRtlLanguage ? 'rtl' : ''}
      />
      <Grid flow="vertical">
        <GridItem cols={9} mt="medium">
          <Combobox
            labels={{
              label: `${t.whatsapp.messageTemplates.fields.buttons()} ${t.common.optional()}`,
            }}
            required
            variant="readonly"
            singleInputDisabled
            options={[]}
            selection={[
              {
                label:
                  t.whatsapp.messageTemplates.templateButtonLabels[
                    buttonType
                  ](),
                value: buttonType,
                id: buttonType,
              },
            ]}
          />
        </GridItem>
      </Grid>
      {ButtonTypeMapper[buttonType]}
    </Box>
  );
};
