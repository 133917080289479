import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import {
  Box,
  Grid,
  GridItem,
  Card,
  StickyGridItem,
  ActivityHistorySection,
  QueryHandler,
  IQueryHandlerResult,
  ToastContext,
  ProfileSettingsPreviewer,
} from '@src/common/components';
import {
  CREATE_COMMENT_MUTATION,
  ICreateCommentResponse,
  ACTIVITIES_QUERY,
  IActivitiesQueryResponse,
} from '@src/common/graphql';
import { t } from '@src/messages';
import { formatPhoneNumber } from '@src/common/utils';
import {
  IMutationCreateCommentArgs,
  CrState,
  ActivityTargetType,
} from '@shared/bff';

import { IWhatsAppBotFragment, ICrFragment } from '../../graphql';
import { prepareDataForBusinessProfilePreviewer } from '../../utils';
import { ConfigurationSection } from '../ConfigurationSection';
import { DesignSection } from '../DesignSection';
import { ContactInfoSection } from '../ContactInfoSection';
import { CompanyInformationSection } from '../CompanyInformationSection';
import { WhatsAppChannelProgress } from '../WhatsAppChannelProgress';

interface IChangeRequestDetailsProps {
  accountName: string;
  bot?: IWhatsAppBotFragment;
  whatsAppCr: ICrFragment;
  showDiff: boolean;
}

const CardWrapper = styled(Card)({
  overflowX: 'auto',
});

export const ChangeRequestDetails = ({
  accountName,
  bot,
  whatsAppCr,
  showDiff,
}: IChangeRequestDetailsProps) => {
  const { toast } = useContext(ToastContext);
  const botExists = whatsAppCr.botId.length > 0;
  const [createCommentRequest] = useMutation<
    ICreateCommentResponse,
    IMutationCreateCommentArgs
  >(CREATE_COMMENT_MUTATION);
  return (
    <>
      {whatsAppCr.state !== CrState.REJECTED && (
        <GridItem mt="small" mb="small">
          <WhatsAppChannelProgress state={whatsAppCr.state} />
        </GridItem>
      )}
      <Grid>
        <GridItem cols={8} pr="small">
          <Grid flow="vertical">
            <GridItem mb="small">
              <ConfigurationSection
                values={{
                  accountName,
                  name: whatsAppCr.bot.name,
                  description: whatsAppCr.bot.description,
                  phoneNumber: formatPhoneNumber({
                    countryCode: whatsAppCr.bot.countryCode,
                    phoneNumber: whatsAppCr.bot.phone,
                  }),
                  providerType: whatsAppCr.bot.numberProviderType,
                  botStatus: bot ? bot.state : undefined,
                  crStatus: botExists ? undefined : whatsAppCr.state,
                }}
                comparisonValues={
                  bot && {
                    accountName,
                    name: bot.name,
                    description: bot.description,
                    phoneNumber: formatPhoneNumber({
                      countryCode: bot.countryCode,
                      phoneNumber: bot.phone,
                    }),
                    providerType: bot.numberProviderType,
                    botStatus: bot.state,
                  }
                }
                showDiff={showDiff}
              />
            </GridItem>
            <GridItem mb="small">
              <DesignSection
                values={{ image: whatsAppCr?.bot.photoUrl }}
                comparisonValues={bot && { image: bot.photoUrl }}
                showDiff={showDiff}
              />
            </GridItem>
            <GridItem mb="small">
              <CompanyInformationSection
                values={{
                  about: whatsAppCr.bot.about,
                  industry: whatsAppCr.bot.vertical,
                }}
                comparisonValues={
                  bot && {
                    about: bot.about,
                    industry: bot.vertical,
                  }
                }
                showDiff={showDiff}
              />
            </GridItem>
            <GridItem>
              <ContactInfoSection
                values={{
                  address: whatsAppCr.bot.address,
                  email: whatsAppCr.bot.email,
                  website: whatsAppCr.bot.websiteOne,
                  additionalWebsite: whatsAppCr.bot.websiteTwo,
                }}
                comparisonValues={
                  bot && {
                    address: bot.address,
                    email: bot.email,
                    website: bot.websiteOne,
                    additionalWebsite: bot.websiteTwo,
                  }
                }
                showDiff={showDiff}
              />
            </GridItem>
          </Grid>
        </GridItem>
        <StickyGridItem cols={4}>
          <CardWrapper
            heading={t.common.components.businessProfilePreviewer.heading()}
          >
            <ProfileSettingsPreviewer
              whatsappData={prepareDataForBusinessProfilePreviewer({
                ...whatsAppCr?.bot,
                ...{ updated: whatsAppCr?.updated },
              })}
            />
          </CardWrapper>
        </StickyGridItem>
      </Grid>
      <Box mt="small" />
      <QueryHandler
        query={ACTIVITIES_QUERY}
        variables={{
          targetType: ActivityTargetType.WA_BOT_CHANGE_REQUEST,
          targetId: whatsAppCr.id,
        }}
        levitate
      >
        {({
          data: { activities },
        }: IQueryHandlerResult<IActivitiesQueryResponse>) => {
          return (
            <ActivityHistorySection
              onSubmit={async (values) => {
                try {
                  await createCommentRequest({
                    variables: {
                      comment: {
                        targetId: whatsAppCr.id,
                        targetType: ActivityTargetType.WA_BOT_CHANGE_REQUEST,
                        comment: values.comment,
                      },
                    },
                    refetchQueries: [
                      {
                        query: ACTIVITIES_QUERY,
                        variables: {
                          targetType: ActivityTargetType.WA_BOT_CHANGE_REQUEST,
                          comment: values.comment,
                          targetId: whatsAppCr.id,
                        },
                      },
                    ],
                  });
                  toast('success', {
                    heading: t.common.toasts.commentCreatedSuccess(),
                  });
                } catch (e) {
                  toast('error', {
                    heading: t.common.toasts.somethingWentWrong(),
                  });
                }
              }}
              data={activities.map((activity) => ({
                id: activity.activityId,
                timestamp: activity.created,
                comment: activity.comment,
                event: activity.notificationTemplate,
              }))}
            />
          );
        }}
      </QueryHandler>
    </>
  );
};
