import { WhatsAppMtUrlType } from '@shared/bff';

import { CallToActionButton } from '../../types';

export const mapCallToActionButtons = (
  callToActionButtons: CallToActionButton[]
) =>
  callToActionButtons.map((callToActionButton) => {
    const { buttonText } = callToActionButton;
    if ('phoneNumber' in callToActionButton) {
      const { phoneNumber } = callToActionButton;

      return {
        phoneAction: {
          buttonText,
          phoneNumber: `+${phoneNumber}`,
        },
      };
    }

    const { urlType, websiteUrl } = callToActionButton;
    const lastChar = websiteUrl.slice(-1);
    const trailingSlash =
      lastChar === '/' ||
      lastChar === '=' ||
      lastChar === '#' ||
      lastChar === '?'
        ? ''
        : '/';

    // When URL type is DYNAMIC, need to append {{1}} to the URL
    const url =
      urlType === WhatsAppMtUrlType.DYNAMIC
        ? `${websiteUrl}${trailingSlash}{{1}}`
        : websiteUrl;

    return { websiteAction: { buttonText, urlType, websiteUrl: url } };
  });
