import React from 'react';
import { Combobox, Input } from '@salesforce/design-system-react';

import { t } from '@src/messages';
import {
  Section,
  Grid,
  GridItem,
  Text,
  DescriptionList,
  RadioGroup,
  Radio,
} from '@src/common/components';
import { IWhatsAppMtOrMtCr, WhatsAppMtCategory } from '@shared/bff';
import { useFeatureFlags } from '@src/common/utils/hooks';

interface IConfigurationProps {
  existingValues: IWhatsAppMtOrMtCr;
  aspClientName: string;
  variant?: 'readonly' | 'static';
}

export const Configuration = ({
  existingValues,
  aspClientName,
  variant = 'readonly',
}: IConfigurationProps) => {
  const { aspClientId, category, name } = existingValues;
  const { whatsAppNewTemplateCategories } = useFeatureFlags();

  return (
    <Section>
      <Grid>
        <GridItem cols={4} pr="small">
          {variant === 'readonly' && (
            <Combobox
              labels={{
                label: t.common.components.accountSelect.label(),
              }}
              required
              variant="readonly"
              singleInputDisabled
              options={[]}
              selection={
                aspClientId
                  ? [
                      {
                        label: aspClientName,
                        value: aspClientId,
                        id: aspClientId,
                      },
                    ]
                  : undefined
              }
            />
          )}
          {variant === 'static' && (
            <DescriptionList
              type="base"
              spaceBetweenColumns={true}
              items={[
                {
                  label: t.common.components.accountSelect.label(),
                  detail: aspClientName,
                  id: 'bar',
                },
              ]}
            />
          )}
        </GridItem>
        <GridItem cols={4} pr="small">
          {variant === 'readonly' && (
            <>
              <Input
                label={t.whatsapp.messageTemplates.fields.templateName()}
                required
                disabled
                value={name}
              />
              <Text variant="body_small" color="weak" mt="xxx-small">
                {t.whatsapp.messageTemplates.register.helpTexts.templateName()}
              </Text>
            </>
          )}
          {variant === 'static' && (
            <DescriptionList
              type="base"
              spaceBetweenColumns={true}
              items={[
                {
                  label: t.whatsapp.messageTemplates.fields.templateName(),
                  detail: name,
                  id: 'template-name',
                },
              ]}
            />
          )}
        </GridItem>
        {!whatsAppNewTemplateCategories && (
          <GridItem cols={4} mb="small">
            {variant === 'readonly' && (
              <Combobox
                labels={{
                  label: t.whatsapp.messageTemplates.fields.templateCategory(),
                }}
                required
                variant="readonly"
                singleInputDisabled
                options={[]}
                selection={[
                  {
                    label:
                      t.whatsapp.messageTemplates.templateCategoryLabels[
                        category
                      ](),
                    value: category,
                    id: category,
                  },
                ]}
              />
            )}
            {variant === 'static' && (
              <DescriptionList
                type="base"
                spaceBetweenColumns={true}
                items={[
                  {
                    label:
                      t.whatsapp.messageTemplates.fields.templateCategory(),
                    detail:
                      t.whatsapp.messageTemplates.templateCategoryLabels[
                        category
                      ](),
                    id: 'template-category',
                  },
                ]}
              />
            )}
          </GridItem>
        )}
        {whatsAppNewTemplateCategories && (
          <GridItem cols={4} mb="small">
            {variant === 'readonly' && (
              <RadioGroup
                disabled={true}
                labels={{
                  label: t.whatsapp.messageTemplates.fields.templateCategory(),
                }}
                required
                tooltip={t.whatsapp.messageTemplates.tooltips.templateCategoryRadioButtons()}
              >
                <Radio
                  labels={{
                    label:
                      t.whatsapp.messageTemplates.templateCategoryLabels.MARKETING(),
                  }}
                  checked={category === WhatsAppMtCategory.MARKETING}
                  value={WhatsAppMtCategory.MARKETING}
                  id="template-category-marketing"
                />
                <Radio
                  labels={{
                    label:
                      t.whatsapp.messageTemplates.templateCategoryLabels.UTILITY(),
                  }}
                  checked={category === WhatsAppMtCategory.UTILITY}
                  value={WhatsAppMtCategory.UTILITY}
                  id="template-category-utility"
                />
                <Radio
                  labels={{
                    label:
                      t.whatsapp.messageTemplates.templateCategoryLabels.AUTHENTICATION(),
                  }}
                  checked={category === WhatsAppMtCategory.AUTHENTICATION}
                  value={WhatsAppMtCategory.AUTHENTICATION}
                  id="template-category-authentication"
                />
              </RadioGroup>
            )}
            {variant === 'static' && (
              <DescriptionList
                type="base"
                spaceBetweenColumns={true}
                items={[
                  {
                    label:
                      t.whatsapp.messageTemplates.fields.templateCategory(),
                    detail:
                      t.whatsapp.messageTemplates.templateCategoryLabels[
                        category
                      ](),
                    id: 'template-category',
                  },
                ]}
              />
            )}
          </GridItem>
        )}
      </Grid>
    </Section>
  );
};
