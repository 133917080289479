import React, { useMemo } from 'react';

import {
  Box,
  Card,
  DocumentationLink,
  Grid,
  GridItem,
  InputField,
  Section,
  SingleSelectField,
} from '@src/common/components';
import { t } from '@src/messages';
import { IViberAspClientInput } from '@shared/bff';

import {
  ICountryOption,
  prepareOptionsForCountrySelector,
} from '../../../utils';
import {
  aspClientFieldsMaxLength,
  IBusinessCategoryOption,
  prepareOptionsForBusinessCategory,
} from '../../utils';

import { WarrantyLetter } from './WarrantyLetter';

export const AspClientRegistrationForm = () => {
  const businessCategoryOptions = useMemo<IBusinessCategoryOption[]>(
    prepareOptionsForBusinessCategory,
    []
  );
  const countryOptions = useMemo<ICountryOption[]>(
    prepareOptionsForCountrySelector,
    []
  );

  return (
    <Grid>
      <GridItem mb="small">
        <Card
          heading={t.viberBusiness.aspClients.register.heading()}
          description={
            <>
              <span>
                {t.viberBusiness.aspClients.register.descriptionOne()}
              </span>
              <Box mb="xx-small" />
              <span>
                {t.viberBusiness.aspClients.register.descriptionTwo()}{' '}
                <DocumentationLink>
                  {t.viberBusiness.aspClients.register.documentationLink()}
                </DocumentationLink>
                .
              </span>
            </>
          }
        >
          <Section>
            <Grid flow="vertical">
              <GridItem cols={5} mb="small">
                <InputField<IViberAspClientInput>
                  name="name"
                  label={t.viberBusiness.aspClients.fields.name()}
                  tooltip={t.viberBusiness.aspClients.register.tooltips.name()}
                  id="account-name-input"
                  maxLength={`${aspClientFieldsMaxLength.name}`}
                  required
                />
              </GridItem>
              <GridItem cols={5} mb="small">
                <InputField<IViberAspClientInput>
                  name="businessLegalName"
                  label={t.viberBusiness.aspClients.fields.businessLegalName()}
                  tooltip={t.viberBusiness.aspClients.register.tooltips.businessLegalName()}
                  id="business-legal-name-input"
                  maxLength={`${aspClientFieldsMaxLength.businessLegalName}`}
                  required
                />
              </GridItem>
              <GridItem cols={5} mb="small">
                <SingleSelectField<IViberAspClientInput>
                  name="businessCategories"
                  labels={{
                    label:
                      t.viberBusiness.aspClients.fields.businessCategories(),
                    placeholderReadOnly:
                      t.viberBusiness.aspClients.register.placeholders.businessCategories(),
                  }}
                  tooltip={t.viberBusiness.aspClients.register.tooltips.businessCategories()}
                  id="business-categories-combobox"
                  options={businessCategoryOptions}
                  required
                  variant="readonly"
                />
              </GridItem>
              <GridItem cols={5} mb="small">
                <SingleSelectField<IViberAspClientInput>
                  name="businessRegisteredCountry"
                  labels={{
                    label:
                      t.viberBusiness.aspClients.fields.businessRegisteredCountry(),
                  }}
                  tooltip={t.viberBusiness.aspClients.register.tooltips.businessRegisteredCountry()}
                  id="business-registered-country-combobox"
                  options={countryOptions}
                  required
                  variant="inline-listbox"
                />
              </GridItem>
            </Grid>
          </Section>
        </Card>
      </GridItem>
      <GridItem>
        <Card heading={t.viberBusiness.aspClients.register.warrantyLetter()}>
          <WarrantyLetter />
        </Card>
      </GridItem>
    </Grid>
  );
};
