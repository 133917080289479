import { gql } from '@apollo/client';

import { IWhatsAppAspClient } from '@shared/bff';

export interface INewTemplateQueryResponse {
  whatsAppAspClientsList: IWhatsAppAspClient[];
}

export const NEW_TEMPLATE_QUERY = gql`
  query NewTemplate {
    whatsAppAspClientsList(withActiveChannels: true, state: [ONBOARDED]) {
      id
      name
    }
  }
`;
