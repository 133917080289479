import React, { useState, useCallback } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Modal } from '@salesforce/design-system-react';
import { useMutation } from '@apollo/client';

import {
  Box,
  Button,
  Card,
  QueryHandler,
  Section,
  InputField,
  IQueryHandlerResult,
} from '@src/common/components';
import { t } from '@src/messages';
import { IMutationOptInArgs } from '@shared/bff';

import {
  EMAIL_NOTIFICATIONS_SETTINGS_QUERY,
  EMAIL_NOTIFICATIONS_SETTINGS_OPT_IN_MUTATION,
  EMAIL_NOTIFICATIONS_SETTINGS_OPT_OUT_MUTATION,
  IEmailNotificationsSettingsQueryResponse,
  IEmailNotificationsSettingsOptInResponse,
  IEmailNotificationsSettingsOptOutResponse,
} from '../graphql';

const EmailDescription = ({ emailAddress }: { emailAddress: string }) => (
  <dl className="slds-dl_horizontal">
    <dt className="slds-text-body_small slds-text-color_weak slds-dl_horizontal__label">
      {t.common.emailAddress()}
    </dt>
    <dd className="slds-dl_horizontal__detail">{emailAddress}</dd>
  </dl>
);

export const SetupNotificationsCard = () => {
  const [optIn] = useMutation<
    IEmailNotificationsSettingsOptInResponse,
    IMutationOptInArgs
  >(EMAIL_NOTIFICATIONS_SETTINGS_OPT_IN_MUTATION);

  const [optOut] = useMutation<IEmailNotificationsSettingsOptOutResponse>(
    EMAIL_NOTIFICATIONS_SETTINGS_OPT_OUT_MUTATION
  );

  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  const [emailAddress, setEmailAddress] = useState<string | undefined>();

  const setupNotificationsEmail = useCallback(
    async ({ email }, actions: FormikHelpers<{ email: string }>) => {
      const { data } = await optIn({ variables: { email } });
      if (data?.optIn.success) {
        setEmailAddress(email);
        actions.setSubmitting(false);
        actions.resetForm();
      }
    },
    []
  );

  return (
    <>
      <QueryHandler query={EMAIL_NOTIFICATIONS_SETTINGS_QUERY} levitate>
        {({
          data: {
            emailNotificationsSettings: { email },
          },
          refetch,
        }: IQueryHandlerResult<IEmailNotificationsSettingsQueryResponse>) => {
          if (email) {
            setEmailAddress(email);
          }

          const clearNotificationsEmail = async () => {
            const { data } = await optOut();
            if (data?.optOut.success) {
              setShowModal(false);
              await refetch();
              setEmailAddress(undefined);
            }
          };

          return (
            <>
              <Formik
                initialValues={{
                  email: emailAddress || '',
                }}
                validationSchema={yup.object({
                  email: yup.string().email(),
                })}
                onSubmit={setupNotificationsEmail}
              >
                {({ submitForm, isSubmitting, dirty }) => (
                  <Card
                    heading={t.home.emailNotifications.heading()}
                    headerActions={
                      !emailAddress ? (
                        <Button
                          type="submit"
                          loading={isSubmitting}
                          disabled={!dirty && !isSubmitting}
                          onClick={submitForm}
                        >
                          {t.common.actions.save()}
                        </Button>
                      ) : (
                        <Button onClick={openModal}>
                          {t.common.actions.remove()}
                        </Button>
                      )
                    }
                    description={
                      !emailAddress
                        ? t.home.emailNotifications.setupDescription()
                        : t.home.emailNotifications.description()
                    }
                    secondDescription={
                      !emailAddress
                        ? t.home.emailNotifications.infoOptIn()
                        : t.home.emailNotifications.infoRemove()
                    }
                    thirdDescription={
                      !emailAddress ? t.home.emailNotifications.infoEmail() : ''
                    }
                    type="darken"
                  >
                    <Form>
                      <Section>
                        {!emailAddress ? (
                          <InputField name="email" />
                        ) : (
                          <EmailDescription emailAddress={emailAddress} />
                        )}
                      </Section>
                    </Form>
                  </Card>
                )}
              </Formik>
              <Modal
                heading={t.home.emailNotifications.removeHeading()}
                footer={[
                  <Button onClick={closeModal} key="cancel">
                    {t.common.actions.cancel()}
                  </Button>,
                  <Button
                    onClick={clearNotificationsEmail}
                    key="confirm"
                    variant="brand"
                  >
                    {t.common.actions.remove()}
                  </Button>,
                ]}
                isOpen={showModal}
                onRequestClose={closeModal}
              >
                <Box pl="medium" pr="medium" pt="medium" pb="x-large">
                  {t.home.emailNotifications.removeWarning()}
                </Box>
              </Modal>
            </>
          );
        }}
      </QueryHandler>
    </>
  );
};
