import React, { useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { GraphQLError } from 'graphql';

import { t } from '@src/messages';
import {
  Grid,
  GridItem,
  QueryHandler,
  IQueryHandlerResult,
  ToastContext,
  ErrorModal,
  Box,
  Text,
  RouteLeavingGuard,
  ScopedNotification,
  UserContext,
  Button,
} from '@src/common/components';
import {
  templateListPath,
  templateDetailsPath,
  TEST_HTTP_HTTPS,
  TEST_INVALID_PHONE_NUMBER,
  templateEditPath,
} from '@src/common/utils';
import { Channel } from '@src/common/types';
import { useFeatureFlags } from '@src/common/utils/hooks';
import {
  WhatsAppMtCategory,
  IWhatsAppMtTemplateContent,
  WhatsAppMtLanguage,
  IWhatsAppMtCrContentInput,
  IWhatsAppAuthenticationMtCrContentInput,
} from '@shared/bff';

import { INewTemplateQueryResponse, NEW_TEMPLATE_QUERY } from '../graphql';
import {
  TemplateValues,
  ErrorCode,
  errorCodes,
  templateErrorCodesExplainer,
} from '../types';
import { ConfirmModal, TemplateForm, TemplatePageHeader } from '../components';
import {
  getTemplateRequestValidationSchema,
  mapContents,
  getTemplateDraftValidationSchema,
  getTransitionToReviewMutation,
  getCreateTemplateMutation,
  getMessageContentPayloadSchema,
  getAuthenticationMessageContentPayloadSchema,
  mapAuthenticationContents,
} from '../utils';

const initialValues: TemplateValues = {
  aspClientId: '',
  name: '',
  category: '' as WhatsAppMtCategory,
  languages: [],
  contents: undefined,
  authenticationContents: undefined,
};

export const RegisterTemplateView = () => {
  const { whatsAppSamples } = useFeatureFlags();
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorExplanation, setErrorExplanation] = useState('');
  const [errorHeading, setErrorHeading] = useState('');
  const [hasTransitionErrors, setHasTransitionErrors] = useState(false);
  const [shouldSubmitRequest, setShouldSubmitRequest] = useState(true);
  const [languagesToSubmit, setLanguagesToSubmit] = useState([] as string[]);
  const [languageErrors, setLanguageErrors] = useState([] as string[]);
  const { toast } = useContext(ToastContext);
  const history = useHistory();

  const handleModalClose = () => setShowModal(false);
  const handleModalOpen = () => {
    if (shouldSubmitRequest) {
      setLanguagesToSubmit([]);
    }
    setShowModal(true);
  };
  const handleErrorModalClose = () => setShowErrorModal(false);

  const createTemplateMutation = getCreateTemplateMutation();
  const transitionToReviewMutation = getTransitionToReviewMutation();

  const handleError = (
    e: { graphQLErrors?: readonly GraphQLError[] },
    areTransitionErrors?: boolean
  ) => {
    const errorCode: ErrorCode = e?.graphQLErrors?.[0]?.extensions?.code;
    const explanation = templateErrorCodesExplainer[errorCode];
    const isKnownError = errorCodes.includes(errorCode);
    setErrorHeading(
      shouldSubmitRequest
        ? t.whatsapp.messageTemplates.register.errorModal.heading()
        : t.whatsapp.messageTemplates.register.errorModal.saveErrorHeading()
    );

    if (isKnownError) {
      setErrorExplanation(explanation());
      setShowErrorModal(true);
      setHasTransitionErrors(false);

      return;
    }

    if (areTransitionErrors) {
      setErrorExplanation(
        t.whatsapp.messageTemplates.register.errorModal.transitionErrors()
      );
      setShowErrorModal(true);
      return;
    }

    toast('error', {
      heading: t.common.toasts.somethingWentWrong(),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getTemplateRequestValidationSchema(whatsAppSamples)}
      onSubmit={async (
        { aspClientId, name, category, contents, authenticationContents },
        actions
      ) => {
        const normalContents = contents && mapContents(contents);
        const authContents =
          authenticationContents &&
          mapAuthenticationContents(authenticationContents);
        try {
          const { data, errors } = await createTemplateMutation({
            variables: {
              crInput: {
                aspClientId,
                name,
                category,
                contents: normalContents,
                authenticationContents: authContents,
              },
            },
          });

          if (errors) {
            actions.setSubmitting(false);
            return handleError({ graphQLErrors: errors });
          }

          const { contents: savedContent, name: savedName } =
            data!.whatsAppCreateMessageTemplateCr;
          const crIds = (savedContent as Array<IWhatsAppMtTemplateContent>)
            .filter((cr) => languagesToSubmit.includes(cr.language))
            .map(({ id }) => id);
          const { errors: transitionErrors } = await transitionToReviewMutation(
            {
              variables: {
                transitionInput: {
                  aspClientId,
                  crIds,
                },
              },
            }
          );

          if (transitionErrors) {
            actions.setSubmitting(false);
            setHasTransitionErrors(true);
            return handleError({ graphQLErrors: transitionErrors }, true);
          }

          actions.setSubmitting(false);
          actions.resetForm();

          history.push(
            templateDetailsPath(Channel.Whatsapp, aspClientId, savedName)
          );
          toast('success', {
            heading: t.common.toasts.submitRequestSuccess(),
          });
        } catch (e) {
          actions.setSubmitting(false);
          handleError(e);
        }
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        submitCount,
        submitForm,
        validateForm,
        dirty,
        setSubmitting,
        resetForm,
      }) => {
        const handlePreSaveDraft = () => {
          setShouldSubmitRequest(false);
          const draftSchema = getTemplateDraftValidationSchema();
          draftSchema
            .validate(values)
            .then(() => {
              handleModalOpen();
            })
            .catch((e) => {
              setSubmitting(false);
              setErrorHeading(
                t.whatsapp.messageTemplates.register.errorModal.requiredHeading()
              );
              if (e.type === TEST_INVALID_PHONE_NUMBER) {
                setErrorExplanation(
                  t.whatsapp.messageTemplates.register.errorModal.invalidPhoneNumber()
                );
              } else if (e.type === TEST_HTTP_HTTPS) {
                setErrorExplanation(
                  t.whatsapp.messageTemplates.register.errorModal.invalidWebsite()
                );
              } else {
                setErrorExplanation(
                  t.whatsapp.messageTemplates.register.errorModal.requiredForNewDraft()
                );
              }

              setShowErrorModal(true);
            });
        };

        const handlePreSubmit = async () => {
          setShouldSubmitRequest(true);

          const errorStrings: string[] = [];

          const {
            aspClientId,
            category,
            languages,
            name,
            contents: contentsValidationErrors,
            authenticationContents: authenticationContentsValidationErrors,
          } = await validateForm();

          if (aspClientId || category || languages || name) {
            submitForm();
            return;
          }
          const validationErrors =
            contentsValidationErrors || authenticationContentsValidationErrors;

          if (validationErrors) {
            if (Array.isArray(validationErrors)) {
              Object.entries(validationErrors).forEach(([key, value]) => {
                const index = parseInt(key);
                if (value === t.common.validation.test.change()) {
                  errorStrings[index] =
                    t.whatsapp.messageTemplates.register.confirmationModal.disabledLanguageLabel.unchanged();
                } else if (typeof value === 'object') {
                  errorStrings[index] =
                    t.whatsapp.messageTemplates.register.confirmationModal.disabledLanguageLabel.incorrectValue();
                }
              });
            }
            submitForm();
          }

          setLanguageErrors(errorStrings);
          handleModalOpen();
        };

        const handleModalSubmit = async () => {
          handleModalClose();

          const savedCrs = await handleSave();

          if (savedCrs) {
            await handleSubmit(
              savedCrs.filter((c) => languagesToSubmit.includes(c.language))
            );
          }
        };

        const handleSave = async () => {
          const {
            aspClientId,
            name,
            category,
            contents,
            authenticationContents,
          } = values;

          let languagesToCreate: WhatsAppMtLanguage[] = [];
          let contentsToCreate: IWhatsAppMtCrContentInput[] | undefined =
            undefined;
          let authenticationContentsToCreate:
            | IWhatsAppAuthenticationMtCrContentInput[]
            | undefined = undefined;

          if (contents) {
            const validationSchema = getMessageContentPayloadSchema(contents);
            const isValid = await Promise.all(
              contents.map((c) => validationSchema.isValid(c))
            );
            const validContents = contents.filter((_, i) => isValid[i]);
            const mappedContents = mapContents(validContents);
            contentsToCreate = mappedContents.filter(
              (c) => c && !('crStatus' in c) && !('templateStatus' in c)
            );
            languagesToCreate = contentsToCreate.map((c) => c.language);
          }

          if (authenticationContents) {
            const validationSchema =
              getAuthenticationMessageContentPayloadSchema(
                authenticationContents
              );
            const isValid = await Promise.all(
              authenticationContents.map((c) => validationSchema.isValid(c))
            );
            const validContents = authenticationContents.filter(
              (_, i) => isValid[i]
            );
            const mappedAuthenticationContents =
              mapAuthenticationContents(validContents);
            authenticationContentsToCreate =
              mappedAuthenticationContents.filter(
                (c) => c && !('crStatus' in c) && !('templateStatus' in c)
              );
            languagesToCreate = authenticationContentsToCreate.map(
              (c) => c.language
            );
          }

          const savedCrs: IWhatsAppMtTemplateContent[] = [];

          if (languagesToCreate.length > 0) {
            const mutationResponse = await createTemplateMutation({
              variables: {
                crInput: {
                  aspClientId,
                  name,
                  category,
                  contents: contentsToCreate,
                  authenticationContents: authenticationContentsToCreate,
                },
              },
            });
            if (mutationResponse.errors) {
              setSubmitting(false);
              return handleError({ graphQLErrors: mutationResponse.errors });
            }
            const { contents: savedContent } =
              mutationResponse.data!.whatsAppCreateMessageTemplateCr;
            savedCrs.push(
              ...(savedContent as Array<IWhatsAppMtTemplateContent>).filter(
                (c) =>
                  languagesToCreate.find((language) => language === c.language)
              )
            );
          }

          return savedCrs;
        };

        const handleSaveDraft = async () => {
          handleModalClose();
          setSubmitting(true);
          const {
            aspClientId,
            name,
            category,
            contents,
            authenticationContents,
          } = values;

          try {
            const { data, errors: createErrors } = await createTemplateMutation(
              {
                variables: {
                  crInput: {
                    aspClientId,
                    name,
                    category,
                    contents: contents && mapContents(contents),
                    authenticationContents:
                      authenticationContents &&
                      mapAuthenticationContents(authenticationContents),
                  },
                },
              }
            );

            if (createErrors) {
              setSubmitting(false);
              return handleError({ graphQLErrors: createErrors });
            }

            const { name: savedName } = data!.whatsAppCreateMessageTemplateCr;

            setSubmitting(false);
            resetForm();
            history.push(
              templateDetailsPath(Channel.Whatsapp, aspClientId, savedName)
            );
            toast('success', {
              heading: t.common.toasts.saveDraftSuccess(),
            });
          } catch (e) {
            setSubmitting(false);
            handleError(e);
          }
        };

        const handleSubmit = async (savedCrs: IWhatsAppMtTemplateContent[]) => {
          const { aspClientId, name } = values;

          try {
            const submitCrIds: string[] = savedCrs
              .map((content) => content.id)
              .filter((crId): crId is string => crId !== undefined);

            const { errors: transitionErrors } =
              await transitionToReviewMutation({
                variables: {
                  transitionInput: {
                    aspClientId,
                    crIds: submitCrIds,
                  },
                },
              });

            if (transitionErrors) {
              setSubmitting(false);
              setHasTransitionErrors(true);
              return handleError({ graphQLErrors: transitionErrors }, true);
            }

            setSubmitting(false);
            resetForm();

            history.push(
              templateDetailsPath(Channel.Whatsapp, aspClientId, name)
            );
            toast('success', {
              heading: t.common.toasts.submitRequestSuccess(),
            });
          } catch (e) {
            setSubmitting(false);
            handleError(e);
          }
        };

        const shouldBlockExternalNavigation = () => dirty;
        return (
          <>
            <Form>
              <Grid>
                <GridItem mb="small">
                  <TemplatePageHeader
                    title={t.whatsapp.messageTemplates.register.heading()}
                    breadcrumbDestination={templateListPath(Channel.Whatsapp)}
                    cancel={{
                      onClick: () =>
                        history.push(templateListPath(Channel.Whatsapp)),
                    }}
                    draft={{
                      onClick: handlePreSaveDraft,
                      disabled: isSubmitting || !dirty || readOnlyAccess,
                      loading: isSubmitting && !shouldSubmitRequest,
                    }}
                    submit={{
                      onClick: handlePreSubmit,
                      disabled: isSubmitting || !dirty || readOnlyAccess,
                      loading: isSubmitting && shouldSubmitRequest,
                    }}
                  />
                </GridItem>
                {readOnlyAccess && (
                  <GridItem mb="small">
                    <ScopedNotification variant="info">
                      {t.common.readOnlyAccessNotification()}
                    </ScopedNotification>
                  </GridItem>
                )}
                <GridItem>
                  <QueryHandler query={NEW_TEMPLATE_QUERY} levitate>
                    {({
                      data: {
                        whatsAppAspClientsList:
                          onboardedAspClientsWithActiveChannels,
                      },
                    }: IQueryHandlerResult<INewTemplateQueryResponse>) => (
                      <TemplateForm
                        values={values}
                        aspClients={onboardedAspClientsWithActiveChannels}
                        errors={errors}
                        submitCount={submitCount}
                      />
                    )}
                  </QueryHandler>
                </GridItem>
              </Grid>
              <ConfirmModal
                handleModalClose={handleModalClose}
                handleModalSubmit={handleModalSubmit}
                handleSaveDraft={handleSaveDraft}
                languageErrors={languageErrors}
                languagesToSubmit={languagesToSubmit}
                mode={'add'}
                setLanguagesToSubmit={setLanguagesToSubmit}
                shouldSubmitRequest={shouldSubmitRequest}
                showModal={showModal}
                values={values}
              />
              <ErrorModal
                heading={errorHeading}
                onClose={handleErrorModalClose}
                isOpen={showErrorModal}
                label={t.common.actions.close()}
                footer={
                  hasTransitionErrors && [
                    <Button
                      id="error-modal-edit-template-button"
                      onClick={() =>
                        history.push(
                          templateEditPath(
                            Channel.Whatsapp,
                            values.aspClientId,
                            values.name
                          )
                        )
                      }
                    >
                      {t.common.actions.editDraft()}
                    </Button>,
                    <Button
                      id="error-modal-view-template-button"
                      onClick={() =>
                        history.push(
                          templateDetailsPath(
                            Channel.Whatsapp,
                            values.aspClientId,
                            values.name
                          )
                        )
                      }
                    >
                      {t.common.actions.viewTemplate()}
                    </Button>,
                  ]
                }
              >
                <Box pl="medium" pr="medium" pt="large" pb="large">
                  <Text id="message-template-error-modal-message">
                    {errorExplanation}
                  </Text>
                </Box>
              </ErrorModal>
            </Form>
            <RouteLeavingGuard
              whenBlockInternalNavigation={dirty && !hasTransitionErrors}
              navigate={(path) => {
                history.push(path);
              }}
              shouldBlockExternalNavigation={shouldBlockExternalNavigation}
            />
          </>
        );
      }}
    </Formik>
  );
};
