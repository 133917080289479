import React, { useMemo } from 'react';
import { IComboboxOption } from '@salesforce/design-system-react';
import { useFormikContext } from 'formik';

import { t } from '@src/messages';
import {
  Section,
  InputField,
  SingleSelectField,
  Grid,
  GridItem,
  Text,
  AspClientSelect,
  RadioGroup,
  Radio,
} from '@src/common/components';
import { useFeatureFlags } from '@src/common/utils/hooks';
import {
  IWhatsAppAuthenticationMtCrContentInput,
  IWhatsAppMtCrInput,
  WhatsAppMtCategory,
  WhatsAppMtLanguage,
} from '@shared/bff';

import {
  prepareOptionsForTemplateCategories,
  ITemplateCategoryOption,
  templateFieldsMaxLength,
  templateNameRegexConstraint,
  templateNameRegexReplace,
  addAuthenticationMessageContent,
  addMessageContent,
} from '../../../utils';
import { ContentPayload } from '../../../types';

interface ITemplateConfigurationProps {
  aspClientOptions: IComboboxOption[];
  category?: WhatsAppMtCategory;
  languages: WhatsAppMtLanguage[];
}

export const TemplateConfiguration = ({
  aspClientOptions,
  category,
  languages,
}: ITemplateConfigurationProps) => {
  const { autoReply, whatsAppNewTemplateCategories } = useFeatureFlags();
  const { setFieldValue } = useFormikContext();

  const templateCategoryOptions = useMemo<ITemplateCategoryOption[]>(
    () => prepareOptionsForTemplateCategories(autoReply),
    []
  );

  return (
    <Section>
      <Grid>
        <GridItem cols={4} pr="small">
          <AspClientSelect type="templates" options={aspClientOptions} />
        </GridItem>
        <GridItem cols={4} pr="small">
          <InputField<IWhatsAppMtCrInput>
            name="name"
            label={t.whatsapp.messageTemplates.fields.templateName()}
            placeholder={t.whatsapp.messageTemplates.placeholders.templateName()}
            tooltip={t.whatsapp.messageTemplates.tooltips.templateName()}
            id="template-name-input"
            maxLength={`${templateFieldsMaxLength.name}`}
            regexConstraint={templateNameRegexConstraint}
            regexReplace={templateNameRegexReplace}
            excludeLeadingSpace
            required
          />
          <Text variant="body_small" color="weak" mt="xxx-small">
            {t.whatsapp.messageTemplates.register.helpTexts.templateName()}
          </Text>
        </GridItem>
        {whatsAppNewTemplateCategories && (
          <GridItem cols={4} pr="small">
            <RadioGroup
              name="category"
              disabled={false}
              labels={{
                label: t.whatsapp.messageTemplates.fields.templateCategory(),
              }}
              tooltip={t.whatsapp.messageTemplates.tooltips.templateCategoryRadioButtons()}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value === WhatsAppMtCategory.AUTHENTICATION) {
                  setFieldValue('contents', undefined);
                  if (languages.length > 0) {
                    let contents: IWhatsAppAuthenticationMtCrContentInput[] =
                      [];
                    languages.forEach((language) => {
                      const newContents = addAuthenticationMessageContent(
                        language,
                        contents
                      );
                      contents = newContents;
                    });
                    setFieldValue('authenticationContents', contents);
                  }
                } else {
                  setFieldValue('authenticationContents', undefined);
                  if (
                    category === WhatsAppMtCategory.AUTHENTICATION &&
                    languages.length > 0
                  ) {
                    let contents: ContentPayload[] = [];
                    languages.forEach((language) => {
                      const newContents = addMessageContent(language, contents);
                      contents = newContents;
                    });
                    setFieldValue('contents', contents);
                  }
                }
                setFieldValue('category', event.target.value);
              }}
              required
            >
              <Radio
                key="marketing"
                labels={{
                  label:
                    t.whatsapp.messageTemplates.templateCategoryLabels.MARKETING(),
                }}
                value={WhatsAppMtCategory.MARKETING}
                id="template-category-marketing"
              />
              <Radio
                key="utility"
                labels={{
                  label:
                    t.whatsapp.messageTemplates.templateCategoryLabels.UTILITY(),
                }}
                value={WhatsAppMtCategory.UTILITY}
                id="template-category-utility"
              />
              <Radio
                key="one-time-authentication"
                labels={{
                  label:
                    t.whatsapp.messageTemplates.templateCategoryLabels.AUTHENTICATION(),
                }}
                value={WhatsAppMtCategory.AUTHENTICATION}
                id="template-category-authentication"
              />
            </RadioGroup>
          </GridItem>
        )}
        {!whatsAppNewTemplateCategories && (
          <GridItem cols={4} mb="small">
            <SingleSelectField<IWhatsAppMtCrInput>
              name="category"
              labels={{
                label: t.whatsapp.messageTemplates.fields.templateCategory(),
                placeholderReadOnly:
                  t.whatsapp.messageTemplates.placeholders.templateCategory(),
              }}
              tooltip={t.whatsapp.messageTemplates.tooltips.templateCategory()}
              options={templateCategoryOptions}
              id="template-category-input"
              required
              variant="readonly"
            />
          </GridItem>
        )}
      </Grid>
    </Section>
  );
};
