import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';

import {
  UploadFileMutationResponse,
  UPLOAD_FILE_MUTATION,
} from '@src/common/graphql';
import {
  Box,
  FileUploader,
  Label,
  MultiLineErrorMessage,
  SFIcon,
  Spinner,
  Text,
  ToastContext,
} from '@src/common/components';
import { t } from '@src/messages';
import { FileTypeMapper, getFileName } from '@src/common/utils';
import { IMutationUploadFileArgs, IFileInput } from '@shared/bff';

interface IFileSampleProps {
  fieldName: string;
  maxSize: number;
  accept?: string;
}

const SpinnerWrapper = styled.div({
  position: 'relative',
  height: '40px',
  width: '40px',
});

export const FileSample = ({
  fieldName,
  maxSize,
  accept,
}: IFileSampleProps) => {
  const { setFieldValue } = useFormikContext();
  const { toast } = useContext(ToastContext);
  const [field] = useField(fieldName);
  const [_, meta] = useField(`${fieldName}.url`);
  const { value } = field;
  const { error, touched } = meta;

  const [uploading, setUploading] = useState<boolean>(false);

  const [uploadFileMutation] = useMutation<
    UploadFileMutationResponse,
    IMutationUploadFileArgs
  >(UPLOAD_FILE_MUTATION);

  return (
    <>
      <Label
        id="header-media-sample-image-tooltip"
        tooltip={t.whatsapp.messageTemplates.tooltips.headerMediaSampleTooltip()}
        required
      >
        <Text color="weak">
          {t.whatsapp.messageTemplates.fields.headerMediaSample()}
        </Text>
      </Label>
      <Box mt="small" />
      {value.url && (
        <Box
          display="inline-flex"
          alignItems="center"
          mb="small"
          id="file-preview"
        >
          <SFIcon name={FileTypeMapper[value.type]} />
          <Box display="inline" ml="x-small" />
          <Text>{getFileName(value.url)}</Text>
        </Box>
      )}
      {uploading && (
        <Box display="inline-flex" alignItems="center">
          <SpinnerWrapper>
            <Spinner size="small" variant="brand" id="file-upload-spinner" />
          </SpinnerWrapper>
          {t.whatsapp.botForm.upload.loadingDescription()}
        </Box>
      )}
      <MultiLineErrorMessage
        errors={error}
        touched={touched}
        render={() => {
          return (
            <FileUploader
              gridSize={7}
              dragDropOptions={{
                ...(accept ? { accept } : {}),
                multiple: false,
                maxSize,
              }}
              uploadButtonTitle={
                value.url
                  ? t.common.components.fileUploader.single.replaceButtonDescription()
                  : t.common.components.fileUploader.single.uploadButtonDescription()
              }
              onFilesUpload={async (files: IFileInput[]) => {
                setUploading(true);
                try {
                  const { name, content, mimeType } = files[0];
                  const { data } = await uploadFileMutation({
                    variables: {
                      file: { name, content, mimeType },
                    },
                  });

                  setFieldValue(fieldName, {
                    url: data?.uploadFile.fileUrl,
                    type: mimeType,
                  });
                } catch (err) {
                  toast('error', {
                    heading: t.common.toasts.somethingWentWrong(),
                  });
                }
                setUploading(false);
              }}
            />
          );
        }}
      />
    </>
  );
};
