import { useField, useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import {
  Box,
  Button,
  FileUploader,
  Grid,
  GridItem,
  Label,
  MultiLineErrorMessage,
  SFIcon,
  Spinner,
  Text,
  ToastContext,
} from '@src/common/components';
import { t } from '@src/messages';
import { FileTypeMapper, getFileName } from '@src/common/utils';
import { IFileInput } from '@src/common/types';

const SpinnerWrapper = styled.div({
  position: 'relative',
  height: '40px',
  width: '40px',
});

const FileWrapper = styled(Box)({
  '& .delete-button': {
    visibility: 'hidden',
  },
  '&:hover': {
    backgroundColor: '#F3F2F2',
    '& .delete-button': {
      visibility: 'visible',
    },
  },
});

const FileName = styled.div({
  width: '200px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const WarrantyLetter = () => {
  const { setFieldValue } = useFormikContext();
  const { toast } = useContext(ToastContext);
  const [field, meta] = useField('warrantyLetterUrl');
  const { value } = field;
  const { error, touched } = meta;

  const [warrantyLetterMimeType, setWarrantyLetterMimeType] =
    useState<string>('application/pdf');
  const [uploading, setUploading] = useState<boolean>(false);

  const onDeleteFile = () => setFieldValue('warrantyLetterUrl', '');

  return (
    <Grid>
      <GridItem ml="small">
        <Label
          id="header-media-sample-image-tooltip"
          tooltip={t.viberBusiness.aspClients.register.tooltips.warrantyLetter()}
          required
        >
          <Text color="weak" mb="small">
            {t.viberBusiness.aspClients.fields.warrantyLetter()}
          </Text>
        </Label>
        <MultiLineErrorMessage
          errors={error}
          touched={touched}
          render={() => {
            return (
              <FileUploader
                dragDropOptions={{
                  multiple: false,
                  accept: 'application/pdf',
                }}
                uploadButtonTitle={
                  value
                    ? t.common.components.fileUploader.single.replaceButtonDescription()
                    : t.common.components.fileUploader.single.uploadButtonDescription()
                }
                onFilesUpload={async (files: IFileInput[]) => {
                  setUploading(true);
                  try {
                    const { name, blob } = files[0];
                    const formData = new FormData();
                    formData.append('file', blob, name);
                    const res = await fetch(
                      `/api/data/upload/temporary/${name}`,
                      {
                        method: 'POST',
                        body: formData,
                      }
                    );
                    const { fileUrl, mimeType } = await res.json();
                    setFieldValue('warrantyLetterUrl', fileUrl);
                    setWarrantyLetterMimeType(mimeType);
                  } catch (err) {
                    toast('error', {
                      heading: t.common.toasts.somethingWentWrong(),
                    });
                  }
                  setUploading(false);
                }}
              />
            );
          }}
        />
        <Box mt="small" />
        {uploading && (
          <Box display="inline-flex" alignItems="center">
            <SpinnerWrapper>
              <Spinner size="small" variant="brand" id="file-upload-spinner" />
            </SpinnerWrapper>
            {t.whatsapp.botForm.upload.loadingDescription()}
          </Box>
        )}
        {value && (
          <Box
            display="inline-flex"
            alignItems="center"
            mb="small"
            id="file-preview"
          >
            <FileWrapper pl="small" pr="small" display="flex">
              <Box display="inline-flex" alignItems="center">
                <SFIcon name={FileTypeMapper[warrantyLetterMimeType]} />
                <Box display="inline" ml="x-small" />
                <FileName>{getFileName(value)}</FileName>
              </Box>
              <Button
                className="delete-button"
                onClick={onDeleteFile}
                iconCategory="utility"
                iconName="delete"
                iconVariant="border-filled"
                variant="icon"
              />
            </FileWrapper>
          </Box>
        )}
      </GridItem>
    </Grid>
  );
};
