import { pick } from 'lodash-es';

import { IWhatsAppBot, IWhatsAppBotInput } from '@shared/bff';

import { ICrBotDataFragment, IWhatsAppBotFragment } from '../graphql';

const botFields: (keyof IWhatsAppBot)[] = [
  'about',
  'aspClientId',
  'address',
  'countryCode',
  'description',
  'email',
  'id',
  'photoUrl',
  'name',
  'phone',
  'state',
  'vertical',
  'websiteOne',
  'websiteTwo',
  'numberProviderType',
];

export const mapChannelToFormValues = (
  bot: IWhatsAppBotFragment | ICrBotDataFragment
): IWhatsAppBotInput => {
  return {
    ...pick(bot, botFields),
  } as IWhatsAppBotInput;
};
