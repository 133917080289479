import React from 'react';
import { FieldArray } from 'formik';
import { t } from 'messages';

import { InputField, GridItem, Grid, Text, Box } from '@src/common/components';

import { ContentPayload } from '../../../types';
import {
  getMessageContentButtonPath,
  contentFieldsMaxLength,
  templateButtonRegexConstraint,
} from '../../../utils';
import { ButtonsSection } from '../ButtonsSection';
import { RemoveButtonIcon } from '../RemoveButtonIcon';

interface IQuickReplyButtonsProps {
  selectedTabIndex: number;
  content: ContentPayload;
  isRtlLanguage: boolean;
}

export const QuickReplyButtons = ({
  selectedTabIndex,
  content,
  isRtlLanguage,
}: IQuickReplyButtonsProps) => {
  const quickReplyButtons = content.quickReplyButtons || [];
  const disabled = quickReplyButtons.length > 2;

  const createButtons = (onRemove: (index: number) => void) =>
    quickReplyButtons.map((_: string, index: number) => {
      const canRemoveButton = index !== 0 || quickReplyButtons.length > 1;
      return (
        <Box mb="small" ml="medium" key={index}>
          <Grid>
            <GridItem cols={4} pr="small">
              <Text
                variant="body_small"
                color="weak"
              >{`${t.whatsapp.messageTemplates.fields.button()} ${
                index + 1
              }`}</Text>
              <InputField
                name={getMessageContentButtonPath(
                  selectedTabIndex,
                  'quickReplyButtons',
                  index
                )}
                label={t.whatsapp.messageTemplates.fields.buttonText()}
                maxLength={`${contentFieldsMaxLength.quickReplyButtons}`}
                className={isRtlLanguage ? 'rtl' : ''}
                id={`button-text-${index + 1}-input`}
                regexConstraint={templateButtonRegexConstraint}
                excludeEmojis
                required
              />
            </GridItem>
            <GridItem cols={1}>
              {canRemoveButton && (
                <RemoveButtonIcon
                  handleRemove={() => onRemove(index)}
                  marginTop={42}
                />
              )}
            </GridItem>
          </Grid>
        </Box>
      );
    });

  return (
    <FieldArray
      name={getMessageContentButtonPath(selectedTabIndex, 'quickReplyButtons')}
      render={(arrayHelpers) => (
        <ButtonsSection
          buttons={createButtons(arrayHelpers.remove)}
          disabled={disabled}
          helpText={t.whatsapp.messageTemplates.register.helpTexts.quickReplyButtons()}
          handleAdd={() => arrayHelpers.push('')}
        />
      )}
    />
  );
};
