import { gql } from '@apollo/client';

import { IWhatsAppBot } from '@shared/bff';

import {
  IBotListItemCrFragment,
  BOT_LIST_ITEM_CR_FRAGMENT,
} from './BotListItemCrFragment';

export type IWhatsAppBotFragment = Pick<
  IWhatsAppBot,
  | 'id'
  | 'aspClientId'
  | 'aspClientName'
  | 'name'
  | 'description'
  | 'about'
  | 'state'
  | 'websiteOne'
  | 'websiteTwo'
  | 'address'
  | 'countryCode'
  | 'email'
  | 'photoUrl'
  | 'phone'
  | 'vertical'
  | 'updated'
  | 'numberProviderType'
  | 'quality'
  | 'dailyLimit'
> & { botCr: IBotListItemCrFragment | null };

export const WHATSAPP_BOT_FRAGMENT = gql`
  fragment WhatsAppBotFragment on WhatsAppBot {
    id
    name
    description
    about
    aspClientId
    aspClientName
    state
    websiteOne
    websiteTwo
    address
    countryCode
    email
    photoUrl
    phone
    vertical
    updated
    numberProviderType
    botCr {
      ...BotListItemCrFragment
    }
    quality
    dailyLimit
  }
  ${BOT_LIST_ITEM_CR_FRAGMENT}
`;
