import { gql } from '@apollo/client';

import { IWhatsAppAspClientsResponse } from '@shared/bff';

export interface IAspClientsQueryResponse {
  whatsAppAspClients: IWhatsAppAspClientsResponse;
}

export const ASP_CLIENTS_QUERY = gql`
  query WhatsAppAspClients {
    whatsAppAspClients {
      pending {
        id
        name
        state
        updated
      }
      onboarded {
        id
        name
        state
        updated
      }
    }
  }
`;
