import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { IBotCrIdMatchParams } from '@src/common/types';
import { QueryHandler, IQueryHandlerResult } from 'common/components';

import {
  CR_QUERY,
  BOT_QUERY,
  ICrQueryResponse,
  IBotQueryResponse,
} from '../../graphql';
import { ChangeRequestDetails } from '../../components/ChangeRequestDetails';

import { Header } from './Header';

interface IChangeRequestDetailsViewProps {
  hasBot?: boolean;
}

export const ChangeRequestDetailsView = ({
  hasBot,
}: IChangeRequestDetailsViewProps) => {
  const { botCrId } = useParams<IBotCrIdMatchParams>();
  const [showCurrent, setShowCurrent] = useState(false);

  return (
    <QueryHandler
      query={CR_QUERY}
      variables={{
        botCrId: botCrId,
      }}
      levitate
    >
      {({ data: { whatsAppCr } }: IQueryHandlerResult<ICrQueryResponse>) => {
        if (hasBot) {
          return (
            <QueryHandler
              query={BOT_QUERY}
              variables={{
                botId: whatsAppCr.botId,
              }}
              levitate
            >
              {({
                data: { whatsAppBot },
              }: IQueryHandlerResult<IBotQueryResponse>) => {
                return (
                  <>
                    <Header
                      showCurrent={showCurrent}
                      whatsAppCr={whatsAppCr}
                      setShowCurrent={setShowCurrent}
                      hasBot={hasBot}
                    />
                    <ChangeRequestDetails
                      accountName={whatsAppCr.bot.aspClientName}
                      bot={whatsAppBot}
                      whatsAppCr={whatsAppCr}
                      showDiff={showCurrent}
                    />
                  </>
                );
              }}
            </QueryHandler>
          );
        }
        return (
          <>
            <Header
              showCurrent={showCurrent}
              whatsAppCr={whatsAppCr}
              setShowCurrent={setShowCurrent}
              hasBot={hasBot}
            />
            <ChangeRequestDetails
              accountName={whatsAppCr.bot.aspClientName}
              whatsAppCr={whatsAppCr}
              showDiff={showCurrent}
            />
          </>
        );
      }}
    </QueryHandler>
  );
};
