import React from 'react';

import { t } from '@src/messages';
import { GridItem, Grid, Text, Button, Box } from '@src/common/components';

import { CallToActionButton } from '../../../types';

import { CallToActionFieldSection } from './CallToActionFieldSection';

interface ICallToActionSectionProps {
  callToActionButtons: CallToActionButton[];
  isRtlLanguage?: boolean;
}

export const CallToActionSection = ({
  callToActionButtons,
  isRtlLanguage,
}: ICallToActionSectionProps) => {
  const buttons = callToActionButtons.map((button, index) => {
    return (
      <Box mb="small" ml="medium" key={index}>
        <Grid>
          <GridItem cols={11}>
            <Text
              variant="body_small"
              color="weak"
            >{`${t.whatsapp.messageTemplates.fields.button()} ${
              index + 1
            }`}</Text>
            <CallToActionFieldSection
              button={button}
              isRtlLanguage={isRtlLanguage}
            />
          </GridItem>
        </Grid>
      </Box>
    );
  });

  return (
    <>
      <Text variant="body_small" color="weak" mt="small" mb="small">
        {t.whatsapp.messageTemplates.register.helpTexts.callToActionButtons()}
      </Text>
      {buttons}
      <Box ml="medium">
        <Button disabled>
          {t.whatsapp.messageTemplates.register.buttonLabels.addButton()}
        </Button>
      </Box>
    </>
  );
};
