import {
  WhatsAppBusinessAPITemplate,
  WhatsAppBusinessAPIComponent,
  WhatsAppBusinessAPICategory,
} from '@sinch/previewer';

import {
  WhatsAppMtCategory,
  WhatsAppMtLanguage,
  IWhatsAppMtTemplateContent,
  WhatsAppMtUrlType,
  WhatsAppMtHeaderType,
  WhatsAppMtButtonType,
  IWhatsAppAuthenticationMtCrContentInput,
} from '@shared/bff';

import { extractIndex } from '../components/form/MessageContent/variable-utils';
import { ContentPayload, CallToActionButton } from '../types';

import {
  mapMessageTemplateLanguageToBodySecurityText,
  mapMessageTemplateLanguageToBodyText,
  mapMessageTemplateLanguageToFooterText,
} from './authenticationStrings';

interface IPrepareDataForTemplatePreviewerPayload {
  name: string;
  category: WhatsAppMtCategory;
  language?: WhatsAppMtLanguage;
  content?: ContentPayload | IWhatsAppMtTemplateContent;
  authenticationContent?: IWhatsAppAuthenticationMtCrContentInput;
  previewSamples?: boolean;
}

type TemplateType = 'HEADER' | 'BODY' | 'FOOTER' | 'BUTTONS';

type TemplateFormat = 'TEXT' | 'IMAGE' | 'DOCUMENT' | 'VIDEO';

type ButtonsType = 'QUICK_REPLY' | 'PHONE_NUMBER' | 'URL';

const templateTypeMapper: Record<TemplateType, TemplateType> = {
  HEADER: 'HEADER',
  BODY: 'BODY',
  FOOTER: 'FOOTER',
  BUTTONS: 'BUTTONS',
};

const templateFormatMapper: Record<TemplateFormat, TemplateFormat> = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  DOCUMENT: 'DOCUMENT',
  VIDEO: 'VIDEO',
};

const buttonTypeWrapper: Record<ButtonsType, ButtonsType> = {
  QUICK_REPLY: 'QUICK_REPLY',
  PHONE_NUMBER: 'PHONE_NUMBER',
  URL: 'URL',
};

const defaultBodyComponent = {
  type: templateTypeMapper.BODY,
  format: templateFormatMapper.TEXT,
  text: '',
};
const defaultFooterComponent = {
  type: templateTypeMapper.FOOTER,
  format: templateFormatMapper.TEXT,
  text: '',
};

const mapQuickReplyButtons = (quickReplyButtons: string[]) =>
  quickReplyButtons.map((quickReplyButton) => ({
    type: buttonTypeWrapper.QUICK_REPLY,
    text: quickReplyButton,
  }));

const mapCallToActionButtons = (
  callToActionButtons: CallToActionButton[],
  buttonUrlVariable: string | null | undefined
) =>
  callToActionButtons.map((callToActionButton) => {
    const { buttonText } = callToActionButton;

    if ('phoneNumber' in callToActionButton) {
      const { phoneNumber } = callToActionButton;

      return {
        type: buttonTypeWrapper.PHONE_NUMBER,
        phone_number: phoneNumber,
        text: buttonText,
      };
    }

    const { websiteUrl, urlType } = callToActionButton;
    const subUrl =
      websiteUrl.charAt(websiteUrl.length - 1) === '/'
        ? buttonUrlVariable
        : `/${buttonUrlVariable}`;

    return {
      type: buttonTypeWrapper.URL,
      text: buttonText,
      URL:
        urlType === WhatsAppMtUrlType.DYNAMIC && buttonUrlVariable
          ? `${websiteUrl}${subUrl}`
          : websiteUrl,
    };
  });

export const prepareDataForTemplatePreviewer = ({
  name,
  category = WhatsAppMtCategory.NO_CATEGORY,
  language = WhatsAppMtLanguage.EN,
  content,
  authenticationContent,
  previewSamples,
}: IPrepareDataForTemplatePreviewerPayload): WhatsAppBusinessAPITemplate => {
  if (!content && !authenticationContent) {
    return getDefaultFields(
      name,
      category as WhatsAppBusinessAPICategory,
      language
    );
  }

  if (authenticationContent) {
    const { securityRecommendation, codeExpirationMinutes, buttonText } =
      authenticationContent;
    return getAuthenticationFields(
      name,
      category as WhatsAppBusinessAPICategory,
      language,
      securityRecommendation,
      codeExpirationMinutes,
      buttonText,
      previewSamples
    );
  }

  if (content && 'otpType' in content && content.otpType) {
    const { securityRecommendation, codeExpirationMinutes, buttonText } =
      content;
    return getAuthenticationFields(
      name,
      category as WhatsAppBusinessAPICategory,
      language,
      securityRecommendation,
      codeExpirationMinutes,
      buttonText || '',
      previewSamples
    );
  }

  if (content) {
    const {
      headerType,
      mediaType,
      headerText,
      bodyText,
      footer,
      buttonType,
      quickReplyButtons = [],
      callToActionButtons = [],
      mediaVariable,
      headerVariable,
      bodyVariables,
      buttonUrlVariable,
    } = content;

    const headerComponent = {
      type: templateTypeMapper.HEADER,
      format: (headerType === WhatsAppMtHeaderType.MEDIA
        ? mediaType
        : headerType) as TemplateFormat,
      text:
        headerType === WhatsAppMtHeaderType.MEDIA &&
        mediaVariable &&
        mediaVariable.url
          ? mediaVariable.url
          : headerText &&
            headerText.includes('{{1}}') &&
            headerVariable &&
            previewSamples
          ? headerText.replace('{{1}}', headerVariable).trim()
          : headerText?.trim() ?? '',
    };
    let messageBody = bodyText;
    const bodyTextVariables = bodyText.match(/\{\{\d*\}\}/g);
    if (previewSamples && bodyTextVariables && bodyTextVariables.length > 0) {
      bodyTextVariables.forEach((value) => {
        const variableIndex = extractIndex(value);
        messageBody = messageBody.replace(
          `{{${variableIndex}}}`,
          bodyVariables && bodyVariables[variableIndex - 1]
            ? bodyVariables[variableIndex - 1]
            : value
        );
      });
    }
    const bodyComponent = bodyText
      ? {
          type: templateTypeMapper.BODY,
          format: templateFormatMapper.TEXT,
          text: messageBody,
        }
      : defaultBodyComponent;
    const footerComponent = footer
      ? {
          type: templateTypeMapper.FOOTER,
          format: templateFormatMapper.TEXT,
          text: footer,
        }
      : defaultFooterComponent;

    // message-preveiwer package does not export types for buttons, any for now
    const buttonsMapper: Record<
      WhatsAppMtButtonType,
      WhatsAppBusinessAPIComponent['buttons']
    > = {
      QUICK_REPLY: mapQuickReplyButtons((quickReplyButtons as string[]) || []),
      CALL_TO_ACTION: mapCallToActionButtons(
        (callToActionButtons as CallToActionButton[]) || [],
        buttonUrlVariable
      ),
      NONE: [],
    };

    const buttonsComponent: WhatsAppBusinessAPIComponent = {
      type: templateTypeMapper.BUTTONS,
      buttons: buttonsMapper[buttonType],
    };

    return {
      // @ts-expect-error category types differ, previewer does not export type for category
      category,
      language,
      name,
      components: [
        headerComponent,
        bodyComponent,
        footerComponent,
        buttonsComponent,
      ],
    };
  }

  return getDefaultFields(
    name,
    category as WhatsAppBusinessAPICategory,
    language
  );
};

const getDefaultFields = (
  name: string,
  category: WhatsAppBusinessAPICategory,
  language: WhatsAppMtLanguage
) => ({
  category,
  language,
  name,
  components: [defaultBodyComponent, defaultFooterComponent],
});

const getAuthenticationFields = (
  name: string,
  category: WhatsAppBusinessAPICategory,
  language: WhatsAppMtLanguage,
  securityRecommendation: boolean | undefined | null,
  codeExpirationMinutes: number | undefined | null,
  buttonText: string,
  previewSamples?: boolean
) => {
  const bodyText = `${mapMessageTemplateLanguageToBodyText[language]}${
    securityRecommendation
      ? ` ${mapMessageTemplateLanguageToBodySecurityText[language]}`
      : ''
  }`;
  const bodyComponent = {
    type: templateTypeMapper.BODY,
    format: templateFormatMapper.TEXT,
    text: previewSamples ? bodyText.replace('{{1}}', '123456') : bodyText,
  };
  const footerComponent = {
    type: templateTypeMapper.FOOTER,
    format: templateFormatMapper.TEXT,
    text:
      codeExpirationMinutes !== null && codeExpirationMinutes !== undefined
        ? mapMessageTemplateLanguageToFooterText(codeExpirationMinutes)[
            language
          ]
        : '',
  };
  const buttonComponent = {
    type: templateTypeMapper.BUTTONS,
    buttons: [
      {
        text: buttonText,
        type: buttonTypeWrapper.URL,
        URL: 'https://www.whatsapp.com/otp/code/?otp_type=COPY_CODE&code=otp{{1}}',
      },
    ],
  };
  return {
    category,
    language,
    name,
    components: [bodyComponent, footerComponent, buttonComponent],
  };
};
