import { gql } from '@apollo/client';

import { IWhatsAppCr } from '@shared/bff';

export type ICreateCrMutationResponse = {
  whatsAppCreateCr: Pick<IWhatsAppCr, 'id'>;
};

export const CREATE_CR_MUTATION = gql`
  mutation WhatsAppCreateCr($whatsAppCr: WhatsAppCrInput!) {
    whatsAppCreateCr(whatsAppCr: $whatsAppCr) {
      id
    }
  }
`;
