import React, { useMemo, ReactNode } from 'react';
import styled from 'styled-components';

import { t } from '@src/messages';
import {
  Text,
  Box,
  DescriptionList,
  GridItem,
  Grid,
  ScopedNotification,
  DocumentationLink,
} from '@src/common/components';
import { formatPhoneNumber } from '@src/common/utils';
import {
  IWhatsAppMtTemplateContent,
  WhatsAppMtStatus,
  CrState,
  WhatsAppMtHeaderType,
  WhatsAppMtButtonType,
} from '@shared/bff';

import {
  mapMessageTemplateLanguageToBodySecurityText,
  mapMessageTemplateLanguageToBodyText,
  mapMessageTemplateLanguageToFooterText,
} from '../utils';

interface IMessageContentDetailsProps {
  content: IWhatsAppMtTemplateContent;
}

interface IDescriptionField {
  label: ReactNode;
  detail: ReactNode;
  id?: string;
}

const MessageBodyWrapper = styled.div({
  whiteSpace: 'pre-wrap',
});

const TextWrapper = styled(Text)({
  wordBreak: 'break-word',
});

export const MessageContentDetails = ({
  content,
}: IMessageContentDetailsProps) => {
  const {
    language,
    headerType,
    headerText,
    mediaType,
    bodyText,
    footer,
    buttonType,
    quickReplyButtons,
    callToActionButtons,
    securityRecommendation,
    codeExpirationMinutes,
    buttonText,
  } = content;

  const securityText = `${mapMessageTemplateLanguageToBodyText[language]}${
    securityRecommendation
      ? ` ${mapMessageTemplateLanguageToBodySecurityText[language]}`
      : ''
  }`;
  const messageBody =
    bodyText !== '' ? bodyText : 'otpType' in content ? securityText : '';
  const footerText =
    footer !== ''
      ? footer
      : codeExpirationMinutes
      ? mapMessageTemplateLanguageToFooterText(codeExpirationMinutes)[language]
      : footer;

  const showRejectedNotification =
    ('templateStatus' in content &&
      content.templateStatus === WhatsAppMtStatus.REJECTED) ||
    ('crStatus' in content && content.crStatus === CrState.REJECTED);

  const HeaderTypeMapper: Record<WhatsAppMtHeaderType, IDescriptionField[]> = {
    [WhatsAppMtHeaderType.NONE]: [],
    [WhatsAppMtHeaderType.TEXT]: [
      {
        label: t.whatsapp.messageTemplates.fields.headerText(),
        detail: headerText,
        id: 'header-text',
      },
    ],
    [WhatsAppMtHeaderType.MEDIA]: [
      {
        label: t.whatsapp.messageTemplates.fields.mediaType(),
        detail:
          t.whatsapp.messageTemplates.templateMediaTypeLabels[mediaType](),
        id: 'media',
      },
    ],
  };

  const ButtonTypeMapper: Record<WhatsAppMtButtonType, IDescriptionField[]> = {
    [WhatsAppMtButtonType.NONE]: buttonText
      ? [
          {
            label: `${t.whatsapp.messageTemplates.fields.button()} 1`,
            detail: buttonText,
            id: 'button-1',
          },
        ]
      : [],
    [WhatsAppMtButtonType.QUICK_REPLY]: quickReplyButtons
      ? quickReplyButtons.map((button, index) => ({
          label: `${t.whatsapp.messageTemplates.fields.button()} ${index + 1}`,
          detail: button,
          id: `button-${index}`,
        }))
      : [],
    [WhatsAppMtButtonType.CALL_TO_ACTION]: callToActionButtons
      ? callToActionButtons.map((button, index) => {
          const { buttonText: text } = button;
          return {
            label: `${t.whatsapp.messageTemplates.fields.button()} ${
              index + 1
            }`,
            detail: (
              <Grid>
                <GridItem mb="small">
                  {'phoneNumber' in button
                    ? t.whatsapp.messageTemplates.templateCallToActionTypeLabels.CALL_PHONE_NUMBER()
                    : t.whatsapp.messageTemplates.templateCallToActionTypeLabels.VISIT_WEBSITE()}
                </GridItem>
                <GridItem mb="small" id={`button-text-${index + 1}`}>
                  {text}
                </GridItem>
                <GridItem
                  id={`${
                    'phoneNumber' in button ? 'phone-number' : 'website-url'
                  }`}
                >
                  <TextWrapper>
                    {'phoneNumber' in button
                      ? button.phoneNumber !== ''
                        ? formatPhoneNumber({ phoneNumber: button.phoneNumber })
                        : ''
                      : button.websiteUrl}
                  </TextWrapper>
                </GridItem>
              </Grid>
            ),
          };
        })
      : [],
  };

  const fields: IDescriptionField[] = useMemo(
    () => [
      {
        label: t.whatsapp.messageTemplates.fields.header(),
        detail: t.whatsapp.messageTemplates.templateHeaderLabels[headerType](),
        id: 'header',
      },
      ...HeaderTypeMapper[headerType],
      {
        label: t.whatsapp.messageTemplates.fields.messageBody(),
        detail: <MessageBodyWrapper>{messageBody}</MessageBodyWrapper>,
        id: 'message-body',
      },
      {
        label: t.whatsapp.messageTemplates.fields.footer(),
        detail: footerText,
        id: 'footer',
      },
      ...(buttonType !== WhatsAppMtButtonType.NONE
        ? [
            {
              label: t.whatsapp.messageTemplates.fields.buttons(),
              detail:
                t.whatsapp.messageTemplates.templateButtonLabels[buttonType](),
              id: 'buttons',
            },
          ]
        : []),
      ...ButtonTypeMapper[buttonType],
    ],
    [content]
  );

  return (
    <Box pl="medium" pr="medium" mt="large">
      <input id="template-content-id" type="hidden" value={content.id} />
      {showRejectedNotification && (
        <ScopedNotification>
          <>
            {t.whatsapp.messageTemplates.rejectScopedNotification.description()}
            <DocumentationLink>
              {t.whatsapp.messageTemplates.rejectScopedNotification.link()}
            </DocumentationLink>
          </>
        </ScopedNotification>
      )}
      <Text variant="heading_small" weight="bold" mt="large">
        {t.whatsapp.messageTemplates.templateLanguageLabels[content.language]()}{' '}
        {t.whatsapp.messageTemplates.register.sections.messageContent()}
      </Text>
      <DescriptionList items={fields} />
    </Box>
  );
};
