import { gql } from '@apollo/client';

import { IWhatsAppAspClient, IWhatsAppMtOrMtCr } from '@shared/bff';

export interface ITemplateQueryResponse {
  whatsAppAspClient: IWhatsAppAspClient;
  whatsAppMessageTemplate: IWhatsAppMtOrMtCr;
}

export const TEMPLATE_QUERY = gql`
  query WhatsAppMessageTemplate($aspClientId: String!, $name: String!) {
    whatsAppAspClient(aspClientId: $aspClientId) {
      name
    }
    whatsAppMessageTemplate(aspClientId: $aspClientId, name: $name) {
      ... on WhatsAppMt {
        name
        aspClientId
        category
        contents {
          ... on WhatsAppMtContent {
            id
            language
            templateStatus
            bodyText
            footer
            headerType
            headerText
            mediaType
            buttonType
            quickReplyButtons
            callToActionButtons {
              ... on WhatsAppMtPhoneAction {
                buttonText
                phoneNumber
              }
              ... on WhatsAppMtWebsiteAction {
                buttonText
                urlType
                websiteUrl
              }
            }
            mediaVariable {
              url
              type
            }
            headerVariable
            bodyVariables
            buttonUrlVariable
            securityRecommendation
            codeExpirationMinutes
            otpType
            buttonText
            autofillText
            packageName
            signatureHash
          }
          ... on WhatsAppMtCrContent {
            id
            language
            crStatus
            bodyText
            footer
            headerType
            headerText
            mediaType
            buttonType
            quickReplyButtons
            callToActionButtons {
              ... on WhatsAppMtPhoneAction {
                buttonText
                phoneNumber
              }
              ... on WhatsAppMtWebsiteAction {
                buttonText
                urlType
                websiteUrl
              }
            }
            mediaVariable {
              url
              type
            }
            headerVariable
            bodyVariables
            buttonUrlVariable
            securityRecommendation
            codeExpirationMinutes
            otpType
            buttonText
            autofillText
            packageName
            signatureHash
          }
        }
      }
      ... on WhatsAppMtCr {
        name
        aspClientId
        category
        contents {
          ... on WhatsAppMtCrContent {
            id
            language
            crStatus
            bodyText
            footer
            headerType
            headerText
            mediaType
            buttonType
            quickReplyButtons
            callToActionButtons {
              ... on WhatsAppMtPhoneAction {
                buttonText
                phoneNumber
              }
              ... on WhatsAppMtWebsiteAction {
                buttonText
                urlType
                websiteUrl
              }
            }
            mediaVariable {
              url
              type
            }
            headerVariable
            bodyVariables
            buttonUrlVariable
            securityRecommendation
            codeExpirationMinutes
            otpType
            buttonText
            autofillText
            packageName
            signatureHash
          }
        }
      }
    }
  }
`;
