import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { t } from '@src/messages';
import { DocumentationLink } from '@src/common/components';
import {
  accountsPath,
  channelListPath,
  templateListPath,
} from '@src/common/utils';
import { Channel } from '@src/common/types';

interface IStep {
  id: number;
  label: string;
  onRenderSetupAssistantAction?: ReactNode;
}

export const getAspClientSteps = (): IStep[] => {
  return [
    {
      id: 1,
      label: t.home.setupAssistant.whatsApp.stepOne.substepOne(),
      onRenderSetupAssistantAction: (
        <Link
          id="homepage-whatsapp-account-link"
          to={accountsPath(Channel.Whatsapp)}
        >
          {t.home.setupAssistant.whatsApp.stepOne.accountsLink()}
        </Link>
      ),
    },
    {
      id: 2,
      label: t.home.setupAssistant.whatsApp.stepOne.substepTwo(),
      onRenderSetupAssistantAction: (
        <DocumentationLink>{t.home.documentationLink()}</DocumentationLink>
      ),
    },
    {
      id: 3,
      label: t.home.setupAssistant.whatsApp.stepOne.substepThree(),
    },
    {
      id: 4,
      label: t.home.setupAssistant.whatsApp.stepOne.substepFour(),
      onRenderSetupAssistantAction: (
        <DocumentationLink>{t.home.documentationLink()}</DocumentationLink>
      ),
    },
  ];
};

export const getChannelSteps = (): IStep[] => {
  return [
    {
      id: 1,
      label: t.home.setupAssistant.whatsApp.stepTwo.substepOne(),
      onRenderSetupAssistantAction: (
        <Link
          id="homepage-whatsapp-channels-link"
          to={channelListPath(Channel.Whatsapp)}
        >
          {t.home.setupAssistant.whatsApp.stepTwo.channelsLink()}
        </Link>
      ),
    },
    {
      id: 2,
      label: t.home.setupAssistant.whatsApp.stepTwo.substepTwo(),
    },
    {
      id: 3,
      label: t.home.setupAssistant.whatsApp.stepTwo.substepThree(),
    },
    {
      id: 4,
      label: t.home.setupAssistant.whatsApp.stepTwo.substepFour(),
      onRenderSetupAssistantAction: (
        <DocumentationLink>{t.home.documentationLink()}</DocumentationLink>
      ),
    },
  ];
};

export const getTemplateSteps = (): IStep[] => {
  return [
    {
      id: 1,
      label: t.home.setupAssistant.whatsApp.stepThree.substepOne(),
      onRenderSetupAssistantAction: (
        <Link
          id="homepage-whatsapp-message-template-link"
          to={templateListPath(Channel.Whatsapp)}
        >
          {t.home.setupAssistant.whatsApp.stepThree.templatesLink()}
        </Link>
      ),
    },
    {
      id: 2,
      label: t.home.setupAssistant.whatsApp.stepThree.substepTwo(),
    },
    {
      id: 3,
      label: t.home.setupAssistant.whatsApp.stepThree.substepThree(),
    },
  ];
};
