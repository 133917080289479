import { IWhatsAppBotInput } from '@shared/bff';

type botFieldsMaxLengthKeys = keyof Pick<
  IWhatsAppBotInput,
  | 'name'
  | 'description'
  | 'about'
  | 'address'
  | 'email'
  | 'websiteOne'
  | 'websiteTwo'
>;

export const botFieldsMaxLength: Record<botFieldsMaxLengthKeys, number> = {
  name: 128,
  description: 256,
  about: 139,
  address: 256,
  email: 128,
  websiteOne: 256,
  websiteTwo: 256,
};
