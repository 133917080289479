import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { ButtonGroup } from '@salesforce/design-system-react';
import { useMutation } from '@apollo/client';

import { t } from '@src/messages';
import {
  PageHeader,
  Grid,
  GridItem,
  Button,
  ToastContext,
  RouteLeavingGuard,
  ProgressCard,
  ChannelLimitsContext,
  Box,
  ScopedNotification,
  UserContext,
} from '@src/common/components';
import { Channel } from '@src/common/types';
import { accountsPath, accountsDetailsPath } from '@src/common/utils';
import { useFeatureFlags } from '@src/common/utils/hooks';
import {
  IWhatsAppAspClientInput,
  IMutationWhatsAppRegisterAspClientArgs,
  AspClientState,
} from '@shared/bff';

import {
  REGISTER_ASP_CLIENT_MUTATION,
  IRegisterAspClientMutationResponse,
} from '../graphql';
import { AspClientRegistrationForm } from '../components';
import { getAspClientValidationSchema } from '../utils';

import { prepareAspClientProgressIndicatorSteps } from './constants';

const initialValues: IWhatsAppAspClientInput = {
  name: '',
  companyLegalName: '',
  facebookBusinessManagerId: '',
};

export const AspClientRegisterView = () => {
  const history = useHistory();
  const { toast } = useContext(ToastContext);
  const { whatsAppChannels } = useContext(ChannelLimitsContext);
  const { allowAccountCreationWithNoPurchasedChannels } = useFeatureFlags();
  const { user } = useContext(UserContext);

  const channelsLimitReached =
    whatsAppChannels.consumed >= whatsAppChannels.purchased &&
    whatsAppChannels.purchased > 0;
  const noPurchasedChannels = whatsAppChannels.purchased === 0;
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const blockAccountCreation =
    noPurchasedChannels && !allowAccountCreationWithNoPurchasedChannels;
  const [registerAspClientMutation] = useMutation<
    IRegisterAspClientMutationResponse,
    IMutationWhatsAppRegisterAspClientArgs
  >(REGISTER_ASP_CLIENT_MUTATION);

  const handleCancel = () => history.push(accountsPath(Channel.Whatsapp));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getAspClientValidationSchema()}
      onSubmit={async (
        { name, companyLegalName, facebookBusinessManagerId },
        actions
      ) => {
        try {
          const { data } = await registerAspClientMutation({
            variables: {
              aspClient: {
                name,
                companyLegalName,
                facebookBusinessManagerId,
              },
            },
          });
          actions.setSubmitting(false);
          actions.resetForm();

          history.push(
            accountsDetailsPath(
              Channel.Whatsapp,
              data!.whatsAppRegisterAspClient.id
            )
          );
          toast('success', {
            heading: t.common.toasts.submitRequestSuccess(),
          });
        } catch (e) {
          toast('error', {
            heading: t.common.toasts.somethingWentWrong(),
          });
        }
      }}
    >
      {({ isSubmitting, dirty }) => {
        const shouldBlockExternalNavigation = () => dirty;
        return (
          <>
            <Form>
              <Grid>
                <GridItem mb="small">
                  <PageHeader
                    channelType={Channel.Whatsapp}
                    sectionType="account"
                    title={t.common.actions.newAspClient()}
                    breadcrumbs={[
                      {
                        header: t.common.accounts(),
                        to: accountsPath(Channel.Whatsapp),
                      },
                    ]}
                    actions={
                      <ButtonGroup
                        className="slds-align-middle"
                        variant="list"
                        id="button-group-page-header-actions"
                      >
                        <Button
                          label={t.common.actions.cancel()}
                          onClick={handleCancel}
                          id="cancel-button"
                        />
                        <Button
                          label={t.common.actions.submitRequest()}
                          type="submit"
                          loading={isSubmitting}
                          disabled={
                            !dirty ||
                            isSubmitting ||
                            readOnlyAccess ||
                            channelsLimitReached ||
                            blockAccountCreation
                          }
                          id="submit-request-button"
                        />
                      </ButtonGroup>
                    }
                  />
                  {readOnlyAccess && (
                    <>
                      <Box mt="small" />
                      <ScopedNotification variant="info">
                        {t.common.readOnlyAccessNotification()}
                      </ScopedNotification>
                    </>
                  )}
                  {channelsLimitReached && (
                    <>
                      <Box mt="small" />
                      <ScopedNotification variant="warning">
                        {t.common.channelLimitNotification()}
                      </ScopedNotification>
                    </>
                  )}
                  {noPurchasedChannels && (
                    <>
                      <Box mt="small" />
                      <ScopedNotification variant="info">
                        {t.common.noPurchasedAppsNotification()}
                      </ScopedNotification>
                    </>
                  )}
                </GridItem>
                <GridItem mb="small">
                  <ProgressCard
                    heading={t.common.components.aspClientProgressIndicator.heading()}
                    description={t.common.components.aspClientProgressIndicator.description()}
                    steps={prepareAspClientProgressIndicatorSteps()}
                    currentStepId={AspClientState.NOT_REGISTERED}
                  />
                </GridItem>
                <GridItem>
                  <AspClientRegistrationForm />
                </GridItem>
              </Grid>
            </Form>
            <RouteLeavingGuard
              whenBlockInternalNavigation={dirty}
              navigate={(path) => {
                history.push(path);
              }}
              shouldBlockExternalNavigation={shouldBlockExternalNavigation}
            />
          </>
        );
      }}
    </Formik>
  );
};
