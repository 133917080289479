import { gql } from '@apollo/client';
import type { Except } from 'type-fest';

import { IWhatsAppAspClient } from '@shared/bff';

export type WhatsAppAspClient = Except<
  IWhatsAppAspClient,
  'updated' | 'created'
>;

export interface IAspClientQueryResponse {
  whatsAppAspClient: WhatsAppAspClient;
}

export const ASP_CLIENT_QUERY = gql`
  query WhatsAppAspClient($aspClientId: String!) {
    whatsAppAspClient(aspClientId: $aspClientId) {
      id
      name
      facebookBusinessManagerId
      companyLegalName
      state
    }
  }
`;
