import React from 'react';

import {
  Box,
  Card,
  ComparisonValues,
  DescriptionList,
  IDescriptionListItem,
} from '@src/common/components';
import { t } from '@src/messages';

interface ISampleMessagesSectionProps {
  values: {
    sampleMessage: string | null;
  };
  crValues?: {
    sampleMessage: string | undefined;
  };
  showCurrent: boolean;
}

export const SampleMessagesSection = ({
  values,
  crValues,
  showCurrent,
}: ISampleMessagesSectionProps) => {
  const items: IDescriptionListItem[] = [
    {
      label: t.viberBusiness.channels.fields.sampleMessage(),
      detail: (
        <ComparisonValues
          id="sample-message"
          value={values.sampleMessage}
          comparisonValue={crValues?.sampleMessage}
          showDiff={
            showCurrent && values.sampleMessage !== crValues?.sampleMessage
          }
        />
      ),
    },
  ];
  return (
    <Card heading={t.viberBusiness.channels.request.sampleMessages()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
