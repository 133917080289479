import React, { ReactNode } from 'react';

import { t } from '@src/messages';
import { ImageUploader } from '@src/common/components';
import { WhatsAppMtMediaType } from '@shared/bff';

import { FileSample } from './FileSample';

interface IHeaderMediaSampleProps {
  mediaType: WhatsAppMtMediaType;
  name: string;
}

const maxSize = 16000000; //16MB

export const HeaderMediaSample = ({
  mediaType,
  name,
}: IHeaderMediaSampleProps) => {
  const MediaSampleMapper: Record<WhatsAppMtMediaType, ReactNode | null> = {
    [WhatsAppMtMediaType.IMAGE]: (
      <ImageUploader
        label={t.whatsapp.messageTemplates.fields.headerMediaSample()}
        fieldName={`${name}.url`}
        mimeFieldName={`${name}.type`}
        tooltip={{
          label:
            t.whatsapp.messageTemplates.tooltips.headerMediaSampleTooltip(),
          id: 'header-media-sample-image-tooltip',
        }}
        modalLabels={{
          title: t.common.components.imageResolver.defaultModalTitle(),
          description:
            t.common.components.imageResolver.resolverDescription.template(),
        }}
        maxImageSize={maxSize}
        cropOptions={{
          aspect: 5 / 3,
        }}
        hideFileName={true}
      />
    ),
    [WhatsAppMtMediaType.VIDEO]: (
      <FileSample
        maxSize={maxSize}
        fieldName={name}
        accept="video/mp4, video/quicktime"
      />
    ),
    [WhatsAppMtMediaType.DOCUMENT]: (
      <FileSample maxSize={maxSize} fieldName={name} />
    ),
    [WhatsAppMtMediaType.NONE]: null,
  };
  return <>{MediaSampleMapper[mediaType]}</>;
};
