import { gql } from '@apollo/client';

import { IWhatsAppBotResponse } from '@shared/bff';

export type IVerifyBotMutationResponse = Pick<
  IWhatsAppBotResponse,
  'botId' | 'registrationResponse'
>;

export const VERIFY_BOT_MUTATION = gql`
  mutation WhatsAppVerifyBot($botId: String!, $code: String!) {
    whatsAppVerifyBot(botId: $botId, code: $code) {
      botId
      registrationResponse
    }
  }
`;
