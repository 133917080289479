import { t } from 'messages';

import { WhatsAppMtUrlType } from '@shared/bff';

export interface IUrlTypeOption {
  value: WhatsAppMtUrlType;
  label: string;
}

const TemplateUrlTypeLabels = t.whatsapp.messageTemplates.templateUrlTypeLabels;

export const prepareOptionsForUrlType = () => [
  {
    value: WhatsAppMtUrlType.STATIC,
    label: TemplateUrlTypeLabels.STATIC(),
  },
  {
    value: WhatsAppMtUrlType.DYNAMIC,
    label: TemplateUrlTypeLabels.DYNAMIC(),
  },
];
