import * as Yup from 'yup';

import {
  isPhoneNumberE164,
  TEST_INVALID_PHONE_NUMBER,
} from '@src/common/utils';
import {
  WhatsAppMtHeaderType,
  WhatsAppMtButtonType,
  WhatsAppMtUrlType,
} from '@shared/bff';
import { t } from '@src/messages';

import {
  HEADER_FIELD_NAME,
  BUTTONS_TYPE_FIELD_NAME,
  CALL_TO_ACTION_TYPE_FIELD_NAME,
  CALL_TO_ACTION_BUTTONS_FIELD_NAME,
} from '../form';
import {
  DraftContentPayload,
  EditMode,
  CallToActionType,
  CallToActionButton,
  DraftAuthenticationContentPayload,
} from '../../types';

import {
  getTemplateFieldRequirements,
  validLanguageSchema,
  getBodyTextSchema,
  getFooterSchema,
  validCallToActionTypes,
  getMediaVariableDraftSchema,
  validWebsiteUrlSchema,
  validVariableUrlSchema,
  getSecurityRecommendationSchema,
  getCodeExpirationMinutesSchema,
  getButtonTextSchema,
  getAutofillSchema,
  getPackageNameSchema,
  getSignatureHashSchema,
} from './templateValidationSchemas';
import { contentFieldsMaxLength } from './contentFieldsMaxLength';

const getHeaderTextSchema = () =>
  Yup.string().when(HEADER_FIELD_NAME, {
    is: WhatsAppMtHeaderType.TEXT,
    then: Yup.string().max(contentFieldsMaxLength.headerText),
  });
const getQuickReplyButtonsSchema = () =>
  Yup.array().when(BUTTONS_TYPE_FIELD_NAME, {
    is: WhatsAppMtButtonType.QUICK_REPLY,
    then: Yup.array()
      .of(Yup.string().max(contentFieldsMaxLength.quickReplyButtons))
      .max(3),
  });

const validCallToActionSchema = () => {
  const buttonTextSchema = () =>
    Yup.string().max(contentFieldsMaxLength.callToActionButtons);
  return Yup.object().shape({
    type: Yup.string().oneOf(validCallToActionTypes).required(),
    buttonText: buttonTextSchema(),
    phoneNumber: Yup.string().when(CALL_TO_ACTION_TYPE_FIELD_NAME, {
      is: CallToActionType.CALL_PHONE_NUMBER,
      then: Yup.string().test(
        TEST_INVALID_PHONE_NUMBER,
        t.common.validation.phoneNumber.e164(),
        (value) => value === '' || isPhoneNumberE164(value)
      ),
    }),
    urlType: Yup.string().when(CALL_TO_ACTION_TYPE_FIELD_NAME, {
      is: CallToActionType.VISIT_WEBSITE,
      then: Yup.string(),
    }),
    websiteUrl: Yup.string().when(CALL_TO_ACTION_TYPE_FIELD_NAME, {
      is: CallToActionType.VISIT_WEBSITE,
      then: validWebsiteUrlSchema(),
    }),
    urlVariable: Yup.string().when(CALL_TO_ACTION_BUTTONS_FIELD_NAME, {
      is: (callToActionButtons) =>
        callToActionButtons &&
        callToActionButtons.find(
          (button: CallToActionButton) =>
            'urlType' in button && button.urlType === WhatsAppMtUrlType.DYNAMIC
        ),
      then: validVariableUrlSchema(),
    }),
  });
};
const getCallToActionSchema = () =>
  Yup.array().when(BUTTONS_TYPE_FIELD_NAME, {
    is: WhatsAppMtButtonType.CALL_TO_ACTION,
    then: Yup.array().of(validCallToActionSchema()).max(2),
  });

const getMessageContentFieldRequirements = (): Record<
  keyof DraftContentPayload,
  Yup.Schema<unknown>
> => ({
  language: validLanguageSchema().required(),
  headerText: getHeaderTextSchema(),
  bodyText: getBodyTextSchema(),
  footer: getFooterSchema(),
  quickReplyButtons: getQuickReplyButtonsSchema(),
  callToActionButtons: getCallToActionSchema(),
  headerVariable: Yup.string(),
  bodyVariables: Yup.array().of(Yup.string()),
  mediaVariable: getMediaVariableDraftSchema(),
  buttonUrlVariable: Yup.string(),
});

const getMessageContentsSchema = () =>
  Yup.array().of(
    Yup.object().shape({ ...getMessageContentFieldRequirements() })
  );

const getAuthenticationMessageContentFieldRequirements = (): Record<
  keyof DraftAuthenticationContentPayload,
  Yup.Schema<unknown>
> => ({
  language: validLanguageSchema().required(),
  securityRecommendation: getSecurityRecommendationSchema(),
  codeExpirationMinutes: getCodeExpirationMinutesSchema(),
  buttonText: getButtonTextSchema(),
  autofillText: getAutofillSchema(),
  packageName: getPackageNameSchema(),
  signatureHash: getSignatureHashSchema(),
});

const getAuthenticationMessageContentsSchema = () =>
  Yup.array().of(
    Yup.object().shape({
      ...getAuthenticationMessageContentFieldRequirements(),
    })
  );

export const getTemplateDraftValidationSchema = (mode?: EditMode) =>
  Yup.object().shape({
    ...getTemplateFieldRequirements(mode),
    contents: getMessageContentsSchema(),
    authenticationContents: getAuthenticationMessageContentsSchema(),
  });
