import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { ButtonGroup } from '@salesforce/design-system-react';
import { omit } from 'lodash-es';
import { useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';

import {
  Box,
  Button,
  FormModal,
  IQueryHandlerResult,
  QueryHandler,
  RouteLeavingGuard,
  ScopedNotification,
  UserContext,
  Text,
  ErrorModal,
  PageHeader,
  ChannelLimitsContext,
  ToastContext,
} from '@src/common/components';
import {
  Channel,
  EditMode,
  ErrorCode,
  errorCodes,
  IBotCrIdMatchParams,
  IBotIdMatchParams,
  senderErrorCodesExplainer,
} from '@src/common/types';
import { t } from '@src/messages';
import {
  channelChangeRequestBotDetailsPath,
  channelChangeRequestDetailsPath,
  channelListPath,
  TEST_INVALID_SUBSCRIBER_NUMBER,
} from '@src/common/utils';
import {
  IMutationWhatsAppCreateCrArgs,
  IMutationWhatsAppTransitionBotCrFromRejectedToDraftArgs,
  IMutationWhatsAppUpdateCrArgs,
  IMutationWhatsAppTransitionBotCrFromDraftToReviewArgs,
  CrState,
  IWhatsAppBotInput,
} from '@shared/bff';

import {
  BOT_QUERY,
  CREATE_CR_MUTATION,
  CR_QUERY,
  IBotQueryResponse,
  ICrBotDataFragment,
  ICreateCrMutationResponse,
  ICrQueryResponse,
  ITransitionBotCrFromDraftToReviewMutationResponse,
  IUpdateCrMutationResponse,
  IWhatsAppBotFragment,
  IWhatsAppTransitionBotCrFromRejectedToDraftMutationResponse,
  TRANSITION_BOT_CR_FROM_DRAFT_TO_REVIEW_MUTATION,
  TRANSITION_BOT_CR_FROM_REJECTED_TO_DRAFT_MUTATION,
  UPDATE_CR_MUTATION,
} from '../graphql';
import {
  getDraftValidationSchema,
  getRequestValidationSchema,
  mapChannelToFormValues,
} from '../utils';
import { BotForm } from '../components';

interface IEditViewProps {
  mode: EditMode;
}

type IEditParams = IBotCrIdMatchParams | IBotIdMatchParams;
type IEditQueryResponse = ICrQueryResponse | IBotQueryResponse;

export const EditView = ({ mode }: IEditViewProps) => {
  const params = useParams<IEditParams>();
  const paramsId = 'botCrId' in params ? params.botCrId : params.botId;
  const query = 'botCrId' in params ? CR_QUERY : BOT_QUERY;
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;

  const mapPageTitle: Record<EditMode, string> = {
    ['edit-draft']: t.common.actions.editDraft(),
    ['edit-rejected']: t.common.actions.editRejected(),
    ['edit-bot']: t.common.actions.editChannelDetails(),
  };

  const { toast } = useContext(ToastContext);
  const history = useHistory();
  const [shouldSubmitRequest, setShouldSubmitRequest] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorExplanation, setErrorExplanation] = useState('');
  const [errorHeading, setErrorHeading] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { updateChannelLimits, whatsAppChannels } =
    useContext(ChannelLimitsContext);
  const { consumed, purchased } = whatsAppChannels;
  const channelsLimitReached = consumed >= purchased;

  const [createCrMutation] = useMutation<
    ICreateCrMutationResponse,
    IMutationWhatsAppCreateCrArgs
  >(CREATE_CR_MUTATION);
  const [whatsAppTransitionSenderCrFromRejectedToDraft] = useMutation<
    IWhatsAppTransitionBotCrFromRejectedToDraftMutationResponse,
    IMutationWhatsAppTransitionBotCrFromRejectedToDraftArgs
  >(TRANSITION_BOT_CR_FROM_REJECTED_TO_DRAFT_MUTATION);
  const [updateCrMutation] = useMutation<
    IUpdateCrMutationResponse,
    IMutationWhatsAppUpdateCrArgs
  >(UPDATE_CR_MUTATION);
  const [transitionToReviewMutation] = useMutation<
    ITransitionBotCrFromDraftToReviewMutationResponse,
    IMutationWhatsAppTransitionBotCrFromDraftToReviewArgs
  >(TRANSITION_BOT_CR_FROM_DRAFT_TO_REVIEW_MUTATION);

  const handleCancelRequest = () => history.goBack();
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const handleErrorModalClose = () => setShowErrorModal(false);
  const handleError = (e: { graphQLErrors?: readonly GraphQLError[] }) => {
    const errorCode: ErrorCode = e?.graphQLErrors?.[0]?.extensions?.code;
    const explanation = senderErrorCodesExplainer[errorCode];
    const isKnownError = errorCodes.includes(errorCode);
    setErrorHeading(
      shouldSubmitRequest
        ? t.whatsapp.channels.errorModal.heading()
        : t.whatsapp.channels.errorModal.saveErrorHeading()
    );

    if (isKnownError) {
      setErrorExplanation(explanation());
      setShowErrorModal(true);

      return;
    }

    toast('error', {
      heading: t.common.toasts.somethingWentWrong(),
    });
  };

  return (
    <>
      <QueryHandler
        query={query}
        variables={{
          ...('botCrId' in params && { botCrId: paramsId }),
          ...('botId' in params && { botId: paramsId }),
        }}
        levitate
      >
        {({ data: existingData }: IQueryHandlerResult<IEditQueryResponse>) => {
          const bot: ICrBotDataFragment | IWhatsAppBotFragment =
            'whatsAppCr' in existingData
              ? existingData.whatsAppCr.bot
              : existingData.whatsAppBot;
          const isRejected =
            'whatsAppCr' in existingData &&
            existingData &&
            existingData.whatsAppCr.state === CrState.REJECTED;

          const isOnboarded =
            'id' in bot ||
            ('whatsAppCr' in existingData &&
              'botId' in existingData.whatsAppCr &&
              existingData.whatsAppCr.botId !== '');

          const navigateToDetails = (crId: string) => {
            if (
              'whatsAppBot' in existingData ||
              existingData.whatsAppCr.botId
            ) {
              history.push(
                channelChangeRequestBotDetailsPath(Channel.Whatsapp, crId)
              );
            } else {
              history.push(
                channelChangeRequestDetailsPath(Channel.Whatsapp, crId)
              );
            }
          };

          const save = async (
            values: IWhatsAppBotInput,
            setSubmitting: (submit: boolean) => void
          ) => {
            let crId: string;

            if ('whatsAppBot' in existingData) {
              const { data, errors } = await createCrMutation({
                variables: {
                  whatsAppCr: {
                    bot: omit(values, ['id']),
                    botId: paramsId,
                  },
                },
              });

              if (errors) {
                setSubmitting(false);
                return handleError({ graphQLErrors: errors });
              }

              crId = data!.whatsAppCreateCr.id;
            } else {
              if (isRejected) {
                await whatsAppTransitionSenderCrFromRejectedToDraft({
                  variables: {
                    crId: paramsId,
                  },
                });
              }

              const { data } = await updateCrMutation({
                variables: {
                  whatsAppCr: {
                    id: paramsId,
                    bot: values,
                    botId: existingData.whatsAppCr.botId,
                  },
                },
              });
              crId = data!.whatsAppUpdateCr.id;
            }
            return crId;
          };
          return (
            <Formik
              initialValues={mapChannelToFormValues(bot)}
              validationSchema={getRequestValidationSchema()}
              onSubmit={async (values, actions) => {
                try {
                  const crId = (await save(
                    values,
                    actions.setSubmitting
                  )) as string;

                  const { errors } = await transitionToReviewMutation({
                    variables: {
                      crId,
                    },
                  });

                  if (errors) {
                    actions.setSubmitting(false);
                    return handleError({ graphQLErrors: errors });
                  }

                  actions.resetForm();
                  navigateToDetails(crId);
                  toast('success', {
                    heading: t.common.toasts.submitRequestSuccess(),
                  });

                  updateChannelLimits();
                } catch (e) {
                  actions.setSubmitting(false);
                  handleError(e);
                }
              }}
            >
              {({
                values,
                dirty,
                isSubmitting,
                setSubmitting,
                validateForm,
                submitForm,
                resetForm,
              }) => {
                const shouldBlockExternalNavigation = () => dirty;

                const handlePreSaveDraft = () => {
                  setShouldSubmitRequest(false);
                  const draftSchema = getDraftValidationSchema();
                  draftSchema
                    .validate(values)
                    .then(() => {
                      handleSaveDraft();
                    })
                    .catch((e) => {
                      setSubmitting(false);
                      if (e.type === TEST_INVALID_SUBSCRIBER_NUMBER) {
                        setErrorExplanation(
                          t.whatsapp.channels.draftValidationModal.validPhoneNumber()
                        );
                      } else if (e.path === 'email') {
                        setErrorExplanation(
                          t.whatsapp.channels.draftValidationModal.validEmail()
                        );
                      } else if (
                        e.path === 'websiteOne' ||
                        e.path === 'websiteTwo'
                      ) {
                        setErrorExplanation(
                          t.whatsapp.channels.draftValidationModal.validWebsite()
                        );
                      } else {
                        setErrorExplanation(
                          t.whatsapp.channels.draftValidationModal.description()
                        );
                      }
                      setErrorHeading(
                        t.whatsapp.channels.draftValidationModal.header()
                      );
                      setShowErrorModal(true);
                    });
                };

                const handlePreSubmit = async () => {
                  setShouldSubmitRequest(true);

                  const errorObj = await validateForm();
                  const hasErrors = Object.entries(errorObj).length > 0;

                  if (hasErrors) {
                    submitForm();

                    return;
                  }

                  handleModalOpen();
                };

                const handleSubmit = () => {
                  handleModalClose();
                  submitForm();
                };

                const handleSaveDraft = async () => {
                  handleModalClose();
                  setSubmitting(true);
                  try {
                    const crId = (await save(values, setSubmitting)) as string;
                    resetForm();
                    navigateToDetails(crId);
                    toast('success', {
                      heading: t.common.toasts.saveDraftSuccess(),
                    });
                    updateChannelLimits();
                  } catch (e) {
                    setSubmitting(false);
                    handleError(e);
                  }
                };

                return (
                  <>
                    <Form>
                      <PageHeader
                        channelType={Channel.Whatsapp}
                        sectionType="channel"
                        title={mapPageTitle[mode]}
                        breadcrumbs={[
                          {
                            header: t.common.channels(),
                            to: channelListPath(Channel.Whatsapp),
                          },
                        ]}
                        actions={
                          <ButtonGroup variant="list">
                            <Button
                              id="cancel-button"
                              key="cancel"
                              label={t.common.actions.cancel()}
                              onClick={handleCancelRequest}
                            />
                            <Button
                              id="save-draft-button"
                              key="saveDraft"
                              label={t.common.actions.saveDraft()}
                              onClick={handlePreSaveDraft}
                              loading={isSubmitting && !shouldSubmitRequest}
                              disabled={
                                !dirty || isSubmitting || readOnlyAccess
                              }
                            />
                            <Button
                              id="submit-request-button"
                              key="submitRequest"
                              label={t.common.actions.submitRequest()}
                              loading={isSubmitting && shouldSubmitRequest}
                              onClick={handlePreSubmit}
                              disabled={
                                (!dirty && mode !== 'edit-draft') ||
                                isSubmitting ||
                                readOnlyAccess ||
                                (channelsLimitReached && !isOnboarded)
                              }
                            />
                          </ButtonGroup>
                        }
                      />
                      {readOnlyAccess && (
                        <>
                          <Box mt="small" />
                          <ScopedNotification variant="info">
                            {t.common.readOnlyAccessNotification()}
                          </ScopedNotification>
                        </>
                      )}
                      {channelsLimitReached && (
                        <>
                          <Box mt="small" />
                          <ScopedNotification variant="warning">
                            {t.common.channelLimitNotification()}
                          </ScopedNotification>
                        </>
                      )}
                      <Box mt="small" />
                      <BotForm
                        isEditing
                        values={values}
                        disablePhoneNumber={
                          mode === 'edit-bot' ||
                          ('whatsAppCr' in existingData &&
                            !!existingData.whatsAppCr.botId)
                        }
                      />
                      <FormModal
                        heading={
                          shouldSubmitRequest
                            ? t.whatsapp.channels.confirmationModal.header()
                            : t.whatsapp.channels.saveDraftModal.header()
                        }
                        onCancel={handleModalClose}
                        onSubmit={
                          shouldSubmitRequest ? handleSubmit : handleSaveDraft
                        }
                        isOpen={showModal}
                        submitLabel={
                          shouldSubmitRequest
                            ? t.common.actions.submit()
                            : t.common.actions.save()
                        }
                        disableSubmit={isSubmitting}
                      >
                        <Box pl="medium" pr="medium" pt="large" pb="large">
                          <Text>
                            {shouldSubmitRequest
                              ? t.whatsapp.channels.confirmationModal.description()
                              : t.whatsapp.channels.saveDraftModal.description()}
                          </Text>
                        </Box>
                      </FormModal>
                      <ErrorModal
                        heading={errorHeading}
                        onClose={handleErrorModalClose}
                        isOpen={showErrorModal}
                        label={t.common.actions.close()}
                      >
                        <Box pl="medium" pr="medium" pt="large" pb="large">
                          <Text>{errorExplanation}</Text>
                        </Box>
                      </ErrorModal>
                    </Form>
                    <RouteLeavingGuard
                      whenBlockInternalNavigation={dirty}
                      navigate={(path) => {
                        history.push(path);
                      }}
                      shouldBlockExternalNavigation={
                        shouldBlockExternalNavigation
                      }
                    />
                  </>
                );
              }}
            </Formik>
          );
        }}
      </QueryHandler>
    </>
  );
};
