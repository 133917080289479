import { gql } from '@apollo/client';

import { IWhatsAppAspClient } from '@shared/bff';

export interface IRegisterAspClientMutationResponse {
  whatsAppRegisterAspClient: IWhatsAppAspClient;
}

export const REGISTER_ASP_CLIENT_MUTATION = gql`
  mutation WhatsAppRegisterAspClient($aspClient: WhatsAppAspClientInput!) {
    whatsAppRegisterAspClient(aspClient: $aspClient) {
      id
    }
  }
`;
