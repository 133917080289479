import { gql } from '@apollo/client';

import { IWhatsAppBotResponse } from '@shared/bff';

export type IRegisterBotMutationResponse = Pick<
  IWhatsAppBotResponse,
  'botId' | 'registrationResponse'
>;

export const REGISTER_BOT_MUTATION = gql`
  mutation WhatsAppRegisterBot(
    $botId: String!
    $registrationMethod: WhatsAppRegistrationMethod!
    $twoFactorCode: String
  ) {
    whatsAppRegisterBot(
      botId: $botId
      registrationMethod: $registrationMethod
      twoFactorCode: $twoFactorCode
    ) {
      botId
      registrationResponse
    }
  }
`;
