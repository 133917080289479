import { t } from 'messages';

import { CallToActionType } from '../../types';

export interface ITemplateCallToActionTypeOption {
  value: CallToActionType;
  label: string;
}

const TemplateCallToActionTypesLabels =
  t.whatsapp.messageTemplates.templateCallToActionTypeLabels;

export const prepareOptionsForCallToActionType = () => [
  {
    value: CallToActionType.CALL_PHONE_NUMBER,
    label: TemplateCallToActionTypesLabels.CALL_PHONE_NUMBER(),
  },
  {
    value: CallToActionType.VISIT_WEBSITE,
    label: TemplateCallToActionTypesLabels.VISIT_WEBSITE(),
  },
];
