import React from 'react';
import styled from 'styled-components';

import {
  Card,
  ChangeRequestInfoSection,
  Grid,
  GridItem,
  StickyGridItem,
  ProfileSettingsPreviewer,
  Box,
} from '@src/common/components';
import { formatPhoneNumber } from '@src/common/utils';
import { t } from '@src/messages';
import { BotState, NumberProviderType } from '@shared/bff';
import { useFeatureFlags } from '@src/common/utils/hooks';

import {
  ICrBotDataFragment,
  ICrFragment,
  IWhatsAppBotFragment,
} from '../../graphql';
import { prepareDataForBusinessProfilePreviewer } from '../../utils';
import { CompanyInformationSection } from '../CompanyInformationSection';
import { ConfigurationSection } from '../ConfigurationSection';
import { ContactInfoSection } from '../ContactInfoSection';
import { DesignSection } from '../DesignSection';
// import { VerificationSection } from '../VerificationSection';
import { PropertiesSection } from '../PropertiesSection';

const CardWrapper = styled(Card)({
  overflowX: 'auto',
});

interface IBotDetailsProps {
  accountName: string;
  bot: IWhatsAppBotFragment;
  whatsAppCr?: ICrFragment;
  showCurrent: boolean;
}

export const BotDetails = ({
  accountName,
  bot,
  whatsAppCr,
  showCurrent,
}: IBotDetailsProps) => {
  const { whatsAppQualityInformation } = useFeatureFlags();

  const hasCrValues = whatsAppCr !== undefined;
  let botCr: ICrBotDataFragment;

  if (hasCrValues) {
    botCr = whatsAppCr!.bot;
  }

  return (
    <Grid>
      <GridItem cols={8} pr="small">
        <Grid flow="vertical">
          {bot.state === BotState.NEW &&
            bot.numberProviderType === NumberProviderType.CUSTOMER && (
              <GridItem mb="small">
                <Card heading="Channel verification" type="darken">
                  <Box pl="medium" pr="medium" mt="xx-small">
                    Next, your WhatsApp sender needs to be registered and
                    verified by Meta. Please schedule a verification session by
                    reaching out to:{' '}
                    <a href="mailto:sfsupport@sinch.com" target="_top">
                      sfsupport@sinch.com
                    </a>
                  </Box>
                </Card>
                {/* <VerificationSection bot={bot} /> */}
              </GridItem>
            )}
          {whatsAppCr && (
            <GridItem mb="small">
              <ChangeRequestInfoSection cr={whatsAppCr} />
            </GridItem>
          )}
          {whatsAppQualityInformation && (
            <GridItem mb="small">
              <PropertiesSection
                values={{
                  quality: bot.quality,
                  dailyLimit: bot.dailyLimit,
                }}
              />
            </GridItem>
          )}
          <GridItem mb="small">
            <ConfigurationSection
              values={{
                accountName,
                name: bot.name,
                description: bot.description,
                phoneNumber: formatPhoneNumber({
                  countryCode: bot.countryCode,
                  phoneNumber: bot.phone,
                }),
                providerType: bot.numberProviderType,
                botStatus: bot.state,
              }}
              comparisonValues={
                hasCrValues
                  ? {
                      accountName,
                      name: botCr!.name,
                      description: botCr!.description,
                      phoneNumber: formatPhoneNumber({
                        countryCode: botCr!.countryCode,
                        phoneNumber: botCr!.phone,
                      }),
                      providerType: botCr!.numberProviderType,
                      botStatus: botCr!.state,
                    }
                  : undefined
              }
              showDiff={showCurrent}
            />
          </GridItem>
          <GridItem mb="small">
            <DesignSection
              values={{ image: bot.photoUrl }}
              comparisonValues={
                hasCrValues ? { image: botCr!.photoUrl } : undefined
              }
              showDiff={showCurrent}
            />
          </GridItem>
          <GridItem mb="small">
            <CompanyInformationSection
              values={{
                about: bot.about,
                industry: bot.vertical,
              }}
              comparisonValues={
                hasCrValues
                  ? {
                      about: botCr!.about,
                      industry: botCr!.vertical,
                    }
                  : undefined
              }
              showDiff={showCurrent}
            />
          </GridItem>
          <GridItem>
            <ContactInfoSection
              values={{
                address: bot.address,
                email: bot.email,
                website: bot.websiteOne,
                additionalWebsite: bot.websiteTwo,
              }}
              comparisonValues={
                hasCrValues
                  ? {
                      address: botCr!.address,
                      email: botCr!.email,
                      website: botCr!.websiteOne,
                      additionalWebsite: botCr!.websiteTwo,
                    }
                  : undefined
              }
              showDiff={showCurrent}
            />
          </GridItem>
        </Grid>
      </GridItem>
      <StickyGridItem cols={4}>
        <CardWrapper
          heading={t.common.components.businessProfilePreviewer.heading()}
        >
          <ProfileSettingsPreviewer
            whatsappData={prepareDataForBusinessProfilePreviewer(bot)}
          />
        </CardWrapper>
      </StickyGridItem>
    </Grid>
  );
};
