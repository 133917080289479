import React from 'react';
import { VisualPicker, Radio } from '@salesforce/design-system-react';
import styled from 'styled-components';
import { t } from 'messages';

import { Text, Grid, GridItem, Box } from '@src/common/components';
import { FileIcon, PhotoIcon, VideoIcon } from '@src/assets';
import { useFeatureFlags } from '@src/common/utils/hooks';
import { WhatsAppMtMediaType } from '@shared/bff';

interface IMediaTypeSectionProps {
  mediaType: WhatsAppMtMediaType;
}

const MEDIA_RADIO_WIDTH = 134;
const MEDIA_RADIO_HEIGHT = 122;

const MediaRadio = styled(Radio)({
  width: MEDIA_RADIO_WIDTH,
  height: MEDIA_RADIO_HEIGHT,
  marginBottom: 48,

  '& > label': {
    width: MEDIA_RADIO_WIDTH,
    height: MEDIA_RADIO_HEIGHT,
  },
  '& .slds-visual-picker__figure': {
    width: MEDIA_RADIO_WIDTH,
    height: MEDIA_RADIO_HEIGHT,
  },
  '& .slds-visual-picker__body': {
    width: MEDIA_RADIO_WIDTH,
  },
  '& .slds-icon_container': {
    lineHeight: '1.5',
  },
  '& input[checked] ~ label .slds-visual-picker__text-check': {
    opacity: '1',
  },
  '& input[checked] ~ label .slds-visual-picker__text, & input[checked] ~ label .slds-visual-picker__figure:hover':
    {
      border: '1px solid #1589EE',
      boxShadow: '0 0 0 1px #1589EE inset',
    },
});

export const MediaTypeSection = ({ mediaType }: IMediaTypeSectionProps) => {
  const { sfGa } = useFeatureFlags();
  return (
    <Grid>
      <GridItem cols={9} mt="small" ml="medium">
        <Text variant="body_small" color="weak">
          {t.whatsapp.messageTemplates.fields.mediaType()}
        </Text>
        <Box mb="small" />
        <VisualPicker className="media_type_selector">
          <MediaRadio
            value="image"
            labels={{
              label:
                t.whatsapp.messageTemplates.templateMediaTypeLabels.IMAGE(),
            }}
            checked={mediaType === WhatsAppMtMediaType.IMAGE}
            onRenderVisualPicker={() => <PhotoIcon />}
            disabled
          />
          {sfGa ? (
            <></>
          ) : (
            <MediaRadio
              value="video"
              labels={{
                label:
                  t.whatsapp.messageTemplates.templateMediaTypeLabels.VIDEO(),
              }}
              checked={mediaType === WhatsAppMtMediaType.VIDEO}
              onRenderVisualPicker={() => <VideoIcon />}
              disabled
            />
          )}
          {sfGa ? (
            <></>
          ) : (
            <MediaRadio
              value="document"
              labels={{
                label:
                  t.whatsapp.messageTemplates.templateMediaTypeLabels.DOCUMENT(),
              }}
              checked={mediaType === WhatsAppMtMediaType.DOCUMENT}
              onRenderVisualPicker={() => <FileIcon />}
              disabled
            />
          )}
        </VisualPicker>
      </GridItem>
    </Grid>
  );
};
