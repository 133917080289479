import React, { ReactNode } from 'react';
import {
  SetupAssistant,
  SetupAssistantStep,
  ProgressBar,
  ProgressIndicator,
} from '@salesforce/design-system-react';
import styled from 'styled-components';

import {
  Text,
  Box,
  QueryHandler,
  IQueryHandlerResult,
} from '@src/common/components';
import { t } from '@src/messages';
import { AspClientStep, ChannelStep, TemplateStep } from '@shared/bff';

import { WHATSAPP_HOME_QUERY, IWhatsAppHomeQueryResponse } from '../../graphql';

import {
  getAspClientSteps,
  getChannelSteps,
  getTemplateSteps,
} from './StepSetup';

type StepType = AspClientStep | ChannelStep | TemplateStep;

interface IStep {
  id: number;
  label: string;
  onRenderSetupAssistantAction?: ReactNode;
}

interface IGetStepsInfoOutput {
  completed: IStep[];
  selected?: IStep;
  progress: number;
}

const NOT_CREATED_ID = 0;
const ALL_COMPLETED_ID = 999;

const mapStepToId: Record<StepType, number> = {
  [AspClientStep.NOT_CREATED]: NOT_CREATED_ID,
  [AspClientStep.REQUESTED]: 3,
  [AspClientStep.ONBOARDED]: ALL_COMPLETED_ID,
  [ChannelStep.NOT_CREATED]: NOT_CREATED_ID,
  [ChannelStep.REQUESTED]: 3,
  [ChannelStep.PROVISIONED]: 4,
  [ChannelStep.VERIFIED]: ALL_COMPLETED_ID,
  [TemplateStep.NOT_CREATED]: NOT_CREATED_ID,
  [TemplateStep.REQUESTED]: 3,
  [TemplateStep.APPROVED]: ALL_COMPLETED_ID,
};

const getStepsInfo = (
  steps: IStep[],
  currentStep: StepType
): IGetStepsInfoOutput => {
  const currentStepId = mapStepToId[currentStep];
  const currentStepIndex = steps
    .map((step) => step.id)
    .lastIndexOf(currentStepId);

  if (currentStepId === NOT_CREATED_ID) {
    return {
      completed: [],
      selected: steps[0],
      progress: 0,
    };
  }

  if (currentStepId === ALL_COMPLETED_ID) {
    return {
      completed: steps,
      selected: undefined,
      progress: 100,
    };
  }

  return {
    completed: steps.slice(0, currentStepIndex),
    selected: steps.find(({ id }) => id === currentStepId)!,
    progress: ((currentStepId - 1) / steps.length) * 100,
  };
};

const SetupAssistantWrapper = styled(SetupAssistant)({
  '& p, & .slds-text-body, & .slds-progress__item_content': {
    color: '#3E3E3C',
  },
});

export const WhatsAppSetup = () => {
  const aspClientSteps = getAspClientSteps();
  const channelSteps = getChannelSteps();
  const templateSteps = getTemplateSteps();

  return (
    <QueryHandler query={WHATSAPP_HOME_QUERY} levitate>
      {({
        data: {
          whatsAppHome: { aspClientStep, channelStep, templateStep },
        },
      }: IQueryHandlerResult<IWhatsAppHomeQueryResponse>) => {
        const aspClientStepsInfo = getStepsInfo(aspClientSteps, aspClientStep);
        const channelStepsInfo = getStepsInfo(channelSteps, channelStep);
        const templateStepsInfo = getStepsInfo(templateSteps, templateStep);

        const totalProgress = Math.floor(
          (aspClientStepsInfo.progress +
            channelStepsInfo.progress +
            templateStepsInfo.progress) /
            3
        );

        return (
          <SetupAssistantWrapper
            id="get-started-checklist"
            isCard
            progressBar={
              <ProgressBar
                color="success"
                id="get-started-checklist-progress-bar"
                labels={{
                  label: t.home.checklistHeading(),
                  complete: t.home.complete(),
                }}
                radius="circular"
                value={totalProgress}
              />
            }
          >
            <SetupAssistantStep
              description={t.home.setupAssistant.whatsApp.stepOne.description()}
              heading={t.home.setupAssistant.whatsApp.stepOne.heading()}
              id="whatsapp-step-1"
              isExpandable
              onRenderContent={() => (
                <>
                  <ProgressIndicator
                    completedSteps={aspClientStepsInfo.completed}
                    id="step-1-progress-indicator"
                    orientation="vertical"
                    steps={aspClientSteps}
                    selectedStep={aspClientStepsInfo.selected}
                    variant="setup-assistant"
                  />
                  <Box mt="small">
                    <Text>{t.home.setupAssistant.whatsApp.stepOne.hint()}</Text>
                  </Box>
                </>
              )}
              progress={aspClientStepsInfo.progress}
            />
            <SetupAssistantStep
              description={t.home.setupAssistant.whatsApp.stepTwo.description()}
              heading={t.home.setupAssistant.whatsApp.stepTwo.heading()}
              id="whatsapp-step-2"
              isExpandable
              onRenderContent={() => (
                <>
                  <ProgressIndicator
                    completedSteps={channelStepsInfo.completed}
                    id="step-2-progress-indicator"
                    orientation="vertical"
                    steps={channelSteps}
                    selectedStep={channelStepsInfo.selected}
                    variant="setup-assistant"
                  />
                  <Box mt="small">
                    <Text>{t.home.setupAssistant.whatsApp.stepTwo.hint()}</Text>
                  </Box>
                </>
              )}
              progress={channelStepsInfo.progress}
            />
            <SetupAssistantStep
              description={t.home.setupAssistant.whatsApp.stepThree.description()}
              heading={t.home.setupAssistant.whatsApp.stepThree.heading()}
              id="whatsapp-step-3"
              isExpandable
              onRenderContent={() => (
                <>
                  <ProgressIndicator
                    completedSteps={templateStepsInfo.completed}
                    id="step-3-progress-indicator"
                    orientation="vertical"
                    steps={templateSteps}
                    selectedStep={templateStepsInfo.selected}
                    variant="setup-assistant"
                  />
                  <Box mt="small">
                    <Text>
                      {t.home.setupAssistant.whatsApp.stepThree.hint()}
                    </Text>
                  </Box>
                </>
              )}
              progress={templateStepsInfo.progress}
            />
          </SetupAssistantWrapper>
        );
      }}
    </QueryHandler>
  );
};
