import * as Yup from 'yup';

import { t } from '@src/messages';
import {
  getHttpHttpsSchema,
  TEST_FACEBOOK_INSTAGRAM_DOMAIN,
  isPhoneNumberE164,
  TEST_INVALID_PHONE_NUMBER,
} from '@src/common/utils';
import { IViberSenderInput } from '@shared/bff';

import {
  senderFieldsMaxLength,
  senderFieldsMinLength,
} from './senderFieldsLength';

const getExtraDocSchema = () =>
  Yup.object().shape({
    url: Yup.string().required(),
    type: Yup.string().required(),
    name: Yup.string().required(),
  });

const getChannelNameSchema = () => Yup.string().max(senderFieldsMaxLength.name);
const getDestinationCountries = () =>
  Yup.array().of(Yup.string()).max(senderFieldsMaxLength.destinationCountries);
const getDescriptionSchema = () =>
  Yup.string()
    .min(senderFieldsMinLength.description)
    .max(senderFieldsMaxLength.description);
const getAddressSchema = () => Yup.string().max(senderFieldsMaxLength.address);
const getWebsiteSchema = () =>
  getHttpHttpsSchema()
    .url()
    .test(
      TEST_FACEBOOK_INSTAGRAM_DOMAIN,
      t.common.validation.url.facebookInstagram(),
      (value) => {
        if (value) {
          return (
            !value.includes('facebook.com') &&
            !value.includes('fbcdn.net') &&
            !value.includes('tfbnw.net') &&
            !value.includes('instagram.com')
          );
        }
        return true;
      }
    );
const getExtraDocsSchema = () => Yup.array().of(getExtraDocSchema()).nullable();
const getSampleMessage = () =>
  Yup.string().max(senderFieldsMaxLength.sampleMessage).nullable();

const getGeneralFieldRequirements = (): Record<
  keyof IViberSenderInput,
  Yup.Schema<unknown>
> => {
  return {
    id: Yup.string(),
    aspClientId: Yup.string().required(),
    name: getChannelNameSchema().required(),
    destinationCountries: getDestinationCountries().required(),
    businessLaunchDate: Yup.date().required(),
    logoUrls: Yup.array().required(),
    description: getDescriptionSchema().required(),
    address: getAddressSchema().required(),
    phoneNumber: Yup.string()
      .required()
      .test(
        TEST_INVALID_PHONE_NUMBER,
        t.common.validation.phoneNumber.e164(),
        (value) => isPhoneNumberE164(value)
      ),
    website: getWebsiteSchema().required(),
    extraDocs: getExtraDocsSchema(),
    sampleMessage: getSampleMessage(),
  };
};

export const getRequestValidationSchema = () =>
  Yup.object().shape({
    ...getGeneralFieldRequirements(),
  });

export const getDraftValidationSchema = () =>
  Yup.object().shape({
    aspClientId: Yup.string().required(),
    name: getChannelNameSchema().required(),
    phoneNumber: Yup.string().test(
      TEST_INVALID_PHONE_NUMBER,
      t.common.validation.phoneNumber.e164(),
      (value) => isPhoneNumberE164(value)
    ),
    website: getWebsiteSchema(),
  });
