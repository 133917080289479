import { WhatsAppPreviewerData } from '@sinch/previewer/dist/modules/apps/WhatsApp/ProfileSettings/components/types';

import { t } from '@src/messages';

type WhatsAppPreviewerValues = {
  name?: string | null;
  description?: string | null;
  countryCode?: string | null;
  phone?: string | null;
  photoUrl?: string | null;
  address?: string | null;
  vertical?: string | null;
  email?: string | null;
  websiteOne?: string | null;
  websiteTwo?: string | null;
  about?: string | null;
  updated?: string | null;
};

export const prepareDataForBusinessProfilePreviewer = ({
  name,
  description,
  countryCode,
  phone,
  photoUrl,
  address,
  vertical,
  email,
  websiteOne,
  websiteTwo,
  about,
  updated,
}: WhatsAppPreviewerValues): WhatsAppPreviewerData => {
  return {
    values: {
      name: name ?? undefined,
      description: description ?? undefined,
      countryCode: countryCode ?? undefined,
      phone: phone ?? undefined,
      photoUrl: photoUrl ?? undefined,
      address: address ?? undefined,
      vertical: vertical ?? undefined,
      email: email ?? undefined,
      websiteOne: websiteOne ?? undefined,
      websiteTwo: websiteTwo ?? undefined,
      about: about ?? undefined,
      updated: updated ?? undefined,
    },
    placeholders: {
      name: t.whatsapp.fieldLabels.channelName(),
      description: t.whatsapp.fieldLabels.channelDescription(),
      phone: t.whatsapp.fieldLabels.phone(),
      address: t.whatsapp.fieldLabels.address(),
      vertical: t.whatsapp.fieldLabels.vertical(),
      email: t.whatsapp.fieldLabels.email(),
      websiteOne: t.whatsapp.fieldLabels.websiteOne(),
      about: t.whatsapp.fieldLabels.aboutBusiness(),
    },
  };
};
