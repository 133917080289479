import React, { useMemo } from 'react';
import { t } from 'messages';

import {
  Card,
  Box,
  DescriptionList,
  IDescriptionListItem,
  ComparisonValues,
} from 'common/components';
import { WhatsAppVertical } from '@shared/bff';

import { ISectionProps } from '../types';

interface ICompanyInformationSectionData {
  about: string;
  industry: WhatsAppVertical;
}

interface ICompanyInformationSectionProps extends ISectionProps {
  values: ICompanyInformationSectionData;
  comparisonValues?: ICompanyInformationSectionData;
}

export const CompanyInformationSection = ({
  values,
  comparisonValues,
  showDiff = false,
}: ICompanyInformationSectionProps) => {
  const items = useMemo<IDescriptionListItem[]>(
    () => [
      {
        label: t.whatsapp.fieldLabels.aboutBusiness(),
        detail: (
          <ComparisonValues
            id="about"
            value={values.about}
            comparisonValue={comparisonValues?.about}
            showDiff={showDiff && values.about !== comparisonValues?.about}
          />
        ),
      },
      {
        label: t.whatsapp.fieldLabels.vertical(),
        detail: (
          <ComparisonValues
            id="industry"
            value={t.whatsapp.verticalTypeLabels[values.industry]()}
            comparisonValue={
              comparisonValues &&
              t.whatsapp.verticalTypeLabels[comparisonValues.industry]()
            }
            showDiff={
              showDiff &&
              values.industry.toString() !==
                comparisonValues?.industry.toString()
            }
          />
        ),
      },
    ],
    [showDiff]
  );
  return (
    <Card heading={t.whatsapp.fieldLabels.companyInformation()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
