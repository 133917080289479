import { IViberSenderInput } from '@shared/bff';

type senderFieldsMinLengthKeys = keyof Pick<IViberSenderInput, 'description'>;
type senderFieldsMaxLengthKeys = keyof Pick<
  IViberSenderInput,
  | 'name'
  | 'destinationCountries'
  | 'description'
  | 'address'
  | 'website'
  | 'sampleMessage'
>;

export const senderFieldsMinLength: Record<senderFieldsMinLengthKeys, number> =
  {
    description: 10,
  };

export const senderFieldsMaxLength: Record<senderFieldsMaxLengthKeys, number> =
  {
    name: 100,
    destinationCountries: 20,
    description: 400,
    address: 100,
    website: 256,
    sampleMessage: 1000,
  };
