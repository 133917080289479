import { gql } from '@apollo/client';

import { IWhatsAppAspClientsResponse } from '@shared/bff';

export interface INewCrQueryResponse {
  whatsAppAspClients: Pick<IWhatsAppAspClientsResponse, 'onboarded'>;
}

export const NEW_CR_QUERY = gql`
  query NewCr {
    whatsAppAspClients {
      onboarded {
        id
        name
      }
    }
  }
`;
