import { t } from 'messages';

import { TagType } from '@src/common/components';
import { CrState, WhatsAppMtStatus } from '@shared/bff';

const stepLabels = t.whatsapp.changeRequestProgressLabels;

export const prepareStepsForProgressIndicator = () => [
  {
    id: CrState.DRAFT,
    label: stepLabels.DRAFT(),
  },
  {
    id: CrState.IN_PROGRESS,
    label: stepLabels.IN_PROGRESS(),
  },
  {
    id: `${CrState.DONE}`,
    label: stepLabels.DONE(),
  },
];

export const templateLabelType: Record<WhatsAppMtStatus, TagType> = {
  ACTIVE: 'success',
  REJECTED: 'error',
  INACTIVE: 'error',
  PAUSED: 'error',
};

export const crLabelType: Record<CrState, TagType> = {
  DRAFT: 'emphasize',
  REVIEW_QUEUE: 'default',
  REVIEW: 'default',
  IN_PROGRESS: 'default',
  APPROVED: 'default',
  DONE: 'success',
  REJECTED: 'error',
  ROLLBACK: 'default',
  BOT_CR_UNSPECIFIED_STATE: 'default',
  SIGNATURE_PENDING: 'default',
};

export const LANGUAGES_IN_TABS_LIMIT = 6;
