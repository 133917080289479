import { gql } from '@apollo/client';

import { IViberCr } from '@shared/bff';

export type IUpdateCrMutationResponse = {
  viberUpdateCr: Pick<IViberCr, 'id'>;
};

export const UPDATE_CR_MUTATION = gql`
  mutation ViberUpdateCr($viberCr: ViberCrUpdateInput!) {
    viberUpdateCr(viberCr: $viberCr) {
      id
    }
  }
`;
