import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { t } from '@src/messages';
import { Channel } from '@src/common/types';
import { accountsPath, channelListPath } from '@src/common/utils';

interface IStep {
  id: number;
  label: string;
  onRenderSetupAssistantAction?: ReactNode;
}

export const getAspClientSteps = (): IStep[] => {
  return [
    {
      id: 1,
      label: t.home.setupAssistant.viber.stepOne.substepOne(),
      onRenderSetupAssistantAction: (
        <Link
          id="homepage-viber-account-link"
          to={accountsPath(Channel.ViberBusiness)}
        >
          {t.home.setupAssistant.viber.stepOne.accountsLink()}
        </Link>
      ),
    },
    {
      id: 2,
      label: t.home.setupAssistant.viber.stepOne.substepTwo(),
    },
    {
      id: 3,
      label: t.home.setupAssistant.viber.stepOne.substepThree(),
    },
  ];
};

export const getChannelSteps = (): IStep[] => {
  return [
    {
      id: 1,
      label: t.home.setupAssistant.viber.stepTwo.substepOne(),
      onRenderSetupAssistantAction: (
        <Link
          id="homepage-viber-channels-link"
          to={channelListPath(Channel.ViberBusiness)}
        >
          {t.home.setupAssistant.viber.stepTwo.channelsLink()}
        </Link>
      ),
    },
    {
      id: 2,
      label: t.home.setupAssistant.viber.stepTwo.substepTwo(),
    },
    {
      id: 3,
      label: t.home.setupAssistant.viber.stepTwo.substepThree(),
    },
  ];
};
