import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  channelSubpath,
  templateSubpath,
  accountsSubpath,
} from '@src/common/utils';

import {
  AspClientsView,
  AspClientRegisterView,
  AspClientDetailsView,
  AspClientEditView,
} from './aspClients';
import {
  BotDetailsView,
  ChangeRequestDetailsView,
  RequestBotView,
  ChannelHome,
  EditView,
} from './channels';
import {
  TemplateListView,
  RegisterTemplateView,
  TemplateDetailsView,
  TemplateEditView,
} from './messageTemplates';

interface IRoutesProps {
  path: string;
}

export const Routes = ({ path }: IRoutesProps) => (
  <Switch>
    <Route
      exact
      path={`${path}${accountsSubpath}`}
      component={AspClientsView}
    />
    <Route
      exact
      path={`${path}${accountsSubpath}/register`}
      component={AspClientRegisterView}
    />
    <Route
      exact
      path={`${path}${accountsSubpath}/account/:aspClientId`}
      component={AspClientDetailsView}
    />
    <Route
      exact
      path={`${path}${accountsSubpath}/account/:aspClientId/edit`}
      component={AspClientEditView}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/bot/:botId`}
      component={BotDetailsView}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/changeRequest/crbot/:botCrId`}
      component={ChangeRequestDetailsView}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/changeRequest/:botCrId/edit-draft`}
      render={() => <EditView mode="edit-draft" />}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/changeRequest/:botCrId/edit-rejected`}
      render={() => <EditView mode="edit-rejected" />}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/bot/:botId/edit-bot`}
      render={() => <EditView mode="edit-bot" />}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/changeRequest/bot/:botCrId`}
      render={(props) => <ChangeRequestDetailsView {...props} hasBot />}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/request`}
      component={RequestBotView}
    />
    <Route exact path={`${path}${channelSubpath}`} component={ChannelHome} />
    <Route
      exact
      path={`${path}${templateSubpath}`}
      component={TemplateListView}
    />
    <Route
      exact
      path={`${path}${templateSubpath}/register`}
      component={RegisterTemplateView}
    />
    <Route
      exact
      path={`${path}${templateSubpath}/template/:aspClientId/:templateName`}
      component={TemplateDetailsView}
    />
    <Route
      exact
      path={`${path}${templateSubpath}/template/:aspClientId/:templateName/add-language`}
      render={(props) => <TemplateEditView {...props} mode="add" />}
    />
    <Route
      exact
      path={`${path}${templateSubpath}/template/:aspClientId/:templateName/edit`}
      render={(props) => <TemplateEditView {...props} mode="edit" />}
    />
  </Switch>
);
