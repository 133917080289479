import * as Yup from 'yup';

import { IViberAspClientInput } from '@shared/bff';

import {
  aspClientFieldsMaxLength,
  aspClientFieldsMinLength,
} from './aspClientFieldsLength';

const getGeneralFieldRequirements = (): Record<
  keyof IViberAspClientInput,
  Yup.Schema<unknown>
> => ({
  name: Yup.string()
    .min(aspClientFieldsMinLength.name)
    .max(aspClientFieldsMaxLength.name)
    .required(),
  businessLegalName: Yup.string()
    .min(aspClientFieldsMinLength.businessLegalName)
    .max(aspClientFieldsMaxLength.businessLegalName)
    .required(),
  businessCategories: Yup.string().required(),
  businessRegisteredCountry: Yup.string().required(),
  warrantyLetterUrl: Yup.string().required(),
});

export const getAspClientValidationSchema = () =>
  Yup.object().shape({
    ...getGeneralFieldRequirements(),
  });
