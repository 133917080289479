import React from 'react';

import {
  Box,
  CheckboxField,
  DocumentationLink,
  FormModal,
  Text,
} from '@src/common/components';
import { t } from '@src/messages';

interface IChannelFormModalProps {
  handleCancel: () => void;
  handleOnSubmit: () => void;
  isOpen: boolean;
  shouldSubmitRequest: boolean;
  hasApprovedConditions: boolean;
  setHasApprovedConditions: (checked: boolean) => void;
  isSubmitting?: boolean;
}

export const ChannelFormModal = ({
  handleCancel,
  handleOnSubmit,
  isOpen,
  shouldSubmitRequest,
  hasApprovedConditions,
  setHasApprovedConditions,
  isSubmitting = false,
}: IChannelFormModalProps) => {
  return (
    <FormModal
      heading={
        shouldSubmitRequest
          ? t.viberBusiness.channels.request.confirmationModal.heading()
          : t.viberBusiness.channels.request.saveDraftModal.heading()
      }
      onCancel={handleCancel}
      onSubmit={handleOnSubmit}
      isOpen={isOpen}
      disableSubmit={
        isSubmitting || (shouldSubmitRequest && !hasApprovedConditions)
      }
      submitLabel={
        shouldSubmitRequest
          ? t.common.actions.submit()
          : t.common.actions.save()
      }
    >
      <Box pl="medium" pr="medium" pt="large" pb="large">
        <Text>
          {shouldSubmitRequest
            ? t.viberBusiness.channels.request.confirmationModal.description()
            : t.viberBusiness.channels.request.saveDraftModal.newTemplateBody()}
        </Text>
        {shouldSubmitRequest && (
          <>
            <Text mt="medium">
              <CheckboxField
                checked={hasApprovedConditions}
                onChange={(_, { checked }) => {
                  setHasApprovedConditions(checked);
                }}
                name="approveConditions"
                id="approve-conditions-checkbox"
                labels={{
                  label:
                    t.viberBusiness.channels.request.confirmationModal.approvalDescription(),
                }}
              />
            </Text>
            <Text color="weak" mt="medium">
              {t.viberBusiness.channels.request.confirmationModal.documentation.description()}{' '}
              <DocumentationLink>
                {t.viberBusiness.channels.request.confirmationModal.documentation.link()}
              </DocumentationLink>
            </Text>
          </>
        )}
      </Box>
    </FormModal>
  );
};
