import React, { useEffect, useState } from 'react';
import { IComboboxOption } from '@salesforce/design-system-react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import {
  Button,
  Card,
  Grid,
  GridItem,
  InputField,
  PhoneNumberField,
  SingleSelectField,
  StickyGridItem,
} from '@src/common/components';
import { t } from '@src/messages';
import { IKakaoTalkInput, IQueryKakaoTalkSenderDataArgs } from '@shared/bff';

import {
  SENDER_DATA_QUERY,
  IKakaoTalkSenderQueryResponse,
} from '../../graphql';
import { ChannelPreviewer } from '../ChannelPreviewer';

const CollectDataButton = styled(Button)({
  width: '100%',
  marginLeft: '10px',
});

const CardWrapper = styled(Card)({
  overflowX: 'auto',
});

export const SenderForm = () => {
  const { values, setFieldValue, setValues } =
    useFormikContext<IKakaoTalkInput>();
  const [midLevelCategories, setMidLevelCategories] = useState<
    IComboboxOption[]
  >([]);
  const [subLevelCategories, setSubLevelCategories] = useState<
    IComboboxOption[]
  >([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const { refetch } = useQuery<
    IKakaoTalkSenderQueryResponse,
    IQueryKakaoTalkSenderDataArgs
  >(SENDER_DATA_QUERY, {
    variables: { profileId: values.profileId },
    skip: true,
  });

  const collectData = async () => {
    setLoadingData(true);
    try {
      const { profileId } = values;
      const { data } = await refetch({ profileId });
      if (data) {
        const { kakaoTalkSenderData } = data;
        const newValues = { ...values, ...kakaoTalkSenderData };
        setValues(newValues);
      }
      setLoadingData(false);
    } catch {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (values.topLevelCategory === '36000') {
      setMidLevelCategories([
        {
          label: 'Elderly care',
          value: '36080',
        },
        {
          label: 'Child care',
          value: '36090',
        },
      ]);
    } else if (values.topLevelCategory === '102') {
      setMidLevelCategories([
        {
          label: 'Clothes',
          value: '10201',
        },
        {
          label: 'Furniture',
          value: '10202',
        },
      ]);
    } else {
      setMidLevelCategories([]);
      setSubLevelCategories([]);
    }
  }, [values.topLevelCategory]);

  useEffect(() => {
    if (values.midLevelCategory === '36080') {
      setSubLevelCategories([
        {
          label: 'Home care service',
          value: '36081',
        },
        {
          label: 'Community centre for the elderly',
          value: '36082',
        },
      ]);
    } else if (values.midLevelCategory === '10102') {
      setSubLevelCategories([
        {
          label: 'Preschool',
          value: '1010201',
        },
        {
          label: 'School',
          value: '1010202',
        },
      ]);
    } else if (values.midLevelCategory === '10201') {
      setSubLevelCategories([
        {
          label: 'Jackets',
          value: '1020101',
        },
        {
          label: 'Pants',
          value: '1020102',
        },
      ]);
    } else if (values.midLevelCategory === '10202') {
      setSubLevelCategories([
        {
          label: 'Sofas',
          value: '1020201',
        },
        {
          label: 'Beds',
          value: '1020202',
        },
      ]);
    } else {
      setSubLevelCategories([]);
    }
  }, [values.midLevelCategory]);

  return (
    <>
      <Grid>
        <GridItem cols={8} pr="small">
          <Grid flow="vertical">
            <GridItem>
              <Card heading={t.common.channelConfiguration()}>
                <GridItem cols={8} pl="small" pr="small">
                  <GridItem mb="small">
                    <Grid
                      flow="vertical"
                      alignment="spread"
                      verticalAlignment="end"
                    >
                      <GridItem cols={6}>
                        <InputField
                          id="profile-id-input"
                          name="profileId"
                          label={t.kakaoTalk.channels.fields.profileId()}
                          tooltip={t.kakaoTalk.channels.request.tooltips.profileId()}
                          required
                        />
                      </GridItem>
                      <GridItem cols={6}>
                        <CollectDataButton
                          disabled={!values.profileId || loadingData}
                          loading={loadingData}
                          onClick={collectData}
                        >
                          {t.kakaoTalk.channels.request.collectData()}
                        </CollectDataButton>
                      </GridItem>
                    </Grid>
                  </GridItem>
                  <GridItem mb="small">
                    <InputField
                      id="channel-name-input"
                      name="channelName"
                      label={t.kakaoTalk.channels.fields.channelName()}
                      tooltip={t.kakaoTalk.channels.request.tooltips.channelName()}
                      required
                    />
                  </GridItem>
                  <GridItem mb="small">
                    <PhoneNumberField
                      name="phoneNumber"
                      label={t.kakaoTalk.channels.fields.phoneNumber()}
                      placeholder={t.kakaoTalk.channels.request.placeholders.phoneNumber()}
                      tooltip={t.kakaoTalk.channels.request.tooltips.phoneNumber()}
                      id="phone-number-input"
                      required
                    />
                  </GridItem>
                  <GridItem mb="small">
                    <SingleSelectField
                      id="top-level-category-input"
                      name="topLevelCategory"
                      labels={{
                        label: t.kakaoTalk.channels.fields.topLevelCategory(),
                      }}
                      tooltip={t.kakaoTalk.channels.request.tooltips.topLevelCategory()}
                      options={[
                        {
                          label: 'Health care',
                          value: '36000',
                        },
                        {
                          label: 'Ecommerce',
                          value: '102',
                        },
                      ]}
                      onSelect={() => {
                        setFieldValue('midLevelCategory', '');
                        setFieldValue('subLevelCategory', '');
                      }}
                      variant="readonly"
                      required
                    />
                  </GridItem>
                  <GridItem mb="small">
                    <SingleSelectField
                      id="mid-level-category-input"
                      name="midLevelCategory"
                      labels={{
                        label: t.kakaoTalk.channels.fields.midLevelCategory(),
                      }}
                      tooltip={t.kakaoTalk.channels.request.tooltips.midLevelCategory()}
                      options={midLevelCategories}
                      singleInputDisabled={midLevelCategories.length === 0}
                      onSelect={() => setFieldValue('subLevelCategory', '')}
                      variant="readonly"
                      required
                    />
                  </GridItem>
                  <GridItem mb="small">
                    <SingleSelectField
                      id="sub-level-category-input"
                      name="subLevelCategory"
                      labels={{
                        label: t.kakaoTalk.channels.fields.subLevelCategory(),
                      }}
                      tooltip={t.kakaoTalk.channels.request.tooltips.subLevelCategory()}
                      options={subLevelCategories}
                      singleInputDisabled={subLevelCategories.length === 0}
                      variant="readonly"
                      required
                    />
                  </GridItem>
                </GridItem>
              </Card>
            </GridItem>
          </Grid>
        </GridItem>
        <StickyGridItem cols={4}>
          <CardWrapper
            heading={t.common.components.businessProfilePreviewer.heading()}
          >
            <ChannelPreviewer profileId={values.profileId} />
          </CardWrapper>
        </StickyGridItem>
      </Grid>
    </>
  );
};
