import { t } from '@src/messages';

export interface ICountryOption {
  value: string;
  label: string;
}

export const prepareOptionsForCountrySelector = (): ICountryOption[] =>
  Object.entries(t.viberBusiness.countries).map(
    ([key, langFn]: [string, () => string]) => ({
      value: key,
      label: langFn(),
    })
  );
