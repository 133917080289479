import React from 'react';

import { LabelTag } from '@src/common/components';
import { t } from '@src/messages';
import { NumberProviderType } from '@shared/bff';

interface INumberProviderLabelTagProps {
  type: NumberProviderType;
}

export const NumberProviderLabelTag = ({
  type,
}: INumberProviderLabelTagProps) => {
  return (
    <LabelTag type="transparent">
      {type === NumberProviderType.SINCH
        ? t.whatsapp.channels.numberProviderLabel.sinch()
        : t.whatsapp.channels.numberProviderLabel.customer()}
    </LabelTag>
  );
};
