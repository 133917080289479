import { gql } from '@apollo/client';

import { IViberCr } from '@shared/bff';

export type IViberTransitionSenderCrFromRejectedToDraftMutationResponse = {
  viberTransitionSenderCrFromRejectedToDraft: Pick<IViberCr, 'id'>;
};

export const TRANSITION_SENDER_CR_FROM_REJECTED_TO_DRAFT_MUTATION = gql`
  mutation viberTransitionSenderCrFromRejectedToDraft($crId: String!) {
    viberTransitionSenderCrFromRejectedToDraft(crId: $crId) {
      id
    }
  }
`;
