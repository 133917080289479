import React from 'react';

import {
  GridItem,
  InputField,
  PhoneNumberField,
  TextareaField,
} from '@src/common/components';
import { t } from '@src/messages';

import { senderFieldsMaxLength } from '../../../utils';

export const ContactInformation = () => (
  <>
    <GridItem cols={8} pl="small" pr="small">
      <GridItem mb="small">
        <TextareaField
          id={'description-input'}
          name="description"
          label={t.viberBusiness.channels.fields.description()}
          tooltip={t.viberBusiness.channels.request.tooltips.description()}
          required
          maxLength={`${senderFieldsMaxLength.description}`}
        />
      </GridItem>
      <GridItem mb="small">
        <InputField
          id={'address-input'}
          name="address"
          label={t.viberBusiness.channels.fields.address()}
          tooltip={t.viberBusiness.channels.request.tooltips.address()}
          maxLength={`${senderFieldsMaxLength.address}`}
          required
        />
      </GridItem>
      <GridItem mb="small">
        <PhoneNumberField
          name="phoneNumber"
          label={t.viberBusiness.channels.fields.phoneNumber()}
          placeholder={t.viberBusiness.channels.request.placeholders.phoneNumber()}
          tooltip={t.viberBusiness.channels.request.tooltips.phoneNumber()}
          id={`phone-number-input`}
          required
        />
      </GridItem>
      <GridItem>
        <InputField
          id={'website-input'}
          name="website"
          label={t.viberBusiness.channels.fields.website()}
          maxLength={`${senderFieldsMaxLength.website}`}
          required
        />
      </GridItem>
    </GridItem>
  </>
);
