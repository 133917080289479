import {
  IWhatsAppMtActions,
  WhatsAppMtUrlType,
  IWhatsAppMtTemplateContent,
  WhatsAppMtOtpType,
} from '@shared/bff';

import { CallToActionType, CallToActionButton } from '../../types';

const convertCallToActionButtonsToForm = (
  callToActionButtons: IWhatsAppMtActions[]
): CallToActionButton[] =>
  callToActionButtons.map((button) => {
    const { buttonText } = button;

    if ('phoneNumber' in button) {
      const { phoneNumber } = button;
      return {
        type: CallToActionType.CALL_PHONE_NUMBER,
        phoneNumber: phoneNumber.replace('+', ''),
        buttonText,
      };
    }
    const { urlType, websiteUrl } = button;

    const url =
      urlType === WhatsAppMtUrlType.DYNAMIC
        ? websiteUrl.substring(0, websiteUrl.lastIndexOf('{') - 1)
        : websiteUrl;
    return {
      type: CallToActionType.VISIT_WEBSITE,
      urlType,
      websiteUrl: url,
      buttonText,
    };
  });

export const convertExistingContentToForm = (
  existingContents: IWhatsAppMtTemplateContent[]
) => {
  return existingContents.map((content) => {
    const {
      id,
      language,
      headerType,
      headerText,
      mediaType,
      bodyText,
      footer,
      buttonType,
      quickReplyButtons,
      callToActionButtons,
      headerVariable,
      bodyVariables,
      mediaVariable,
      buttonUrlVariable,
    } = content;
    const crStatus = 'crStatus' in content ? content.crStatus : undefined;
    const templateStatus =
      'templateStatus' in content ? content.templateStatus : undefined;

    const variables: Pick<
      IWhatsAppMtTemplateContent,
      'headerVariable' | 'bodyVariables' | 'mediaVariable' | 'buttonUrlVariable'
    > = {};

    if (headerVariable || headerVariable === '') {
      variables.headerVariable = headerVariable;
    }

    if (bodyVariables) {
      variables.bodyVariables = bodyVariables;
    }

    if (mediaVariable) {
      variables.mediaVariable = mediaVariable;
    }

    if (buttonUrlVariable || buttonUrlVariable === '') {
      variables.buttonUrlVariable = buttonUrlVariable;
    }

    return {
      crStatus,
      templateStatus,
      id,
      language,
      headerType,
      headerText: headerText ?? undefined,
      mediaType,
      bodyText,
      footer: footer ?? '',
      buttonType,
      quickReplyButtons: quickReplyButtons ?? undefined,
      callToActionButtons: convertCallToActionButtonsToForm(
        callToActionButtons || []
      ),
      ...variables,
    };
  });
};

export const convertExistingAuthenticationContentToForm = (
  existingContents: IWhatsAppMtTemplateContent[]
) => {
  return existingContents.map((content) => {
    const {
      id,
      language,
      otpType,
      buttonText,
      securityRecommendation,
      codeExpirationMinutes,
      autofillText,
      packageName,
      signatureHash,
    } = content;
    const crStatus = 'crStatus' in content ? content.crStatus : undefined;
    const templateStatus =
      'templateStatus' in content ? content.templateStatus : undefined;

    return {
      crStatus,
      templateStatus,
      id,
      language,
      otpType: otpType ?? WhatsAppMtOtpType.COPY_CODE,
      buttonText: buttonText ?? '',
      securityRecommendation,
      codeExpirationMinutes,
      autofillText,
      packageName,
      signatureHash,
    };
  });
};
