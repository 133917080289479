import { gql } from '@apollo/client';

import { IViberCr } from '@shared/bff';

import {
  ICrSenderDataFragment,
  CR_SENDER_DATA_FRAGMENT,
} from './CrSenderDataFragment';

export type ICrFragment = Pick<
  IViberCr,
  'id' | 'senderId' | 'changes' | 'state' | 'created' | 'updated'
> & {
  sender: ICrSenderDataFragment;
};

export const VIBER_CR_FRAGMENT = gql`
  fragment CrFragment on ViberCr {
    id
    senderId
    sender {
      ...CrSenderDataFragment
    }
    changes
    state
    created
    updated
  }
  ${CR_SENDER_DATA_FRAGMENT}
`;
