import { WhatsAppMtLanguage } from '@shared/bff';

export const mapMessageTemplateLanguageToFooterText = (
  codeExpirationMinutes: number
): Record<WhatsAppMtLanguage, string> => ({
  [WhatsAppMtLanguage.AF]: `Hierdie kode verstryk oor ${codeExpirationMinutes} minute.`,
  [WhatsAppMtLanguage.SQ]: `Ky kod skadon pas ${codeExpirationMinutes} minutash.`,
  [WhatsAppMtLanguage.AR]: `"تنتهي صلاحية هذا الرمز خلال ${codeExpirationMinutes} من الدقائق."`,
  [WhatsAppMtLanguage.AZ]: ``,
  [WhatsAppMtLanguage.BN]: `এই কোডের মেয়াদ ${codeExpirationMinutes} মিনিটের মধ্যে শেষ হয়ে যাবে।`,
  [WhatsAppMtLanguage.BG]: `Този код изтича след ${codeExpirationMinutes} минути`,
  [WhatsAppMtLanguage.CA]: ``,
  [WhatsAppMtLanguage.ZH_CN]: `这组验证码将在 ${codeExpirationMinutes} 分钟后过期。`,
  [WhatsAppMtLanguage.ZH_HK]: `這組驗證碼將於 ${codeExpirationMinutes} 分鐘後失效。`,
  [WhatsAppMtLanguage.ZH_TW]: `這組驗證碼將於 ${codeExpirationMinutes} 分鐘後失效。`,
  [WhatsAppMtLanguage.HR]: `Ovaj kôd istječe za ${codeExpirationMinutes} min.`,
  [WhatsAppMtLanguage.CS]: `Platnost kódu skončí za ${codeExpirationMinutes} min.`,
  [WhatsAppMtLanguage.DA]: `Denne kode udløber om ${codeExpirationMinutes} minutter.`,
  [WhatsAppMtLanguage.NL]: `Deze code verloopt over ${codeExpirationMinutes} minuten.`,
  [WhatsAppMtLanguage.EN]: `This code expires in ${codeExpirationMinutes} minutes.`,
  [WhatsAppMtLanguage.EN_GB]: `This code expires in ${codeExpirationMinutes} minutes.`,
  [WhatsAppMtLanguage.EN_US]: `This code expires in ${codeExpirationMinutes} minutes.`,
  [WhatsAppMtLanguage.ET]: `See kood aegub ${codeExpirationMinutes} minuti pärast.`,
  [WhatsAppMtLanguage.FIL]: `Mag-e-expire ang code na ito sa loob ng ${codeExpirationMinutes} minuto.`,
  [WhatsAppMtLanguage.FI]: `Tämä koodi vanhenee ${codeExpirationMinutes} minuutin kuluttua.`,
  [WhatsAppMtLanguage.FR]: `Ce code expire dans ${codeExpirationMinutes} minutes.`,
  [WhatsAppMtLanguage.DE]: `Dieser Code verfällt in ${codeExpirationMinutes} Minuten.`,
  [WhatsAppMtLanguage.EL]: `Ο κωδικός λήγει σε ${codeExpirationMinutes} λεπτά.`,
  [WhatsAppMtLanguage.GU]: `આ કોડ ${codeExpirationMinutes} મિનિટમાં સમાપ્ત થશે.`,
  [WhatsAppMtLanguage.HA]: ``,
  [WhatsAppMtLanguage.HE]: `תוקף הקוד הזה יפוג בעוד ${codeExpirationMinutes} דקות.`,
  [WhatsAppMtLanguage.HI]: `इस कोड की समय-सीमा ${codeExpirationMinutes} मिनट में समाप्त हो जाएगी `,
  [WhatsAppMtLanguage.HU]: `Ez a kód ${codeExpirationMinutes} perc múlva lejár.`,
  [WhatsAppMtLanguage.ID]: `Kode ini kedaluwarsa dalam ${codeExpirationMinutes} menit.`,
  [WhatsAppMtLanguage.GA]: `This code expires in ${codeExpirationMinutes} minutes.`,
  [WhatsAppMtLanguage.IT]: `Questo codice scadrà tra ${codeExpirationMinutes} minuti.`,
  [WhatsAppMtLanguage.JA]: `このコードは${codeExpirationMinutes}分後に期限切れとなります`,
  [WhatsAppMtLanguage.KN]: `ಈ ಕೋಡ್ ${codeExpirationMinutes} ನಿಮಿಷಗಳಲ್ಲಿ ಅವಧಿ ಮೀರುತ್ತದೆ`,
  [WhatsAppMtLanguage.KK]: ``,
  [WhatsAppMtLanguage.KO]: `이 코드는 ${codeExpirationMinutes}분 후에 만료됩니다.`,
  [WhatsAppMtLanguage.LO]: ``,
  [WhatsAppMtLanguage.LV]: `Koda derīgums beidzas pēc ${codeExpirationMinutes} minūtēm`,
  [WhatsAppMtLanguage.LT]: `Šis kodas baigs galioti po ${codeExpirationMinutes} min.`,
  [WhatsAppMtLanguage.MK]: `Кодов истекува за ${codeExpirationMinutes} минути.`,
  [WhatsAppMtLanguage.MS]: `Kod ini akan luput dalam masa ${codeExpirationMinutes} minit.`,
  [WhatsAppMtLanguage.ML]: `ഈ കോഡ് ${codeExpirationMinutes} മിനിറ്റിനുള്ളിൽ കാലഹരണപ്പെടുന്നു`,
  [WhatsAppMtLanguage.MR]: `हा कोड ${codeExpirationMinutes} मिनिटांमध्ये कालबाह्य होतो.`,
  [WhatsAppMtLanguage.NB]: `Denne koden utløper om ${codeExpirationMinutes} minutter.`,
  [WhatsAppMtLanguage.FA]: ``,
  [WhatsAppMtLanguage.PL]: `Ten kod wygaśnie za ${codeExpirationMinutes} minut.`,
  [WhatsAppMtLanguage.PT_BR]: `Este código expira em ${codeExpirationMinutes} minutos.`,
  [WhatsAppMtLanguage.PT_PT]: `Este código expira dentro de ${codeExpirationMinutes} minutos`,
  [WhatsAppMtLanguage.PA]: `ਇਸ ਕੋਡ ਦੀ ਮਿਆਦ ${codeExpirationMinutes} ਮਿੰਟਾਂ ਵਿੱਚ ਸਮਾਪਤ ਹੋ ਜਾਵੇਗੀ।`,
  [WhatsAppMtLanguage.RO]: `Acest cod va expira peste ${codeExpirationMinutes} minute.`,
  [WhatsAppMtLanguage.RU]: `Срок действия этого кода истекает через ${codeExpirationMinutes} минут.`,
  [WhatsAppMtLanguage.SR]: `Овај кôд истиче за ${codeExpirationMinutes} минута.`,
  [WhatsAppMtLanguage.SK]: `Tento kód vyprší o ${codeExpirationMinutes} min.`,
  [WhatsAppMtLanguage.SL]: `Koda bo potekla čez ${codeExpirationMinutes} minut.`,
  [WhatsAppMtLanguage.ES]: `Este código caduca en ${codeExpirationMinutes} minutos.`,
  [WhatsAppMtLanguage.ES_AR]: `Este código caduca en ${codeExpirationMinutes} minutos.`,
  [WhatsAppMtLanguage.ES_ES]: `Este código caduca en ${codeExpirationMinutes} minutos.`,
  [WhatsAppMtLanguage.ES_MX]: `Este código caduca en ${codeExpirationMinutes} minutos.`,
  [WhatsAppMtLanguage.SW]: `Msimbo huu utakwisha muda baada ya dakika ${codeExpirationMinutes}.`,
  [WhatsAppMtLanguage.SV]: `Koden går ut om ${codeExpirationMinutes} minuter.`,
  [WhatsAppMtLanguage.TA]: `இந்தக் குறியீடு ${codeExpirationMinutes} நிமிடங்களில் காலாவதியாகும்.`,
  [WhatsAppMtLanguage.TE]: `ఈ కోడ్ గడువు ${codeExpirationMinutes} నిమిషాల్లో ముగుస్తుంది.`,
  [WhatsAppMtLanguage.TH]: `รหัสนี้จะหมดอายุใน ${codeExpirationMinutes} นาที`,
  [WhatsAppMtLanguage.TR]: `Bu kodun süresi ${codeExpirationMinutes} dakika sonra doluyor.`,
  [WhatsAppMtLanguage.UK]: `Термін дії цього коду закінчується через ${codeExpirationMinutes} хвилин.`,
  [WhatsAppMtLanguage.UR]: `یہ کوڈ ${codeExpirationMinutes} منٹ میں زائد المیعاد ہو جائے گا۔`,
  [WhatsAppMtLanguage.UZ]: ``,
  [WhatsAppMtLanguage.VI]: `Mã này sẽ hết hạn trong ${codeExpirationMinutes} phút nữa.`,
  [WhatsAppMtLanguage.ZU]: ``,
  [WhatsAppMtLanguage.KA]: `ამ კოდის მოქმედების ვადა ამოიწურება ${codeExpirationMinutes} წუთში.`,
  [WhatsAppMtLanguage.RW_RW]: ``,
  [WhatsAppMtLanguage.KY_KG]: ``,
  [WhatsAppMtLanguage.NO_LANGUAGE]: ``,
});
