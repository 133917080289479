import React, { useState, useContext } from 'react';
import { ButtonGroup } from '@salesforce/design-system-react';
import { useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import {
  QueryHandler,
  IQueryHandlerResult,
  GridItem,
  Grid,
  PageHeader,
  Button,
  FormModal,
  Box,
  Text,
  ToastContext,
  UserContext,
  ScopedNotification,
} from '@src/common/components';
import { t } from '@src/messages';
import { templateListPath, templateEditPath } from '@src/common/utils';
import { Channel } from '@src/common/types';
import { useFeatureFlags } from '@src/common/utils/hooks';
import { IMutationWhatsAppDeleteMessageTemplateArgs } from '@shared/bff';

import {
  TEMPLATE_QUERY,
  ITemplateQueryResponse,
  DELETE_TEMPLATE_MUTATION,
  IWhatsAppMtDeleteResponse,
} from '../graphql';
import { TemplateDetailsSection } from '../components';
import { ITemplateMatchParams } from '../types';

export const TemplateDetailsView = () => {
  const { deleteWaTemplate } = useFeatureFlags();
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const [showModal, setShowModal] = useState(false);
  const { aspClientId, templateName } = useParams<ITemplateMatchParams>();
  const history = useHistory();
  const [deleteTemplate, { loading }] = useMutation<
    IWhatsAppMtDeleteResponse,
    IMutationWhatsAppDeleteMessageTemplateArgs
  >(DELETE_TEMPLATE_MUTATION);
  const { toast } = useContext(ToastContext);

  const handleModalClose = () => setShowModal(false);
  const handleModalOpen = () => setShowModal(true);

  return (
    <QueryHandler
      query={TEMPLATE_QUERY}
      variables={{ aspClientId, name: templateName }}
      levitate
    >
      {({
        data: {
          whatsAppAspClient: { name: aspClientName },
          whatsAppMessageTemplate: { name, category, contents },
        },
      }: IQueryHandlerResult<ITemplateQueryResponse>) => {
        const handleEditClick = () =>
          history.push(templateEditPath(Channel.Whatsapp, aspClientId, name));

        const handleDeleteTemplateClick = async () => {
          try {
            await deleteTemplate({
              variables: {
                name,
                aspClientId,
              },
            });

            history.push(templateListPath(Channel.Whatsapp));
          } catch (e) {
            toast('error', {
              heading: t.common.toasts.somethingWentWrong(),
            });
          }
        };

        const template = {
          aspClientId,
          name,
          category,
          contents,
        };

        return (
          <>
            <Grid>
              <GridItem mb="small">
                <PageHeader
                  channelType={Channel.Whatsapp}
                  sectionType="messageTemplate"
                  title={templateName}
                  id="template-details-header"
                  breadcrumbs={[
                    {
                      header: t.common.messageTemplates(),
                      to: templateListPath(Channel.Whatsapp),
                    },
                  ]}
                  // TODO: Add missing actions (Clone Template)
                  actions={
                    <ButtonGroup variant="list">
                      <Button
                        key="edit"
                        label={t.common.actions.editTemplate()}
                        onClick={handleEditClick}
                        disabled={readOnlyAccess}
                      />
                      {deleteWaTemplate ? (
                        <Button
                          key="delete"
                          label={t.common.actions.deleteTemplate()}
                          onClick={handleModalOpen}
                          disabled={readOnlyAccess}
                        />
                      ) : (
                        <></>
                      )}
                    </ButtonGroup>
                  }
                />
              </GridItem>
              {readOnlyAccess && (
                <GridItem mb="small">
                  <ScopedNotification variant="info">
                    {t.common.readOnlyAccessNotification()}
                  </ScopedNotification>
                </GridItem>
              )}
              <GridItem>
                <TemplateDetailsSection
                  aspClientName={aspClientName}
                  template={template}
                />
              </GridItem>
            </Grid>
            <FormModal
              heading={t.whatsapp.messageTemplates.details.deleteModal.heading()}
              onCancel={handleModalClose}
              onSubmit={handleDeleteTemplateClick}
              isOpen={showModal}
              submitLabel={t.common.actions.delete()}
              submitting={loading}
            >
              <Box pl="medium" pr="medium" pt="large" pb="large">
                <Text>
                  {t.whatsapp.messageTemplates.details.deleteModal.body()}
                </Text>
              </Box>
            </FormModal>
          </>
        );
      }}
    </QueryHandler>
  );
};
