import React from 'react';
import { IComboboxOption } from '@salesforce/design-system-react';

import {
  SingleSelectField,
  TextareaField,
  GridItem,
  Card,
} from '@src/common/components';
import { t } from '@src/messages';
import { IWhatsAppBotInput, WhatsAppVertical } from '@shared/bff';

import { botFieldsMaxLength, validVerticalTypes } from '../../../utils';

export const CompanyInfo = () => (
  <Card heading={t.whatsapp.fieldLabels.companyInformation()}>
    <GridItem cols={8} pl="small" pr="small">
      <GridItem mb="small">
        <TextareaField<IWhatsAppBotInput>
          id="company-about-input"
          name="about"
          label={t.whatsapp.fieldLabels.aboutBusiness()}
          maxLength={`${botFieldsMaxLength.about}`}
          tooltip={t.whatsapp.tooltips.companyInfoAbout()}
          required
        />
      </GridItem>
      <GridItem mb="small">
        <SingleSelectField<IWhatsAppBotInput>
          id="company-industry-input"
          name="vertical"
          labels={{
            label: t.whatsapp.fieldLabels.vertical(),
            placeholderReadOnly:
              t.whatsapp.channels.request.placeholders.vertical(),
          }}
          options={validVerticalTypes.map(
            (key: WhatsAppVertical): IComboboxOption => ({
              value: key,
              label: t.whatsapp.verticalTypeLabels[key](),
            })
          )}
          required
          variant="readonly"
          tooltip={t.whatsapp.tooltips.companyInfoIndustry()}
        />
      </GridItem>
    </GridItem>
  </Card>
);
