import { WhatsAppMtLanguage } from '@shared/bff';

export const mapMessageTemplateLanguageToBodyText: Record<
  WhatsAppMtLanguage,
  string
> = {
  [WhatsAppMtLanguage.AF]: `*{{1}}* is jou verifikasiekode.`,
  [WhatsAppMtLanguage.SQ]: `*{{1}}* është kodi yt i verifikimit.`,
  [WhatsAppMtLanguage.AR]: `*{{1}}* هو رمز التحقق الخاص بك.`,
  [WhatsAppMtLanguage.AZ]: ``,
  [WhatsAppMtLanguage.BN]: `*{{1}}* হলো আপনার ভেরিফিকেশন কোড।`,
  [WhatsAppMtLanguage.BG]: `*{{1}}* е вашият код за потвърждение.`,
  [WhatsAppMtLanguage.CA]: ``,
  [WhatsAppMtLanguage.ZH_CN]: `*{{1}}* 是你的验证码。`,
  [WhatsAppMtLanguage.ZH_HK]: `*{{1}}* 是你的驗證碼。`,
  [WhatsAppMtLanguage.ZH_TW]: `*{{1}}* 是你的驗證碼。`,
  [WhatsAppMtLanguage.HR]: `*{{1}}* je vaš kôd za provjeru valjanosti.`,
  [WhatsAppMtLanguage.CS]: `Váš ověřovací kód je *{{1}}*.`,
  [WhatsAppMtLanguage.DA]: `*{{1}}* er din verificeringskode.`,
  [WhatsAppMtLanguage.NL]: `*{{1}}* is je verificatiecode.`,
  [WhatsAppMtLanguage.EN]: `*{{1}}* is your verification code.`,
  [WhatsAppMtLanguage.EN_GB]: `*{{1}}* is your verification code.`,
  [WhatsAppMtLanguage.EN_US]: `*{{1}}* is your verification code.`,
  [WhatsAppMtLanguage.ET]: `*{{1}}* on sinu kinnituskood.`,
  [WhatsAppMtLanguage.FIL]: `*{{1}}* ang iyong verification code.`,
  [WhatsAppMtLanguage.FI]: `*{{1}}* on vahvistuskoodisi.`,
  [WhatsAppMtLanguage.FR]: `Votre code de vérification est *{{1}}*.`,
  [WhatsAppMtLanguage.DE]: `Dein Verifizierungscode lautet *{{1}}*.`,
  [WhatsAppMtLanguage.EL]: `Το *{{1}}* είναι ο κωδικός επαλήθευσης.`,
  [WhatsAppMtLanguage.GU]: `*{{1}}*એ તમારો ખાતરીનો કોડ છે.`,
  [WhatsAppMtLanguage.HA]: ``,
  [WhatsAppMtLanguage.HE]: `קוד האימות הוא *{{1}}*.`,
  [WhatsAppMtLanguage.HI]: `*{{1}}* आपका वेरिफ़िकेशन कोड है.`,
  [WhatsAppMtLanguage.HU]: `A(z) *{{1}}* az ellenőrző kódod.`,
  [WhatsAppMtLanguage.ID]: `*{{1}}* adalah kode verifikasi Anda.`,
  [WhatsAppMtLanguage.GA]: `*{{1}}* is your verification code.`,
  [WhatsAppMtLanguage.IT]: `*{{1}}* è il tuo codice di verifica.`,
  [WhatsAppMtLanguage.JA]: `*{{1}}*があなたの認証コードです。`,
  [WhatsAppMtLanguage.KN]: `*{{1}}* ನಿಮ್ಮ ಪರಿಶೀಲನೆ ಕೋಡ್ ಆಗಿದೆ.`,
  [WhatsAppMtLanguage.KK]: ``,
  [WhatsAppMtLanguage.KO]: `인증 코드는 *{{1}}*입니다.`,
  [WhatsAppMtLanguage.LO]: ``,
  [WhatsAppMtLanguage.LV]: `*{{1}}* ir jūsu verifikācijas kods.`,
  [WhatsAppMtLanguage.LT]: `*{{1}}* yra jūsų patvirtinimo kodas.`,
  [WhatsAppMtLanguage.MK]: `*{{1}}* е твојот код за верификација.`,
  [WhatsAppMtLanguage.MS]: `*{{1}}* adalah kod penentusahan anda.`,
  [WhatsAppMtLanguage.ML]: `നിങ്ങളുടെ വെരിഫിക്കേഷൻ കോഡ് *{{1}}* ആണ്.`,
  [WhatsAppMtLanguage.MR]: `*{{1}}* हा तुमचा व्हेरिफिकेशन कोड आहे.`,
  [WhatsAppMtLanguage.NB]: `*{{1}}* er bekreftelseskoden din.`,
  [WhatsAppMtLanguage.FA]: ``,
  [WhatsAppMtLanguage.PL]: `*{{1}}* to kod weryfikacyjny.`,
  [WhatsAppMtLanguage.PT_BR]: `Seu código de verificação é *{{1}}*.`,
  [WhatsAppMtLanguage.PT_PT]: `*{{1}}* é o teu código de verificação.`,
  [WhatsAppMtLanguage.PA]: `*{{1}}* ਤੁਹਾਡਾ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਹੈ।`,
  [WhatsAppMtLanguage.RO]: `*{{1}}* este codul tău de verificare.`,
  [WhatsAppMtLanguage.RU]: `Ваш код подтверждения — *{{1}}*.`,
  [WhatsAppMtLanguage.SR]: `*{{1}}* је ваш кôд за потврду.`,
  [WhatsAppMtLanguage.SK]: `*{{1}}* je váš overovací kód.`,
  [WhatsAppMtLanguage.SL]: `*{{1}}* je tvoja potrditvena koda.`,
  [WhatsAppMtLanguage.ES]: `Tu código de verificación es *{{1}}*.`,
  [WhatsAppMtLanguage.ES_AR]: `Tu código de verificación es *{{1}}*.`,
  [WhatsAppMtLanguage.ES_ES]: `Tu código de verificación es *{{1}}*.`,
  [WhatsAppMtLanguage.ES_MX]: `Tu código de verificación es *{{1}}*.`,
  [WhatsAppMtLanguage.SW]: `*{{1}}* ni msimbo wako wa uthibitishaji.`,
  [WhatsAppMtLanguage.SV]: `*{{1}}* är din verifieringskod.`,
  [WhatsAppMtLanguage.TA]: `*{{1}}* என்பது உங்கள் சரிபார்ப்பு குறியீடாகும்.`,
  [WhatsAppMtLanguage.TE]: `*{{1}}* మీ వెరిఫికేషన్ కోడ్.`,
  [WhatsAppMtLanguage.TH]: `รหัสยืนยันของคุณคือ *{{1}}*`,
  [WhatsAppMtLanguage.TR]: `*{{1}}*, doğrulama kodun.`,
  [WhatsAppMtLanguage.UK]: `Ваш код підтвердження – *{{1}}*.`,
  [WhatsAppMtLanguage.UR]: `*{{1}}* آپ کا توثیقی کوڈ ہے۔`,
  [WhatsAppMtLanguage.UZ]: ``,
  [WhatsAppMtLanguage.VI]: `*{{1}}* là mã xác minh của bạn.`,
  [WhatsAppMtLanguage.ZU]: ``,
  [WhatsAppMtLanguage.KA]: `*{{1}}* არის თქვენი დამადასტურებელი კოდი.`,
  [WhatsAppMtLanguage.RW_RW]: ``,
  [WhatsAppMtLanguage.KY_KG]: ``,
  [WhatsAppMtLanguage.NO_LANGUAGE]: ``,
};
