import { gql } from '@apollo/client';

import { IWhatsAppAspClient } from '@shared/bff';

export interface IUpdateAspClientMutationResponse {
  whatsAppUpdateAspClient: IWhatsAppAspClient;
}

export const UPDATE_ASP_CLIENT_MUTATION = gql`
  mutation WhatsAppUpdateAspClient($aspClient: UpdateWhatsAppAspClientInput!) {
    whatsAppUpdateAspClient(aspClient: $aspClient) {
      id
    }
  }
`;
