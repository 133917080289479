import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { ButtonGroup } from '@salesforce/design-system-react';
import { useMutation } from '@apollo/client';

import { t } from '@src/messages';
import {
  PageHeader,
  Grid,
  GridItem,
  Button,
  ToastContext,
  RouteLeavingGuard,
  QueryHandler,
  IQueryHandlerResult,
  UserContext,
  ScopedNotification,
} from '@src/common/components';
import { Channel, IAspClientIdMatchParams } from '@src/common/types';
import { accountsPath, accountsDetailsPath } from '@src/common/utils';
import {
  IMutationWhatsAppUpdateAspClientArgs,
  ActivityTargetType,
} from '@shared/bff';

import {
  UPDATE_ASP_CLIENT_MUTATION,
  IUpdateAspClientMutationResponse,
  ASP_CLIENT_QUERY,
  IAspClientQueryResponse,
} from '../graphql';
import { AspClientRegistrationForm } from '../components';
import { getAspClientValidationSchema } from '../utils';

export const AspClientEditView = () => {
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const history = useHistory();
  const { toast } = useContext(ToastContext);
  const { aspClientId } = useParams<IAspClientIdMatchParams>();
  const [updateAspClientMutation] = useMutation<
    IUpdateAspClientMutationResponse,
    IMutationWhatsAppUpdateAspClientArgs
  >(UPDATE_ASP_CLIENT_MUTATION);

  const handleCancelClick = () =>
    history.push(accountsDetailsPath(Channel.Whatsapp, aspClientId as string));

  return (
    <QueryHandler
      query={ASP_CLIENT_QUERY}
      variables={{ aspClientId, targetType: ActivityTargetType.ASP_CLIENT }}
      levitate
    >
      {({
        data: {
          whatsAppAspClient: {
            name,
            facebookBusinessManagerId,
            companyLegalName,
          },
        },
      }: IQueryHandlerResult<IAspClientQueryResponse>) => {
        return (
          <Formik
            initialValues={{
              name,
              companyLegalName,
              facebookBusinessManagerId,
            }}
            validationSchema={getAspClientValidationSchema()}
            onSubmit={async (submittedData, actions) => {
              try {
                const { data } = await updateAspClientMutation({
                  variables: {
                    aspClient: {
                      name: submittedData.name,
                      companyLegalName: submittedData.companyLegalName,
                      facebookBusinessManagerId:
                        submittedData.facebookBusinessManagerId,
                      aspClientId: aspClientId as string,
                    },
                  },
                });
                const { id } = data!.whatsAppUpdateAspClient;
                actions.setSubmitting(false);
                actions.resetForm();

                history.push(accountsDetailsPath(Channel.Whatsapp, id));
                toast('success', {
                  heading: t.common.toasts.submitRequestSuccess(),
                });
              } catch (e) {
                toast('error', {
                  heading: t.common.toasts.somethingWentWrong(),
                });
              }
            }}
          >
            {({ isSubmitting, dirty }) => {
              const shouldBlockExternalNavigation = () => dirty;
              return (
                <>
                  <Form>
                    <Grid>
                      <GridItem mb="small">
                        <PageHeader
                          channelType={Channel.Whatsapp}
                          sectionType="account"
                          title={name}
                          id="account-edit-header"
                          breadcrumbs={[
                            {
                              header: t.common.accounts(),
                              to: accountsPath(Channel.Whatsapp),
                            },
                          ]}
                          actions={
                            <ButtonGroup
                              className="slds-align-middle"
                              variant="list"
                              id="button-group-page-header-actions"
                            >
                              <Button
                                label={t.common.actions.cancel()}
                                onClick={handleCancelClick}
                              />
                              <Button
                                label={t.common.actions.submitRequest()}
                                type="submit"
                                loading={isSubmitting}
                                disabled={
                                  !dirty || isSubmitting || readOnlyAccess
                                }
                                id="submit-request-button"
                              />
                            </ButtonGroup>
                          }
                        />
                      </GridItem>
                      {readOnlyAccess && (
                        <GridItem mb="small">
                          <ScopedNotification variant="info">
                            {t.common.readOnlyAccessNotification()}
                          </ScopedNotification>
                        </GridItem>
                      )}
                      <GridItem>
                        <AspClientRegistrationForm />
                      </GridItem>
                    </Grid>
                  </Form>
                  <RouteLeavingGuard
                    whenBlockInternalNavigation={dirty}
                    navigate={(path) => {
                      history.push(path);
                    }}
                    shouldBlockExternalNavigation={
                      shouldBlockExternalNavigation
                    }
                  />
                </>
              );
            }}
          </Formik>
        );
      }}
    </QueryHandler>
  );
};
