import React from 'react';

import { GridItem, TextareaField } from '@src/common/components';
import { t } from '@src/messages';

import { senderFieldsMaxLength } from '../../../utils';

export const SampleMessages = () => {
  return (
    <>
      <GridItem cols={8} mb="small" pl="small" pr="small">
        <TextareaField
          id={'sample-message-input'}
          name="sampleMessage"
          label={t.viberBusiness.channels.fields.sampleMessage()}
          maxLength={`${senderFieldsMaxLength.sampleMessage}`}
          tooltip={t.viberBusiness.channels.request.tooltips.sampleMessage()}
        />
      </GridItem>
    </>
  );
};
