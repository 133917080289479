import React from 'react';

import { Box, Card, Grid, GridItem, Text } from '@src/common/components';
import { t } from '@src/messages';
import { ILogoUrl } from '@shared/bff';

import { LogoDisplayer } from '../LogoDisplayer';

interface IChannelDesignSectionProps {
  crValues: {
    logoUrls: ILogoUrl[];
  };
  currentValues?: {
    logoUrls: ILogoUrl[];
  };
  showCurrent: boolean;
}

export const ChannelDesignSection = ({
  crValues,
  currentValues,
  showCurrent,
}: IChannelDesignSectionProps) => {
  const hasChanges =
    showCurrent &&
    JSON.stringify(crValues.logoUrls) !==
      JSON.stringify(currentValues?.logoUrls);
  return (
    <Card heading={t.common.channelDesign()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <Text mb="small" color="weak">
          {t.viberBusiness.channels.fields.brandLogo()}
        </Text>
        <Grid>
          <GridItem pr="x-small" cols={hasChanges ? 6 : 12}>
            <LogoDisplayer logoUrls={crValues.logoUrls} />
          </GridItem>
          {hasChanges && (
            <GridItem cols={6}>
              {currentValues && (
                <LogoDisplayer logoUrls={currentValues?.logoUrls} highlighted />
              )}
            </GridItem>
          )}
        </Grid>
      </Box>
    </Card>
  );
};
