import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { ButtonGroup, Modal } from '@salesforce/design-system-react';

import { Channel } from '@src/common/types';
import {
  PageHeader,
  Box,
  ToastContext,
  DocumentationLink,
  Button,
  UserContext,
  ScopedNotification,
} from '@src/common/components';
import { t } from '@src/messages';
import {
  channelListPath,
  channelBotDetailsPath,
  channelChangeRequestEditDraftPath,
  channelChangeRequestEditRejectedPath,
} from '@src/common/utils';
import {
  IMutationWhatsAppBotActionArgs,
  CrState,
  WhatsAppBotAction,
  BotState,
} from '@shared/bff';

import {
  ICrFragment,
  IBotActionMutationResponse,
  BOT_ACTION_MUTATION,
  BOT_QUERY,
} from '../../graphql';

interface IHeaderProps {
  whatsAppCr: ICrFragment;
  showCurrent?: boolean;
  setShowCurrent: React.Dispatch<React.SetStateAction<boolean>>;
  hasBot?: boolean;
}

export const Header = ({
  showCurrent,
  setShowCurrent,
  whatsAppCr,
  hasBot,
}: IHeaderProps) => {
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const { toast } = useContext(ToastContext);
  const history = useHistory();
  const handleCurrentClick = () => setShowCurrent(!showCurrent);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [botActionRequest, { loading: deleteLoading }] = useMutation<
    IBotActionMutationResponse,
    IMutationWhatsAppBotActionArgs
  >(BOT_ACTION_MUTATION);

  const isRejected = whatsAppCr.state === CrState.REJECTED;
  const isInDraft = whatsAppCr.state === CrState.DRAFT;

  const handleGoToChannelClick = () =>
    history.push(channelBotDetailsPath(Channel.Whatsapp, whatsAppCr.botId));

  const handleEditDraft = () =>
    history.push(
      channelChangeRequestEditDraftPath(Channel.Whatsapp, whatsAppCr.id)
    );

  const handleEditRejected = async () =>
    history.push(
      channelChangeRequestEditRejectedPath(Channel.Whatsapp, whatsAppCr.id)
    );

  const handleCancel = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteConfirm = () => {
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      await botActionRequest({
        variables: {
          action: WhatsAppBotAction.DEACTIVATE,
          botId: whatsAppCr.botId,
        },
        refetchQueries: [
          {
            query: BOT_QUERY,
            variables: {
              botId: whatsAppCr.botId,
            },
          },
        ],
      });
      setShowDeleteModal(false);
    } catch (e) {
      toast('error', {
        heading: t.common.toasts.somethingWentWrong(),
      });
    }
  };

  return (
    <>
      <PageHeader
        channelType={Channel.Whatsapp}
        sectionType="channel"
        title={whatsAppCr?.bot?.name}
        breadcrumbs={[
          {
            header: t.common.channels(),
            to: channelListPath(Channel.Whatsapp),
          },
        ]}
        actions={
          <ButtonGroup variant="list">
            {hasBot && (
              <Button
                id="show-current-button"
                key="current"
                label={
                  showCurrent
                    ? t.common.actions.hideCurrent()
                    : t.common.actions.showCurrent()
                }
                onClick={handleCurrentClick}
              />
            )}

            {hasBot && (
              <Button
                id="go-to-channel-button"
                key="go-to-channel"
                label={t.common.actions.goToChannel()}
                onClick={handleGoToChannelClick}
              />
            )}

            {isInDraft && (
              <Button
                id="edit-draft-button"
                key="editDraft"
                label={t.common.actions.editDraft()}
                onClick={handleEditDraft}
                disabled={readOnlyAccess}
              />
            )}

            {isRejected && (
              <Button
                id="edit-rejected-button"
                key="editReject"
                label={t.common.actions.editRejected()}
                onClick={handleEditRejected}
                disabled={readOnlyAccess}
              />
            )}

            {whatsAppCr.bot.state !== BotState.INACTIVE &&
            whatsAppCr.bot.state !== BotState.NEW ? (
              <Button
                label={t.whatsapp.channels.deleteChannel()}
                onClick={handleDeleteConfirm}
                disabled={readOnlyAccess}
              />
            ) : (
              // can not do flag && <Button /> as last element in ButtonGroup, otherwise crash
              <></>
            )}
          </ButtonGroup>
        }
      />
      {readOnlyAccess && (
        <>
          <Box mt="small" />
          <ScopedNotification variant="info">
            {t.common.readOnlyAccessNotification()}
          </ScopedNotification>
        </>
      )}
      {isRejected && (
        <Box mt="small" mb="small">
          <ScopedNotification>
            <>
              {t.common.rejectScopedNotification.description()}
              <Box display="inline" ml="xx-small" />
              <DocumentationLink>
                {t.common.rejectScopedNotification.link()}
              </DocumentationLink>
            </>
          </ScopedNotification>
        </Box>
      )}
      <Modal
        heading={t.whatsapp.channels.deleteChannelModal.header()}
        footer={[
          <Button onClick={handleCancel} key="cancel">
            {t.common.actions.cancel()}
          </Button>,
          <Button
            id="delete-submit-button"
            variant="brand"
            type="submit"
            onClick={handleDelete}
            loading={deleteLoading}
            key="submit"
          >
            {t.common.actions.delete()}
          </Button>,
        ]}
        isOpen={showDeleteModal}
        ariaHideApp={false}
        dismissOnClickOutside={false}
        onRequestClose={handleCancel}
      >
        <Box pl="medium" pr="medium" pb="medium" pt="medium">
          {`${t.whatsapp.channels.deleteChannelModal.description()}`}
          <DocumentationLink>
            {`${t.whatsapp.channels.deleteChannelModal.documentPage()}`}
          </DocumentationLink>
        </Box>
      </Modal>
    </>
  );
};
