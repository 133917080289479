import React, { useMemo } from 'react';
import { IComboboxOption } from '@salesforce/design-system-react';

import {
  DatepickerField,
  GridItem,
  InputField,
  IQueryHandlerResult,
  MultiSelectField,
  QueryHandler,
  SingleSelectField,
  Text,
} from '@src/common/components';
import { t } from '@src/messages';
import {
  ASP_CLIENTS_QUERY,
  IAspClientsQueryResponse,
} from '@src/modules/viberBusiness/aspClients/graphql/queries';
import { IViberAspClient } from '@shared/bff';

import {
  ICountryOption,
  prepareOptionsForCountrySelector,
} from '../../../../utils';
import { senderFieldsMaxLength } from '../../../utils';

interface IChannelConfigurationProps {
  isEditing?: boolean;
}

const mapOnboardedToCombobox = (
  onboarded: IViberAspClient[]
): IComboboxOption[] => {
  return onboarded.map((account): IComboboxOption => {
    return { label: account.name, value: account.id };
  });
};
export const ChannelConfiguration = ({
  isEditing,
}: IChannelConfigurationProps) => {
  const countryOptions = useMemo<ICountryOption[]>(
    prepareOptionsForCountrySelector,
    []
  );

  return (
    <>
      <GridItem cols={8} pl="small" pr="small">
        <GridItem mb="small">
          <QueryHandler query={ASP_CLIENTS_QUERY} levitate>
            {({
              data: {
                viberAspClients: { onboarded },
              },
            }: IQueryHandlerResult<IAspClientsQueryResponse>) => (
              <SingleSelectField
                id={'account-select-input'}
                name="aspClientId"
                labels={{
                  label: t.viberBusiness.channels.fields.account(),
                  placeholderReadOnly:
                    t.viberBusiness.channels.request.placeholders.account(),
                }}
                tooltip={t.viberBusiness.channels.request.tooltips.account()}
                options={mapOnboardedToCombobox(onboarded)}
                variant="readonly"
                required
                singleInputDisabled={isEditing}
              />
            )}
          </QueryHandler>
        </GridItem>
        <GridItem mb="small">
          <InputField
            id={'channel-name-input'}
            name="name"
            label={t.viberBusiness.channels.fields.channelName()}
            tooltip={t.viberBusiness.channels.request.tooltips.channelName()}
            maxLength={`${senderFieldsMaxLength.name}`}
            required
          />
        </GridItem>
        <GridItem mb="small">
          <MultiSelectField
            id={'countries-select-input'}
            name="destinationCountries"
            labels={{
              label: t.viberBusiness.channels.fields.destinationCountries(),
              placeholder:
                t.viberBusiness.channels.request.placeholders.destinationCountries(),
            }}
            tooltip={t.viberBusiness.channels.request.tooltips.destinationCountries()}
            options={countryOptions}
            variant="inline-listbox"
            required
            maxNumberOfSelected={senderFieldsMaxLength.destinationCountries}
          />
          <Text variant="body_small" color="weak" mt="xxx-small">
            {t.viberBusiness.channels.request.helpTexts.destinationCountries()}
          </Text>
        </GridItem>
        <GridItem cols={6}>
          <DatepickerField
            id={'data-picker-input'}
            name="businessLaunchDate"
            labels={{
              label: t.viberBusiness.channels.fields.businessLaunchDate(),
              placeholder:
                t.viberBusiness.channels.request.placeholders.businessLaunchDate(),
            }}
            required
          />
        </GridItem>
      </GridItem>
    </>
  );
};
