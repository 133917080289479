import { AspClientState } from '@shared/bff';
import { t } from '@src/messages';

export const prepareAspClientProgressIndicatorSteps = () => [
  {
    id: AspClientState.NOT_REGISTERED,
    label: t.whatsapp.aspClients.status.DRAFT(),
  },
  {
    id: AspClientState.REQUESTED,
    label: t.whatsapp.aspClients.status.REQUESTED(),
  },
  {
    id: AspClientState.PENDING_FB_VERIFICATION,
    label: t.whatsapp.aspClients.status.PENDING_FB_VERIFICATION(),
  },
  {
    id: `${AspClientState.ONBOARDED}--last`,
    label: t.whatsapp.aspClients.status.ONBOARDED(),
  },
];
