import React, { useContext, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { ButtonGroup } from '@salesforce/design-system-react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { omit } from 'lodash-es';
import { GraphQLError } from 'graphql';

import { t } from '@src/messages';
import {
  channelCrBotCompareBotPath,
  channelListPath,
  TEST_INVALID_SUBSCRIBER_NUMBER,
} from '@src/common/utils';
import {
  Box,
  PageHeader,
  ToastContext,
  Button,
  RouteLeavingGuard,
  ChannelLimitsContext,
  ScopedNotification,
  UserContext,
  FormModal,
  ErrorModal,
  Text,
} from '@src/common/components';
import {
  Channel,
  ErrorCode,
  errorCodes,
  senderErrorCodesExplainer,
} from '@src/common/types';
import {
  IWhatsAppBotInput,
  BotState,
  WhatsAppVertical,
  NumberProviderType,
  IMutationWhatsAppCreateCrArgs,
  IMutationWhatsAppTransitionBotCrFromDraftToReviewArgs,
} from '@shared/bff';

import { BotForm } from '../components';
import { getDraftValidationSchema, getRequestValidationSchema } from '../utils';
import {
  ICreateCrMutationResponse,
  ITransitionBotCrFromDraftToReviewMutationResponse,
  CREATE_CR_MUTATION,
  TRANSITION_BOT_CR_FROM_DRAFT_TO_REVIEW_MUTATION,
} from '../graphql';

const initialValues: IWhatsAppBotInput = {
  name: '',
  aspClientId: '',
  description: '',
  countryCode: '',
  phone: '',
  photoUrl: '',
  state: BotState.NEW,
  vertical: WhatsAppVertical.AUTOMOTIVE,
  address: '',
  email: '',
  websiteOne: '',
  numberProviderType: NumberProviderType.SINCH,
};

export const RequestBotView = () => {
  const { toast } = useContext(ToastContext);
  const { whatsAppChannels, updateChannelLimits } =
    useContext(ChannelLimitsContext);
  const { user } = useContext(UserContext);

  const channelsLimitReached =
    whatsAppChannels.consumed >= whatsAppChannels.purchased &&
    whatsAppChannels.purchased > 0;
  const noPurchasedChannels = whatsAppChannels.purchased === 0;
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const history = useHistory();
  const [shouldSubmitRequest, setShouldSubmitRequest] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorExplanation, setErrorExplanation] = useState('');
  const [errorHeading, setErrorHeading] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [createChangeRequest] = useMutation<
    ICreateCrMutationResponse,
    IMutationWhatsAppCreateCrArgs
  >(CREATE_CR_MUTATION);

  const [transitionBotCrFromDraftToReviewRequest] = useMutation<
    ITransitionBotCrFromDraftToReviewMutationResponse,
    IMutationWhatsAppTransitionBotCrFromDraftToReviewArgs
  >(TRANSITION_BOT_CR_FROM_DRAFT_TO_REVIEW_MUTATION);

  const handleError = (e: { graphQLErrors?: readonly GraphQLError[] }) => {
    const errorCode: ErrorCode = e?.graphQLErrors?.[0]?.extensions?.code;
    const explanation = senderErrorCodesExplainer[errorCode];
    const isKnownError = errorCodes.includes(errorCode);
    setErrorHeading(
      shouldSubmitRequest
        ? t.whatsapp.channels.errorModal.heading()
        : t.whatsapp.channels.errorModal.saveErrorHeading()
    );

    if (isKnownError) {
      setErrorExplanation(explanation());
      setShowErrorModal(true);
      return;
    }

    toast('error', {
      heading: t.common.toasts.somethingWentWrong(),
    });
  };

  const handleCancelRequest = () =>
    history.push(channelListPath(Channel.Whatsapp));

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const handleErrorModalClose = () => setShowErrorModal(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getRequestValidationSchema()}
      onSubmit={async (
        newBotValues: IWhatsAppBotInput,
        actions: FormikHelpers<IWhatsAppBotInput>
      ) => {
        try {
          const { data: createChangeData, errors: createChangeErrors } =
            await createChangeRequest({
              variables: {
                whatsAppCr: {
                  bot: omit(newBotValues, ['id']),
                  botId: null,
                },
              },
            });

          if (createChangeErrors) {
            actions.setSubmitting(false);
            return handleError({ graphQLErrors: createChangeErrors });
          }

          const { errors: transitionBotCrErrors } =
            await transitionBotCrFromDraftToReviewRequest({
              variables: {
                crId: createChangeData!.whatsAppCreateCr.id,
              },
            });

          if (transitionBotCrErrors) {
            return handleError({ graphQLErrors: transitionBotCrErrors });
          }

          actions.resetForm();
          history.push(
            channelCrBotCompareBotPath(
              Channel.Whatsapp,
              createChangeData!.whatsAppCreateCr.id
            )
          );
          toast('success', {
            heading: t.common.toasts.submitRequestSuccess(),
          });
          updateChannelLimits();
        } catch (e) {
          actions.setSubmitting(false);
          handleError(e);
        }
      }}
    >
      {({
        values,
        dirty,
        resetForm,
        submitForm,
        validateForm,
        isSubmitting,
        setSubmitting,
      }) => {
        const shouldBlockExternalNavigation = () => dirty;

        const handleSubmit = () => {
          handleModalClose();
          submitForm();
        };

        const handleSave = async () => {
          handleModalClose();
          setSubmitting(true);

          try {
            const { data, errors } = await createChangeRequest({
              variables: {
                whatsAppCr: {
                  bot: omit(values, ['id']),
                  botId: null,
                },
              },
            });

            if (errors) {
              setSubmitting(false);
              return handleError({ graphQLErrors: errors });
            }

            resetForm();
            history.push(
              channelCrBotCompareBotPath(
                Channel.Whatsapp,
                data!.whatsAppCreateCr.id
              )
            );
            toast('success', {
              heading: t.common.toasts.saveDraftSuccess(),
            });
          } catch (e) {
            handleError(e);
            setSubmitting(false);
          }
        };

        const handlePreSaveDraft = () => {
          setShouldSubmitRequest(false);
          const draftSchema = getDraftValidationSchema();
          draftSchema
            .validate(values)
            .then(() => {
              handleModalOpen();
            })
            .catch((e) => {
              setSubmitting(false);
              if (e.type === TEST_INVALID_SUBSCRIBER_NUMBER) {
                setErrorExplanation(
                  t.whatsapp.channels.draftValidationModal.validPhoneNumber()
                );
              } else if (e.path === 'email') {
                setErrorExplanation(
                  t.whatsapp.channels.draftValidationModal.validEmail()
                );
              } else if (e.path === 'websiteOne' || e.path === 'websiteTwo') {
                setErrorExplanation(
                  t.whatsapp.channels.draftValidationModal.validWebsite()
                );
              } else {
                setErrorExplanation(
                  t.whatsapp.channels.draftValidationModal.description()
                );
              }
              setErrorHeading(
                t.whatsapp.channels.draftValidationModal.header()
              );
              setShowErrorModal(true);
            });
        };

        const handlePreSubmit = async () => {
          setShouldSubmitRequest(true);

          const errorObj = await validateForm();
          const hasErrors = Object.entries(errorObj).length > 0;

          if (hasErrors) {
            submitForm();

            return;
          }

          handleModalOpen();
        };

        return (
          <>
            <Form>
              <PageHeader
                channelType={Channel.Whatsapp}
                sectionType="channel"
                title={t.common.actions.newChannel()}
                breadcrumbs={[
                  {
                    header: t.common.channels(),
                    to: channelListPath(Channel.Whatsapp),
                  },
                ]}
                actions={
                  <ButtonGroup variant="list">
                    <Button
                      id="cancel-button"
                      key="cancel"
                      label={t.common.actions.cancel()}
                      onClick={handleCancelRequest}
                    />
                    <Button
                      id="save-draft-button"
                      key="saveDraft"
                      label={t.common.actions.saveDraft()}
                      onClick={handlePreSaveDraft}
                      loading={isSubmitting && !shouldSubmitRequest}
                      disabled={
                        !dirty ||
                        isSubmitting ||
                        readOnlyAccess ||
                        noPurchasedChannels ||
                        channelsLimitReached
                      }
                    />
                    <Button
                      id="submit-request-button"
                      key="submitRequest"
                      label={t.common.actions.submitRequest()}
                      loading={isSubmitting && shouldSubmitRequest}
                      onClick={handlePreSubmit}
                      disabled={
                        !dirty ||
                        isSubmitting ||
                        readOnlyAccess ||
                        channelsLimitReached ||
                        noPurchasedChannels
                      }
                    />
                  </ButtonGroup>
                }
              />
              {readOnlyAccess && (
                <>
                  <Box mt="small" />
                  <ScopedNotification variant="info">
                    {t.common.readOnlyAccessNotification()}
                  </ScopedNotification>
                </>
              )}
              {channelsLimitReached && (
                <>
                  <Box mt="small" />
                  <ScopedNotification variant="warning">
                    {t.common.channelLimitNotification()}
                  </ScopedNotification>
                </>
              )}
              {noPurchasedChannels && (
                <>
                  <Box mt="small" />
                  <ScopedNotification variant="info">
                    {t.common.noPurchasedAppsNotification()}
                  </ScopedNotification>
                </>
              )}
              <Box mt="small" />
              <BotForm values={values} />
              <FormModal
                heading={
                  shouldSubmitRequest
                    ? t.whatsapp.channels.confirmationModal.header()
                    : t.whatsapp.channels.saveDraftModal.header()
                }
                onCancel={handleModalClose}
                onSubmit={shouldSubmitRequest ? handleSubmit : handleSave}
                isOpen={showModal}
                submitLabel={
                  shouldSubmitRequest
                    ? t.common.actions.submit()
                    : t.common.actions.save()
                }
                disableSubmit={isSubmitting}
              >
                <Box pl="medium" pr="medium" pt="large" pb="large">
                  <Text>
                    {shouldSubmitRequest
                      ? t.whatsapp.channels.confirmationModal.description()
                      : t.whatsapp.channels.saveDraftModal.description()}
                  </Text>
                </Box>
              </FormModal>
              <ErrorModal
                heading={errorHeading}
                onClose={handleErrorModalClose}
                isOpen={showErrorModal}
                label={t.common.actions.close()}
              >
                <Box pl="medium" pr="medium" pt="large" pb="large">
                  <Text id="whatsapp-channel-error-modal-message">
                    {errorExplanation}
                  </Text>
                </Box>
              </ErrorModal>
            </Form>
            <RouteLeavingGuard
              whenBlockInternalNavigation={dirty}
              navigate={(path) => {
                history.push(path);
              }}
              shouldBlockExternalNavigation={shouldBlockExternalNavigation}
            />
          </>
        );
      }}
    </Formik>
  );
};
