import React from 'react';
import { Combobox, Input, InputIcon } from '@salesforce/design-system-react';
import styled from 'styled-components';

import { Grid, GridItem } from '@src/common/components';
import { t } from '@src/messages';

import { CallToActionButton } from '../../../types';

interface ICallToActionFieldSectionProps {
  button: CallToActionButton;
  isRtlLanguage?: boolean;
}

const InputFieldWrapper = styled.div({
  '& .slds-input__icon': {
    height: '0.7rem',
    width: '0.7rem',
    fill: '#080707',
    marginTop: '-0.4rem',
  },
});

export const CallToActionFieldSection = ({
  button,
  isRtlLanguage,
}: ICallToActionFieldSectionProps) => {
  const { type, buttonText } = button;

  return (
    <>
      <Grid>
        <GridItem cols={6} pr="small">
          <Combobox
            labels={{
              label: t.whatsapp.messageTemplates.fields.typeOfAction(),
            }}
            options={[]}
            required
            singleInputDisabled
            variant="readonly"
            selection={[
              {
                label:
                  t.whatsapp.messageTemplates.templateCallToActionTypeLabels[
                    type
                  ](),
                value: type,
                id: type,
              },
            ]}
          />
        </GridItem>
      </Grid>
      <Grid>
        <GridItem cols={3} pr="small">
          <Input
            label={t.whatsapp.messageTemplates.fields.buttonText()}
            required
            disabled
            value={buttonText}
            className={isRtlLanguage ? 'rtl' : ''}
          />
        </GridItem>
        {'phoneNumber' in button ? (
          <GridItem cols={9}>
            <InputFieldWrapper>
              <Input
                label={t.whatsapp.messageTemplates.fields.phoneNumber()}
                placeholder={t.whatsapp.messageTemplates.placeholders.phoneNumber()}
                required
                disabled
                value={button.phoneNumber}
                iconLeft={<InputIcon name="add" category="utility" />}
              />
            </InputFieldWrapper>
          </GridItem>
        ) : (
          <>
            <GridItem cols={3} pr="small">
              <Combobox
                labels={{
                  label: t.whatsapp.messageTemplates.fields.urlType(),
                }}
                options={[]}
                required
                variant="readonly"
                singleInputDisabled
                selection={[
                  {
                    label:
                      t.whatsapp.messageTemplates.templateUrlTypeLabels[
                        button.urlType
                      ](),
                    value: button.urlType,
                    id: button.urlType,
                  },
                ]}
              />
            </GridItem>
            <GridItem cols={6}>
              <Input
                label={t.whatsapp.messageTemplates.fields.websiteUrl()}
                required
                disabled
                value={button.websiteUrl}
              />
            </GridItem>
          </>
        )}
      </Grid>
    </>
  );
};
