import { gql } from '@apollo/client';

import { IViberAspClient } from '@shared/bff';

export interface IRegisterAspClientMutationResponse {
  viberRegisterAspClient: IViberAspClient;
}

export const REGISTER_ASP_CLIENT_MUTATION = gql`
  mutation ViberRegisterAspClient($aspClient: ViberAspClientInput!) {
    viberRegisterAspClient(aspClient: $aspClient) {
      id
    }
  }
`;
