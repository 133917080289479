import { gql } from '@apollo/client';

import { IEmailNotificationsSettingsUpdateResponse } from '@shared/bff';

export interface IEmailNotificationsSettingsOptInResponse {
  optIn: IEmailNotificationsSettingsUpdateResponse;
}

export interface IEmailNotificationsSettingsOptOutResponse {
  optOut: IEmailNotificationsSettingsUpdateResponse;
}

export const EMAIL_NOTIFICATIONS_SETTINGS_OPT_IN_MUTATION = gql`
  mutation EmailNotificationsSettingsOptIn($email: String!) {
    optIn(email: $email) {
      success
    }
  }
`;

export const EMAIL_NOTIFICATIONS_SETTINGS_OPT_OUT_MUTATION = gql`
  mutation EmailNotificationsSettingsOptOut {
    optOut {
      success
    }
  }
`;
