import React from 'react';

import {
  Box,
  Card,
  ComparisonValues,
  DescriptionList,
  IDescriptionListItem,
} from '@src/common/components';
import { t } from '@src/messages';
import { IViberSender } from '@shared/bff';

interface IContactInformationSectionProps {
  values: Pick<
    IViberSender,
    'description' | 'address' | 'phoneNumber' | 'website'
  >;
  crValues?: Pick<
    IViberSender,
    'description' | 'address' | 'phoneNumber' | 'website'
  >;
  showCurrent: boolean;
}

export const ContactInformationSection = ({
  values,
  crValues,
  showCurrent,
}: IContactInformationSectionProps) => {
  const items: IDescriptionListItem[] = [
    {
      label: t.viberBusiness.channels.fields.description(),
      detail: (
        <ComparisonValues
          id="description"
          value={values.description}
          comparisonValue={crValues?.description}
          showDiff={showCurrent && values.description !== crValues?.description}
        />
      ),
    },
    {
      id: 'address',
      label: t.viberBusiness.channels.fields.address(),
      detail: (
        <ComparisonValues
          id="address"
          value={values.address}
          comparisonValue={crValues?.address}
          showDiff={showCurrent && values.address !== crValues?.address}
        />
      ),
    },
    {
      label: t.viberBusiness.channels.fields.phoneNumber(),
      detail: (
        <ComparisonValues
          id="phone-number"
          value={values.phoneNumber}
          comparisonValue={crValues?.phoneNumber}
          showDiff={showCurrent && values.phoneNumber !== crValues?.phoneNumber}
        />
      ),
    },
    {
      label: t.viberBusiness.channels.fields.website(),
      detail: (
        <ComparisonValues
          id="website"
          value={values.website}
          comparisonValue={crValues?.website}
          showDiff={showCurrent && values.website !== crValues?.website}
        />
      ),
    },
  ];
  return (
    <Card heading={t.common.contactInformation()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
