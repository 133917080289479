import { gql } from '@apollo/client';

import { IWhatsAppBot } from '@shared/bff';

export type IListCrBotDataFragment = Pick<
  IWhatsAppBot,
  | 'name'
  | 'description'
  | 'about'
  | 'aspClientId'
  | 'state'
  | 'websiteOne'
  | 'websiteTwo'
  | 'address'
  | 'countryCode'
  | 'email'
  | 'photoUrl'
  | 'phone'
  | 'vertical'
  | 'numberProviderType'
>;

export const LIST_CR_BOT_DATA_FRAGMENT = gql`
  fragment ListCrBotDataFragment on WhatsAppBot {
    name
    description
    about
    aspClientId
    state
    websiteOne
    websiteTwo
    address
    countryCode
    email
    photoUrl
    phone
    vertical
    numberProviderType
  }
`;
