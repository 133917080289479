import React from 'react';

import {
  AspClientsList,
  IQueryHandlerResult,
  QueryHandler,
} from '@src/common/components';
import { Channel } from '@src/common/types';

import {
  ASP_CLIENTS_QUERY,
  IAspClientsQueryResponse,
} from '../graphql/queries';

export const AspClientsView = () => {
  return (
    <QueryHandler query={ASP_CLIENTS_QUERY} levitate>
      {({
        data: {
          viberAspClients: { pending, onboarded },
        },
      }: IQueryHandlerResult<IAspClientsQueryResponse>) => (
        <AspClientsList
          channelType={Channel.ViberBusiness}
          pendingAspClients={pending}
          onboardedAspClients={onboarded}
        />
      )}
    </QueryHandler>
  );
};
