import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { omit } from 'lodash-es';
import { ButtonGroup } from '@salesforce/design-system-react';
import { useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';

import {
  Box,
  Button,
  ErrorModal,
  PageHeader,
  RouteLeavingGuard,
  ToastContext,
  Text,
  ChannelLimitsContext,
  QueryHandler,
  IQueryHandlerResult,
  UserContext,
  ScopedNotification,
} from '@src/common/components';
import {
  Channel,
  EditMode,
  ErrorCode,
  errorCodes,
  IBotCrIdMatchParams,
  IBotIdMatchParams,
  senderErrorCodesExplainer,
} from '@src/common/types';
import { t } from '@src/messages';
import {
  channelChangeRequestBotDetailsPath,
  channelChangeRequestDetailsPath,
  channelListPath,
  TEST_FACEBOOK_INSTAGRAM_DOMAIN,
  TEST_HTTP_HTTPS,
  TEST_INVALID_PHONE_NUMBER,
} from '@src/common/utils';
import {
  IMutationViberCreateCrArgs,
  IMutationViberTransitionSenderCrFromRejectedToDraftArgs,
  IMutationViberUpdateCrArgs,
  IMutationViberTransitionSenderCrFromDraftToReviewArgs,
  CrState,
  IViberSenderInput,
} from '@shared/bff';

import { ChannelFormModal, SenderForm } from '../components';
import {
  getDraftValidationSchema,
  getRequestValidationSchema,
  mapChannelToFormValues,
  mapSenderValues,
} from '../utils';
import {
  ITransitionSenderCrFromDraftToReviewMutationResponse,
  IViberTransitionSenderCrFromRejectedToDraftMutationResponse,
  IUpdateCrMutationResponse,
  TRANSITION_SENDER_CR_FROM_DRAFT_TO_REVIEW_MUTATION,
  TRANSITION_SENDER_CR_FROM_REJECTED_TO_DRAFT_MUTATION,
  UPDATE_CR_MUTATION,
  ICreateCrMutationResponse,
  CREATE_CR_MUTATION,
  CR_QUERY,
  SENDER_QUERY,
  ICrQueryResponse,
  ISenderQueryResponse,
} from '../graphql';

interface IEditViewProps {
  mode: EditMode;
}

type IEditParams = IBotCrIdMatchParams | IBotIdMatchParams;
type IEditQueryResponse = ICrQueryResponse | ISenderQueryResponse;

export const EditView = ({ mode }: IEditViewProps) => {
  const params = useParams<IEditParams>();
  const paramsId = 'botCrId' in params ? params.botCrId : params.botId;
  const query = 'botCrId' in params ? CR_QUERY : SENDER_QUERY;
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;

  const mapPageTitle: Record<EditMode, string> = {
    ['edit-draft']: t.common.actions.editDraft(),
    ['edit-rejected']: t.common.actions.editRejected(),
    ['edit-bot']: t.common.actions.editChannelDetails(),
  };
  const history = useHistory();
  const { toast } = useContext(ToastContext);
  const [shouldSubmitRequest, setShouldSubmitRequest] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorExplanation, setErrorExplanation] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorHeading, setErrorHeading] = useState('');
  const [hasApprovedConditions, setHasApprovedConditions] = useState(false);

  const { updateChannelLimits, viberChannels } =
    useContext(ChannelLimitsContext);
  const { consumed, purchased } = viberChannels;
  const channelsLimitReached = consumed >= purchased;

  const [createCrMutation] = useMutation<
    ICreateCrMutationResponse,
    IMutationViberCreateCrArgs
  >(CREATE_CR_MUTATION);
  const [viberTransitionSenderCrFromRejectedToDraft] = useMutation<
    IViberTransitionSenderCrFromRejectedToDraftMutationResponse,
    IMutationViberTransitionSenderCrFromRejectedToDraftArgs
  >(TRANSITION_SENDER_CR_FROM_REJECTED_TO_DRAFT_MUTATION);
  const [updateCrMutation] = useMutation<
    IUpdateCrMutationResponse,
    IMutationViberUpdateCrArgs
  >(UPDATE_CR_MUTATION);
  const [transitionToReviewMutation] = useMutation<
    ITransitionSenderCrFromDraftToReviewMutationResponse,
    IMutationViberTransitionSenderCrFromDraftToReviewArgs
  >(TRANSITION_SENDER_CR_FROM_DRAFT_TO_REVIEW_MUTATION);

  const handleCancelRequest = () => history.goBack();

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const handleErrorModalClose = () => setShowErrorModal(false);
  const handleError = (e: { graphQLErrors?: readonly GraphQLError[] }) => {
    const errorCode: ErrorCode = e?.graphQLErrors?.[0]?.extensions?.code;
    const explanation = senderErrorCodesExplainer[errorCode];
    const isKnownError = errorCodes.includes(errorCode);
    setErrorHeading(
      shouldSubmitRequest
        ? t.viberBusiness.channels.request.errorModal.heading()
        : t.viberBusiness.channels.request.errorModal.saveErrorHeading()
    );

    if (isKnownError) {
      setErrorExplanation(explanation());
      setShowErrorModal(true);

      return;
    }

    toast('error', {
      heading: t.common.toasts.somethingWentWrong(),
    });
  };

  return (
    <QueryHandler
      query={query}
      variables={{
        ...('botCrId' in params && { senderCrId: paramsId }),
        ...('botId' in params && { senderId: paramsId }),
      }}
      levitate
    >
      {({ data: existingData }: IQueryHandlerResult<IEditQueryResponse>) => {
        const sender =
          'viberCr' in existingData
            ? existingData.viberCr.sender
            : existingData.viberSender;
        const isRejected =
          'viberCr' in existingData &&
          existingData.viberCr.state === CrState.REJECTED;

        const isOnboarded =
          ('viberCr' in existingData && existingData.viberCr.senderId !== '') ||
          ('id' in sender && sender.id !== '');

        const navigateToDetails = (crId: string) => {
          if ('viberSender' in existingData || existingData.viberCr.senderId) {
            history.push(
              channelChangeRequestBotDetailsPath(Channel.ViberBusiness, crId)
            );
          } else {
            history.push(
              channelChangeRequestDetailsPath(Channel.ViberBusiness, crId)
            );
          }
        };

        const save = async (
          values: IViberSenderInput,
          setSubmitting: (submit: boolean) => void
        ) => {
          const mappedSenderValues = mapSenderValues(values);
          let crId: string;

          if ('viberSender' in existingData) {
            const { data, errors } = await createCrMutation({
              variables: {
                viberCr: {
                  sender: omit(mappedSenderValues, ['id']),
                  senderId: paramsId,
                },
              },
            });

            if (errors) {
              setSubmitting(false);
              return handleError({ graphQLErrors: errors });
            }

            crId = data!.viberCreateCr.id;
          } else {
            if (isRejected) {
              await viberTransitionSenderCrFromRejectedToDraft({
                variables: {
                  crId: paramsId,
                },
              });
            }

            const { data } = await updateCrMutation({
              variables: {
                viberCr: {
                  id: paramsId,
                  sender: mappedSenderValues,
                  senderId: existingData.viberCr.senderId,
                },
              },
            });
            crId = data!.viberUpdateCr.id;
          }
          return crId;
        };

        return (
          <Formik
            initialValues={mapChannelToFormValues(sender)}
            validationSchema={getRequestValidationSchema()}
            onSubmit={async (values, actions) => {
              try {
                const crId = (await save(
                  values,
                  actions.setSubmitting
                )) as string;

                const { errors } = await transitionToReviewMutation({
                  variables: {
                    crId,
                  },
                });

                if (errors) {
                  actions.setSubmitting(false);
                  return handleError({ graphQLErrors: errors });
                }

                actions.resetForm();
                navigateToDetails(crId);
                toast('success', {
                  heading: t.common.toasts.submitRequestSuccess(),
                });

                updateChannelLimits();
              } catch (e) {
                actions.setSubmitting(false);
                handleError(e);
              }
            }}
          >
            {({
              values,
              dirty,
              isSubmitting,
              setSubmitting,
              validateForm,
              submitForm,
              resetForm,
            }) => {
              const shouldBlockExternalNavigation = () => dirty;

              const handlePreSaveDraft = () => {
                setShouldSubmitRequest(false);
                const draftSchema = getDraftValidationSchema();
                draftSchema
                  .validate(values)
                  .then(() => {
                    handleSaveDraft();
                  })
                  .catch((e) => {
                    setSubmitting(false);
                    if (e.type === TEST_INVALID_PHONE_NUMBER) {
                      setErrorExplanation(
                        t.viberBusiness.channels.request.errorModal.invalidPhoneNumber()
                      );
                    } else if (e.type === TEST_HTTP_HTTPS) {
                      setErrorExplanation(
                        t.viberBusiness.channels.request.errorModal.invalidWebsite()
                      );
                    } else if (e.type === TEST_FACEBOOK_INSTAGRAM_DOMAIN) {
                      setErrorExplanation(
                        t.viberBusiness.channels.request.errorModal.forbiddenFacebookInstagramDomain()
                      );
                    } else {
                      setErrorExplanation(
                        t.viberBusiness.channels.request.errorModal.requiredForNewDraft()
                      );
                    }
                    setErrorHeading(
                      t.viberBusiness.channels.request.errorModal.requiredHeading()
                    );
                    setShowErrorModal(true);
                  });
              };

              const handlePreSubmit = async () => {
                setShouldSubmitRequest(true);

                const errorObj = await validateForm();
                const hasErrors = Object.entries(errorObj).length > 0;

                if (hasErrors) {
                  submitForm();

                  return;
                }

                if (
                  mode === 'edit-draft' &&
                  'viberCr' in existingData &&
                  !existingData.viberCr.senderId
                ) {
                  handleModalOpen();
                } else {
                  submitForm();
                }
              };

              const handleSubmit = () => {
                handleModalClose();
                submitForm();
              };

              const handleSaveDraft = async () => {
                handleModalClose();
                setSubmitting(true);
                try {
                  const crId = (await save(values, setSubmitting)) as string;
                  resetForm();
                  navigateToDetails(crId);
                  toast('success', {
                    heading: t.common.toasts.saveDraftSuccess(),
                  });
                  updateChannelLimits();
                } catch (e) {
                  setSubmitting(false);
                  handleError(e);
                }
              };

              return (
                <>
                  <Form>
                    <PageHeader
                      channelType={Channel.ViberBusiness}
                      sectionType="channel"
                      title={mapPageTitle[mode]}
                      breadcrumbs={[
                        {
                          header: t.common.channels(),
                          to: channelListPath(Channel.ViberBusiness),
                        },
                      ]}
                      actions={
                        <ButtonGroup variant="list">
                          <Button
                            id="cancel-button"
                            key="cancel"
                            label={t.common.actions.cancel()}
                            onClick={handleCancelRequest}
                          />
                          <Button
                            id="save-draft-button"
                            key="saveDraft"
                            label={t.common.actions.saveDraft()}
                            loading={isSubmitting && !shouldSubmitRequest}
                            onClick={handlePreSaveDraft}
                            disabled={!dirty || isSubmitting || readOnlyAccess}
                          />
                          <Button
                            id="submit-request-button"
                            key="submitRequest"
                            label={t.common.actions.submitRequest()}
                            loading={isSubmitting && shouldSubmitRequest}
                            onClick={handlePreSubmit}
                            disabled={
                              (!dirty && mode !== 'edit-draft') ||
                              isSubmitting ||
                              readOnlyAccess ||
                              (channelsLimitReached && !isOnboarded)
                            }
                          />
                        </ButtonGroup>
                      }
                    />
                    {readOnlyAccess && (
                      <>
                        <Box mt="small" />
                        <ScopedNotification variant="info">
                          {t.common.readOnlyAccessNotification()}
                        </ScopedNotification>
                      </>
                    )}
                    {channelsLimitReached && (
                      <>
                        <Box mt="small" />
                        <ScopedNotification variant="warning">
                          {t.common.channelLimitNotification()}
                        </ScopedNotification>
                      </>
                    )}
                    <Box mt="small" />
                    <SenderForm isEditing />
                    <ChannelFormModal
                      handleCancel={handleModalClose}
                      handleOnSubmit={handleSubmit}
                      isOpen={showModal}
                      shouldSubmitRequest={shouldSubmitRequest}
                      hasApprovedConditions={hasApprovedConditions}
                      setHasApprovedConditions={setHasApprovedConditions}
                      isSubmitting={isSubmitting}
                    />
                    <ErrorModal
                      heading={errorHeading}
                      onClose={handleErrorModalClose}
                      isOpen={showErrorModal}
                      label={t.common.actions.close()}
                    >
                      <Box pl="medium" pr="medium" pt="large" pb="large">
                        <Text>{errorExplanation}</Text>
                      </Box>
                    </ErrorModal>
                  </Form>
                  <RouteLeavingGuard
                    whenBlockInternalNavigation={dirty}
                    navigate={(path) => {
                      history.push(path);
                    }}
                    shouldBlockExternalNavigation={
                      shouldBlockExternalNavigation
                    }
                  />
                </>
              );
            }}
          </Formik>
        );
      }}
    </QueryHandler>
  );
};
