import { gql } from '@apollo/client';
import type { Except } from 'type-fest';

import { IViberAspClient } from '@shared/bff';

export type ViberAspClient = Except<IViberAspClient, 'updated' | 'created'>;

export interface IAspClientQueryResponse {
  viberAspClient: ViberAspClient;
}

export const ASP_CLIENT_QUERY = gql`
  query ViberAspClient($aspClientId: String!) {
    viberAspClient(aspClientId: $aspClientId) {
      id
      name
      businessLegalName
      businessCategories
      businessRegisteredCountry
      warrantyLetterUrl
      state
    }
  }
`;
