/* eslint-disable security/detect-unsafe-regex */
import * as Yup from 'yup';

import { t } from '@src/messages';
import { RegexReplacements } from '@src/common/components';

const { mixed } = t.common.validation;

export const templateNameRegex = /^[a-z0-9_]+$/;
export const templateNameRegexConstraint = /[^a-z0-9_ ]/g;
export const templateNameRegexReplace: RegexReplacements = [
  { searchValue: /[ ]/g, replacer: () => '_' },
];

export const templateHeaderRegex = /\u002A/g;
export const templateHeaderRegexReplace: RegexReplacements = [
  { searchValue: /\{\{-?\d*\}\}/g, replacer: () => '{{1}}' },
  {
    searchValue: /(.*\{\{1\}\}.*){2,}/g,
    replacer: (substr) =>
      substr.replace(/\{\{1\}\}/g, (match, i, text) =>
        text.indexOf(match) === i ? match : ''
      ),
  },
];

export const templateButtonRegexConstraint = /\u002A|_|~|`{3,}|\{\{\d*\}\}/g;

export const templateFooterRegexConstraint = /\{\{\d*\}\}/g;

export const templateMessageBodyRegexConstraint = /\t/g;
export const templateMessageBodyRegexReplace: RegexReplacements = [
  { searchValue: /\n\n\n+/g, replacer: () => '\n\n' },
];

export const getTemplateNameSchema = () =>
  Yup.string().matches(templateNameRegex, {
    message: mixed.default(),
  });
