import React, { useContext, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import {
  Box,
  Grid,
  GridItem,
  ImagePreview,
  ImageResolver,
  Label,
  LoadingIndicator,
  MultiLineErrorMessage,
  Text,
  ToastContext,
} from '@src/common/components';
import { t } from '@src/messages';
import {
  IUploadCroppedImagesMutationResponse,
  UPLOAD_CROPPED_IMAGES_MUTATION,
} from '@src/common/graphql';
import { ICroppedImage, IMutationUploadCroppedImagesArgs } from '@shared/bff';

import { LOGO_URLS_SIZES } from '../../../types';
import { LogoDisplayer } from '../../LogoDisplayer';
import { getLogoToPreview } from '../../../utils';

const BoxWrapper = styled(Box)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const ChannelDesign = () => {
  const { setFieldValue } = useFormikContext();
  const { toast } = useContext(ToastContext);
  const [field, meta] = useField('logoUrls');
  const { error, touched } = meta;
  const { value } = field;

  const [uploadCroppedImagesMutation] = useMutation<
    IUploadCroppedImagesMutationResponse,
    IMutationUploadCroppedImagesArgs
  >(UPLOAD_CROPPED_IMAGES_MUTATION);

  const [uploading, setUploading] = useState<boolean>(false);
  const [filename, setFileName] = useState(value);

  const errorList = error ? (Array.isArray(error) ? error : [error]) : [];
  const previewImage = getLogoToPreview(value, 100);

  const handleFileDropped = (droppedFilename: string) => {
    setFileName(droppedFilename);
  };

  const handleFileDialogCancel = () => {
    setFileName('');
  };

  const getFileName = (width: number, height: number) => {
    return filename.replace(/(\.[\w\d_-]+)$/i, `_${width}x${height}$1`);
  };

  return (
    <>
      <Box pl="small" pr="small">
        <Label
          id="brand-logo-image-tooltip"
          tooltip={t.viberBusiness.channels.request.tooltips.brandLogo()}
          required
        >
          <Text color="weak">
            {t.viberBusiness.channels.fields.brandLogo()}
          </Text>
        </Label>
        <Box mb="xx-small" />
        <Text color="weak">
          {t.viberBusiness.channels.request.helpTexts.brandLogo()}
        </Text>
        <Box mb="small" />
        <Grid>
          <GridItem cols={8}>
            <BoxWrapper display="flex" alignItems="center">
              <MultiLineErrorMessage
                errors={error}
                touched={touched}
                render={() => {
                  return (
                    <ImagePreview
                      src={previewImage ?? ''}
                      width="100px"
                      height="100px"
                      err={touched && errorList.length > 0}
                    />
                  );
                }}
              />
              <Box display="inline" ml="medium" />
              <ImageResolver
                title={t.viberBusiness.channels.fields.uploadLogoImage()}
                description={t.common.components.imageResolver.resolverDescription.bot()}
                dragDropOptions={{
                  maxSize: 5000000, //5MB
                }}
                cropOptions={{
                  aspect: 9 / 7,
                }}
                options={{
                  outputWidth: 360,
                  outputHeight: 280,
                }}
                onFileUpload={async ({ name, content, mimeType }) => {
                  setUploading(true);
                  try {
                    const { data } = await uploadCroppedImagesMutation({
                      variables: {
                        file: { name, content, mimeType },
                        sizes: LOGO_URLS_SIZES,
                      },
                    });
                    setFieldValue(
                      'logoUrls',
                      data!.uploadCroppedImages.images.map(
                        ({ url, width, height }: ICroppedImage) => ({
                          url,
                          name: getFileName(width, height),
                          size: { width, height },
                        })
                      )
                    );
                  } catch (err) {
                    console.log(error);
                    toast('error', {
                      heading: t.common.toasts.somethingWentWrong(),
                    });
                  }
                  setUploading(false);
                }}
                onFileDropped={handleFileDropped}
                onFileDialogCancel={handleFileDialogCancel}
              />
            </BoxWrapper>
          </GridItem>
        </Grid>
        <Box mb="small" />
        <LogoDisplayer logoUrls={value} />
      </Box>
      <LoadingIndicator
        open={uploading}
        title={t.whatsapp.botForm.upload.loadingDescription()}
      />
    </>
  );
};
