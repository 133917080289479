import { gql } from '@apollo/client';

import { IAspClientsQueryResponse } from '@src/modules/viberBusiness/aspClients/graphql/queries';
import { IViberCr, IViberSender } from '@shared/bff';

import {
  CR_SENDER_DATA_FRAGMENT,
  SENDER_LIST_ITEM_CR_FRAGMENT,
} from '../../fragments';

export interface ISenderCrsQueryResponse {
  viberCrs: ISenderCrListItemFragment[];
}

export type ISenderCrListItemFragment = Pick<
  IViberCr,
  'id' | 'state' | 'senderId' | 'updated'
> & {
  sender: Pick<IViberSender, 'name'>;
};

export interface ISendersQueryResponse {
  viberSenders: ISenderListItemFragment[];
}

export type ISenderListItemFragment = Pick<
  IViberSender,
  'id' | 'name' | 'state'
>;

export type IChannelsQueryResponse = ISendersQueryResponse &
  ISenderCrsQueryResponse &
  IAspClientsQueryResponse;

export const CHANNELS_QUERY = gql`
  query ChannelsQuery($viberSendersSort: BotSort, $viberCrsSort: BotCrSort) {
    viberSenders(filter: null, sort: $viberSendersSort) {
      id
      name
      state
      updated
      cr {
        ...SenderListItemCrFragment
      }
    }
    viberCrs(filter: null, sort: $viberCrsSort) {
      id
      senderId
      state
      updated
      sender {
        ...CrSenderDataFragment
      }
    }
    viberAspClients {
      onboarded {
        id
      }
    }
  }
  ${CR_SENDER_DATA_FRAGMENT}
  ${SENDER_LIST_ITEM_CR_FRAGMENT}
`;
