import React, { useContext } from 'react';

import {
  Box,
  Card,
  ComparisonValues,
  DescriptionList,
  IDescriptionListItem,
  LabelTag,
  UserContext,
} from '@src/common/components';
import { t } from '@src/messages';
import { formatDateTime, mapSenderStateToType } from '@src/common/utils';
import { IViberSender } from '@shared/bff';

import { getCountryIsoCodeLangFn } from '../../../utils';

interface IChannelConfigurationSectionProps {
  values: Pick<
    IViberSender,
    'name' | 'destinationCountries' | 'businessLaunchDate' | 'state'
  >;
  crValues?: Pick<
    IViberSender,
    'name' | 'destinationCountries' | 'businessLaunchDate'
  >;
  showCurrent: boolean;
}

export const ChannelConfigurationSection = ({
  values,
  crValues,
  showCurrent,
}: IChannelConfigurationSectionProps) => {
  const { user } = useContext(UserContext);
  const countries = values.destinationCountries
    .map((country) => getCountryIsoCodeLangFn(country)())
    .join(', ');
  const crCountries = crValues?.destinationCountries
    .map((country) => getCountryIsoCodeLangFn(country)())
    .join(', ');

  const launchDate = values.businessLaunchDate
    ? formatDateTime(
        values.businessLaunchDate,
        user.zoneInfo,
        user.locale,
        'LL'
      )
    : '';
  const crLaunchDate = crValues?.businessLaunchDate
    ? formatDateTime(
        crValues.businessLaunchDate,
        user.zoneInfo,
        user.locale,
        'LL'
      )
    : '';

  const items: IDescriptionListItem[] = [
    {
      label: t.viberBusiness.channels.fields.channelName(),
      detail: (
        <ComparisonValues
          id="channel-name"
          value={values.name}
          comparisonValue={crValues?.name}
          showDiff={showCurrent && values.name !== crValues?.name}
        />
      ),
    },
    {
      label: t.viberBusiness.channels.fields.destinationCountries(),
      detail: (
        <ComparisonValues
          id="destination-countries"
          value={countries}
          comparisonValue={crCountries}
          showDiff={showCurrent && countries !== crCountries}
        />
      ),
    },
    {
      label: t.viberBusiness.channels.fields.businessLaunchDate(),
      detail: (
        <ComparisonValues
          id="business-launch-date"
          value={launchDate}
          comparisonValue={crLaunchDate}
          showDiff={
            showCurrent &&
            values.businessLaunchDate !== crValues?.businessLaunchDate
          }
        />
      ),
    },
    {
      id: 'channel-status',
      label: t.viberBusiness.channels.fields.channelStatus(),
      detail: (
        <LabelTag type={mapSenderStateToType(values.state)}>
          {t.viberBusiness.channels.status[values.state]()}
        </LabelTag>
      ),
    },
  ];
  return (
    <Card heading={t.common.channelConfiguration()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
