import { gql } from '@apollo/client';

import { IWhatsAppAspClient, IWhatsAppMtQueryResponse } from '@shared/bff';

export interface ITemplateListQueryResponse {
  whatsAppAspClientsList: IWhatsAppAspClient[];
  whatsAppMessageTemplates: IWhatsAppMtQueryResponse;
}

export const TEMPLATE_LIST_QUERY = gql`
  query TemplateList {
    whatsAppAspClientsList(withActiveChannels: true, state: [ONBOARDED]) {
      id
      name
    }
    whatsAppMessageTemplates {
      pending {
        aspClientId
        name
        category
        contents {
          language
          crStatus
        }
      }
      approved {
        aspClientId
        name
        category
        contents {
          ... on WhatsAppMtContent {
            language
            templateStatus
          }
          ... on WhatsAppMtCrContent {
            language
            crStatus
          }
        }
      }
    }
  }
`;
