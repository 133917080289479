import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { t } from 'messages';

import {
  Box,
  DescriptionList,
  IDescriptionListItem,
  Text,
  FormModal,
  Radio,
  ToastContext,
} from 'common/components';
import {
  IMutationWhatsAppRegisterBotArgs,
  WhatsAppRegistrationMethod,
} from '@shared/bff';

import {
  REGISTER_BOT_MUTATION,
  IRegisterBotMutationResponse,
  IWhatsAppBotFragment,
} from '../../graphql';

interface IRegisterBotForm {
  show: boolean;
  bot: IWhatsAppBotFragment;
  onCancel: () => void;
  onSubmitComplete: () => void;
  onSubmitFailed: (errorCode: string) => void;
}

const RadioWrapper = styled(Radio)({ display: 'inline-block !important' });
const FIELD_REGISTRATION_METHOD = 'registrationMethod';

export const RegisterBotForm = ({
  show,
  bot,
  onCancel,
  onSubmitComplete,
  onSubmitFailed,
}: IRegisterBotForm) => {
  const [registerBot] = useMutation<
    IRegisterBotMutationResponse,
    IMutationWhatsAppRegisterBotArgs
  >(REGISTER_BOT_MUTATION);
  const { toast } = useContext(ToastContext);

  return (
    <Formik
      initialValues={{
        [FIELD_REGISTRATION_METHOD]: WhatsAppRegistrationMethod.SMS,
      }}
      onSubmit={async (values) => {
        try {
          const { errors } = await registerBot({
            variables: {
              botId: bot.id,
              registrationMethod: values[FIELD_REGISTRATION_METHOD],
            },
          });

          if (errors) {
            const errorCode = errors?.[0]?.extensions?.code;

            if (errorCode === 'WHATSAPP_REGISTRATION_ALREADY_REGISTERED') {
              // This is an error expected to be very rare where multiple users
              // try to verify a WA bot. To solve this we simply refresh the page
              // so whichever user is last will see the new status which should
              // be that the WA bot is registered.
              window.location.reload();
              return;
            }

            if (
              typeof errorCode === 'string' &&
              errorCode.startsWith('WHATSAPP_REGISTRATION')
            ) {
              onSubmitFailed(errorCode);
            } else {
              toast('error', {
                heading: t.common.toasts.somethingWentWrong(),
              });
            }

            return;
          }

          onSubmitComplete();
        } catch (e) {
          toast('error', {
            heading: t.common.toasts.somethingWentWrong(),
          });
        }
      }}
    >
      {({ isSubmitting, submitForm, values, setFieldValue }) => {
        const handleSubmitClick = async () => {
          submitForm();
        };

        const handleRegistrationMethodChange =
          (method: WhatsAppRegistrationMethod) => () => {
            setFieldValue(FIELD_REGISTRATION_METHOD, method);
          };

        const modalDescriptionItems: IDescriptionListItem[] = [
          {
            label: t.whatsapp.verifyBotModal.phoneNumber(),
            detail: `${bot.countryCode}${bot.phone}`,
          },
          {
            label: t.whatsapp.verifyBotModal.verificationMethod(),
            detail: (
              <>
                <RadioWrapper
                  key={WhatsAppRegistrationMethod.SMS}
                  value={WhatsAppRegistrationMethod.SMS}
                  checked={
                    values[FIELD_REGISTRATION_METHOD] ===
                    WhatsAppRegistrationMethod.SMS
                  }
                  labels={{ label: t.whatsapp.verifyBotModal.sms() }}
                  onChange={handleRegistrationMethodChange(
                    WhatsAppRegistrationMethod.SMS
                  )}
                />
                <RadioWrapper
                  key={WhatsAppRegistrationMethod.VOICE}
                  value={WhatsAppRegistrationMethod.VOICE}
                  checked={
                    values[FIELD_REGISTRATION_METHOD] ===
                    WhatsAppRegistrationMethod.VOICE
                  }
                  labels={{ label: t.whatsapp.verifyBotModal.voice() }}
                  onChange={handleRegistrationMethodChange(
                    WhatsAppRegistrationMethod.VOICE
                  )}
                />
              </>
            ),
          },
        ];
        return (
          <Form>
            <FormModal
              heading={t.whatsapp.verifyBotModal.header()}
              onCancel={onCancel}
              onSubmit={handleSubmitClick}
              isOpen={show}
              submitting={isSubmitting}
              submitLabel={t.whatsapp.verifyBotModal.sendCode()}
              disableSubmit={isSubmitting}
            >
              <Box pl="small" pr="small" pt="small" pb="small">
                <Text>{t.whatsapp.verifyBotModal.title()}</Text>
                <Box mt="small" mb="small" />
                <DescriptionList items={modalDescriptionItems} />
              </Box>
            </FormModal>
          </Form>
        );
      }}
    </Formik>
  );
};
