import { pick } from 'lodash-es';

import { IWhatsAppBotInput } from '@shared/bff';

import { ICrBotDataFragment } from '../graphql';

const editableBotFields: (keyof IWhatsAppBotInput)[] = [
  'about',
  'aspClientId',
  'address',
  'countryCode',
  'description',
  'email',
  'id',
  'photoUrl',
  'name',
  'phone',
  'state',
  'vertical',
  'websiteOne',
  'websiteTwo',
  'numberProviderType',
];

/**
 * Fields allowed when creating a change request
 */
const createChangeRequestFields: (keyof IWhatsAppBotInput)[] =
  editableBotFields.filter((prop) => prop !== 'id');

export const prepareCreateCrDataForMutation = (
  bot: ICrBotDataFragment
): IWhatsAppBotInput => {
  return { ...(pick(bot, createChangeRequestFields) as IWhatsAppBotInput) };
};
