import React from 'react';
import { t } from 'messages';

import {
  InputField,
  SingleSelectField,
  TextareaField,
  Box,
  Grid,
  GridItem,
  Text,
} from '@src/common/components';
import { useFeatureFlags } from '@src/common/utils/hooks';
import {
  WhatsAppMtHeaderType,
  WhatsAppMtButtonType,
  IWhatsAppMtCrContentInput,
} from '@shared/bff';

import { FieldButtons } from '../FieldButtons';
import {
  BUTTONS_TYPE_FIELD_NAME,
  HEADER_FIELD_NAME,
  contentFieldsMaxLength,
} from '../../../utils';

export const DisabledMessageContent = () => {
  const { sfPilot } = useFeatureFlags();

  const defaultHeaderValue = {
    value: WhatsAppMtHeaderType.NONE,
    label: t.whatsapp.messageTemplates.templateHeaderLabels.NONE(),
  };
  const defaultButtonsValue = {
    value: WhatsAppMtButtonType.NONE,
    label: t.whatsapp.messageTemplates.templateHeaderLabels.NONE(),
  };
  return (
    <Box pl="medium" pr="medium" mt="large">
      <Text variant="heading_small" weight="bold" mt="large">
        {t.whatsapp.messageTemplates.register.sections.messageContent()}
      </Text>
      <Grid flow="vertical">
        <GridItem cols={9} mt="medium" mb="small">
          <SingleSelectField<IWhatsAppMtCrContentInput>
            name={HEADER_FIELD_NAME}
            labels={{
              label: `${t.whatsapp.messageTemplates.fields.header()} ${t.common.optional()}`,
            }}
            options={[]}
            selection={[defaultHeaderValue]}
            singleInputDisabled
            variant="readonly"
            id="header-input"
          />
        </GridItem>
      </Grid>
      <TextareaField<IWhatsAppMtCrContentInput>
        name="bodyText"
        label={t.whatsapp.messageTemplates.fields.messageBody()}
        maxLength={`${contentFieldsMaxLength.bodyText}`}
        required
        disabled
        id="message-body-input"
        rightActions={
          <FieldButtons
            tooltip={t.whatsapp.messageTemplates.tooltips.messageBody()}
            disabled
            onAddVariable={() => {
              // no action since disabled
            }}
          />
        }
      />
      <Box mt="small" />
      <InputField<IWhatsAppMtCrContentInput>
        name="footer"
        label={`${t.whatsapp.messageTemplates.fields.footer()} ${t.common.optional()}`}
        maxLength={`${contentFieldsMaxLength.footer}`}
        disabled
        id="footer-input"
      />
      {sfPilot ? null : (
        <Grid flow="vertical">
          <GridItem cols={9} mt="small">
            <SingleSelectField<IWhatsAppMtCrContentInput>
              name={BUTTONS_TYPE_FIELD_NAME}
              labels={{
                label: `${t.whatsapp.messageTemplates.fields.buttons()} ${t.common.optional()}`,
              }}
              options={[]}
              selection={[defaultButtonsValue]}
              singleInputDisabled
              variant="readonly"
              id="buttons-input"
            />
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};
