import { gql } from '@apollo/client';

import { IWhatsAppBotResponse } from '@shared/bff';

export type IBotActionMutationResponse = Pick<
  IWhatsAppBotResponse,
  'botId' | 'registrationResponse'
>;

export const BOT_ACTION_MUTATION = gql`
  mutation WhatsAppBotAction($action: WhatsAppBotAction!, $botId: String!) {
    whatsAppBotAction(action: $action, botId: $botId) {
      botId
      registrationResponse
    }
  }
`;
