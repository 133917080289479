import { WhatsAppMtLanguage } from '@shared/bff';

export const getSelectedLanguage = (
  allLanguages: WhatsAppMtLanguage[],
  removedLanguage: WhatsAppMtLanguage,
  selectedTabIndex: number
): WhatsAppMtLanguage | '' => {
  const removedLanguageIndex = allLanguages.indexOf(removedLanguage);
  const languagesLeft = allLanguages.filter(
    (language) => language !== removedLanguage
  );
  const noLanguagesLeft = languagesLeft.length === 0;
  const selectedLanguageIndex = languagesLeft.indexOf(
    allLanguages[selectedTabIndex]
  );

  if (noLanguagesLeft) {
    return '';
  } else if (removedLanguageIndex === selectedTabIndex) {
    return languagesLeft[languagesLeft.length - 1];
  } else {
    return languagesLeft[selectedLanguageIndex];
  }
};
