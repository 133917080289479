import { gql } from '@apollo/client';

export interface IWhatsAppMtDeleteResponse {
  success: boolean;
}

export const DELETE_TEMPLATE_MUTATION = gql`
  mutation WhatsAppDeleteMessageTemplate(
    $name: String!
    $aspClientId: String!
  ) {
    whatsAppDeleteMessageTemplate(name: $name, aspClientId: $aspClientId) {
      success
    }
  }
`;
