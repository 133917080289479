import React, { useMemo } from 'react';
import { IStep } from '@salesforce/design-system-react';
import { t } from 'messages';

import { ChannelProgress } from '@src/common/components';
import { CrState } from '@shared/bff';

interface IViberBusinessChannelProgressProps {
  state: CrState;
}

export const ViberBusinessChannelProgress = ({
  state,
}: IViberBusinessChannelProgressProps) => {
  const stepLabels = t.whatsapp.changeRequestProgressLabels;
  const steps = useMemo<IStep[]>(
    () => [
      {
        id: CrState.DRAFT,
        label: stepLabels.DRAFT(),
      },
      {
        id: CrState.REVIEW_QUEUE,
        label: stepLabels.REVIEW_QUEUE(),
      },
      {
        id: CrState.REVIEW,
        label: stepLabels.REVIEW(),
      },
      {
        id: CrState.APPROVED,
        label: stepLabels.APPROVED(),
      },
      {
        id: CrState.IN_PROGRESS,
        label: stepLabels.IN_PROGRESS(),
      },
      {
        id: `${CrState.DONE}--last`,
        label: stepLabels.DONE(),
      },
    ],
    []
  );
  return <ChannelProgress state={state} steps={steps} />;
};
