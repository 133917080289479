import React from 'react';
import styled from 'styled-components';

import {
  Box,
  CheckboxField,
  FormModal,
  Label,
  LabelTag,
  ScopedNotification,
  Text,
} from '@src/common/components';
import { t } from '@src/messages';

import { EditContentPayload, EditMode, TemplateValues } from '../types';

import { crLabelType } from './constants';

interface ConfirmModal {
  values: TemplateValues;
  mode: EditMode;
  shouldSubmitRequest: boolean;
  languagesToSubmit: string[];
  showModal: boolean;
  languageErrors: string[];
  handleModalClose: () => void;
  filterOnRejected?: (c: EditContentPayload) => boolean;
  filterOnDraft?: (c: EditContentPayload) => boolean;
  filterOnAdded?: (c: EditContentPayload) => boolean;
  handleModalSubmit: () => Promise<void>;
  handleSaveDraft: () => Promise<void>;
  setLanguagesToSubmit: React.Dispatch<React.SetStateAction<string[]>>;
  isSubmitting?: boolean;
}

const LabelTagWrapper = styled(LabelTag)({
  marginLeft: 8,
});
export const ConfirmModal = ({
  values,
  mode,
  shouldSubmitRequest,
  languagesToSubmit,
  showModal,
  languageErrors,
  handleModalClose,
  handleModalSubmit,
  handleSaveDraft,
  setLanguagesToSubmit,
  isSubmitting = false,
}: ConfirmModal) => {
  const contents = values.contents || values.authenticationContents;
  return (
    <FormModal
      heading={
        shouldSubmitRequest
          ? t.whatsapp.messageTemplates.register.confirmationModal.heading()
          : t.whatsapp.messageTemplates.register.saveDraftModal.heading()
      }
      disableSubmit={
        isSubmitting || (shouldSubmitRequest && languagesToSubmit.length === 0)
      }
      onCancel={handleModalClose}
      onSubmit={shouldSubmitRequest ? handleModalSubmit : handleSaveDraft}
      isOpen={showModal}
      submitLabel={
        shouldSubmitRequest
          ? t.common.actions.submit()
          : t.common.actions.save()
      }
    >
      <Box pl="medium" pr="medium" pt="large" pb="large">
        {shouldSubmitRequest ? (
          <>
            <Text>
              {t.whatsapp.messageTemplates.register.confirmationModal.selectLanguagesBody()}
            </Text>
            <Box pt="medium" pb="x-large">
              <Label
                id="submit-languages-confirmation-tooltip"
                tooltip={t.whatsapp.messageTemplates.tooltips.submitLanguages()}
                required
              >
                <Text>
                  {t.whatsapp.messageTemplates.register.confirmationModal.availableLanguages()}
                </Text>
              </Label>
              {contents &&
                (contents as EditContentPayload[]).map(
                  ({ language, crStatus }, index) => (
                    <Box key={index} pt="x-small" pb="x-small" display="flex">
                      <CheckboxField
                        disabled={languageErrors[index] !== undefined}
                        checked={languagesToSubmit.includes(language)}
                        onChange={(_, { checked }) =>
                          setLanguagesToSubmit(
                            checked
                              ? [...languagesToSubmit, language]
                              : languagesToSubmit.filter((l) => l !== language)
                          )
                        }
                        name="submitLanguage"
                        id={`submit-language-${index}-checkbox`}
                        labels={{
                          label:
                            t.whatsapp.messageTemplates.templateLanguageLabels[
                              language
                            ](),
                        }}
                      />
                      {crStatus && (
                        <LabelTagWrapper type={crLabelType[crStatus]}>
                          {t.common.changeRequestStateLabels[crStatus]()}
                        </LabelTagWrapper>
                      )}
                      {languageErrors[index] && (
                        <LabelTagWrapper type={'transparent'}>
                          {languageErrors[index]}
                        </LabelTagWrapper>
                      )}
                    </Box>
                  )
                )}
            </Box>
            <Text>
              {t.whatsapp.messageTemplates.register.confirmationModal.body()}
            </Text>
            <Box alignItems="center" mt="medium" display="flex">
              <ScopedNotification variant="info">
                {t.whatsapp.messageTemplates.register.confirmationModal.warning()}{' '}
                <a
                  href="https://developers.facebook.com/docs/whatsapp/overview/getting-opt-in/#helpful-tips"
                  target="_blank"
                >
                  {t.whatsapp.messageTemplates.register.confirmationModal.warningLink()}
                </a>
              </ScopedNotification>
            </Box>
          </>
        ) : (
          <Text>
            {mode === 'add'
              ? t.whatsapp.messageTemplates.register.saveDraftModal.addTemplateBody()
              : t.whatsapp.messageTemplates.register.saveDraftModal.editTemplateBody()}
          </Text>
        )}
      </Box>
    </FormModal>
  );
};
