import { t } from '@src/messages';

export type ErrorCode =
  | 'NAME_IS_ALREADY_IN_USE'
  | 'DUPLICATE_LANGUAGE_IS_NOT_ALLOWED';

export const errorCodes: ErrorCode[] = [
  'NAME_IS_ALREADY_IN_USE',
  'DUPLICATE_LANGUAGE_IS_NOT_ALLOWED',
];

export const templateErrorCodesExplainer: Record<ErrorCode, () => string> = {
  NAME_IS_ALREADY_IN_USE:
    t.whatsapp.messageTemplates.register.errorModal.nameIsAlreadyInUse,
  DUPLICATE_LANGUAGE_IS_NOT_ALLOWED:
    t.whatsapp.messageTemplates.register.errorModal
      .duplicateLanguageIsNotAllowed,
};
