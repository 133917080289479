import { union } from 'lodash-es';

import {
  WhatsAppMtHeaderType,
  WhatsAppMtButtonType,
  WhatsAppMtMediaType,
  WhatsAppMtLanguage,
  IWhatsAppAuthenticationMtCrContentInput,
  WhatsAppMtOtpType,
} from '@shared/bff';

import { ContentPayload } from '../../types';

const initialMessageContentValues = {
  headerType: WhatsAppMtHeaderType.NONE,
  buttonType: WhatsAppMtButtonType.NONE,
  mediaType: WhatsAppMtMediaType.NONE,
  bodyText: '',
  footer: '',
};

const initialAuthenticationMessageContentValues = {
  otpType: WhatsAppMtOtpType.COPY_CODE,
  buttonText: '',
};

export const addMessageContent = (
  language: WhatsAppMtLanguage,
  contents: ContentPayload[]
) => {
  const newMessageContent = { ...initialMessageContentValues, language };
  return union(contents, [newMessageContent]);
};

export const addAuthenticationMessageContent = (
  language: WhatsAppMtLanguage,
  authenticationContents: IWhatsAppAuthenticationMtCrContentInput[]
) => {
  const newAuthenticationMessageContent = {
    ...initialAuthenticationMessageContentValues,
    language,
  };
  return union(authenticationContents, [newAuthenticationMessageContent]);
};

export const removeMessageContent = (
  language: WhatsAppMtLanguage,
  contents: ContentPayload[]
) => contents.filter((c) => c.language !== language);

export const removeAuthenticationMessageContent = (
  language: WhatsAppMtLanguage,
  contents: IWhatsAppAuthenticationMtCrContentInput[]
) => contents.filter((c) => c.language !== language);
