import { useMutation } from '@apollo/client';

import {
  IMutationWhatsAppCreateMessageTemplateCrArgs,
  IMutationWhatsAppTransitionMessageTemplateCrFromDraftToReviewArgs,
  IMutationWhatsAppTransitionMessageTemplateCrFromRejectedToDraftArgs,
  IMutationWhatsAppUpdateMessageTemplateDraftCrArgs,
} from '@shared/bff';

import {
  CREATE_TEMPLATE_MUTATION,
  ICreateTemplateMutationResponse,
  ITransitionToDraftMutationResponse,
  ITransitionToReviewMutationResponse,
  IUpdateTemplateMutationResponse,
  TRANSITION_TO_DRAFT_MUTATION,
  TRANSITION_TO_REVIEW_MUTATION,
  UPDATE_TEMPLATE_MUTATION,
} from '../../graphql';

export const getCreateTemplateMutation = () => {
  const [createTemplateMutation] = useMutation<
    ICreateTemplateMutationResponse,
    IMutationWhatsAppCreateMessageTemplateCrArgs
  >(CREATE_TEMPLATE_MUTATION);
  return createTemplateMutation;
};
export const getUpdateTemplateMutation = () => {
  const [updateTemplateMutation] = useMutation<
    IUpdateTemplateMutationResponse,
    IMutationWhatsAppUpdateMessageTemplateDraftCrArgs
  >(UPDATE_TEMPLATE_MUTATION);
  return updateTemplateMutation;
};
export const getTransitionToReviewMutation = () => {
  const [transitionToReviewMutation] = useMutation<
    ITransitionToReviewMutationResponse,
    IMutationWhatsAppTransitionMessageTemplateCrFromDraftToReviewArgs
  >(TRANSITION_TO_REVIEW_MUTATION);
  return transitionToReviewMutation;
};
export const getTransitionToDraft = () => {
  const [transitionToDraft] = useMutation<
    ITransitionToDraftMutationResponse,
    IMutationWhatsAppTransitionMessageTemplateCrFromRejectedToDraftArgs
  >(TRANSITION_TO_DRAFT_MUTATION);
  return transitionToDraft;
};
