import { IWhatsAppAuthenticationMtCrContentInput } from '@shared/bff';

type authenticationMessageContentFieldsLengthKeys = keyof Pick<
  IWhatsAppAuthenticationMtCrContentInput,
  'codeExpirationMinutes' | 'buttonText' | 'autofillText'
>;

export const authenticationContentFieldsMinLength: Record<
  authenticationMessageContentFieldsLengthKeys,
  number
> = {
  codeExpirationMinutes: 1,
  buttonText: 0,
  autofillText: 0,
};

export const authenticationContentFieldsMaxLength: Record<
  authenticationMessageContentFieldsLengthKeys,
  number
> = {
  codeExpirationMinutes: 90,
  buttonText: 25,
  autofillText: 25,
};
