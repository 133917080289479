import { t } from 'messages';

import { WhatsAppMtButtonType } from '@shared/bff';

export interface ITemplateButtonOption {
  value: WhatsAppMtButtonType;
  label: string;
}

const TemplateButtonLabels = t.whatsapp.messageTemplates.templateButtonLabels;

export const prepareOptionsForTemplateButtonsType = () => [
  {
    value: WhatsAppMtButtonType.NONE,
    label: TemplateButtonLabels.NONE(),
  },
  {
    value: WhatsAppMtButtonType.QUICK_REPLY,
    label: TemplateButtonLabels.QUICK_REPLY(),
  },
  {
    value: WhatsAppMtButtonType.CALL_TO_ACTION,
    label: TemplateButtonLabels.CALL_TO_ACTION(),
  },
];
