import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@salesforce/design-system-react';
import { WhatsAppBusinessAPITemplate } from '@sinch/previewer';

import { t } from '@src/messages';
import { Card, TemplatePreviewer, Text } from '@src/common/components';

const CardWrapper = styled(Card)({
  overflowX: 'auto',
});

const TextWrapper = styled(Text)({
  margin: 0,
});

interface TemplatePreviewCardProps {
  template: WhatsAppBusinessAPITemplate;
  onToggleSamples: (showSamplePreview: boolean) => void;
  samplesChecked: boolean;
}

export const TemplatePreviewCard = ({
  template,
  onToggleSamples,
  samplesChecked,
}: TemplatePreviewCardProps) => {
  return (
    <CardWrapper
      heading={
        <TextWrapper variant="heading_small" weight="bold" mt="xx-small">
          {t.whatsapp.preview.header()}
        </TextWrapper>
      }
      headerActions={
        <Checkbox
          variant="toggle"
          labels={{
            label: t.whatsapp.preview.toggleDescription(),
            toggleDisabled: '',
            toggleEnabled: '',
          }}
          checked={samplesChecked}
          onChange={(_, data) => onToggleSamples(data.checked)}
        ></Checkbox>
      }
    >
      <TemplatePreviewer template={template} />
    </CardWrapper>
  );
};
