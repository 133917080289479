import { ButtonGroup } from '@salesforce/design-system-react';
import React from 'react';

import { Button, PageHeader } from '@src/common/components';
import { t } from '@src/messages';
import { Channel } from '@src/common/types';

interface TemplatePageHeaderCancel {
  onClick: () => void;
}

interface TemplatePageHeaderDraft {
  onClick: () => void;
  loading: boolean;
  disabled: boolean;
}

interface TemplatePageHeaderSubmit {
  onClick: () => void;
  loading: boolean;
  disabled: boolean;
}
interface TemplatePageHeader {
  title: string;
  breadcrumbDestination: string;
  cancel: TemplatePageHeaderCancel;
  draft: TemplatePageHeaderDraft;
  submit: TemplatePageHeaderSubmit;
}
export const TemplatePageHeader = ({
  title,
  breadcrumbDestination,
  cancel,
  draft,
  submit,
}: TemplatePageHeader) => {
  return (
    <PageHeader
      channelType={Channel.Whatsapp}
      sectionType="messageTemplate"
      title={title}
      id="template-edit-header"
      breadcrumbs={[
        {
          header: t.common.messageTemplates(),
          to: breadcrumbDestination,
        },
      ]}
      actions={
        <ButtonGroup variant="list">
          <Button
            id="cancel-template-button"
            label={t.common.actions.cancel()}
            onClick={cancel.onClick}
          />
          <Button
            label={t.common.actions.saveDraft()}
            onClick={draft.onClick}
            loading={draft.loading}
            disabled={draft.disabled}
          />
          <Button
            label={t.common.actions.submitRequest()}
            onClick={submit.onClick}
            loading={submit.loading}
            disabled={submit.disabled}
          />
        </ButtonGroup>
      }
    />
  );
};
