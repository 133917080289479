import { gql } from '@apollo/client';

import { IViberSender } from '@shared/bff';

export type ISenderActionMutationResponse = {
  viberSender: Pick<IViberSender, 'id'>;
};

export const SENDER_ACTION_MUTATION = gql`
  mutation ViberSenderAction($senderId: String!, $action: ViberSenderAction!) {
    viberSenderAction(senderId: $senderId, action: $action)
  }
`;
