import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { ButtonGroup } from '@salesforce/design-system-react';

import {
  Box,
  Button,
  ErrorModal,
  FormModal,
  PageHeader,
  RouteLeavingGuard,
  Text,
  ToastContext,
} from '@src/common/components';
import { Channel } from '@src/common/types';
import { t } from '@src/messages';
import { channelListPath, TEST_INVALID_PHONE_NUMBER } from '@src/common/utils';
import { IKakaoTalkInput } from '@shared/bff';

import { getDraftValidationSchema, getRequestValidationSchema } from '../utils';
import { SenderForm } from '../components';

const initialValues: IKakaoTalkInput = {
  profileId: '',
  channelName: '',
  phoneNumber: '',
  topLevelCategory: '',
  midLevelCategory: '',
  subLevelCategory: '',
};

export const RequestSenderView = () => {
  const history = useHistory();
  const { toast } = useContext(ToastContext);

  const [showModal, setShowModal] = useState(false);
  const [shouldSubmitRequest, setShouldSubmitRequest] = useState(true);
  const [errorExplanation, setErrorExplanation] = useState('');
  const [errorHeading, setErrorHeading] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleCancelRequest = () =>
    history.push(channelListPath(Channel.KakaoTalk));

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const handleErrorModalClose = () => setShowErrorModal(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getRequestValidationSchema()}
      onSubmit={async (_, actions) => {
        try {
          actions.setSubmitting(false);
          // Save channel

          // Transition channel to review

          actions.resetForm();
          // Navigate to details page
          toast('success', {
            heading: t.common.toasts.submitRequestSuccess(),
          });
        } catch (e) {
          actions.setSubmitting(false);
          // Handle error
        }
      }}
    >
      {({
        values,
        dirty,
        isSubmitting,
        setSubmitting,
        validateForm,
        submitForm,
        resetForm,
      }) => {
        const shouldBlockExternalNavigation = () => dirty;

        const handlePreSaveDraft = () => {
          setShouldSubmitRequest(false);
          const draftSchema = getDraftValidationSchema();
          draftSchema
            .validate(values)
            .then(() => {
              handleSave();
            })
            .catch((e) => {
              setSubmitting(false);
              if (e.type === TEST_INVALID_PHONE_NUMBER) {
                setErrorExplanation(
                  t.kakaoTalk.channels.request.errorModal.invalidPhoneNumber()
                );
              } else {
                setErrorExplanation(
                  t.kakaoTalk.channels.draftValidationModal.description()
                );
              }
              setErrorHeading(
                t.kakaoTalk.channels.request.errorModal.saveErrorHeading()
              );
              setShowErrorModal(true);
            });
        };

        const handleSave = async () => {
          handleModalClose();
          setSubmitting(true);

          try {
            // Save channel

            resetForm();
            // Navigate to details page
            toast('success', {
              heading: t.common.toasts.saveDraftSuccess(),
            });
          } catch (e) {
            // Handle error
            setSubmitting(false);
          }
        };

        const handlePreSubmit = async () => {
          setShouldSubmitRequest(true);

          const errorObj = await validateForm();
          const hasErrors = Object.entries(errorObj).length > 0;

          if (hasErrors) {
            submitForm();

            return;
          }

          handleModalOpen();
        };

        const handleSubmit = () => {
          handleModalClose();
          submitForm();
        };

        return (
          <>
            <Form>
              <PageHeader
                channelType={Channel.KakaoTalk}
                sectionType="channel"
                title={t.common.actions.newChannel()}
                breadcrumbs={[
                  {
                    header: t.common.channels(),
                    to: channelListPath(Channel.KakaoTalk),
                  },
                ]}
                actions={
                  <ButtonGroup variant="list">
                    <Button
                      id="cancel-button"
                      key="cancel"
                      label={t.common.actions.cancel()}
                      onClick={handleCancelRequest}
                      disabled={isSubmitting}
                    />
                    <Button
                      id="save-draft-button"
                      key="saveDraft"
                      label={t.common.actions.saveDraft()}
                      onClick={handlePreSaveDraft}
                      loading={isSubmitting && !shouldSubmitRequest}
                      disabled={!dirty || isSubmitting}
                    />
                    <Button
                      id="submit-request-button"
                      key="submitRequest"
                      label={t.common.actions.submitRequest()}
                      onClick={handlePreSubmit}
                      loading={isSubmitting && shouldSubmitRequest}
                      disabled={!dirty || isSubmitting}
                    />
                  </ButtonGroup>
                }
              />
              <Box mt="small" />
              <SenderForm />
              <FormModal
                heading={t.kakaoTalk.channels.confirmationModal.header()}
                onCancel={handleModalClose}
                onSubmit={handleSubmit}
                isOpen={showModal}
                submitLabel={t.common.actions.submit()}
                disableSubmit={isSubmitting}
              >
                <Box pl="medium" pr="medium" pt="large" pb="large">
                  <Text>
                    {t.kakaoTalk.channels.confirmationModal.description()}
                  </Text>
                </Box>
              </FormModal>
              <ErrorModal
                heading={errorHeading}
                onClose={handleErrorModalClose}
                isOpen={showErrorModal}
                label={t.common.actions.close()}
              >
                <Box pl="medium" pr="medium" pt="large" pb="large">
                  <Text>{errorExplanation}</Text>
                </Box>
              </ErrorModal>
            </Form>
            <RouteLeavingGuard
              whenBlockInternalNavigation={dirty}
              navigate={(path) => {
                history.push(path);
              }}
              shouldBlockExternalNavigation={shouldBlockExternalNavigation}
            />
          </>
        );
      }}
    </Formik>
  );
};
