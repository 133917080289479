import React, { useMemo } from 'react';
import styled from 'styled-components';

import { t } from '@src/messages';
import {
  Card,
  Box,
  Text,
  Image,
  IDescriptionListItem,
  DescriptionList,
  ComparisonValues,
} from '@src/common/components';
import { getFileName } from '@src/common/utils';

import { ISectionProps } from '../types';

interface IDesignSectionData {
  image: string;
}

export interface IDesignSectionProps extends ISectionProps {
  values: IDesignSectionData;
  comparisonValues?: IDesignSectionData;
}

const NoWrapTextWrapper = styled(Text)({
  whiteSpace: 'nowrap',
  overflow: 'initial',
});

export const DesignSection = ({
  values,
  comparisonValues,
  showDiff = false,
}: IDesignSectionProps) => {
  const items = useMemo<IDescriptionListItem[]>(
    () => [
      {
        label: <Text>{t.whatsapp.fieldLabels.brandLogo()}</Text>,
        detail: (
          <ComparisonValues
            value={''}
            comparisonValue={getFileName(comparisonValues?.image)}
            showDiff={showDiff && values.image !== comparisonValues?.image}
          />
        ),
      },
      {
        label: (
          <Box display="inline-flex">
            <Image src={values.image} width="100px" height="100px" />
            <Box display="inline" ml="medium" />
            <Box mt="small">
              <Text className="slds-form-element__label">
                {t.whatsapp.fieldLabels.imageName()}
              </Text>
              <NoWrapTextWrapper color="weak">
                {getFileName(values.image) || '-'}
              </NoWrapTextWrapper>
            </Box>
          </Box>
        ),
        detail: (
          <ComparisonValues
            value={''}
            comparisonValue={
              <Image
                src={comparisonValues?.image}
                width="100px"
                height="100px"
              />
            }
            showDiff={showDiff && values.image !== comparisonValues?.image}
          />
        ),
      },
    ],
    [showDiff]
  );

  return (
    <Card heading={t.common.channelDesign()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
