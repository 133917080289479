import { gql } from '@apollo/client';

import { IViberAspClientsResponse } from '@shared/bff';

import { IViberSenderFragment, VIBER_SENDER_FRAGMENT } from '../../fragments';

export interface ISenderQueryResponse {
  viberSender: IViberSenderFragment;
  viberAspClients: Pick<IViberAspClientsResponse, 'onboarded'>;
}

export const SENDER_QUERY = gql`
  query ViberSender($senderId: String!) {
    viberSender(senderId: $senderId) {
      ...ViberSenderFragment
    }
    viberAspClients {
      onboarded {
        id
        name
        businessLegalName
        businessCategories
        businessRegisteredCountry
      }
    }
  }
  ${VIBER_SENDER_FRAGMENT}
`;
