import React, { useContext } from 'react';

import {
  Box,
  Card,
  ComparisonValues,
  DescriptionList,
  IDescriptionListItem,
  LabelTag,
  UserContext,
} from '@src/common/components';
import { t } from '@src/messages';
import { mapCrStateToType, formatDateTime } from '@src/common/utils';
import { IViberSender, CrState } from '@shared/bff';

import { getCountryIsoCodeLangFn } from '../../../utils';

interface IChannelConfigurationSectionProps {
  crValues: Pick<
    IViberSender,
    'name' | 'destinationCountries' | 'businessLaunchDate'
  >;
  currentValues?: Pick<
    IViberSender,
    'name' | 'destinationCountries' | 'businessLaunchDate'
  >;
  crState: CrState;
  showCurrent: boolean;
}

export const ChannelConfigurationSection = ({
  crValues,
  currentValues,
  crState,
  showCurrent,
}: IChannelConfigurationSectionProps) => {
  const crCountries = crValues.destinationCountries
    .map((country) => getCountryIsoCodeLangFn(country)())
    .join(', ');
  const currentCountries = currentValues?.destinationCountries
    .map((country) => getCountryIsoCodeLangFn(country)())
    .join(', ');
  const { user } = useContext(UserContext);

  const crLaunchDate = crValues.businessLaunchDate
    ? formatDateTime(
        crValues.businessLaunchDate,
        user.zoneInfo,
        user.locale,
        'LL'
      )
    : '';
  const currentLaunchDate = currentValues?.businessLaunchDate
    ? formatDateTime(
        currentValues.businessLaunchDate,
        user.zoneInfo,
        user.locale,
        'LL'
      )
    : '';

  const items: IDescriptionListItem[] = [
    {
      label: t.viberBusiness.channels.fields.channelName(),
      detail: (
        <ComparisonValues
          id="channel-name"
          value={crValues.name}
          comparisonValue={currentValues?.name}
          showDiff={showCurrent && crValues.name !== currentValues?.name}
        />
      ),
    },
    {
      label: t.viberBusiness.channels.fields.destinationCountries(),
      detail: (
        <ComparisonValues
          id="destination-countries"
          value={crCountries}
          comparisonValue={currentCountries}
          showDiff={showCurrent && crCountries !== currentCountries}
        />
      ),
    },
    {
      label: t.viberBusiness.channels.fields.businessLaunchDate(),
      detail: (
        <ComparisonValues
          id="business-launch-date"
          value={crLaunchDate}
          comparisonValue={currentLaunchDate}
          showDiff={
            showCurrent &&
            crValues.businessLaunchDate !== currentValues?.businessLaunchDate
          }
        />
      ),
    },
    {
      id: 'channel-status',
      label: t.viberBusiness.channels.fields.channelStatus(),
      detail: (
        <LabelTag
          type={mapCrStateToType(crState)}
          showRaw={crState === CrState.BOT_CR_UNSPECIFIED_STATE}
        >
          {t.common.changeRequestStateLabels[crState]()}
        </LabelTag>
      ),
    },
  ];
  return (
    <Card heading={t.common.channelConfiguration()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
