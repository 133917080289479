import { IWhatsAppMtCrContentInput } from '@shared/bff';

type MessageContentButtonType = keyof Pick<
  IWhatsAppMtCrContentInput,
  'quickReplyButtons' | 'callToActionButtons'
>;

export const getMessageContentButtonPath = (
  selectedTabIndex: number,
  buttonType: MessageContentButtonType,
  index?: number
) => {
  const indexStr = index !== undefined ? `[${index}]` : '';
  return `contents[${selectedTabIndex}].${buttonType}${indexStr}`;
};
