import React from 'react';
import styled from 'styled-components';
import { PhoneFrame, ViberBusinessPreview } from '@sinch/viber-bot-preview';
import { useFormikContext } from 'formik';

import {
  Box,
  Card,
  Grid,
  GridItem,
  StickyGridItem,
} from '@src/common/components';
import { t } from '@src/messages';
import { IViberSenderInput, CrState } from '@shared/bff';
import { useFeatureFlags } from '@src/common/utils/hooks';

import { getLogoToPreview } from '../../utils';
import { ViberBusinessChannelProgress } from '../ViberBusinessChannelProgress';

import { ChannelConfiguration } from './ChannelConfiguration';
import { ContactInformation } from './ContactInformation';
import { ChannelDesign } from './ChannelDesign';
import { ExtraDocuments } from './ExtraDocuments';
import { SampleMessages } from './SampleMessages';

const CardWrapper = styled(Card)({
  overflowX: 'auto',
});

interface ISenderFormProps {
  isEditing?: boolean;
}

const ImageStyle = styled.div({
  img: {
    'max-width': 'fit-content',
  },
});

export const SenderForm = ({ isEditing }: ISenderFormProps) => {
  const { extraDocuments } = useFeatureFlags();
  const { values } = useFormikContext<IViberSenderInput>();

  return (
    <>
      <ViberBusinessChannelProgress state={CrState.DRAFT} />
      <Box mt="small" />
      <Grid>
        <GridItem cols={8} pr="small">
          <Grid flow="vertical">
            <GridItem mb="small">
              <Card heading={t.common.channelConfiguration()}>
                <ChannelConfiguration isEditing={isEditing} />
              </Card>
            </GridItem>
            <GridItem mb="small">
              <Card heading={t.common.channelDesign()}>
                <ChannelDesign />
              </Card>
            </GridItem>
            <GridItem mb="small">
              <Card heading={t.common.contactInformation()}>
                <ContactInformation />
              </Card>
            </GridItem>
            <GridItem mb="small">
              <Card heading={t.viberBusiness.channels.request.sampleMessages()}>
                <SampleMessages />
              </Card>
            </GridItem>
            {extraDocuments && (
              <GridItem mb="small">
                <Card
                  heading={t.viberBusiness.channels.request.extraDocuments()}
                >
                  <ExtraDocuments />
                </Card>
              </GridItem>
            )}
          </Grid>
        </GridItem>
        <StickyGridItem cols={4}>
          <CardWrapper
            heading={t.common.components.businessProfilePreviewer.heading()}
          >
            <PhoneFrame>
              <ImageStyle>
                <ViberBusinessPreview
                  input={{
                    name: values.name,
                    about: values.description,
                    address: values.address,
                    phoneNumber: `+${values.phoneNumber}`,
                    website: values.website,
                    photoUrl: getLogoToPreview(values!.logoUrls, 360),
                  }}
                />
              </ImageStyle>
            </PhoneFrame>
          </CardWrapper>
        </StickyGridItem>
      </Grid>
    </>
  );
};
