import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@salesforce/design-system-react';
import styled from 'styled-components';
import { BaseEmoji } from 'emoji-mart';

import { uuid } from '@src/common/utils';
import { Box, Button } from '@src/common/components';

import { EmojiPicker } from '../EmojiPicker';

interface FieldButtonsProps {
  tooltip: string;
  disabled?: boolean;
  onAddEmoji?: (emoji: BaseEmoji) => void;
  onAddMonospace?: () => void;
  onAddItalic?: () => void;
  onAddBold?: () => void;
  onAddStrikethrough?: () => void;
  onAddVariable: () => void;
}

const EmojiWrapper = styled(Box)({
  position: 'relative',
});

export const FieldButtons = ({
  tooltip,
  disabled = false,
  onAddEmoji,
  onAddMonospace,
  onAddItalic,
  onAddBold,
  onAddStrikethrough,
  onAddVariable,
}: FieldButtonsProps) => {
  const picker = useRef<HTMLDivElement>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleEmojiButtonClick = () => setShowEmojiPicker(!showEmojiPicker);

  const onClickOutside = () => setShowEmojiPicker(false);

  const handleClick = (e: MouseEvent) => {
    if (onAddEmoji) {
      if (picker.current && picker.current.contains(e.target as Node)) {
        return;
      }

      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      pt="xx-small"
    >
      {onAddEmoji && (
        <div ref={picker}>
          <EmojiWrapper mr="x-small">
            {showEmojiPicker && <EmojiPicker onAddEmoji={onAddEmoji} />}
            <Button
              iconCategory="utility"
              iconName="emoji"
              iconSize="small"
              iconVariant="border"
              variant="icon"
              onClick={handleEmojiButtonClick}
              disabled={disabled}
            />
          </EmojiWrapper>
        </div>
      )}
      {onAddMonospace && (
        <Box mr="x-small">
          <Button
            iconCategory="utility"
            iconName="insert_tag_field"
            iconSize="small"
            iconVariant="border"
            variant="icon"
            onClick={onAddMonospace}
            disabled={disabled}
          />
        </Box>
      )}
      {onAddItalic && (
        <Box mr="x-small">
          <Button
            iconCategory="utility"
            iconName="italic"
            iconSize="small"
            iconVariant="border"
            variant="icon"
            onClick={onAddItalic}
            disabled={disabled}
          />
        </Box>
      )}
      {onAddBold && (
        <Box mr="x-small">
          <Button
            iconCategory="utility"
            iconName="bold"
            iconSize="small"
            iconVariant="border"
            variant="icon"
            onClick={onAddBold}
            disabled={disabled}
          />
        </Box>
      )}
      {onAddStrikethrough && (
        <Box mr="x-small">
          <Button
            iconCategory="utility"
            iconName="strikethrough"
            iconSize="small"
            iconVariant="border"
            variant="icon"
            onClick={onAddStrikethrough}
            disabled={disabled}
          />
        </Box>
      )}
      <Box mr="x-small">
        <Button
          iconCategory="utility"
          iconName="variable"
          iconSize="small"
          iconVariant="border"
          variant="icon"
          onClick={onAddVariable}
          disabled={disabled}
        />
      </Box>
      <Tooltip
        id={`${uuid()}-level-help-tooltip`}
        content={tooltip}
        variant="learnMore"
        align="top right"
        position="overflowBoundaryElement"
      />
    </Box>
  );
};
