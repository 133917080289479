import React, { useMemo, ReactNode, useRef, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { BaseEmoji } from 'emoji-mart';
import { t } from 'messages';

import {
  InputField,
  SingleSelectField,
  TextareaField,
  Box,
  Grid,
  GridItem,
  Text,
} from '@src/common/components';
import { useFeatureFlags } from '@src/common/utils/hooks';
import { languageDirection } from '@src/common/utils';
import {
  WhatsAppMtMediaType,
  WhatsAppMtButtonType,
  WhatsAppMtHeaderType,
} from '@shared/bff';

import {
  TemplateValues,
  CallToActionType,
  ContentPayload,
} from '../../../types';
import {
  prepareOptionsForTemplateHeader,
  ITemplateHeaderOption,
  ITemplateButtonOption,
  prepareOptionsForTemplateButtonsType,
  contentFieldsMaxLength,
  HEADER_FIELD_NAME,
  BUTTONS_TYPE_FIELD_NAME,
  templateHeaderRegex,
  templateFooterRegexConstraint,
  templateHeaderRegexReplace,
  templateMessageBodyRegexConstraint,
  BODY_TEXT_FIELD_NAME,
  HEADER_TEXT_FIELD_NAME,
  templateMessageBodyRegexReplace,
} from '../../../utils';
import { MediaTypeSelector } from '../MediaTypeSelector';
import { QuickReplyButtons } from '../QuickReplyButtons';
import { CallToActionButtons } from '../CallToActionButtons';
import { FieldButtons } from '../FieldButtons';

import { createVariable, correctTextWithVariables } from './variable-utils';

interface IMessageContentProps {
  selectedTabIndex: number;
  content: ContentPayload;
}

const markupMapper: Record<string, string> = {
  MONOSPACE: '```',
  ITALIC: '_',
  BOLD: '*',
  STRIKETHROUGH: '~',
};

export const MessageContent = ({
  selectedTabIndex,
  content,
}: IMessageContentProps) => {
  const { sfPilot, whatsAppSamples } = useFeatureFlags();

  const { setFieldValue, setFieldTouched, touched } =
    useFormikContext<TemplateValues>();
  const bodyTextRef = useRef<HTMLTextAreaElement>(null);
  const [selection, setSelection] = useState({ start: 0, end: 0 });
  const [shouldSetRange, setShouldSetRange] = useState(false);
  useEffect(() => {
    if (!shouldSetRange) return;
    const { start, end } = selection;
    bodyTextRef.current!.focus();
    bodyTextRef.current!.setSelectionRange(start, end);
  }, [shouldSetRange]);

  const initaliseQuickReplyButtons = () => {
    setFieldValue(`contents[${selectedTabIndex}].quickReplyButtons`, ['']);
  };

  const resetQuickReplyButtons = () => {
    setFieldValue(`contents[${selectedTabIndex}].quickReplyButtons`, undefined);
  };

  const initaliseCallToActionButtons = () => {
    setFieldValue(`contents[${selectedTabIndex}].callToActionButtons`, [
      {
        type: CallToActionType.CALL_PHONE_NUMBER,
        buttonText: '',
        phoneNumber: '',
      },
    ]);
  };

  const resetCallToActionButtons = () => {
    setFieldValue(
      `contents[${selectedTabIndex}].callToActionButtons`,
      undefined
    );
    setFieldValue(`contents[${selectedTabIndex}].buttonUrlVariable`, undefined);
  };

  const initialiseMediaType = () => {
    setFieldValue(
      `contents[${selectedTabIndex}].mediaType`,
      WhatsAppMtMediaType.IMAGE
    );
    if (whatsAppSamples) {
      setFieldValue(`contents[${selectedTabIndex}].mediaVariable`, {
        url: '',
        type: '',
      });
    }
  };

  const resetMediaType = () => {
    setFieldValue(`contents[${selectedTabIndex}].mediaType`, undefined);
    setFieldValue(`contents[${selectedTabIndex}].mediaVariable`, undefined);
  };

  const initialiseHeaderText = () => {
    setFieldValue(`contents[${selectedTabIndex}].headerText`, '');
  };

  const resetHeaderText = () => {
    setFieldValue(`contents[${selectedTabIndex}].headerText`, undefined);
    if (whatsAppSamples) {
      setFieldValue(`contents[${selectedTabIndex}].headerVariable`, undefined);
    }
  };

  const addRichTextToBody = (markup: string) => {
    const bodyText = content.bodyText;
    const selectionStart = selection.start;
    const selectionEnd = selection.end;
    const hasSelection = selectionEnd > selectionStart;

    if (!hasSelection) {
      const newBodyTextWithoutSelectedText = [
        bodyText.slice(0, selectionStart),
        `${markup}${markup}`,
        bodyText.slice(selectionStart),
      ].join('');

      setFieldValue(
        `contents[${selectedTabIndex}].bodyText`,
        newBodyTextWithoutSelectedText
      );
      bodyTextRef.current!.focus();
      setSelection({
        start: selectionStart + markup.length,
        end: selectionEnd + markup.length,
      });
      setShouldSetRange(true);

      return;
    }

    const selectedText = bodyText.substring(selectionStart, selectionEnd);
    const newBodyText = [
      bodyText.slice(0, selectionStart),
      `${markup}${selectedText}${markup}`,
      bodyText.slice(selectionEnd),
    ].join('');

    setFieldValue(`contents[${selectedTabIndex}].bodyText`, newBodyText);
    bodyTextRef.current!.focus();
    setSelection({
      start: selectionStart + markup.length,
      end: selectionEnd + markup.length,
    });
    setShouldSetRange(true);
  };

  const addVariableToHeaderText = () => {
    const text = content.headerText || '';

    setFieldValue(
      `contents[${selectedTabIndex}].headerText`,
      `${text.trimRight()}${text.length > 0 ? ' ' : ''}${createVariable()}`
    );
    if (whatsAppSamples) {
      setFieldValue(`contents[${selectedTabIndex}].headerVariable`, '');
    }
  };

  const addVariableToBodyText = () => {
    const text = content.bodyText;

    const { correctedText, variablesCount, newBodyVariables } =
      correctTextWithVariables(text, content.bodyVariables);

    setFieldValue(
      `contents[${selectedTabIndex}].bodyText`,
      `${correctedText}${text.length > 0 ? ' ' : ''}${createVariable(
        variablesCount + 1
      )}`
    );
    if (whatsAppSamples) {
      setFieldValue(
        `contents[${selectedTabIndex}].bodyVariables`,
        newBodyVariables
      );
    }

    bodyTextRef.current!.focus();
  };

  const addEmojiToBody = (emoji: BaseEmoji) => {
    const selectionStart = selection.start;
    const selectionEnd = selection.end;

    const text = content.bodyText;
    const newBodyText =
      text.slice(0, selectionStart) + emoji.native + text.slice(selectionEnd);

    setFieldValue(`contents[${selectedTabIndex}].bodyText`, `${newBodyText}`);

    bodyTextRef.current!.focus();
    setSelection({
      start: selectionStart + emoji.native.length,
      end: selectionStart + emoji.native.length,
    });
    setShouldSetRange(true);
  };

  const handleButtonsFieldChanged = (
    selectedButtonType: WhatsAppMtButtonType
  ) => {
    if (selectedButtonType === WhatsAppMtButtonType.QUICK_REPLY) {
      initaliseQuickReplyButtons();
      resetCallToActionButtons();
    } else if (selectedButtonType === WhatsAppMtButtonType.CALL_TO_ACTION) {
      initaliseCallToActionButtons();
      resetQuickReplyButtons();
    } else {
      resetQuickReplyButtons();
      resetCallToActionButtons();
    }
  };

  const handleHeaderFieldChanged = (
    selectedHeaderType: WhatsAppMtHeaderType
  ) => {
    if (selectedHeaderType === WhatsAppMtHeaderType.MEDIA) {
      initialiseMediaType();
      resetHeaderText();
    } else if (selectedHeaderType === WhatsAppMtHeaderType.TEXT) {
      initialiseHeaderText();
      resetMediaType();
    } else {
      resetMediaType();
      resetHeaderText();
    }
  };

  const hideMedia = sfPilot;
  const templateHeaderOptions = useMemo<ITemplateHeaderOption[]>(
    () => prepareOptionsForTemplateHeader(hideMedia),
    []
  );
  const templateButtonOptions = useMemo<ITemplateButtonOption[]>(
    prepareOptionsForTemplateButtonsType,
    []
  );

  const hasVariableInHeader = content.headerText?.includes(createVariable());
  const isRtlLanguage = languageDirection(content.language) === 'rtl';

  const HeaderTypeMapper: Record<WhatsAppMtHeaderType, ReactNode | null> = {
    [WhatsAppMtHeaderType.NONE]: null,
    [WhatsAppMtHeaderType.TEXT]: (
      <Box mb="small" ml="medium">
        <InputField
          name={`contents[${selectedTabIndex}].${HEADER_TEXT_FIELD_NAME}`}
          label={t.whatsapp.messageTemplates.fields.headerText()}
          maxLength={`${contentFieldsMaxLength.headerText}`}
          required
          rightActions={
            <FieldButtons
              tooltip={t.whatsapp.messageTemplates.tooltips.headerText()}
              disabled={hasVariableInHeader}
              onAddVariable={addVariableToHeaderText}
            />
          }
          className={isRtlLanguage ? 'rtl' : ''}
          id="header-text-input"
          regexConstraint={templateHeaderRegex}
          regexReplace={templateHeaderRegexReplace}
          excludeEmojis
        />
      </Box>
    ),
    [WhatsAppMtHeaderType.MEDIA]: (
      <MediaTypeSelector
        content={content}
        selectedTabIndex={selectedTabIndex}
      />
    ),
  };

  const ButtonTypeMapper: Record<WhatsAppMtButtonType, ReactNode | null> = {
    [WhatsAppMtButtonType.NONE]: null,
    [WhatsAppMtButtonType.QUICK_REPLY]: (
      <QuickReplyButtons
        content={content}
        selectedTabIndex={selectedTabIndex}
        isRtlLanguage={isRtlLanguage}
      />
    ),
    [WhatsAppMtButtonType.CALL_TO_ACTION]: (
      <CallToActionButtons
        selectedTabIndex={selectedTabIndex}
        content={content}
        isRtlLanguage={isRtlLanguage}
      />
    ),
  };

  return (
    <Box pl="medium" pr="medium" mt="large">
      <Text variant="heading_small" weight="bold" mt="large">
        {t.whatsapp.messageTemplates.templateLanguageLabels[content.language]()}{' '}
        {t.whatsapp.messageTemplates.register.sections.messageContent()}
      </Text>
      <SingleSelectField
        name={`contents[${selectedTabIndex}].${HEADER_FIELD_NAME}`}
        labels={{
          label: `${t.whatsapp.messageTemplates.fields.header()} ${t.common.optional()}`,
          placeholderReadOnly:
            t.whatsapp.messageTemplates.placeholders.header(),
        }}
        options={templateHeaderOptions}
        id="header-input"
        variant="readonly"
        onSelect={(selectedHeaderType) =>
          handleHeaderFieldChanged(selectedHeaderType as WhatsAppMtHeaderType)
        }
      />
      {HeaderTypeMapper[content.headerType]}
      <TextareaField
        name={`contents[${selectedTabIndex}].${BODY_TEXT_FIELD_NAME}`}
        textareaRef={bodyTextRef}
        label={t.whatsapp.messageTemplates.fields.messageBody()}
        maxLength={`${contentFieldsMaxLength.bodyText}`}
        onBlur={() => {
          const trimmedBodyText = content.bodyText.trim();
          const { correctedText, newBodyVariables } = correctTextWithVariables(
            trimmedBodyText,
            content.bodyVariables
          );

          if (whatsAppSamples) {
            setFieldValue(
              `contents[${selectedTabIndex}].bodyVariables`,
              newBodyVariables
            );
          }

          setFieldValue(
            `contents[${selectedTabIndex}].bodyText`,
            `${correctedText}`
          );
          const fieldHasBeenTouched = (
            touched.contents as unknown as ContentPayload[]
          )?.[selectedTabIndex]?.bodyText;
          if (!correctedText && !fieldHasBeenTouched) {
            return;
          }
          setFieldTouched(`contents[${selectedTabIndex}].bodyText`, true);
        }}
        onSelect={(e) => {
          const selectionStart = e.target.selectionStart;
          const selectionEnd = e.target.selectionEnd;
          setSelection({ start: selectionStart, end: selectionEnd });
          setShouldSetRange(false);
        }}
        regexConstraint={templateMessageBodyRegexConstraint}
        regexReplace={templateMessageBodyRegexReplace}
        className={isRtlLanguage ? 'rtl' : ''}
        id="message-body-input"
        required
        rightActions={
          <FieldButtons
            tooltip={t.whatsapp.messageTemplates.tooltips.messageBody()}
            onAddEmoji={(emoji) => addEmojiToBody(emoji)}
            onAddMonospace={() => addRichTextToBody(markupMapper.MONOSPACE)}
            onAddItalic={() => addRichTextToBody(markupMapper.ITALIC)}
            onAddBold={() => addRichTextToBody(markupMapper.BOLD)}
            onAddStrikethrough={() =>
              addRichTextToBody(markupMapper.STRIKETHROUGH)
            }
            onAddVariable={addVariableToBodyText}
          />
        }
      />
      <Box mt="small" />
      <InputField
        name={`contents[${selectedTabIndex}].footer`}
        label={`${t.whatsapp.messageTemplates.fields.footer()} ${t.common.optional()}`}
        maxLength={`${contentFieldsMaxLength.footer}`}
        className={isRtlLanguage ? 'rtl' : ''}
        id="footer-input"
        regexConstraint={templateFooterRegexConstraint}
        excludeEmojis
      />
      {sfPilot ? null : (
        <Grid flow="vertical">
          <GridItem cols={9} mt="small">
            <SingleSelectField
              name={`contents[${selectedTabIndex}].${BUTTONS_TYPE_FIELD_NAME}`}
              labels={{
                label: `${t.whatsapp.messageTemplates.fields.buttons()} ${t.common.optional()}`,
                placeholderReadOnly:
                  t.whatsapp.messageTemplates.placeholders.buttons(),
              }}
              options={templateButtonOptions}
              variant="readonly"
              onSelect={(selectedButtonType) =>
                handleButtonsFieldChanged(
                  selectedButtonType as WhatsAppMtButtonType
                )
              }
              id="buttons-input"
            />
          </GridItem>
        </Grid>
      )}
      {ButtonTypeMapper[content.buttonType]}
    </Box>
  );
};
