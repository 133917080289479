import React from 'react';

import {
  Card,
  Box,
  DescriptionList,
  IDescriptionListItem,
  LabelTag,
  ComparisonValues,
} from '@src/common/components';
import { t } from '@src/messages';
import { mapCrStateToType, mapBotStateToType } from '@src/common/utils';
import { NumberProviderType, BotState, CrState } from '@shared/bff';

import { ISectionProps } from '../types';
import { PhoneNumber } from '../PhoneNumber';

interface IConfigurationSectionData {
  name: string;
  accountName: string;
  description: string;
  phoneNumber: string;
  providerType: NumberProviderType;
  botStatus?: BotState;
  crStatus?: CrState;
}

interface IConfigurationSectionProps extends ISectionProps {
  values: IConfigurationSectionData;
  comparisonValues?: IConfigurationSectionData;
}

export const ConfigurationSection = ({
  values,
  comparisonValues,
  showDiff = false,
}: IConfigurationSectionProps) => {
  const items: IDescriptionListItem[] = [
    {
      label: t.whatsapp.fieldLabels.account(),
      detail: (
        <ComparisonValues
          id="account-name"
          value={values.accountName}
          comparisonValue={comparisonValues?.accountName}
          showDiff={
            showDiff && values.accountName !== comparisonValues?.accountName
          }
        />
      ),
    },
    {
      label: t.whatsapp.fieldLabels.channelName(),
      detail: (
        <ComparisonValues
          id="channel-name"
          value={values.name}
          comparisonValue={comparisonValues?.name}
          showDiff={showDiff && values.name !== comparisonValues?.name}
        />
      ),
    },
    {
      label: t.whatsapp.fieldLabels.channelDescription(),
      detail: (
        <ComparisonValues
          id="channel-description"
          value={values.description}
          comparisonValue={comparisonValues?.description}
          showDiff={
            showDiff && values.description !== comparisonValues?.description
          }
        />
      ),
    },
    {
      label: t.whatsapp.fieldLabels.phone(),
      detail: (
        <ComparisonValues
          value={
            <PhoneNumber
              phoneNumber={values.phoneNumber}
              providerType={values.providerType}
            />
          }
          comparisonValue={
            comparisonValues && (
              <PhoneNumber
                phoneNumber={comparisonValues?.phoneNumber}
                providerType={comparisonValues?.providerType}
              />
            )
          }
          showDiff={
            showDiff && values.phoneNumber !== comparisonValues?.phoneNumber
          }
        />
      ),
    },
    {
      label: t.whatsapp.fieldLabels.channelStatus(),
      detail: (
        <>
          {values.crStatus && (
            <LabelTag
              type={mapCrStateToType(values.crStatus as CrState)}
              showRaw={values.crStatus === CrState.BOT_CR_UNSPECIFIED_STATE}
            >
              <span id="cr-status">
                {t.common.changeRequestStateLabels[values.crStatus]()}
              </span>
            </LabelTag>
          )}
          {values.botStatus && (
            <LabelTag type={mapBotStateToType(values.botStatus as BotState)}>
              <span id="bot-status">
                {t.whatsapp.stateLabels[values.botStatus]()}
              </span>
            </LabelTag>
          )}
        </>
      ),
    },
  ];
  return (
    <Card heading={t.common.channelConfiguration()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
