import { gql } from '@apollo/client';

import { IWhatsAppHomeResponse } from '@shared/bff';

export interface IWhatsAppHomeQueryResponse {
  whatsAppHome: IWhatsAppHomeResponse;
}

export const WHATSAPP_HOME_QUERY = gql`
  query WhatsAppHome {
    whatsAppHome {
      aspClientStep
      channelStep
      templateStep
    }
  }
`;
