import { ViberAspClientState } from '@shared/bff';
import { t } from '@src/messages';

interface IAspClientProgressIndicatorStep {
  id: ViberAspClientState;
  label: string;
}

export const prepareAspClientProgressIndicatorSteps =
  (): IAspClientProgressIndicatorStep[] => [
    {
      id: ViberAspClientState.NOT_REGISTERED,
      label: t.viberBusiness.aspClients.status.NOT_REGISTERED(),
    },
    {
      id: ViberAspClientState.REQUESTED,
      label: t.viberBusiness.aspClients.status.REQUESTED(),
    },
    {
      id: `${ViberAspClientState.ONBOARDED}--last` as ViberAspClientState,
      label: t.viberBusiness.aspClients.status.ONBOARDED(),
    },
  ];
