import React from 'react';
import styled from 'styled-components';

import { Box, Card, Grid, GridItem, SFIcon } from '@src/common/components';
import { t } from '@src/messages';
import { FileTypeMapper } from '@src/common/utils';
import { IExtraDoc } from '@shared/bff';

interface IExtraDocumentsSectionProps {
  crValues: {
    extraDocs: IExtraDoc[];
  };
  currentValues: {
    extraDocs: IExtraDoc[];
  };
  showCurrent: boolean;
}

const ComparisonValueBox = styled(Box)({
  backgroundColor: '#F6FFBB',
  padding: '0 5px',
});

export const ExtraDocumentsSection = ({
  crValues,
  currentValues,
  showCurrent,
}: IExtraDocumentsSectionProps) => {
  const hasChangedExtraDocs = currentValues.extraDocs.some((currentDocs) => {
    return crValues.extraDocs.some((crDoc) => {
      return crDoc.url !== currentDocs.url;
    });
  });

  return (
    <Card heading={t.viberBusiness.channels.request.extraDocuments()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <Grid>
          <GridItem pr="x-small" cols={showCurrent ? 6 : 12}>
            <Box display="flex" flexDirection="column">
              {crValues.extraDocs.map(({ name, url, type }) => (
                <Box
                  display="inline-flex"
                  alignItems="center"
                  pl="x-large"
                  pr="medium"
                  mt="xx-small"
                >
                  <SFIcon name={FileTypeMapper[type]} />
                  <Box display="inline" ml="x-small" />
                  <a href={`/${url}`} target="_blank">
                    {name}
                  </a>
                </Box>
              ))}
            </Box>
          </GridItem>
          {showCurrent && hasChangedExtraDocs && (
            <GridItem cols={6}>
              <Box display="flex" flexDirection="column">
                {currentValues.extraDocs.map(({ name, url, type }) => (
                  <Box
                    display="inline-flex"
                    alignItems="center"
                    pl="x-large"
                    pr="medium"
                    mt="xx-small"
                  >
                    <ComparisonValueBox>
                      <SFIcon name={FileTypeMapper[type]} />
                      <Box display="inline" ml="x-small" />
                      <a href={`/${url}`} target="_blank">
                        {name}
                      </a>
                    </ComparisonValueBox>
                  </Box>
                ))}
              </Box>
            </GridItem>
          )}
        </Grid>
      </Box>
    </Card>
  );
};
