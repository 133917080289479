import React, { useContext, useState } from 'react';
import { IComboboxOption } from '@salesforce/design-system-react';
import { FormikErrors } from 'formik';

import { t } from '@src/messages';
import {
  Card,
  Grid,
  GridItem,
  ProgressCard,
  StickyGridItem,
} from '@src/common/components';
import { useFeatureFlags } from '@src/common/utils/hooks';
import {
  IWhatsAppAspClient,
  WhatsAppMtCategory,
  WhatsAppMtLanguage,
} from '@shared/bff';
import { WindowSizeContext } from '@src/common/components/WindowSizeProvider';

import { TemplateValues } from '../../types';
import {
  prepareDataForTemplatePreviewer,
  getSelectedLanguage,
} from '../../utils';
import { TemplatePreviewCard } from '../TemplatePreviewCard';
import { LanguageSelectionTabs } from '../LanguageSelectionTabs';
import { prepareStepsForProgressIndicator } from '../constants';

import { TemplateConfiguration } from './TemplateConfiguration';
import { MessageContent, DisabledMessageContent } from './MessageContent';
import { Samples } from './Samples';
import {
  AuthenticationMessageContent,
  DisabledAuthenticationMessageContent,
} from './AuthenticationMessageContent';

interface ITemplateFormProps {
  values: TemplateValues;
  aspClients: IWhatsAppAspClient[];
  errors: FormikErrors<TemplateValues>;
  submitCount: number;
}

export const TemplateForm = ({
  values: { name, contents, languages, category, authenticationContents },
  aspClients,
  errors,
  submitCount,
}: ITemplateFormProps) => {
  const { whatsAppSamples } = useFeatureFlags();

  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [previewSamples, setPreviewSamples] = useState<boolean>(true);
  const { isLargeWindow } = useContext(WindowSizeContext);

  const selectedTabIndex: number = contents
    ? contents.findIndex((content) => content.language === selectedLanguage)
    : authenticationContents
    ? authenticationContents.findIndex(
        (content) => content.language === selectedLanguage
      )
    : 0;
  const selectedContent = contents && contents[selectedTabIndex];
  const selectedAuthenticationContent =
    authenticationContents && authenticationContents[selectedTabIndex];

  const aspClientOptions: IComboboxOption[] = aspClients.map(
    ({ id, name: aspName }) => ({
      value: id,
      label: aspName,
    })
  );

  const handleLanguageRemoved = (removedLanguage: WhatsAppMtLanguage) => {
    const language = getSelectedLanguage(
      languages,
      removedLanguage,
      selectedTabIndex
    );
    setSelectedLanguage(language);
  };

  const handleLanguageAdded = (addedLanguage: WhatsAppMtLanguage) => {
    setSelectedLanguage(addedLanguage);
  };

  return (
    <Grid>
      <GridItem pb="small">
        <Card
          heading={t.whatsapp.messageTemplates.register.sections.messageTemplateConfiguration()}
        >
          <TemplateConfiguration
            aspClientOptions={aspClientOptions}
            category={category}
            languages={languages}
          />
        </Card>
      </GridItem>
      <GridItem cols={isLargeWindow ? 8 : 7} pr="small">
        <Grid stretch={true}>
          {isLargeWindow && (
            <GridItem cols={5} pr="small">
              <Card
                heading={t.whatsapp.messageTemplates.fields.languages()}
                fullHeight={true}
              >
                <LanguageSelectionTabs
                  selectedTabId={selectedLanguage}
                  handleTabChanged={setSelectedLanguage}
                  category={category}
                  languages={languages}
                  contents={contents}
                  editableContent={contents}
                  editable={true}
                  errors={errors}
                  submitCount={submitCount}
                  handleLanguageRemoved={handleLanguageRemoved}
                  handleLanguageAdded={handleLanguageAdded}
                />
              </Card>
            </GridItem>
          )}
          <GridItem cols={isLargeWindow ? 7 : 12}>
            {!isLargeWindow && (
              <Card heading={t.whatsapp.messageTemplates.fields.languages()}>
                <LanguageSelectionTabs
                  selectedTabId={selectedLanguage}
                  handleTabChanged={setSelectedLanguage}
                  category={category}
                  languages={languages}
                  contents={contents}
                  editableContent={contents}
                  editable={true}
                  errors={errors}
                  submitCount={submitCount}
                  handleLanguageRemoved={handleLanguageRemoved}
                  handleLanguageAdded={handleLanguageAdded}
                />
              </Card>
            )}
            <ProgressCard
              heading={t.whatsapp.messageTemplates.approvalProgress.title()}
              description={t.whatsapp.messageTemplates.approvalProgress.subtitle()}
              steps={prepareStepsForProgressIndicator()}
            />
            {category !== WhatsAppMtCategory.AUTHENTICATION && (
              <Card hasNoHeader>
                {selectedContent ? (
                  <MessageContent
                    selectedTabIndex={selectedTabIndex}
                    content={selectedContent}
                  />
                ) : (
                  <DisabledMessageContent />
                )}
              </Card>
            )}
            {category === WhatsAppMtCategory.AUTHENTICATION && (
              <Card hasNoHeader>
                {selectedAuthenticationContent ? (
                  <AuthenticationMessageContent
                    selectedTabIndex={selectedTabIndex}
                    content={selectedAuthenticationContent}
                  />
                ) : (
                  <DisabledAuthenticationMessageContent />
                )}
              </Card>
            )}
            {whatsAppSamples && selectedContent && (
              <Card
                heading={
                  selectedLanguage
                    ? `${t.whatsapp.messageTemplates.templateLanguageLabels[
                        selectedContent.language
                      ]()} ${t.whatsapp.messageTemplates.register.sections.samples()}`
                    : t.whatsapp.messageTemplates.register.sections.samples()
                }
              >
                <Samples
                  selectedTabIndex={selectedTabIndex}
                  content={selectedContent}
                />
              </Card>
            )}
          </GridItem>
        </Grid>
      </GridItem>
      <StickyGridItem cols={isLargeWindow ? 4 : 5}>
        <TemplatePreviewCard
          template={prepareDataForTemplatePreviewer({
            name,
            category,
            language: selectedContent
              ? selectedContent.language
              : selectedAuthenticationContent?.language,
            content: selectedContent,
            authenticationContent: selectedAuthenticationContent,
            previewSamples: previewSamples,
          })}
          onToggleSamples={setPreviewSamples}
          samplesChecked={previewSamples}
        ></TemplatePreviewCard>
      </StickyGridItem>
    </Grid>
  );
};
