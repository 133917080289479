import React from 'react';

import { Channel } from '@src/common/types';
import {
  ChannelsList,
  IQueryHandlerResult,
  QueryHandler,
} from '@src/common/components';
import { BotSortField, SortOrder } from '@shared/bff';

import { CHANNELS_QUERY, IChannelsQueryResponse } from '../graphql';

export const SenderListView = () => {
  return (
    <QueryHandler
      query={CHANNELS_QUERY}
      variables={{
        senderSendersSort: {
          sortField: BotSortField.BY_MODIFIED_DATE,
          sortOrder: SortOrder.DESCENDING,
        },
        viberCrsSort: {
          sortField: BotSortField.BY_MODIFIED_DATE,
          sortOrder: SortOrder.DESCENDING,
        },
      }}
      levitate
    >
      {({
        data: {
          viberAspClients: { onboarded },
          viberSenders,
          viberCrs,
        },
      }: IQueryHandlerResult<IChannelsQueryResponse>) => {
        return (
          <ChannelsList
            channelType={Channel.ViberBusiness}
            needToRegisterAccount={onboarded.length === 0}
            pendingChannels={viberCrs.filter((viberCr) => !viberCr.senderId)}
            onboardedChannels={viberSenders}
          />
        );
      }}
    </QueryHandler>
  );
};
