import { gql } from '@apollo/client';

import { IKakaoTalkSenderData } from '@shared/bff';

export type IKakaoTalkSenderQueryResponse = {
  kakaoTalkSenderData: IKakaoTalkSenderData;
};

export const SENDER_DATA_QUERY = gql`
  query KakaoTalkSender($profileId: String!) {
    kakaoTalkSenderData(profileId: $profileId) {
      channelName
      phoneNumber
      topLevelCategory
      midLevelCategory
    }
  }
`;
