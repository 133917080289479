import { gql } from '@apollo/client';

import { IViberCr } from '@shared/bff';

export type ISenderListItemCrFragment = Pick<
  IViberCr,
  'id' | 'changes' | 'state' | 'created' | 'updated'
>;

export const SENDER_LIST_ITEM_CR_FRAGMENT = gql`
  fragment SenderListItemCrFragment on ViberCr {
    id
    changes
    state
    created
    updated
  }
`;
