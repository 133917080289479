import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FormikErrors } from 'formik';
import {
  VerticalNavigation,
  ICategory,
  ICategoryItem,
} from '@salesforce/design-system-react';

import { t } from '@src/messages';
import {
  Box,
  SFIcon,
  LabelTag,
  TagType,
  EmptySection,
  Text,
} from '@src/common/components';
import {
  IWhatsAppMtTemplateContent,
  WhatsAppMtCategory,
  WhatsAppMtLanguage,
} from '@shared/bff';

import { ContentPayload, TemplateValues } from '../types';
import {
  ITemplateLanguageOption,
  prepareOptionsForTemplateLanguages,
} from '../utils';

import { crLabelType, templateLabelType } from './constants';
import { LanguageSelector } from './form/LanguageSelector';

interface ILanguageSelectionTabsProps {
  selectedTabId: string;
  handleTabChanged: (id: string) => void;
  category?: WhatsAppMtCategory;
  languages: WhatsAppMtLanguage[];
  existingLanguages?: WhatsAppMtLanguage[];
  contents: (IWhatsAppMtTemplateContent | ContentPayload)[] | undefined;
  editableContent?: ContentPayload[];
  errors?: FormikErrors<TemplateValues>;
  submitCount?: number;
  editable?: boolean;
  handleLanguageRemoved?: (language: WhatsAppMtLanguage) => void;
  handleLanguageAdded?: (language: WhatsAppMtLanguage) => void;
}

const VerticalNavigationWrapper = styled(VerticalNavigation)({
  '& .slds-tabs_default__content': {
    display: 'none',
  },
  '& .slds-tabs_default__nav': {
    paddingLeft: 16,
  },
});

const LabelTagWrapper = styled(LabelTag)({
  whiteSpace: 'nowrap',
});

const ScrollableBox = styled(Box)({
  overflow: 'auto',
});

export const LanguageSelectionTabs = ({
  selectedTabId,
  handleTabChanged,
  category,
  languages,
  existingLanguages,
  contents,
  editableContent,
  errors,
  submitCount,
  editable,
  handleLanguageRemoved,
  handleLanguageAdded,
}: ILanguageSelectionTabsProps) => {
  const shouldShowErrors = (language: WhatsAppMtLanguage) => {
    if (editableContent) {
      const editableContentIndex = editableContent.findIndex(
        (c) => c.language === language
      );
      const hasErrors =
        errors && errors.contents && errors.contents[editableContentIndex];
      return !!(hasErrors && !!submitCount && submitCount > 0);
    }

    return false;
  };

  const isContentPayload = (
    content: IWhatsAppMtTemplateContent | ContentPayload
  ): content is ContentPayload => {
    return !('crStatus' in content);
  };

  const getLanguageContent = (
    templateLanguages: WhatsAppMtLanguage[]
  ): ICategory => {
    return {
      id: 'language',
      label: '',
      items: templateLanguages.map((language): ICategoryItem => {
        const content =
          contents &&
          contents.find((findContent) => findContent.language === language)!;

        const showError = shouldShowErrors(language);
        const labelType: TagType = content
          ? 'templateStatus' in content
            ? templateLabelType[content.templateStatus]
            : !isContentPayload(content)
            ? crLabelType[content.crStatus]
            : 'default'
          : 'default';

        const statusLabel: string = content
          ? 'templateStatus' in content
            ? t.whatsapp.messageTemplates.templateStates[
                content.templateStatus
              ]()
            : !isContentPayload(content)
            ? t.common.changeRequestStateLabels[content.crStatus]()
            : ''
          : '';

        return {
          id: language,
          label: t.whatsapp.messageTemplates.templateLanguageLabels[language](),
          icon: showError && (
            <SFIcon name="error" size="xx-small" colorVariant="error" />
          ),
          notificationBadge: statusLabel && (
            <LabelTagWrapper type={labelType} className="slds-col_bump-left">
              {statusLabel}
            </LabelTagWrapper>
          ),
          url: '',
        };
      }),
    };
  };

  const templateLanguageOptions = useMemo<ITemplateLanguageOption[]>(
    prepareOptionsForTemplateLanguages,
    []
  );
  const languageOptions = templateLanguageOptions.filter(
    (o: ITemplateLanguageOption) => !(existingLanguages ?? []).includes(o.value)
  );

  return (
    <>
      <ScrollableBox pl="small" pr="small" pt="small">
        {languages && languages.length > 0 ? (
          <VerticalNavigationWrapper
            id="mt_language_navigation"
            categories={[getLanguageContent(languages)]}
            selectedId={selectedTabId}
            onSelect={(_, { item }) => handleTabChanged(item.id)}
          ></VerticalNavigationWrapper>
        ) : (
          category && (
            <Box pr="medium" pl="medium">
              <EmptySection type="no-data" size="small" />
              <Text
                variant="body_small"
                color="weak"
                mt="xxx-small"
                align="center"
              >
                {t.whatsapp.messageTemplates.noLanguages()}
              </Text>
            </Box>
          )
        )}
      </ScrollableBox>
      {!category && (
        <Box pr="medium" pl="medium">
          <EmptySection type="no-data" size="small" />
          <Text variant="body_small" color="weak" mt="xxx-small" align="center">
            {t.whatsapp.messageTemplates.noCategory()}
          </Text>
        </Box>
      )}
      {category && editable && (
        <Box pl="small" pr="small" pt="small">
          <LanguageSelector
            options={languageOptions}
            hideLabel
            handleLanguageRemoved={handleLanguageRemoved || (() => {})}
            handleLanguageAdded={handleLanguageAdded || (() => {})}
          />
        </Box>
      )}
    </>
  );
};
