import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { channelSubpath, accountsSubpath } from '@src/common/utils';

import {
  AspClientsView,
  AspClientRegisterView,
  AspClientDetailsView,
} from './aspClients';
import {
  SenderListView,
  ChangeRequestDetailsView,
  EditView,
  RequestSenderView,
  SenderDetailsView,
} from './channels';

interface IRoutesProps {
  path: string;
}

export const Routes = ({ path }: IRoutesProps) => (
  <Switch>
    <Route exact path={`${path}${channelSubpath}`} component={SenderListView} />
    <Route
      exact
      path={`${path}${channelSubpath}/request`}
      component={RequestSenderView}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/changeRequest/crbot/:botCrId`}
      component={ChangeRequestDetailsView}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/changeRequest/bot/:botCrId`}
      render={(props) => <ChangeRequestDetailsView {...props} hasSender />}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/changeRequest/:botCrId/edit-draft`}
      render={() => <EditView mode="edit-draft" />}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/changeRequest/:botCrId/edit-rejected`}
      render={() => <EditView mode="edit-rejected" />}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/bot/:botId/edit-bot`}
      render={() => <EditView mode="edit-bot" />}
    />
    <Route
      exact
      path={`${path}${channelSubpath}/bot/:botId`}
      component={SenderDetailsView}
    />
    <Route
      exact
      path={`${path}${accountsSubpath}`}
      component={AspClientsView}
    />
    <Route
      exact
      path={`${path}${accountsSubpath}/register`}
      component={AspClientRegisterView}
    />
    <Route
      exact
      path={`${path}${accountsSubpath}/account/:aspClientId`}
      component={AspClientDetailsView}
    />
  </Switch>
);
