import React, { useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import { Formik, Form } from 'formik';
import { omit, sortBy, isEqual } from 'lodash-es';
import { GraphQLError } from 'graphql';

import {
  QueryHandler,
  IQueryHandlerResult,
  GridItem,
  Grid,
  ToastContext,
  Box,
  ErrorModal,
  Text,
  RouteLeavingGuard,
  UserContext,
  ScopedNotification,
  Button,
} from '@src/common/components';
import { t } from '@src/messages';
import {
  templateListPath,
  templateDetailsPath,
  TEST_HTTP_HTTPS,
  TEST_INVALID_PHONE_NUMBER,
} from '@src/common/utils';
import { Channel } from '@src/common/types';
import { useFeatureFlags } from '@src/common/utils/hooks';
import {
  WhatsAppMtCategory,
  CrState,
  WhatsAppMtStatus,
  IWhatsAppMtCrContentInput,
  IWhatsAppMtCrContent,
  IWhatsAppMtTemplateContent,
  IWhatsAppAuthenticationMtCrContentInput,
} from '@shared/bff';

import { TEMPLATE_QUERY, ITemplateQueryResponse } from '../graphql';
import {
  getTemplateRequestValidationSchema,
  mapContents,
  convertExistingContentToForm,
  getTemplateDraftValidationSchema,
  getMessageContentPayloadSchema,
  getTransitionToDraft,
  getCreateTemplateMutation,
  getTransitionToReviewMutation,
  getUpdateTemplateMutation,
  getAuthenticationMessageContentPayloadSchema,
  convertExistingAuthenticationContentToForm,
  mapAuthenticationContents,
} from '../utils';
import {
  ITemplateMatchParams,
  ErrorCode,
  errorCodes,
  templateErrorCodesExplainer,
  TemplateValues,
  EditMode,
  EditContentPayload,
  EditAuthenticationContentPayload,
} from '../types';
import { ConfirmModal, EditForm, TemplatePageHeader } from '../components';

interface ITemplateEditViewProps {
  mode: EditMode;
}

export const TemplateEditView = ({ mode }: ITemplateEditViewProps) => {
  const { whatsAppSamples } = useFeatureFlags();

  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const { aspClientId, templateName } = useParams<ITemplateMatchParams>();
  const history = useHistory();
  const { toast } = useContext(ToastContext);

  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorExplanation, setErrorExplanation] = useState('');
  const [errorHeading, setErrorHeading] = useState('');
  const [hasTransitionErrors, setHasTransitionErrors] = useState(false);
  const [shouldSubmitRequest, setShouldSubmitRequest] = useState(true);
  const [languagesToSubmit, setLanguagesToSubmit] = useState([] as string[]);
  const [languageErrors, setLanguageErrors] = useState([] as string[]);

  const handleModalClose = () => setShowModal(false);

  const handleModalOpen = () => {
    if (shouldSubmitRequest) {
      setLanguagesToSubmit([]);
    }
    setShowModal(true);
  };

  const handleErrorModalClose = () => setShowErrorModal(false);

  const transitionToDraft = getTransitionToDraft();
  const createTemplateMutation = getCreateTemplateMutation();
  const transitionToReviewMutation = getTransitionToReviewMutation();
  const updateTemplateMutation = getUpdateTemplateMutation();

  const handleError = (
    e: { graphQLErrors?: readonly GraphQLError[] },
    areTransitionErrors?: boolean
  ) => {
    const errorCode: ErrorCode = e?.graphQLErrors?.[0]?.extensions?.code;
    const explanation = templateErrorCodesExplainer[errorCode];
    const isKnownError = errorCodes.includes(errorCode);
    setErrorHeading(
      shouldSubmitRequest
        ? t.whatsapp.messageTemplates.register.errorModal.heading()
        : t.whatsapp.messageTemplates.register.errorModal.saveErrorHeading()
    );

    if (isKnownError) {
      setErrorExplanation(explanation());
      setShowErrorModal(true);

      return;
    }

    if (areTransitionErrors) {
      setErrorExplanation(
        t.whatsapp.messageTemplates.register.errorModal.transitionErrors()
      );
      setShowErrorModal(true);
      return;
    }

    toast('error', {
      heading: t.common.toasts.somethingWentWrong(),
    });
  };

  const filterOnRejected = (
    c: EditContentPayload | EditAuthenticationContentPayload
  ) =>
    ('crStatus' in c && c.crStatus === CrState.REJECTED) ||
    ('templateStatus' in c && c.templateStatus === WhatsAppMtStatus.REJECTED);

  const filterOnDraft = (
    c: EditContentPayload | EditAuthenticationContentPayload
  ) => 'crStatus' in c && c.crStatus === CrState.DRAFT;

  const handleSave = async (
    name: string,
    category: WhatsAppMtCategory,
    contents: EditContentPayload[] | undefined,
    authenticationContents: EditAuthenticationContentPayload[] | undefined,
    editableContent: EditContentPayload[],
    editableAuthenticationContent: EditAuthenticationContentPayload[],
    setSubmitting: (submit: boolean) => void
  ) => {
    let languagesFromRejectedToDraft: string[] = [];
    let languagesToCreate: string[] = [];
    let languagesToUpdate: string[] = [];

    let contentsToCreate: IWhatsAppMtCrContentInput[] | undefined = undefined;
    let contentsToUpdate: IWhatsAppMtCrContentInput[] | undefined = undefined;

    let authenticationContentsToCreate:
      | IWhatsAppAuthenticationMtCrContentInput[]
      | undefined = undefined;
    let authenticationContentsToUpdate:
      | IWhatsAppAuthenticationMtCrContentInput[]
      | undefined = undefined;

    if (contents) {
      const contentPayloadValidationSchema =
        getMessageContentPayloadSchema(editableContent);

      const isValid = await Promise.all(
        contents.map((c) => contentPayloadValidationSchema.isValid(c))
      );
      const validContents = contents.filter((_, i) => isValid[i]);

      languagesFromRejectedToDraft = validContents
        .filter(filterOnRejected)
        .map(({ id }) => id) as string[];

      const mappedContents = mapContents(validContents);

      contentsToCreate = mappedContents.filter(
        (c) => c && !('crStatus' in c) && !('templateStatus' in c)
      );
      languagesToCreate = contentsToCreate.map((c) => c.language);

      contentsToUpdate = mappedContents
        .filter((c) => c && ('crStatus' in c || 'templateStatus' in c))
        .map((c) =>
          omit(c, ['crStatus', 'templateStatus', 'id'])
        ) as IWhatsAppMtCrContentInput[];
      languagesToUpdate = contentsToUpdate.map((c) => c.language);
    }

    if (authenticationContents) {
      const contentPayloadValidationSchema =
        getAuthenticationMessageContentPayloadSchema(
          editableAuthenticationContent
        );

      const isValid = await Promise.all(
        authenticationContents.map((c) =>
          contentPayloadValidationSchema.isValid(c)
        )
      );
      const validContents = authenticationContents.filter((_, i) => isValid[i]);

      languagesFromRejectedToDraft = validContents
        .filter(filterOnRejected)
        .map(({ id }) => id) as string[];

      const mappedAuthenticationContents =
        mapAuthenticationContents(validContents);

      authenticationContentsToCreate = mappedAuthenticationContents.filter(
        (c) => c && !('crStatus' in c) && !('templateStatus' in c)
      );
      languagesToCreate = authenticationContentsToCreate.map((c) => c.language);

      authenticationContentsToUpdate = mappedAuthenticationContents
        .filter((c) => c && ('crStatus' in c || 'templateStatus' in c))
        .map((c) =>
          omit(c, ['crStatus', 'templateStatus', 'id'])
        ) as IWhatsAppAuthenticationMtCrContentInput[];
      languagesToUpdate = authenticationContentsToUpdate.map((c) => c.language);
    }

    if (languagesFromRejectedToDraft.length > 0) {
      await transitionToDraft({
        variables: {
          transitionInput: {
            aspClientId,
            crIds: languagesFromRejectedToDraft,
          },
        },
      });
    }

    const savedCrs: IWhatsAppMtTemplateContent[] = [];

    if (languagesToCreate.length > 0) {
      const { data, errors } = await createTemplateMutation({
        variables: {
          crInput: {
            aspClientId,
            name,
            category,
            contents: contentsToCreate,
            authenticationContents: authenticationContentsToCreate,
          },
        },
      });
      if (errors) {
        setSubmitting(false);
        return handleError({ graphQLErrors: errors });
      }
      const { contents: savedContent } = data!.whatsAppCreateMessageTemplateCr;
      savedCrs.push(
        ...(savedContent as Array<IWhatsAppMtTemplateContent>).filter(
          (c) =>
            'crStatus' in c &&
            c.crStatus === CrState.DRAFT &&
            languagesToCreate.find((language) => language === c.language)
        )
      );
    }

    if (languagesToUpdate.length > 0) {
      const { data } = await updateTemplateMutation({
        variables: {
          crInput: {
            aspClientId,
            name,
            category,
            contents: contentsToUpdate,
            authenticationContents: authenticationContentsToUpdate,
          },
        },
      });
      const { contents: savedContent } =
        data!.whatsAppUpdateMessageTemplateDraftCr;
      savedCrs.push(
        ...(savedContent as Array<IWhatsAppMtTemplateContent>).filter(
          (c) =>
            'crStatus' in c &&
            c.crStatus === CrState.DRAFT &&
            languagesToUpdate.find((language) => language === c.language)
        )
      );
    }

    return savedCrs;
  };

  const handleSubmit = async (
    name: string,
    savedCrs: IWhatsAppMtTemplateContent[],
    setSubmitting: (submit: boolean) => void,
    resetForm: () => void
  ) => {
    try {
      const submitCrIds: string[] = savedCrs
        .map((content) => content.id)
        .filter((crId): crId is string => crId !== undefined);

      const { errors: transitionErrors } = await transitionToReviewMutation({
        variables: {
          transitionInput: {
            aspClientId,
            crIds: submitCrIds,
          },
        },
      });

      if (transitionErrors) {
        setSubmitting(false);
        setHasTransitionErrors(true);
        return handleError({ graphQLErrors: transitionErrors }, true);
      }

      setSubmitting(false);
      resetForm();

      history.push(templateDetailsPath(Channel.Whatsapp, aspClientId, name));
      toast('success', {
        heading: t.common.toasts.submitRequestSuccess(),
      });
    } catch (e) {
      setSubmitting(false);
      handleError(e);
    }
  };

  return (
    <QueryHandler
      query={TEMPLATE_QUERY}
      variables={{ aspClientId, name: templateName }}
      levitate
    >
      {({
        data: {
          whatsAppAspClient: { name: aspClientName },
          whatsAppMessageTemplate: existingValues,
        },
      }: IQueryHandlerResult<ITemplateQueryResponse>) => {
        const isAuthenticationTemplate =
          existingValues.category === WhatsAppMtCategory.AUTHENTICATION;
        existingValues = {
          ...existingValues,
          contents: sortBy(existingValues.contents, [
            (c) =>
              t.whatsapp.messageTemplates.templateLanguageLabels[c.language](),
          ]) as IWhatsAppMtCrContent[],
        };

        let allExistingContents: EditContentPayload[],
          editableContent: EditContentPayload[] = [],
          allExistingAuthenticationContents: EditAuthenticationContentPayload[],
          editableAuthenticationContent: EditAuthenticationContentPayload[] =
            [];
        if (isAuthenticationTemplate) {
          allExistingAuthenticationContents =
            convertExistingAuthenticationContentToForm(existingValues.contents);
          editableAuthenticationContent =
            allExistingAuthenticationContents.filter(
              (c) => filterOnRejected(c) || filterOnDraft(c)
            );
        } else {
          allExistingContents = convertExistingContentToForm(
            existingValues.contents
          );
          editableContent = allExistingContents.filter(
            (c) => filterOnRejected(c) || filterOnDraft(c)
          );
        }

        const initialValues: TemplateValues = {
          aspClientId,
          name: templateName,
          category: existingValues.category,
          languages: [],
          contents:
            mode === 'edit'
              ? !isAuthenticationTemplate
                ? editableContent
                : undefined
              : undefined,
          authenticationContents:
            mode === 'edit'
              ? isAuthenticationTemplate
                ? editableAuthenticationContent
                : undefined
              : undefined,
        };

        return (
          <Formik
            initialValues={initialValues}
            validationSchema={getTemplateRequestValidationSchema(
              whatsAppSamples,
              mode,
              editableContent,
              editableAuthenticationContent
            )}
            onSubmit={async (
              { name, category, contents, authenticationContents },
              actions
            ) => {
              const savedCrs = await handleSave(
                name,
                category,
                contents,
                authenticationContents,
                editableContent,
                editableAuthenticationContent,
                actions.setSubmitting
              );

              if (savedCrs) {
                await handleSubmit(
                  existingValues.name,
                  savedCrs.filter((c) =>
                    languagesToSubmit.includes(c.language)
                  ),
                  actions.setSubmitting,
                  actions.resetForm
                );
              }
            }}
          >
            {({
              values,
              errors,
              isSubmitting,
              submitCount,
              validateForm,
              submitForm,
              dirty,
              setSubmitting,
              resetForm,
            }) => {
              const handlePreSaveDraft = () => {
                setShouldSubmitRequest(false);

                const draftSchema = getTemplateDraftValidationSchema(mode);
                draftSchema
                  .validate(values)
                  .then(() => {
                    handleModalOpen();
                  })
                  .catch((e) => {
                    setSubmitting(false);
                    setErrorHeading(
                      t.whatsapp.messageTemplates.register.errorModal.requiredHeading()
                    );
                    if (e.type === TEST_INVALID_PHONE_NUMBER) {
                      setErrorExplanation(
                        t.whatsapp.messageTemplates.register.errorModal.invalidPhoneNumber()
                      );
                    } else if (e.type === TEST_HTTP_HTTPS) {
                      setErrorExplanation(
                        t.whatsapp.messageTemplates.register.errorModal.invalidWebsite()
                      );
                    } else {
                      setErrorExplanation(
                        t.whatsapp.messageTemplates.register.errorModal.requiredForNewDraft()
                      );
                    }
                    setShowErrorModal(true);
                  });
              };

              const handlePreSubmit = async () => {
                setShouldSubmitRequest(true);

                const errorStrings: string[] = [];

                const {
                  contents: contentsValidationErrors,
                  authenticationContents:
                    authenticationContentsValidationErrors,
                } = await validateForm();
                const validationErrors =
                  contentsValidationErrors ||
                  authenticationContentsValidationErrors;
                if (validationErrors) {
                  if (Array.isArray(validationErrors)) {
                    Object.entries(validationErrors).forEach(([key, value]) => {
                      const index = parseInt(key);
                      if (value === t.common.validation.test.change()) {
                        errorStrings[index] =
                          t.whatsapp.messageTemplates.register.confirmationModal.disabledLanguageLabel.unchanged();
                      } else if (typeof value === 'object') {
                        errorStrings[index] =
                          t.whatsapp.messageTemplates.register.confirmationModal.disabledLanguageLabel.incorrectValue();
                      }
                    });
                  }
                  submitForm();
                }

                setLanguageErrors(errorStrings);
                handleModalOpen();
              };

              const handleModalSubmit = async () => {
                handleModalClose();
                setSubmitting(true);

                const { name, category } = existingValues;
                const { contents, authenticationContents } = values;
                const savedCrs = await handleSave(
                  name,
                  category,
                  contents,
                  authenticationContents,
                  editableContent,
                  editableAuthenticationContent,
                  setSubmitting
                );

                if (savedCrs) {
                  await handleSubmit(
                    existingValues.name,
                    savedCrs.filter((c) =>
                      languagesToSubmit.includes(c.language)
                    ),
                    setSubmitting,
                    resetForm
                  );
                }
              };

              const handleSaveDraft = async () => {
                handleModalClose();
                setSubmitting(true);

                try {
                  const { name, category } = existingValues;
                  const { contents, authenticationContents } = values;
                  await handleSave(
                    name,
                    category,
                    contents,
                    authenticationContents,
                    editableContent,
                    editableAuthenticationContent,
                    setSubmitting
                  );

                  setSubmitting(false);
                  resetForm();

                  history.push(
                    templateDetailsPath(Channel.Whatsapp, aspClientId, name)
                  );
                  toast('success', {
                    heading: t.common.toasts.saveDraftSuccess(),
                  });
                } catch (e) {
                  setSubmitting(false);
                  handleError(e);
                }
              };

              const shouldBlockExternalNavigation = () => dirty;

              const comparisonFormValues = isAuthenticationTemplate
                ? values.authenticationContents
                : values.contents;
              const comparisonResponseContentValues = isAuthenticationTemplate
                ? editableAuthenticationContent
                : editableContent;

              const MapPageHeading: Record<EditMode, string> = {
                ['edit']: templateName,
                ['add']: t.common.actions.addLanguage(),
              };

              return (
                <>
                  <Form>
                    <Grid>
                      <GridItem mb="small">
                        <TemplatePageHeader
                          title={MapPageHeading[mode]}
                          breadcrumbDestination={templateListPath(
                            Channel.Whatsapp
                          )}
                          cancel={{
                            onClick: () =>
                              history.push(
                                templateDetailsPath(
                                  Channel.Whatsapp,
                                  aspClientId,
                                  templateName
                                )
                              ),
                          }}
                          draft={{
                            onClick: handlePreSaveDraft,
                            disabled:
                              isSubmitting ||
                              readOnlyAccess ||
                              (mode === 'add'
                                ? !dirty
                                : isEqual(
                                    comparisonFormValues,
                                    comparisonResponseContentValues
                                  )),
                            loading: isSubmitting && !shouldSubmitRequest,
                          }}
                          submit={{
                            onClick: handlePreSubmit,
                            disabled:
                              isSubmitting ||
                              readOnlyAccess ||
                              (mode === 'add' ? !dirty : false) ||
                              (mode === 'edit' &&
                                values.contents &&
                                values.contents.length <= 0) ||
                              (mode === 'edit' &&
                                values.authenticationContents &&
                                values.authenticationContents.length <= 0),
                            loading: isSubmitting && shouldSubmitRequest,
                          }}
                        />
                      </GridItem>
                      {readOnlyAccess && (
                        <GridItem mb="small">
                          <ScopedNotification variant="info">
                            {t.common.readOnlyAccessNotification()}
                          </ScopedNotification>
                        </GridItem>
                      )}
                      <GridItem>
                        <EditForm
                          editMode={mode}
                          existingValues={existingValues}
                          allExistingContents={allExistingContents}
                          allExistingAuthenticationContents={
                            allExistingAuthenticationContents
                          }
                          values={values}
                          aspClientName={aspClientName}
                          errors={errors}
                          submitCount={submitCount}
                        />
                      </GridItem>
                    </Grid>
                    <ConfirmModal
                      handleModalClose={handleModalClose}
                      handleModalSubmit={handleModalSubmit}
                      handleSaveDraft={handleSaveDraft}
                      languageErrors={languageErrors}
                      languagesToSubmit={languagesToSubmit}
                      mode={mode}
                      setLanguagesToSubmit={setLanguagesToSubmit}
                      shouldSubmitRequest={shouldSubmitRequest}
                      showModal={showModal}
                      values={values}
                    />
                    <ErrorModal
                      heading={errorHeading}
                      onClose={handleErrorModalClose}
                      isOpen={showErrorModal}
                      label={t.common.actions.close()}
                      footer={
                        hasTransitionErrors && [
                          <Button
                            id="error-modal-edit-template-button"
                            onClick={handleErrorModalClose}
                          >
                            {t.common.actions.editDraft()}
                          </Button>,
                          <Button
                            id="error-modal-view-template-button"
                            onClick={() =>
                              history.push(
                                templateDetailsPath(
                                  Channel.Whatsapp,
                                  values.aspClientId,
                                  values.name
                                )
                              )
                            }
                          >
                            {t.common.actions.viewTemplate()}
                          </Button>,
                        ]
                      }
                    >
                      <Box pl="medium" pr="medium" pt="large" pb="large">
                        <Text>{errorExplanation}</Text>
                      </Box>
                    </ErrorModal>
                  </Form>
                  <RouteLeavingGuard
                    whenBlockInternalNavigation={dirty && !hasTransitionErrors}
                    navigate={(path) => {
                      history.push(path);
                    }}
                    shouldBlockExternalNavigation={
                      shouldBlockExternalNavigation
                    }
                  />
                </>
              );
            }}
          </Formik>
        );
      }}
    </QueryHandler>
  );
};
