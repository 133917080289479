import { gql } from '@apollo/client';

import { IWhatsAppCr } from '@shared/bff';

export type IWhatsAppTransitionBotCrFromRejectedToDraftMutationResponse = {
  whatsAppTransitionBotCrFromRejectedToDraft: Pick<IWhatsAppCr, 'id'>;
};

export const TRANSITION_BOT_CR_FROM_REJECTED_TO_DRAFT_MUTATION = gql`
  mutation WhatsAppTransitionBotCrFromRejectedToDraft($crId: String!) {
    whatsAppTransitionBotCrFromRejectedToDraft(crId: $crId) {
      id
    }
  }
`;
