import { IWhatsAppMtCrContentInput } from '@shared/bff';

type messageContentFieldsMaxLengthKeys = keyof Pick<
  IWhatsAppMtCrContentInput,
  | 'headerText'
  | 'bodyText'
  | 'footer'
  | 'quickReplyButtons'
  | 'callToActionButtons'
>;

export const contentFieldsMaxLength: Record<
  messageContentFieldsMaxLengthKeys,
  number
> = {
  headerText: 60,
  bodyText: 1024,
  footer: 60,
  quickReplyButtons: 20,
  callToActionButtons: 20,
};
