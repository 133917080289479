import { IWhatsAppAspClientInput } from '@shared/bff';

type aspClientFieldsKeys = keyof Pick<
  IWhatsAppAspClientInput,
  'name' | 'companyLegalName' | 'facebookBusinessManagerId'
>;
export const aspClientFieldsMinLength: Record<aspClientFieldsKeys, number> = {
  name: 2,
  companyLegalName: 2,
  facebookBusinessManagerId: 2,
};

export const aspClientFieldsMaxLength: Record<aspClientFieldsKeys, number> = {
  name: 255,
  companyLegalName: 255,
  facebookBusinessManagerId: 255,
};
