import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { channelSubpath } from '@src/common/utils';

import { SenderListView, RequestSenderView } from './channels';

interface IRoutesProps {
  path: string;
}

export const Routes = ({ path }: IRoutesProps) => (
  <Switch>
    <Route exact path={`${path}${channelSubpath}`} component={SenderListView} />
    <Route
      exact
      path={`${path}${channelSubpath}/request`}
      component={RequestSenderView}
    />
  </Switch>
);
