import { t } from 'messages';

import { WhatsAppMtCategory } from '@shared/bff';

export interface ITemplateCategoryOption {
  value: WhatsAppMtCategory;
  label: string;
}

const templateCategoryLabels =
  t.whatsapp.messageTemplates.templateCategoryLabels;

export const prepareOptionsForTemplateCategories = (autoReply?: boolean) => [
  {
    value: WhatsAppMtCategory.ACCOUNT_UPDATE,
    label: templateCategoryLabels.ACCOUNT_UPDATE(),
  },
  {
    value: WhatsAppMtCategory.PAYMENT_UPDATE,
    label: templateCategoryLabels.PAYMENT_UPDATE(),
  },
  {
    value: WhatsAppMtCategory.PERSONAL_FINANCE_UPDATE,
    label: templateCategoryLabels.PERSONAL_FINANCE_UPDATE(),
  },
  {
    value: WhatsAppMtCategory.SHIPPING_UPDATE,
    label: templateCategoryLabels.SHIPPING_UPDATE(),
  },
  {
    value: WhatsAppMtCategory.RESERVATION_UPDATE,
    label: templateCategoryLabels.RESERVATION_UPDATE(),
  },
  {
    value: WhatsAppMtCategory.ISSUE_RESOLUTION,
    label: templateCategoryLabels.ISSUE_RESOLUTION(),
  },
  {
    value: WhatsAppMtCategory.APPOINTMENT_UPDATE,
    label: templateCategoryLabels.APPOINTMENT_UPDATE(),
  },
  {
    value: WhatsAppMtCategory.TRANSPORTATION_UPDATE,
    label: templateCategoryLabels.TRANSPORTATION_UPDATE(),
  },
  {
    value: WhatsAppMtCategory.TICKET_UPDATE,
    label: templateCategoryLabels.TICKET_UPDATE(),
  },
  {
    value: WhatsAppMtCategory.ALERT_UPDATE,
    label: templateCategoryLabels.ALERT_UPDATE(),
  },
  ...(autoReply
    ? [
        {
          value: WhatsAppMtCategory.AUTO_REPLY,
          label: templateCategoryLabels.AUTO_REPLY(),
        },
      ]
    : []),
];
