import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonGroup } from '@salesforce/design-system-react';
import { useMutation } from '@apollo/client';

import {
  ActivityHistorySection,
  Box,
  Button,
  DocumentationLink,
  Grid,
  GridItem,
  IQueryHandlerResult,
  PageHeader,
  ProgressCard,
  QueryHandler,
  ScopedNotification,
  ToastContext,
  UserContext,
} from '@src/common/components';
import { Channel, IAspClientIdMatchParams } from '@src/common/types';
import { t } from '@src/messages';
import { accountsPath } from '@src/common/utils';
import {
  ACTIVITIES_QUERY,
  CREATE_COMMENT_MUTATION,
  IActivitiesQueryResponse,
  ICreateCommentResponse,
} from '@src/common/graphql';
import {
  ActivityTargetType,
  IMutationCreateCommentArgs,
  ViberAspClientState,
} from '@shared/bff';

import { prepareAspClientProgressIndicatorSteps } from '../utils';
import { AspClientDetailsSection } from '../components';
import { ASP_CLIENT_QUERY, IAspClientQueryResponse } from '../graphql/queries';

export const AspClientDetailsView = () => {
  const { toast } = useContext(ToastContext);
  const { aspClientId } = useParams<IAspClientIdMatchParams>();
  const [createCommentRequest] = useMutation<
    ICreateCommentResponse,
    IMutationCreateCommentArgs
  >(CREATE_COMMENT_MUTATION);
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;

  return (
    <QueryHandler
      query={ASP_CLIENT_QUERY}
      variables={{ aspClientId, targetType: ActivityTargetType.ASP_CLIENT }}
      levitate
    >
      {({
        data: {
          viberAspClient: {
            id,
            name,
            businessLegalName,
            businessCategories,
            businessRegisteredCountry,
            warrantyLetterUrl,
            state,
          },
        },
      }: IQueryHandlerResult<IAspClientQueryResponse>) => {
        const isRejected = state === ViberAspClientState.REJECTED;
        const showProgressIndicator = ![
          ViberAspClientState.ONBOARDED,
          ViberAspClientState.REJECTED,
        ].includes(state);
        const currentStepId =
          state === ViberAspClientState.UNSPECIFIED ||
          state === ViberAspClientState.NOT_REGISTERED
            ? undefined
            : state;

        return (
          <Grid>
            <GridItem mb="small">
              <PageHeader
                channelType={Channel.ViberBusiness}
                sectionType="account"
                title={name}
                id="account-details-header"
                breadcrumbs={[
                  {
                    header: t.common.accounts(),
                    to: accountsPath(Channel.ViberBusiness),
                  },
                ]}
                actions={
                  <ButtonGroup
                    className="slds-align-middle"
                    variant="list"
                    id="button-group-page-header-actions"
                  >
                    {isRejected && (
                      <Button
                        label={t.common.actions.editRejected()}
                        id="edit-rejected-button"
                        disabled={readOnlyAccess}
                      />
                    )}
                  </ButtonGroup>
                }
              />
            </GridItem>
            {readOnlyAccess && (
              <GridItem mb="small">
                <ScopedNotification variant="info">
                  {t.common.readOnlyAccessNotification()}
                </ScopedNotification>
              </GridItem>
            )}
            {isRejected && (
              <GridItem mb="small">
                <ScopedNotification>
                  <>
                    {t.viberBusiness.aspClients.details.scopedNotification.description()}
                    <Box display="inline" ml="xx-small" />
                    <DocumentationLink>
                      {t.viberBusiness.aspClients.details.scopedNotification.link()}
                    </DocumentationLink>
                  </>
                </ScopedNotification>
              </GridItem>
            )}
            {showProgressIndicator && (
              <GridItem mb="small">
                <ProgressCard
                  heading={t.common.components.aspClientProgressIndicator.heading()}
                  description={t.common.components.aspClientProgressIndicator.description()}
                  steps={prepareAspClientProgressIndicatorSteps()}
                  currentStepId={currentStepId}
                />
              </GridItem>
            )}
            <GridItem>
              <AspClientDetailsSection
                data={{
                  name: name,
                  businessLegalName: businessLegalName,
                  businessCategories: businessCategories,
                  businessRegisteredCountry: businessRegisteredCountry,
                  warrantyLetterUrl: warrantyLetterUrl,
                  state: state,
                }}
              />
            </GridItem>
            <QueryHandler
              query={ACTIVITIES_QUERY}
              variables={{
                targetType: ActivityTargetType.ASP_CLIENT,
                targetId: id,
              }}
              levitate
            >
              {({
                data: { activities },
              }: IQueryHandlerResult<IActivitiesQueryResponse>) => {
                return (
                  <GridItem mt="small">
                    <ActivityHistorySection
                      onSubmit={async (values) => {
                        try {
                          await createCommentRequest({
                            variables: {
                              comment: {
                                targetId: id,
                                targetType: ActivityTargetType.ASP_CLIENT,
                                comment: values.comment,
                              },
                            },
                            refetchQueries: [
                              {
                                query: ACTIVITIES_QUERY,
                                variables: {
                                  targetType: ActivityTargetType.ASP_CLIENT,
                                  targetId: id,
                                },
                              },
                            ],
                          });
                          toast('success', {
                            heading: t.common.toasts.commentCreatedSuccess(),
                          });
                        } catch (e) {
                          toast('error', {
                            heading: t.common.toasts.somethingWentWrong(),
                          });
                        }
                      }}
                      data={activities.map((activity) => ({
                        id: activity.activityId,
                        timestamp: activity.created,
                        comment: activity.comment,
                        event: activity.notificationTemplate,
                      }))}
                    />
                  </GridItem>
                );
              }}
            </QueryHandler>
          </Grid>
        );
      }}
    </QueryHandler>
  );
};
