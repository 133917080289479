import { omit, pick } from 'lodash-es';

import { convertToISOString, isISOFormat } from '@src/common/utils';
import { IExtraDoc, ILogoUrl, ISize, IViberSenderInput } from '@shared/bff';

import { ICrSenderDataFragment, IViberSenderFragment } from '../../graphql';

export const mapSenderValues = (values: IViberSenderInput) => {
  return {
    ...values,
    phoneNumber: `+${values.phoneNumber}`,
    businessLaunchDate: values.businessLaunchDate
      ? !isISOFormat(values.businessLaunchDate)
        ? convertToISOString(values.businessLaunchDate)
        : values.businessLaunchDate
      : null,
  };
};

export const mapChannelValues = (values: IViberSenderInput) => {
  const newSenderValues = mapSenderValues(values);
  return {
    sender: omit(newSenderValues, ['id']),
    senderId: null,
  };
};

const senderFields: (keyof IViberSenderInput)[] = [
  'id',
  'aspClientId',
  'name',
  'destinationCountries',
  'businessLaunchDate',
  'logoUrls',
  'description',
  'address',
  'phoneNumber',
  'website',
  'sampleMessage',
  'extraDocs',
];
const extraDocsFields: (keyof IExtraDoc)[] = [
  'name',
  'type',
  'url',
  'permanent',
];
const logoUrlsFields: (keyof ILogoUrl)[] = ['size', 'url', 'name'];
const logoSizeFields: (keyof ISize)[] = ['height', 'width'];

export const mapChannelToFormValues = (
  sender: IViberSenderFragment | ICrSenderDataFragment
): IViberSenderInput => {
  return {
    ...pick(sender, senderFields),
    phoneNumber: sender.phoneNumber.replace('+', ''),
    extraDocs: sender.extraDocs?.map((doc) => ({
      ...pick(doc, extraDocsFields),
    })),
    logoUrls: sender.logoUrls.map((logo) => {
      const size = pick(logo.size, logoSizeFields);
      return {
        ...pick(logo, logoUrlsFields),
        size,
      };
    }),
  } as IViberSenderInput;
};
