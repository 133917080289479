import { t } from 'messages';

import { WhatsAppMtOtpType } from '@shared/bff';

export interface IAuthenticationTemplateButtonOption {
  value: WhatsAppMtOtpType;
  label: string;
}

const TemplateButtonLabels =
  t.whatsapp.messageTemplates.authenticationTemplateButtonLabels;

export const prepareOptionsForAuthenticationTemplateButtonType = () => [
  {
    value: WhatsAppMtOtpType.COPY_CODE,
    label: TemplateButtonLabels.COPY_CODE(),
  },
  {
    value: WhatsAppMtOtpType.ONE_TAP,
    label: TemplateButtonLabels.ONE_TAP(),
  },
];
