import React from 'react';

import {
  Box,
  Grid,
  GridItem,
  Label,
  SFIcon,
  Text,
  UploadButton,
} from '@src/common/components';
import { FileTypeMapper, getFileName } from '@src/common/utils';
import { t } from '@src/messages';
import { IWhatsAppMtMediaVariable } from '@shared/bff';

interface IExistingFileSampleProps {
  mediaVariable: IWhatsAppMtMediaVariable;
}

export const ExistingFileSample = ({
  mediaVariable,
}: IExistingFileSampleProps) => {
  return (
    <>
      <Label
        id="header-media-sample-image-tooltip"
        tooltip={t.whatsapp.messageTemplates.tooltips.headerMediaSampleTooltip()}
        required
      >
        <Text color="weak">
          {t.whatsapp.messageTemplates.fields.headerMediaSample()}
        </Text>
      </Label>
      <Box mt="small" />

      <Box display="inline-flex" alignItems="center" mb="small">
        {mediaVariable.type && (
          <SFIcon name={FileTypeMapper[mediaVariable.type]} />
        )}
        <Box display="inline" ml="x-small" />
        <Text>{getFileName(mediaVariable.url)}</Text>
      </Box>
      <Grid>
        <GridItem cols={5}>
          <div id="drop-zone-files">
            <Box display="flex" alignItems="center">
              <UploadButton
                title={t.common.components.fileUploader.single.uploadButtonDescription()}
                id="upload-files-button"
                disabled
              />
            </Box>
          </div>
        </GridItem>
      </Grid>
    </>
  );
};
