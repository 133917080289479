import React, { useMemo } from 'react';
import { t } from 'messages';

import {
  Card,
  Box,
  DescriptionList,
  IDescriptionListItem,
  LabelTag,
  TagType,
  SFIcon,
} from 'common/components';
import { ViberAspClientState, IViberAspClient } from '@shared/bff';
import { FileTypeMapper, getFileName } from '@src/common/utils';

import { getCountryIsoCodeLangFn } from '../../../utils';
import { prepareOptionsForBusinessCategory } from '../../utils';

const labelType: Record<ViberAspClientState, TagType> = {
  REQUESTED: 'default',
  NOT_REGISTERED: 'default',
  ONBOARDED: 'success',
  REJECTED: 'error',
  UNSPECIFIED: 'default',
};

interface IAspClientDetailsSectionProps {
  data: Pick<
    IViberAspClient,
    | 'name'
    | 'businessLegalName'
    | 'businessCategories'
    | 'businessRegisteredCountry'
    | 'warrantyLetterUrl'
    | 'state'
  >;
}

export const AspClientDetailsSection = ({
  data: {
    name,
    businessLegalName,
    businessCategories,
    businessRegisteredCountry,
    warrantyLetterUrl,
    state,
  },
}: IAspClientDetailsSectionProps) => {
  const showRaw = [
    ViberAspClientState.UNSPECIFIED,
    ViberAspClientState.NOT_REGISTERED,
  ].includes(state);

  const items = useMemo<IDescriptionListItem[]>(
    () => [
      {
        id: 'account-details-name',
        label: t.viberBusiness.aspClients.fields.name(),
        detail: name,
      },
      {
        label: t.viberBusiness.aspClients.fields.status(),
        detail: (
          <LabelTag type={labelType[state]} showRaw={showRaw}>
            {t.viberBusiness.aspClients.status[state]()}
          </LabelTag>
        ),
      },
      {
        id: 'account-details-business-legal-name',
        label: t.viberBusiness.aspClients.fields.businessLegalName(),
        detail: businessLegalName,
      },
      {
        id: 'account-details-business-categories',
        label: t.viberBusiness.aspClients.fields.businessCategories(),
        detail:
          prepareOptionsForBusinessCategory().find(
            (cat) => cat.value === businessCategories
          )?.label ?? businessCategories,
      },
      {
        id: 'account-details-business-registered-country',
        label: t.viberBusiness.aspClients.fields.businessRegisteredCountry(),
        detail: getCountryIsoCodeLangFn(businessRegisteredCountry)(),
      },
    ],
    []
  );

  return (
    <>
      <Card id="account-details" heading={t.common.accountDetails()}>
        <Box pl="medium" pr="medium" mt="xx-small">
          <DescriptionList items={items} />
        </Box>
      </Card>
      <Card heading={t.viberBusiness.aspClients.register.warrantyLetter()}>
        {warrantyLetterUrl && (
          <Box
            display="inline-flex"
            alignItems="center"
            pl="x-large"
            pr="medium"
            mt="xx-small"
          >
            <SFIcon name={FileTypeMapper['application/pdf']} />
            <Box display="inline" ml="x-small" />
            <a href={`${warrantyLetterUrl}`} target="_blank">
              {getFileName(warrantyLetterUrl)}
            </a>
          </Box>
        )}
      </Card>
    </>
  );
};
