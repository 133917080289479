import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  Combobox,
  OnComboboxSelectCallback,
  OnComboboxRequestRemoveSelectedOptionCallback,
  IComboboxOption,
  OnComboboxChangeCallback,
  Tooltip,
} from '@salesforce/design-system-react';
import { t } from 'messages';

import {
  MultiLineErrorMessage,
  RequiredFieldLabel,
} from '@src/common/components';
import { WhatsAppMtCategory, WhatsAppMtLanguage } from '@shared/bff';

import {
  ITemplateLanguageOption,
  removeMessageContent,
  addMessageContent,
  removeAuthenticationMessageContent,
  addAuthenticationMessageContent,
} from '../../../utils';
import { TemplateValues } from '../../../types';

interface ILanguageSelectorProps {
  options: ITemplateLanguageOption[];
  hideLabel?: boolean;
  handleLanguageRemoved: (language: WhatsAppMtLanguage) => void;
  handleLanguageAdded: (language: WhatsAppMtLanguage) => void;
}

const getSelectedOptions = (
  options: ITemplateLanguageOption[],
  selectedValues: string[]
) =>
  selectedValues.map(
    (value) => options.find((o) => o.value === value) || { value, label: value }
  );

const getNonSelectedOptions = (
  options: ITemplateLanguageOption[],
  selectedValues: string[]
) => options.filter((o) => !selectedValues.includes(o.value));

const getLanguageToRemove = (
  value: WhatsAppMtLanguage[],
  selectedLanguageCodes: string[]
) => value.filter((language) => !selectedLanguageCodes.includes(language))[0];

export const LanguageSelector = ({
  options,
  hideLabel,
  handleLanguageRemoved,
  handleLanguageAdded,
}: ILanguageSelectorProps) => {
  const [field, meta, helpers] = useField('languages');
  const { setTouched } = helpers;
  const { error, touched } = meta;
  const { value } = field;

  const { values, setValues } = useFormikContext<TemplateValues>();

  // Label can only be as a string, but we need to handle required fields
  const label = (
    <RequiredFieldLabel>
      {t.whatsapp.messageTemplates.fields.languages()}
    </RequiredFieldLabel>
  ) as unknown;

  const nonSelectedLanguages: ITemplateLanguageOption[] = getNonSelectedOptions(
    options,
    value
  );
  const [availableOptions, setAvailableOptions] =
    useState<ITemplateLanguageOption[]>(nonSelectedLanguages);
  const [searchValue, setSearchValue] = useState<string>('');

  const selectedOptions: IComboboxOption[] = getSelectedOptions(options, value);

  const onBlur = () => {
    if (!searchValue && selectedOptions.length === 0 && !touched) {
      return;
    }
    setTouched(true);
  };

  const onSearch: OnComboboxChangeCallback = (_, { value: searchText }) => {
    const filteredOptions = nonSelectedLanguages.filter((o) =>
      o.label.toLowerCase().includes(searchText.toLowerCase())
    );
    setSearchValue(searchText);
    setAvailableOptions(filteredOptions);
  };

  const onLanguageSelect: OnComboboxSelectCallback = (_, { selection }) => {
    const selectedLanguageCodes = (selection as ITemplateLanguageOption[]).map(
      (s) => s.value
    );
    const languageOptions: ITemplateLanguageOption[] = getNonSelectedOptions(
      options,
      selectedLanguageCodes
    );
    setTouched(true);
    setSearchValue('');
    setAvailableOptions(languageOptions);
    handleLanguageAdded(
      selectedLanguageCodes[selectedLanguageCodes.length - 1]
    );

    const shouldAddMessageContent = value.length < selectedLanguageCodes.length;
    let updatedContents, updatedAuthenticationContents;
    if (shouldAddMessageContent) {
      const languageToAdd = selectedLanguageCodes.filter(
        (language) => !value.includes(language)
      )[0];
      if (values.category !== WhatsAppMtCategory.AUTHENTICATION) {
        const contents = values.contents || [];
        updatedContents = addMessageContent(languageToAdd, contents);
      }
      if (values.category === WhatsAppMtCategory.AUTHENTICATION) {
        const authenticationContents = values.authenticationContents || [];
        updatedAuthenticationContents = addAuthenticationMessageContent(
          languageToAdd,
          authenticationContents
        );
      }
    } else {
      const languageToRemove = getLanguageToRemove(
        value,
        selectedLanguageCodes
      );
      if (values.contents) {
        updatedContents = removeMessageContent(
          languageToRemove,
          values.contents
        );
      }
      if (values.authenticationContents) {
        updatedAuthenticationContents = removeAuthenticationMessageContent(
          languageToRemove,
          values.authenticationContents
        );
      }
      handleLanguageRemoved(languageToRemove);
    }
    const updatedValues = {
      ...values,
      languages: selectedLanguageCodes,
      contents: updatedContents,
      authenticationContents: updatedAuthenticationContents,
    };
    setValues(updatedValues);
  };

  const onLanguageRemove: OnComboboxRequestRemoveSelectedOptionCallback = (
    _,
    { selection }
  ) => {
    const selectedLanguageCodes = (selection as ITemplateLanguageOption[]).map(
      (s) => s.value
    );
    const languageOptions: ITemplateLanguageOption[] = getNonSelectedOptions(
      options,
      selectedLanguageCodes
    );
    setTouched(true);
    setAvailableOptions(languageOptions);

    const languageToRemove = getLanguageToRemove(value, selectedLanguageCodes);
    let updatedContents = values.contents;
    let updatedAuthenticationContents = values.authenticationContents;

    if (values.contents) {
      updatedContents = removeMessageContent(languageToRemove, values.contents);
    }
    if (values.authenticationContents) {
      updatedAuthenticationContents = removeAuthenticationMessageContent(
        languageToRemove,
        values.authenticationContents
      );
    }

    const updatedValues = {
      ...values,
      languages: selectedLanguageCodes,
      contents: updatedContents,
      authenticationContents: updatedAuthenticationContents,
    };
    setValues(updatedValues);
    handleLanguageRemoved(languageToRemove);
  };

  return (
    <MultiLineErrorMessage
      errors={error}
      touched={touched}
      render={(errorText) => (
        <Combobox
          labels={{
            // Label can only be as a string, but we need to handle required fields
            label: !hideLabel && (label as string),
            placeholder: t.common.actions.addLanguage(),
          }}
          fieldLevelHelpTooltip={
            <Tooltip
              id="language-level-help-tooltip"
              align="top left"
              content={t.whatsapp.messageTemplates.tooltips.languages()}
              variant="learnMore"
            />
          }
          options={availableOptions}
          selection={selectedOptions}
          value={searchValue}
          events={{
            onBlur,
            onSelect: onLanguageSelect,
            onRequestRemoveSelectedOption: onLanguageRemove,
            onChange: onSearch,
          }}
          errorText={errorText}
          multiple
          variant="inline-listbox"
          id="languages-input"
          menuItemVisibleLength={5}
        />
      )}
    />
  );
};
