import React, { useContext } from 'react';
import { Tooltip } from '@salesforce/design-system-react';

import {
  Box,
  ChannelLimitsContext,
  ChannelLimitTag,
  DescriptionList,
  IDescriptionListItem,
  ScopedNotification,
  UserContext,
} from '@src/common/components';
import { t } from '@src/messages';
import { useFeatureFlags } from '@src/common/utils/hooks';

interface IEnterpriseDetailsLabelProps {
  label: string;
  tooltip: string;
  id: string;
}

const EnterpriseDetailsLabel = ({
  label,
  tooltip,
  id,
}: IEnterpriseDetailsLabelProps) => (
  <Box display="inline-flex">
    {label}
    <Box mr="xx-small" />
    <Tooltip id={id} content={tooltip} variant="base" />
  </Box>
);

export const EnterpriseDetails = () => {
  const { viberBusiness, channelLimits } = useFeatureFlags();
  const { user } = useContext(UserContext);
  const { viberChannels, whatsAppChannels } = useContext(ChannelLimitsContext);

  const noPurchasedChannels = viberBusiness
    ? viberChannels.purchased === 0 && whatsAppChannels.purchased === 0
    : whatsAppChannels.purchased === 0;

  const items: IDescriptionListItem[] = [
    {
      label: (
        <EnterpriseDetailsLabel
          label={t.home.enterpriseDetails.labels.enterprise()}
          tooltip={t.home.enterpriseDetails.tooltips.enterprise()}
          id="enterprise-tooltip"
        />
      ),
      detail: user.enterpriseName,
    },
    ...(viberBusiness && channelLimits
      ? [
          {
            label: (
              <EnterpriseDetailsLabel
                label={t.home.enterpriseDetails.labels.viberChannels()}
                tooltip={t.home.enterpriseDetails.tooltips.viberChannels()}
                id="viber-channels-tooltip"
              />
            ),
            detail: (
              <ChannelLimitTag
                consumed={viberChannels.consumed}
                purchased={viberChannels.purchased}
                tag="viber"
              />
            ),
          },
        ]
      : []),
    ...(channelLimits
      ? [
          {
            label: (
              <EnterpriseDetailsLabel
                label={t.home.enterpriseDetails.labels.whatsappChannels()}
                tooltip={t.home.enterpriseDetails.tooltips.whatsappChannels()}
                id="whatsapp-channels-tooltip"
              />
            ),
            detail: (
              <ChannelLimitTag
                consumed={whatsAppChannels.consumed}
                purchased={whatsAppChannels.purchased}
                tag="wa"
              />
            ),
          },
        ]
      : []),
  ];
  return (
    <>
      <DescriptionList
        items={items}
        spaceBetweenColumns
        detailsTextAlignment="right"
      />
      {noPurchasedChannels && (
        <>
          <Box mt="small" />
          <ScopedNotification variant="info">
            {t.common.noPurchasedAppsNotification()}
          </ScopedNotification>
        </>
      )}
    </>
  );
};
