import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { sortBy } from 'lodash-es';

import { t } from '@src/messages';
import {
  Card,
  StickyGridItem,
  Grid,
  GridItem,
  QueryHandler,
  IQueryHandlerResult,
  ActivityHistorySection,
  ToastContext,
  ProgressCard,
} from '@src/common/components';
import {
  ACTIVITIES_QUERY,
  IActivitiesQueryResponse,
  ICreateCommentResponse,
  CREATE_COMMENT_MUTATION,
} from '@src/common/graphql';
import { useFeatureFlags } from '@src/common/utils/hooks';
import {
  IWhatsAppMtOrMtCr,
  IMutationCreateCommentArgs,
  WhatsAppMtLanguage,
  IWhatsAppMtTemplateContent,
  ActivityTargetType,
  CrState,
} from '@shared/bff';
import { WindowSizeContext } from '@src/common/components/WindowSizeProvider';

import { prepareDataForTemplatePreviewer } from '../utils';

import { MessageContentDetails } from './MessageContentDetails';
import { SamplesDetails } from './SamplesDetails';
import { LanguageSelectionTabs } from './LanguageSelectionTabs';
import { TemplatePreviewCard } from './TemplatePreviewCard';
import { prepareStepsForProgressIndicator } from './constants';
import { Configuration } from './edit';

interface ITemplateDetailsSectionProps {
  aspClientName: string;
  template: IWhatsAppMtOrMtCr;
}

export const TemplateDetailsSection = ({
  aspClientName,
  template,
}: ITemplateDetailsSectionProps) => {
  const { whatsAppSamples } = useFeatureFlags();

  const { name, category, contents } = template;

  const { toast } = useContext(ToastContext);
  const [createCommentRequest] = useMutation<
    ICreateCommentResponse,
    IMutationCreateCommentArgs
  >(CREATE_COMMENT_MUTATION);

  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [previewSamples, setPreviewSamples] = useState<boolean>(true);
  const { isLargeWindow } = useContext(WindowSizeContext);

  const sortedContent = sortBy(contents, [
    (c) => t.whatsapp.messageTemplates.templateLanguageLabels[c.language](),
  ]);

  let selectedTabIndex: number = sortedContent.findIndex(
    (content) => content.language === selectedLanguage
  );
  const allLanguages = sortedContent.map((content) => content.language);

  if (selectedTabIndex === -1) {
    selectedTabIndex = 0;
    setSelectedLanguage(allLanguages[0]);
  }

  const selectedContent = sortedContent[selectedTabIndex];
  const showSamples =
    selectedContent.mediaVariable ||
    selectedContent.headerVariable ||
    selectedContent.headerVariable === '' ||
    selectedContent.bodyVariables ||
    selectedContent.buttonUrlVariable ||
    selectedContent.buttonUrlVariable === '';

  const languages: WhatsAppMtLanguage[] = (
    sortedContent as Array<IWhatsAppMtTemplateContent>
  ).map(({ language }: IWhatsAppMtTemplateContent) => language);
  const targetType =
    'crStatus' in selectedContent
      ? ActivityTargetType.WA_MT_CHANGE_REQUEST
      : ActivityTargetType.WA_MT;
  const targetId = selectedContent.id;

  return (
    <Grid>
      <GridItem mb="small">
        <Card
          heading={t.whatsapp.messageTemplates.register.sections.messageTemplateConfiguration()}
        >
          <Configuration
            aspClientName={aspClientName}
            existingValues={template}
            variant="static"
          />
        </Card>
      </GridItem>
      <GridItem cols={isLargeWindow ? 8 : 7} pr="small">
        <Grid stretch={true}>
          {isLargeWindow && (
            <GridItem cols={5} pr="small">
              <Card
                heading={t.whatsapp.messageTemplates.fields.languages()}
                fullHeight={true}
              >
                <LanguageSelectionTabs
                  selectedTabId={selectedLanguage}
                  handleTabChanged={setSelectedLanguage}
                  contents={sortedContent}
                  languages={languages}
                  category={category}
                />
              </Card>
            </GridItem>
          )}
          <GridItem cols={isLargeWindow ? 7 : 12}>
            {!isLargeWindow && (
              <Card heading={t.whatsapp.messageTemplates.fields.languages()}>
                <LanguageSelectionTabs
                  selectedTabId={selectedLanguage}
                  handleTabChanged={setSelectedLanguage}
                  contents={sortedContent}
                  languages={languages}
                  category={category}
                />
              </Card>
            )}
            {selectedContent &&
              'crStatus' in selectedContent &&
              selectedContent.crStatus &&
              selectedContent.crStatus !== CrState.REJECTED && (
                <ProgressCard
                  heading={t.whatsapp.messageTemplates.approvalProgress.title()}
                  description={t.whatsapp.messageTemplates.approvalProgress.subtitle()}
                  steps={prepareStepsForProgressIndicator()}
                  currentStepId={selectedContent.crStatus}
                />
              )}
            <Card hasNoHeader>
              <MessageContentDetails content={selectedContent} />
            </Card>
            {whatsAppSamples && showSamples && (
              <Card
                heading={`${t.whatsapp.messageTemplates.templateLanguageLabels[
                  selectedContent.language
                ]()} ${t.whatsapp.messageTemplates.register.sections.samples()}`}
              >
                <SamplesDetails
                  mediaVariable={selectedContent.mediaVariable}
                  mediaType={selectedContent.mediaType}
                  headerVariable={selectedContent.headerVariable}
                  bodyVariables={selectedContent.bodyVariables}
                  buttonUrlVariable={selectedContent.buttonUrlVariable}
                />
              </Card>
            )}
          </GridItem>
          <GridItem cols={12} mt="small">
            <QueryHandler
              query={ACTIVITIES_QUERY}
              variables={{
                targetType,
                targetId,
              }}
              levitate
            >
              {({
                data: { activities },
              }: IQueryHandlerResult<IActivitiesQueryResponse>) => {
                return (
                  <ActivityHistorySection
                    titlePrefix={t.whatsapp.messageTemplates.templateLanguageLabels[
                      selectedContent.language
                    ]()}
                    size="small"
                    onSubmit={async (values) => {
                      try {
                        await createCommentRequest({
                          variables: {
                            comment: {
                              targetId,
                              targetType,
                              comment: values.comment,
                            },
                          },
                          refetchQueries: [
                            {
                              query: ACTIVITIES_QUERY,
                              variables: {
                                targetType,
                                targetId,
                              },
                            },
                          ],
                        });
                        toast('success', {
                          heading: t.common.toasts.commentCreatedSuccess(),
                        });
                      } catch (e) {
                        toast('error', {
                          heading: t.common.toasts.somethingWentWrong(),
                        });
                      }
                    }}
                    data={activities.map((activity) => ({
                      id: activity.activityId,
                      timestamp: activity.created,
                      comment: activity.comment,
                      event: activity.notificationTemplate,
                    }))}
                  />
                );
              }}
            </QueryHandler>
          </GridItem>
        </Grid>
      </GridItem>
      <StickyGridItem cols={isLargeWindow ? 4 : 5}>
        <TemplatePreviewCard
          template={prepareDataForTemplatePreviewer({
            name,
            category,
            language: selectedContent.language,
            content: selectedContent,
            previewSamples: previewSamples,
          })}
          onToggleSamples={setPreviewSamples}
          samplesChecked={previewSamples}
        ></TemplatePreviewCard>
      </StickyGridItem>
    </Grid>
  );
};
