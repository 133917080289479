import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonGroup } from '@salesforce/design-system-react';

import {
  Box,
  Button,
  ChannelLimitsContext,
  DocumentationLink,
  GridItem,
  PageHeader,
  ScopedNotification,
  UserContext,
} from '@src/common/components';
import { Channel } from '@src/common/types';
import {
  channelBotDetailsPath,
  channelChangeRequestEditDraftPath,
  channelChangeRequestEditRejectedPath,
  channelListPath,
} from '@src/common/utils';
import { t } from '@src/messages';
import { CrState } from '@shared/bff';

import { ICrFragment } from '../../graphql';

interface IHeaderProps {
  viberCr: ICrFragment;
  hasSender?: boolean;
  showCurrent: boolean;
  setShowCurrent: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Header = ({
  viberCr,
  hasSender,
  showCurrent,
  setShowCurrent,
}: IHeaderProps) => {
  const history = useHistory();

  const handleCurrentClick = () => setShowCurrent(!showCurrent);
  const { viberChannels } = useContext(ChannelLimitsContext);

  const channelsLimitReached =
    viberChannels.consumed >= viberChannels.purchased &&
    viberChannels.purchased > 0;

  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;

  const handleEditDraftClick = () =>
    history.push(
      channelChangeRequestEditDraftPath(Channel.ViberBusiness, viberCr.id)
    );
  const handleEditRejectedClick = () =>
    history.push(
      channelChangeRequestEditRejectedPath(Channel.ViberBusiness, viberCr.id)
    );

  const handleGoToChannelClick = () =>
    history.push(
      channelBotDetailsPath(Channel.ViberBusiness, viberCr.senderId)
    );

  const showEditDraft = viberCr.state === CrState.DRAFT;
  const showEditRejected = viberCr.state === CrState.REJECTED;

  return (
    <>
      <GridItem mb="small">
        <PageHeader
          channelType={Channel.ViberBusiness}
          sectionType="channel"
          title={viberCr.sender?.name}
          id="change-request-details-header"
          breadcrumbs={[
            {
              header: t.common.channels(),
              to: channelListPath(Channel.ViberBusiness),
            },
          ]}
          actions={
            <ButtonGroup>
              {hasSender ? (
                <Button
                  id="show-current-button"
                  key="current"
                  label={
                    showCurrent
                      ? t.common.actions.hideCurrent()
                      : t.common.actions.showCurrent()
                  }
                  onClick={handleCurrentClick}
                />
              ) : (
                <></>
              )}
              {hasSender ? (
                <Button
                  id="go-to-channel-button"
                  key="go-to-channel"
                  label={t.common.actions.goToChannel()}
                  onClick={handleGoToChannelClick}
                />
              ) : (
                <></>
              )}
              {showEditDraft ? (
                <Button
                  id="edit-draft-button"
                  key="draft"
                  label={t.common.actions.editDraft()}
                  onClick={handleEditDraftClick}
                  disabled={readOnlyAccess}
                />
              ) : (
                <></>
              )}
              {showEditRejected ? (
                <Button
                  id="edit-rejected-button"
                  key="rejected"
                  label={t.common.actions.editRejected()}
                  onClick={handleEditRejectedClick}
                  disabled={readOnlyAccess}
                />
              ) : (
                <></>
              )}
            </ButtonGroup>
          }
        />
      </GridItem>
      {readOnlyAccess && (
        <GridItem mb="small">
          <ScopedNotification variant="info">
            {t.common.readOnlyAccessNotification()}
          </ScopedNotification>
        </GridItem>
      )}
      {!hasSender && channelsLimitReached && (
        <GridItem mb="small">
          <ScopedNotification variant="warning">
            {t.common.channelLimitNotification()}
          </ScopedNotification>
        </GridItem>
      )}
      {showEditRejected && (
        <GridItem mb="small">
          <ScopedNotification>
            <>
              {t.common.rejectScopedNotification.description()}
              <Box display="inline" ml="xx-small" />
              <DocumentationLink>
                {t.common.rejectScopedNotification.link()}
              </DocumentationLink>
            </>
          </ScopedNotification>
        </GridItem>
      )}
    </>
  );
};
