type TranslationObject = {
  [key: string]: (() => string) | TranslationObject;
};

export const initializeTranslations = (object: TranslationObject) => {
  const newObject: Record<string, unknown> = {};
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === 'function') {
      newObject[key] = value();
    } else if (typeof value === 'object') {
      newObject[key] = initializeTranslations(value);
    }
  });
  return newObject;
};
