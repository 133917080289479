import React, { useMemo } from 'react';
import { t } from 'messages';
import { useFormikContext } from 'formik';

import {
  Box,
  CheckboxField,
  Grid,
  GridItem,
  InputField,
  SingleSelectField,
  Text,
} from '@src/common/components';
import {
  IWhatsAppAuthenticationMtCrContentInput,
  WhatsAppMtOtpType,
} from '@shared/bff';
import { languageDirection } from '@src/common/utils';

import {
  AUTOFILL_TEXT_FIELD_NAME,
  CODE_EXPIRATION_MINUTES_FIELD_NAME,
  COPY_CODE_BUTTON_TEXT_FIELD_NAME,
  IAuthenticationTemplateButtonOption,
  OTP_TYPE_FIELD_NAME,
  PACKAGE_NAME_FIELD_NAME,
  SECURITY_RECOMMENDATION_FIELD_NAME,
  SIGNATURE_HASH_FIELD_NAME,
  authenticationContentFieldsMaxLength,
  authenticationContentFieldsMinLength,
  prepareOptionsForAuthenticationTemplateButtonType,
} from '../../../utils';
import { TemplateValues } from '../../../types';

interface IAuthenticationMessageContentProps {
  selectedTabIndex: number;
  content: IWhatsAppAuthenticationMtCrContentInput;
}

export const AuthenticationMessageContent = ({
  selectedTabIndex,
  content,
}: IAuthenticationMessageContentProps) => {
  const isRtlLanguage = languageDirection(content.language) === 'rtl';

  const { setFieldValue } = useFormikContext<TemplateValues>();

  const resetOneTapFields = () => {
    setFieldValue(
      `authenticationContents[${selectedTabIndex}].autofillText`,
      undefined
    );
    setFieldValue(
      `authenticationContents[${selectedTabIndex}].packageName`,
      undefined
    );
    setFieldValue(
      `authenticationContents[${selectedTabIndex}].signatureHash`,
      undefined
    );
  };

  const initializeOneTapFields = () => {
    setFieldValue(
      `authenticationContents[${selectedTabIndex}].autofillText`,
      undefined
    );
    setFieldValue(
      `authenticationContents[${selectedTabIndex}].packageName`,
      ''
    );
    setFieldValue(
      `authenticationContents[${selectedTabIndex}].signatureHash`,
      ''
    );
  };

  const handleButtonsFieldChanged = (selectedOtpType: WhatsAppMtOtpType) => {
    if (selectedOtpType === WhatsAppMtOtpType.COPY_CODE) {
      resetOneTapFields();
    } else {
      initializeOneTapFields();
    }
  };

  const authenticationTemplateButtonTypeOptions = useMemo<
    IAuthenticationTemplateButtonOption[]
  >(prepareOptionsForAuthenticationTemplateButtonType, []);
  return (
    <Box pl="medium" pr="medium" mt="large">
      <Text variant="heading_small" weight="bold" mt="large">
        {t.whatsapp.messageTemplates.register.sections.messageContent()}
      </Text>
      <Grid flow="vertical">
        <GridItem cols={10} pr="small" pt="small">
          <Text variant="body">
            {t.whatsapp.messageTemplates.fields.messageBody()}
          </Text>
          <CheckboxField
            name={`authenticationContents[${selectedTabIndex}].${SECURITY_RECOMMENDATION_FIELD_NAME}`}
            labels={{
              label: `${t.whatsapp.messageTemplates.fields.securityRecommendation()} ${t.common.optional()}`,
            }}
            checked={content.securityRecommendation ?? false}
            id="security-recommendation-input"
          />
        </GridItem>
      </Grid>
      <Grid flow="vertical">
        <GridItem cols={8} pr="small" pt="small">
          <Text variant="body">
            {t.whatsapp.messageTemplates.fields.footer()}
          </Text>
          <InputField
            name={`authenticationContents[${selectedTabIndex}].${CODE_EXPIRATION_MINUTES_FIELD_NAME}`}
            label={`${t.whatsapp.messageTemplates.fields.codeExpirationMinutes()} ${t.common.optional()}`}
            type="number"
            variant="counter"
            minValue={
              authenticationContentFieldsMinLength.codeExpirationMinutes
            }
            maxValue={
              authenticationContentFieldsMaxLength.codeExpirationMinutes
            }
            tooltip={t.whatsapp.messageTemplates.tooltips.codeExpirationMinutes()}
            id="code-expiration-minutes-input"
          />
        </GridItem>
      </Grid>
      <Grid flow="vertical">
        <GridItem cols={6} mt="small">
          <Text variant="body">
            {t.whatsapp.messageTemplates.fields.button()}
          </Text>
          <SingleSelectField
            name={`authenticationContents[${selectedTabIndex}].${OTP_TYPE_FIELD_NAME}`}
            labels={{
              label: t.whatsapp.messageTemplates.fields.buttonType(),
            }}
            options={authenticationTemplateButtonTypeOptions}
            variant="readonly"
            onSelect={(selectedButtonType) =>
              handleButtonsFieldChanged(selectedButtonType as WhatsAppMtOtpType)
            }
            required
            id="otp-type-input"
          />
        </GridItem>
      </Grid>
      {content.otpType === WhatsAppMtOtpType.ONE_TAP && (
        <Box>
          <Box mt="small" />
          <InputField
            name={`authenticationContents[${selectedTabIndex}].${AUTOFILL_TEXT_FIELD_NAME}`}
            label={t.whatsapp.messageTemplates.fields.autofillText()}
            className={isRtlLanguage ? 'rtl' : ''}
            tooltip={t.whatsapp.messageTemplates.tooltips.autofillText()}
            id="autofill-text-input"
          />
          <Box mt="small" />
          <InputField
            name={`authenticationContents[${selectedTabIndex}].${PACKAGE_NAME_FIELD_NAME}`}
            label={t.whatsapp.messageTemplates.fields.packageName()}
            required
            className={isRtlLanguage ? 'rtl' : ''}
            tooltip={t.whatsapp.messageTemplates.tooltips.packageName()}
            id="package-name-input"
          />
          <Box mt="small" />
          <InputField
            name={`authenticationContents[${selectedTabIndex}].${SIGNATURE_HASH_FIELD_NAME}`}
            label={t.whatsapp.messageTemplates.fields.signatureHash()}
            required
            className={isRtlLanguage ? 'rtl' : ''}
            tooltip={t.whatsapp.messageTemplates.tooltips.signatureHash()}
            id="signature-hash-input"
          />
        </Box>
      )}
      <Box mt="small" />
      <InputField
        name={`authenticationContents[${selectedTabIndex}].${COPY_CODE_BUTTON_TEXT_FIELD_NAME}`}
        label={t.whatsapp.messageTemplates.fields.copyCodeButtonText()}
        className={isRtlLanguage ? 'rtl' : ''}
        tooltip={t.whatsapp.messageTemplates.tooltips.copyCodeButtonText()}
        required
        id="copy-code-button-text-input"
      />
    </Box>
  );
};
