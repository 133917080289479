import React, { ReactNode } from 'react';
import { Button } from '@salesforce/design-system-react';
import { t } from 'messages';

import { Box, Text } from '@src/common/components';

interface IButtonsWrapperProps {
  buttons: ReactNode;
  disabled: boolean;
  helpText: string;
  handleAdd: () => void;
}

export const ButtonsSection = ({
  buttons,
  disabled,
  helpText,
  handleAdd,
}: IButtonsWrapperProps) => {
  return (
    <>
      <Box mt="small" />
      <Text variant="body_small" color="weak">
        {helpText}
      </Text>
      <Box mt="small" />
      {buttons}
      <Box ml="medium">
        <Button disabled={disabled} onClick={handleAdd}>
          {t.whatsapp.messageTemplates.register.buttonLabels.addButton()}
        </Button>
      </Box>
    </>
  );
};
