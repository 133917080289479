import { gql } from '@apollo/client';

import { IWhatsAppMtOrMtCr } from '@shared/bff';

export interface ICreateTemplateMutationResponse {
  whatsAppCreateMessageTemplateCr: IWhatsAppMtOrMtCr;
}

export const CREATE_TEMPLATE_MUTATION = gql`
  mutation WhatsAppCreateMessageTemplate($crInput: WhatsAppMtCrInput!) {
    whatsAppCreateMessageTemplateCr(crInput: $crInput) {
      ... on WhatsAppMt {
        aspClientId
        name
        contents {
          ... on WhatsAppMtContent {
            id
            templateStatus
          }
          ... on WhatsAppMtCrContent {
            id
            crStatus
            language
          }
        }
      }
      ... on WhatsAppMtCr {
        aspClientId
        name
        contents {
          ... on WhatsAppMtCrContent {
            id
            crStatus
            language
          }
        }
      }
    }
  }
`;
