import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Grid,
  IQueryHandlerResult,
  QueryHandler,
} from '@src/common/components';
import { IBotCrIdMatchParams } from '@src/common/types';

import { ChangeRequestDetails } from '../../components';
import {
  CR_QUERY,
  ICrQueryResponse,
  ISenderQueryResponse,
  SENDER_QUERY,
} from '../../graphql';

import { Header } from './Header';

interface IChangeRequestDetailsViewProps {
  hasSender?: boolean;
}

export const ChangeRequestDetailsView = ({
  hasSender,
}: IChangeRequestDetailsViewProps) => {
  const { botCrId } = useParams<IBotCrIdMatchParams>();

  const [showCurrent, setShowCurrent] = useState(false);

  return (
    <>
      <QueryHandler
        query={CR_QUERY}
        variables={{
          senderCrId: botCrId,
        }}
        levitate
      >
        {({
          data: {
            viberCr,
            viberAspClients: { onboarded },
          },
        }: IQueryHandlerResult<ICrQueryResponse>) => {
          const aspClient = onboarded.find(
            (client) => client.id === viberCr.sender.aspClientId
          );

          if (hasSender) {
            return (
              <QueryHandler
                query={SENDER_QUERY}
                variables={{
                  senderId: viberCr.senderId,
                }}
                levitate
              >
                {({
                  data: { viberSender },
                }: IQueryHandlerResult<ISenderQueryResponse>) => {
                  return (
                    <Grid>
                      <Header
                        viberCr={viberCr}
                        hasSender={hasSender}
                        showCurrent={showCurrent}
                        setShowCurrent={setShowCurrent}
                      />
                      <ChangeRequestDetails
                        aspClient={aspClient!}
                        cr={viberCr}
                        sender={viberSender}
                        showCurrent={showCurrent}
                      />
                    </Grid>
                  );
                }}
              </QueryHandler>
            );
          }
          return (
            <Grid>
              <Header
                viberCr={viberCr}
                hasSender={hasSender}
                showCurrent={showCurrent}
                setShowCurrent={setShowCurrent}
              />
              <ChangeRequestDetails
                aspClient={aspClient!}
                cr={viberCr}
                showCurrent={showCurrent}
              />
            </Grid>
          );
        }}
      </QueryHandler>
    </>
  );
};
