import React, { useContext, useState } from 'react';
import { useFormikContext } from 'formik';

import { t } from '@src/messages';
import {
  Box,
  FilesList,
  FileUploader,
  Grid,
  GridItem,
  Label,
  Text,
  ToastContext,
} from '@src/common/components';
import { IExtraDocInput, IViberSenderInput } from '@shared/bff';
import { IFileInput } from '@src/common/types';

export const ExtraDocuments = () => {
  const { toast } = useContext(ToastContext);
  const { setFieldValue, values } = useFormikContext<IViberSenderInput>();
  const { extraDocs } = values;

  const [uploading, setUploading] = useState<boolean>(false);

  const deleteFile = (index: number) => {
    const files = extraDocs!.filter((_, i) => index !== i);
    setFieldValue('extraDocs', files);
  };

  return (
    <>
      <Box pl="small" pr="small" id="extra-document-section">
        <Label
          id="extra-documents-tooltip"
          tooltip={t.viberBusiness.channels.request.tooltips.extraDocuments()}
        >
          <Text color="weak">
            {t.viberBusiness.channels.request.helpTexts.extraDocuments()}
          </Text>
        </Label>
        <Box mb="small" />
        <FileUploader
          dragDropOptions={{
            maxSize: 15000000, //15MB
          }}
          uploadButtonTitle={t.common.components.fileUploader.multiple.uploadButtonDescription()}
          onFilesUpload={async (files: IFileInput[]) => {
            setUploading(true);

            try {
              const fileUrls: IExtraDocInput[] = [];

              for await (const file of files) {
                const { name, blob } = file;
                const formData = new FormData();
                formData.append('file', blob, name);
                const res = await fetch(`/api/data/upload/temporary/${name}`, {
                  method: 'POST',
                  body: formData,
                });
                const { fileUrl, mimeType } = await res.json();

                fileUrls.push({
                  url: fileUrl!,
                  name,
                  type: mimeType,
                  permanent: false,
                });
              }

              setFieldValue('extraDocs', [...(extraDocs || []), ...fileUrls]);
            } catch (error) {
              toast('error', {
                heading: t.common.toasts.somethingWentWrong(),
              });
            }
            setUploading(false);
          }}
        />
        <Grid>
          <GridItem cols={5}>
            <FilesList
              files={extraDocs ?? []}
              uploading={uploading}
              onDeleteFile={deleteFile}
            />
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};
