import React, { useMemo } from 'react';
import { t } from 'messages';

import {
  Card,
  Box,
  DescriptionList,
  IDescriptionListItem,
  LabelTag,
  TagType,
} from 'common/components';
import { AspClientState } from '@shared/bff';

import { WhatsAppAspClient } from '../../graphql';

export interface IAspClientDetailsSectionProps {
  data: WhatsAppAspClient;
}

const labelType: Record<AspClientState, TagType> = {
  REQUESTED: 'default',
  NOT_REGISTERED: 'default',
  ONBOARDED: 'success',
  REJECTED: 'error',
  PENDING_FB_VERIFICATION: 'default',
  UNSPECIFIED: 'default',
};

export const AspClientDetailsSection = ({
  data: { name, companyLegalName, facebookBusinessManagerId, state },
}: IAspClientDetailsSectionProps) => {
  const showRaw = [
    AspClientState.UNSPECIFIED,
    AspClientState.NOT_REGISTERED,
  ].includes(state);

  const items = useMemo<IDescriptionListItem[]>(
    () => [
      {
        id: 'account-details-name',
        label: t.whatsapp.aspClients.fields.name(),
        detail: name,
      },
      {
        label: t.whatsapp.aspClients.fields.status(),
        detail: (
          <LabelTag type={labelType[state]} showRaw={showRaw}>
            {t.whatsapp.aspClients.status[state]()}
          </LabelTag>
        ),
      },
      {
        id: 'account-details-business-legal-name',
        label: t.whatsapp.aspClients.fields.businessLegalName(),
        detail: companyLegalName,
      },
      {
        id: 'account-details-facebook-business-manager-id',
        label: t.whatsapp.aspClients.fields.facebookBusinessManagerID(),
        detail: facebookBusinessManagerId,
      },
    ],
    []
  );

  return (
    <Card id="account-details" heading={t.common.accountDetails()}>
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
