import * as Yup from 'yup';

import { IWhatsAppAspClientInput } from '@shared/bff';

import {
  aspClientFieldsMaxLength,
  aspClientFieldsMinLength,
} from './aspClientFieldsLength';

export const getAspClientValidationSchema = () =>
  Yup.object<IWhatsAppAspClientInput>({
    name: Yup.string()
      .min(aspClientFieldsMinLength.name)
      .max(aspClientFieldsMaxLength.name)
      .required(),
    companyLegalName: Yup.string()
      .min(aspClientFieldsMinLength.companyLegalName)
      .max(aspClientFieldsMaxLength.companyLegalName)
      .required(),
    facebookBusinessManagerId: Yup.string()
      .min(aspClientFieldsMinLength.facebookBusinessManagerId)
      .max(aspClientFieldsMaxLength.facebookBusinessManagerId)
      .required(),
  });
