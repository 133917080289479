import { gql } from '@apollo/client';

import { IWhatsAppMtOrMtCr } from '@shared/bff';

export interface IUpdateTemplateMutationResponse {
  whatsAppUpdateMessageTemplateDraftCr: IWhatsAppMtOrMtCr;
}

export const UPDATE_TEMPLATE_MUTATION = gql`
  mutation WhatsAppUpdateMessageTemplateDraftCr($crInput: WhatsAppMtCrInput!) {
    whatsAppUpdateMessageTemplateDraftCr(crInput: $crInput) {
      ... on WhatsAppMt {
        aspClientId
        name
        contents {
          ... on WhatsAppMtContent {
            id
            templateStatus
            language
          }
          ... on WhatsAppMtCrContent {
            id
            crStatus
            language
          }
        }
      }
      ... on WhatsAppMtCr {
        aspClientId
        name
        contents {
          ... on WhatsAppMtCrContent {
            id
            crStatus
            language
          }
        }
      }
    }
  }
`;
