import { t } from '@src/messages';

export interface IBusinessCategoryOption {
  value: string;
  label: string;
}

const businessCategories = t.viberBusiness.aspClients.businessCategories;

export const prepareOptionsForBusinessCategory =
  (): IBusinessCategoryOption[] => [
    {
      value: 'Brand',
      label: businessCategories.BRAND(),
    },
    {
      value: 'Media Agency',
      label: businessCategories.MEDIA_AGENCY(),
    },
    {
      value: 'Financial institution',
      label: businessCategories.FINANCIAL_INSTITUTION(),
    },
    {
      value: 'Community organization',
      label: businessCategories.COMMUNITY_ORGANIZATION(),
    },
    {
      value: 'Governmental/non-governmental organisation',
      label: businessCategories.GOVERNMENTAL_NON_GOVERNMENTAL_ORGANISATION(),
    },
    {
      value: 'Publisher',
      label: businessCategories.PUBLISHER(),
    },
    {
      value: 'Commerce',
      label: businessCategories.COMMERCE(),
    },
    {
      value: 'E-Commerce',
      label: businessCategories.ECOMMERCE(),
    },
    {
      value: 'Real estate and construction companies',
      label: businessCategories.REAL_ESTATE_AND_CONSTRUCTION_COMPANIES(),
    },
    {
      value: 'IT and internet companies',
      label: businessCategories.IT_AND_INTERNET_COMPANIES(),
    },
    {
      value: 'Education',
      label: businessCategories.EDUCATION(),
    },
    {
      value: 'Healthcare',
      label: businessCategories.HEALTHCARE(),
    },
    {
      value: 'Transportations',
      label: businessCategories.TRANSPORTATIONS(),
    },
    {
      value: 'Retail',
      label: businessCategories.RETAIL(),
    },
    {
      value: 'Other',
      label: businessCategories.OTHER(),
    },
  ];
