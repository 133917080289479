import { gql } from '@apollo/client';

import { IWhatsAppMtOrMtCr } from '@shared/bff';

export interface ITransitionToDraftMutationResponse {
  WhatsAppMtOrMtCr: IWhatsAppMtOrMtCr;
}

export const TRANSITION_TO_DRAFT_MUTATION = gql`
  mutation WhatsAppTransitionMessageTemplateCrFromRejectedToDraft(
    $transitionInput: TransitionCrInput!
  ) {
    whatsAppTransitionMessageTemplateCrFromRejectedToDraft(
      transitionInput: $transitionInput
    ) {
      ... on WhatsAppMt {
        aspClientId
        name
      }
      ... on WhatsAppMtCr {
        aspClientId
        name
      }
    }
  }
`;
