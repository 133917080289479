import { t } from 'messages';

import { WhatsAppMtHeaderType } from '@shared/bff';

export interface ITemplateHeaderOption {
  value: WhatsAppMtHeaderType;
  label: string;
}

const TemplateHeaderLabels = t.whatsapp.messageTemplates.templateHeaderLabels;

export const prepareOptionsForTemplateHeader = (hideMedia?: boolean) => [
  {
    value: WhatsAppMtHeaderType.NONE,
    label: TemplateHeaderLabels.NONE(),
  },
  {
    value: WhatsAppMtHeaderType.TEXT,
    label: TemplateHeaderLabels.TEXT(),
  },
  ...(hideMedia
    ? []
    : [
        {
          value: WhatsAppMtHeaderType.MEDIA,
          label: TemplateHeaderLabels.MEDIA(),
        },
      ]),
];
