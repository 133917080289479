import { gql } from '@apollo/client';

import { IWhatsAppAspClientsResponse } from '@shared/bff';

import { IWhatsAppBotFragment, WHATSAPP_BOT_FRAGMENT } from '../../fragments';

export interface IBotQueryResponse {
  whatsAppBot: IWhatsAppBotFragment;
  whatsAppAspClients: Pick<IWhatsAppAspClientsResponse, 'onboarded'>;
}

export const BOT_QUERY = gql`
  query WhatsAppBot($botId: String!) {
    whatsAppBot(botId: $botId) {
      ...WhatsAppBotFragment
    }
  }
  ${WHATSAPP_BOT_FRAGMENT}
`;
