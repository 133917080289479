import React from 'react';
import { useFormikContext } from 'formik';
import { VisualPicker, Radio } from '@salesforce/design-system-react';
import styled from 'styled-components';
import { t } from 'messages';

import { useFeatureFlags } from '@src/common/utils/hooks';
import { Text, Grid, GridItem, Box } from '@src/common/components';
import { FileIcon, PhotoIcon, VideoIcon } from '@src/assets';
import { WhatsAppMtMediaType } from '@shared/bff';

import { TemplateValues, ContentPayload } from '../../../types';
import { MEDIA_TYPE_FIELD_NAME } from '../../../utils';

interface IMediaTypeSelectorProps {
  selectedTabIndex: number;
  content: ContentPayload;
}

const MEDIA_RADIO_WIDTH = 134;
const MEDIA_RADIO_HEIGHT = 122;

const MediaRadio = styled(Radio)({
  width: MEDIA_RADIO_WIDTH,
  height: MEDIA_RADIO_HEIGHT,
  marginBottom: 48,

  '& > label': {
    width: MEDIA_RADIO_WIDTH,
    height: MEDIA_RADIO_HEIGHT,
  },
  '& .slds-visual-picker__figure': {
    backgroundColor: '#CCD0D6',
    width: MEDIA_RADIO_WIDTH,
    height: MEDIA_RADIO_HEIGHT,
  },
  '& .slds-visual-picker__body': {
    width: MEDIA_RADIO_WIDTH,
  },
  '& .slds-icon_container': {
    lineHeight: '1.5',
  },
  '& .slds-visual-picker__text-check': {
    opacity: '0',
  },
  '& input:checked ~ label .slds-visual-picker__text-check': {
    opacity: '1',
  },
});

const VisualPickerWrapper = styled(VisualPicker)({
  '& .slds-form-element__control': {
    display: 'flex',
  },
});

export const MediaTypeSelector = ({
  content,
  selectedTabIndex,
}: IMediaTypeSelectorProps) => {
  const { whatsAppSamples } = useFeatureFlags();

  const { setFieldValue } = useFormikContext<TemplateValues>();
  const fieldPath = `contents[${selectedTabIndex}].${MEDIA_TYPE_FIELD_NAME}`;
  const variableFieldPath = `contents[${selectedTabIndex}].mediaVariable`;
  const selectedMediaType = content.mediaType;

  const handleImageChanged = () => {
    setFieldValue(fieldPath, WhatsAppMtMediaType.IMAGE);
    if (whatsAppSamples) {
      setFieldValue(variableFieldPath, { url: '', type: '' });
    }
  };

  const handleVideoChanged = () => {
    setFieldValue(fieldPath, WhatsAppMtMediaType.VIDEO);
    if (whatsAppSamples) {
      setFieldValue(variableFieldPath, { url: '', type: '' });
    }
  };

  const handleDocumentChanged = () => {
    setFieldValue(fieldPath, WhatsAppMtMediaType.DOCUMENT);
    if (whatsAppSamples) {
      setFieldValue(variableFieldPath, { url: '', type: '' });
    }
  };

  const { sfGa } = useFeatureFlags();

  return (
    <Grid>
      <GridItem cols={9} mt="small" ml="medium">
        <Text variant="body_small" color="weak">
          {t.whatsapp.messageTemplates.fields.mediaType()}
        </Text>
        <Box mb="small" />
        <VisualPickerWrapper className="media_type_selector">
          <MediaRadio
            value="image"
            labels={{
              label:
                t.whatsapp.messageTemplates.templateMediaTypeLabels.IMAGE(),
            }}
            checked={selectedMediaType === WhatsAppMtMediaType.IMAGE}
            onChange={handleImageChanged}
            onRenderVisualPicker={() => <PhotoIcon />}
            id="image-input"
          />
          {sfGa ? (
            <></>
          ) : (
            <MediaRadio
              value="video"
              labels={{
                label:
                  t.whatsapp.messageTemplates.templateMediaTypeLabels.VIDEO(),
              }}
              checked={selectedMediaType === WhatsAppMtMediaType.VIDEO}
              onChange={handleVideoChanged}
              onRenderVisualPicker={() => <VideoIcon />}
              id="video-input"
            />
          )}
          {sfGa ? (
            <></>
          ) : (
            <MediaRadio
              value="document"
              labels={{
                label:
                  t.whatsapp.messageTemplates.templateMediaTypeLabels.DOCUMENT(),
              }}
              checked={selectedMediaType === WhatsAppMtMediaType.DOCUMENT}
              onChange={handleDocumentChanged}
              onRenderVisualPicker={() => <FileIcon />}
              id="document-input"
            />
          )}
        </VisualPickerWrapper>
      </GridItem>
    </Grid>
  );
};
