import React, { useContext, useState } from 'react';
import { FormikErrors } from 'formik';
import { union, uniq } from 'lodash-es';

import { t } from '@src/messages';
import {
  Card,
  Grid,
  GridItem,
  StickyGridItem,
  ProgressCard,
} from '@src/common/components';
import { useFeatureFlags } from '@src/common/utils/hooks';
import {
  IWhatsAppMtOrMtCr,
  WhatsAppMtLanguage,
  IWhatsAppMtTemplateContent,
  CrState,
  WhatsAppMtStatus,
} from '@shared/bff';
import { WindowSizeContext } from '@src/common/components/WindowSizeProvider';

import {
  EditAuthenticationContentPayload,
  EditContentPayload,
  EditMode,
  EditTemplateValues,
} from '../../types';
import {
  getSelectedLanguage,
  prepareDataForTemplatePreviewer,
} from '../../utils';
import { MessageContent } from '../form/MessageContent';
import { Samples } from '../form/Samples';
import { LanguageSelectionTabs } from '../LanguageSelectionTabs';
import { prepareStepsForProgressIndicator } from '../constants';
import { TemplatePreviewCard } from '../TemplatePreviewCard';
import { AuthenticationMessageContent } from '../form/AuthenticationMessageContent';

import { Configuration } from './Configuration';
import { ExistingContent } from './ExistingContent';
import { ExistingSamples } from './ExistingSamples';
import { ExistingAuthenticationContent } from './ExistingAuthenticationContent';

interface IEditFormProps {
  editMode: EditMode;
  existingValues: IWhatsAppMtOrMtCr;
  allExistingContents: EditContentPayload[];
  allExistingAuthenticationContents: EditAuthenticationContentPayload[];
  values: EditTemplateValues;
  aspClientName: string;
  errors: FormikErrors<EditTemplateValues>;
  submitCount: number;
}

export const EditForm = ({
  editMode,
  existingValues,
  allExistingContents,
  allExistingAuthenticationContents,
  values,
  aspClientName,
  errors,
  submitCount,
}: IEditFormProps) => {
  const { whatsAppSamples } = useFeatureFlags();

  const { name, category, contents: existingContents } = existingValues;
  const { languages, contents, authenticationContents } = values;

  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [previewSamples, setPreviewSamples] = useState<boolean>(true);
  const { isLargeWindow } = useContext(WindowSizeContext);

  const existingLanguages: WhatsAppMtLanguage[] = (
    existingContents as Array<IWhatsAppMtTemplateContent>
  ).map(({ language }: IWhatsAppMtTemplateContent) => language);

  const allLanguages: WhatsAppMtLanguage[] =
    existingLanguages.concat(languages);
  const allContent = contents
    ? uniq(
        union(
          allExistingContents.map((content) => {
            const editableContent = contents.find(
              (c) => c.language === content.language
            );
            return editableContent ?? content;
          }),
          contents
        )
      )
    : undefined;
  const allAuthenticationContent = authenticationContents
    ? uniq(
        union(
          allExistingAuthenticationContents.map((content) => {
            const editableContent = authenticationContents.find(
              (c) => c.language === content.language
            );
            return editableContent ?? content;
          }),
          authenticationContents
        )
      )
    : undefined;

  let selectedTabIndex: number = allLanguages.findIndex(
    (language) => language === selectedLanguage
  );

  if (selectedTabIndex === -1) {
    selectedTabIndex = 0;
    setSelectedLanguage(allLanguages[0]);
  }

  const selectedMtLanguage: WhatsAppMtLanguage = allLanguages[selectedTabIndex];
  const selectedContent = allContent && allContent[selectedTabIndex];
  const selectedAuthenticationContent =
    allAuthenticationContent && allAuthenticationContent[selectedTabIndex];
  const selectedItem = selectedContent || selectedAuthenticationContent;

  const isInDraft =
    selectedItem &&
    'crStatus' in selectedItem &&
    selectedItem.crStatus === CrState.DRAFT;
  const isRejected =
    (selectedItem &&
      'crStatus' in selectedItem &&
      selectedItem.crStatus === CrState.REJECTED) ||
    (selectedItem &&
      'templateStatus' in selectedItem &&
      selectedItem.templateStatus === WhatsAppMtStatus.REJECTED);
  const isNewLanguage = !existingLanguages.includes(selectedMtLanguage);
  const isEditableContent =
    (editMode === 'add' && isNewLanguage) ||
    (editMode === 'edit' && (isNewLanguage || isInDraft || isRejected));
  const editableContentIndex = contents
    ? contents.findIndex((c) => c.language === selectedMtLanguage)
    : authenticationContents
    ? authenticationContents.findIndex((c) => c.language === selectedMtLanguage)
    : 0;

  const handleLanguageAdded = (addedLanguage: WhatsAppMtLanguage) => {
    setSelectedLanguage(addedLanguage);
  };

  const handleLanguageRemoved = (removedLanguage: WhatsAppMtLanguage) => {
    const language = getSelectedLanguage(
      languages,
      removedLanguage,
      selectedTabIndex
    );
    setSelectedLanguage(language);
  };

  return (
    <Grid>
      <GridItem pb="small">
        <Card
          heading={t.whatsapp.messageTemplates.register.sections.messageTemplateConfiguration()}
        >
          <Configuration
            aspClientName={aspClientName}
            existingValues={existingValues}
          />
        </Card>
      </GridItem>
      <GridItem cols={isLargeWindow ? 8 : 7} pr="small">
        <Grid stretch={true}>
          {isLargeWindow && (
            <GridItem cols={5} pr="small">
              <Card
                heading={t.whatsapp.messageTemplates.fields.languages()}
                fullHeight={true}
              >
                <LanguageSelectionTabs
                  selectedTabId={selectedLanguage}
                  handleTabChanged={setSelectedLanguage}
                  category={category}
                  languages={allLanguages}
                  existingLanguages={existingLanguages}
                  contents={existingContents}
                  editable={true}
                  editableContent={contents}
                  errors={errors}
                  submitCount={submitCount}
                  handleLanguageRemoved={handleLanguageRemoved}
                  handleLanguageAdded={handleLanguageAdded}
                />
              </Card>
            </GridItem>
          )}
          <GridItem cols={isLargeWindow ? 7 : 12}>
            {!isLargeWindow && (
              <Card heading={t.whatsapp.messageTemplates.fields.languages()}>
                <LanguageSelectionTabs
                  selectedTabId={selectedLanguage}
                  handleTabChanged={setSelectedLanguage}
                  category={category}
                  languages={allLanguages}
                  existingLanguages={existingLanguages}
                  contents={existingContents}
                  editable={true}
                  editableContent={contents}
                  errors={errors}
                  submitCount={submitCount}
                  handleLanguageRemoved={handleLanguageRemoved}
                  handleLanguageAdded={handleLanguageAdded}
                />
              </Card>
            )}
            {selectedItem &&
              'crStatus' in selectedItem &&
              selectedItem.crStatus &&
              selectedItem.crStatus !== CrState.REJECTED && (
                <ProgressCard
                  heading={t.whatsapp.messageTemplates.approvalProgress.title()}
                  description={t.whatsapp.messageTemplates.approvalProgress.subtitle()}
                  steps={prepareStepsForProgressIndicator()}
                  currentStepId={selectedItem.crStatus}
                />
              )}
            <Card hasNoHeader>
              {selectedContent && !isEditableContent && (
                <ExistingContent content={selectedContent} />
              )}
              {selectedContent && isEditableContent && (
                <MessageContent
                  selectedTabIndex={editableContentIndex}
                  content={selectedContent}
                />
              )}
              {selectedAuthenticationContent && !isEditableContent && (
                <ExistingAuthenticationContent
                  content={selectedAuthenticationContent}
                />
              )}
              {selectedAuthenticationContent && isEditableContent && (
                <AuthenticationMessageContent
                  selectedTabIndex={editableContentIndex}
                  content={selectedAuthenticationContent}
                />
              )}
            </Card>
            {selectedContent && whatsAppSamples && selectedMtLanguage && (
              <Card
                heading={`${t.whatsapp.messageTemplates.templateLanguageLabels[
                  selectedMtLanguage
                ]()} ${t.whatsapp.messageTemplates.register.sections.samples()}`}
              >
                {selectedContent && !isEditableContent && (
                  <ExistingSamples content={selectedContent} />
                )}
                {selectedContent && isEditableContent && (
                  <Samples
                    selectedTabIndex={editableContentIndex}
                    content={selectedContent}
                  />
                )}
              </Card>
            )}
          </GridItem>
        </Grid>
      </GridItem>
      <StickyGridItem cols={isLargeWindow ? 4 : 5}>
        <TemplatePreviewCard
          template={prepareDataForTemplatePreviewer({
            name,
            category,
            language: selectedContent
              ? selectedContent.language
              : selectedAuthenticationContent?.language,
            content: selectedContent,
            authenticationContent: selectedAuthenticationContent,
            previewSamples: previewSamples,
          })}
          onToggleSamples={setPreviewSamples}
          samplesChecked={previewSamples}
        ></TemplatePreviewCard>
      </StickyGridItem>
    </Grid>
  );
};
