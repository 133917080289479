import React from 'react';

import { ChannelsList } from '@src/common/components';
import { Channel } from '@src/common/types';

export const SenderListView = () => {
  return (
    <ChannelsList
      channelType={Channel.KakaoTalk}
      pendingChannels={[]}
      onboardedChannels={[]}
    />
  );
};
