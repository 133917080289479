import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash-es';

import {
  EmptySection,
  DataTable,
  IColumn,
  CellType,
  Box,
  LabelTag,
  SortDirection,
  IDataTableSorting,
} from '@src/common/components';
import { t } from '@src/messages';
import { templateDetailsPath, sortTableItems } from '@src/common/utils';
import { Channel } from '@src/common/types';
import {
  IWhatsAppMtCr,
  WhatsAppMtCategory,
  IWhatsAppMtCrContent,
} from '@shared/bff';

import { crLabelType } from './constants';

interface IPendingMessageTemplatesProps {
  items: IWhatsAppMtCr[];
  id: string;
}

interface ITableItem extends IWhatsAppMtCr {
  id: string;
}

const LanguageContainer = styled(Box)({
  maxWidth: '100%',
});

const LanguageWrapper = styled.span({
  margin: '4px 0',
  minWidth: '190px',
});

export const PendingMessageTemplates = ({
  items,
  id,
}: IPendingMessageTemplatesProps) => {
  const defaultSorting = {
    property: 'name',
    sortDirection: SortDirection.ASCENDING,
  };
  const [sorting, setSorting] = useState<IDataTableSorting>(defaultSorting);
  const [sortedItems, setSortedItems] = useState<ITableItem[]>(
    sortTableItems(sorting, items).map((item) => ({
      // SF table requires an ID on an item, but our templates are actually
      // templates grouped together to look like 1 item. To solve this we
      // create an ID based on other properties that should make it unique.
      id: `${item.aspClientId}_${item.name}`,
      ...item,
    }))
  );

  const columns = useMemo<IColumn[]>(
    () => [
      {
        label: t.whatsapp.messageTemplates.fields.templateName(),
        property: 'name',
        width: '20%',
        type: CellType.Link,
        getPath: ({ aspClientId, name }) =>
          templateDetailsPath(Channel.Whatsapp, aspClientId, name),
        sortable: true,
        isSorted: sorting.property === 'name',
        sortDirection:
          sorting.property === 'name' ? sorting.sortDirection : undefined,
        columnId: `${id}-template-name`,
        truncate: true,
      },
      {
        label: t.whatsapp.messageTemplates.fields.templateCategory(),
        property: 'category',
        width: '20%',
        type: CellType.Formatted,
        formatTitle: (category: WhatsAppMtCategory) =>
          t.whatsapp.messageTemplates.templateCategoryLabels[category](),
        format: (category: WhatsAppMtCategory) =>
          t.whatsapp.messageTemplates.templateCategoryLabels[category](),
        sortable: true,
        isSorted: sorting.property === 'category',
        sortDirection:
          sorting.property === 'category' ? sorting.sortDirection : undefined,
        columnId: `${id}-template-category`,
      },
      {
        label: t.whatsapp.messageTemplates.fields.languages(),
        property: 'contents',
        width: '60%',
        type: CellType.Languages,
        columnId: `${id}-template-languages`,
        formatTitle: (contents: IWhatsAppMtCrContent[]) =>
          contents
            .map(
              ({ language, crStatus }) =>
                `${t.whatsapp.messageTemplates.templateLanguageLabels[
                  language
                ]()} ${t.common.changeRequestStateLabels[crStatus]()}`
            )
            .join(', '),
        format: (contents: IWhatsAppMtCrContent[]) => (
          <LanguageContainer display="flex" flexWrap="wrap">
            {sortBy(contents, [
              (c) =>
                t.whatsapp.messageTemplates.templateLanguageLabels[
                  c.language
                ](),
            ]).map(({ language, crStatus }: IWhatsAppMtCrContent) => (
              <LanguageWrapper key={language}>
                {t.whatsapp.messageTemplates.templateLanguageLabels[language]()}
                <Box mr="xx-small" display="inline" />
                <LabelTag type={crLabelType[crStatus]}>
                  {t.common.changeRequestStateLabels[crStatus]()}
                </LabelTag>
                <Box mr="medium" display="inline" />
              </LanguageWrapper>
            ))}
          </LanguageContainer>
        ),
      },
    ],
    [sorting]
  );

  return (
    <DataTable
      items={sortedItems}
      columns={columns}
      noDataComponent={
        <EmptySection
          type="no-templates"
          title={t.whatsapp.messageTemplates.list.noPendingMessageTemplates.title()}
          subtitle={t.whatsapp.messageTemplates.list.noPendingMessageTemplates.description()}
        />
      }
      handleSort={(sort: IDataTableSorting) => {
        setSorting(sort);
        setSortedItems(sortTableItems(sort, sortedItems));
      }}
      id={id}
    />
  );
};
