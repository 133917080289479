import { gql } from '@apollo/client';

import { IWhatsAppCr } from '@shared/bff';

export type IBotListItemCrFragment = Pick<
  IWhatsAppCr,
  'id' | 'changes' | 'state' | 'created' | 'updated'
>;

export const BOT_LIST_ITEM_CR_FRAGMENT = gql`
  fragment BotListItemCrFragment on WhatsAppCr {
    id
    changes
    state
    created
    updated
  }
`;
