import { gql } from '@apollo/client';

import { IViberCr } from '@shared/bff';

export type ICreateCrMutationResponse = {
  viberCreateCr: Pick<IViberCr, 'id'>;
};

export const CREATE_CR_MUTATION = gql`
  mutation ViberCreateCr($viberCr: ViberCrInput!) {
    viberCreateCr(viberCr: $viberCr) {
      id
    }
  }
`;
