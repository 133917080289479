import { gql } from '@apollo/client';

import { IViberAspClientsResponse } from '@shared/bff';

import { ICrFragment, VIBER_CR_FRAGMENT } from '../../fragments';

export interface ICrQueryResponse {
  viberCr: ICrFragment;
  viberAspClients: Pick<IViberAspClientsResponse, 'onboarded'>;
}

export const CR_QUERY = gql`
  query ViberCr($senderCrId: String!) {
    viberCr(senderCrId: $senderCrId) {
      ...CrFragment
    }
    viberAspClients {
      onboarded {
        id
        name
        businessLegalName
        businessCategories
        businessRegisteredCountry
      }
    }
  }
  ${VIBER_CR_FRAGMENT}
`;
