import { gql } from '@apollo/client';

import { IWhatsAppMtOrMtCr } from '@shared/bff';

export interface ITransitionToReviewMutationResponse {
  WhatsAppMtOrMtCr: IWhatsAppMtOrMtCr;
}

export const TRANSITION_TO_REVIEW_MUTATION = gql`
  mutation WhatsAppTransitionMessageTemplateCrFromDraftToReview(
    $transitionInput: TransitionCrInput!
  ) {
    whatsAppTransitionMessageTemplateCrFromDraftToReview(
      transitionInput: $transitionInput
    ) {
      ... on WhatsAppMt {
        aspClientId
        name
      }
      ... on WhatsAppMtCr {
        aspClientId
        name
      }
    }
  }
`;
