import * as Yup from 'yup';

import { NumberProviderType } from '@shared/bff';

import { FIELD_NUMBER_PROVIDER_TYPE } from '../../components/form/PhoneInput';

import {
  getEmailSchema,
  getNameSchema,
  getOptionalPhoneNumberSchema,
  getVerticalSchema,
  getWebsiteSchema,
} from './botValidationSchemas';

const getGeneralFieldRequirements = () => {
  return {
    aspClientId: Yup.string().required(),
    name: getNameSchema().required(),
    vertical: getVerticalSchema().required(),
    email: getEmailSchema(),
    websiteOne: getWebsiteSchema(),
    websiteTwo: getWebsiteSchema(),
    phone: Yup.string().when(FIELD_NUMBER_PROVIDER_TYPE, {
      is: NumberProviderType.CUSTOMER,
      then: getOptionalPhoneNumberSchema(),
    }),
  };
};

export const getDraftValidationSchema = () =>
  Yup.object().shape({
    ...getGeneralFieldRequirements(),
  });
