import { gql } from '@apollo/client';

import { IViberCr } from '@shared/bff';

export type ITransitionSenderCrFromDraftToReviewMutationResponse = {
  viberTransitionSenderCrFromDraftToReview: Pick<IViberCr, 'id'>;
};

export const TRANSITION_SENDER_CR_FROM_DRAFT_TO_REVIEW_MUTATION = gql`
  mutation TransitionSenderCrFromDraftToReview($crId: String!) {
    viberTransitionSenderCrFromDraftToReview(crId: $crId) {
      id
    }
  }
`;
