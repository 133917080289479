import { WhatsAppMtLanguage } from '@shared/bff';

export const mapMessageTemplateLanguageToBodySecurityText: Record<
  WhatsAppMtLanguage,
  string
> = {
  [WhatsAppMtLanguage.AF]:
    'Moenie hierdie kode deel nie, vir jou eie sekuriteit.',
  [WhatsAppMtLanguage.SQ]: 'Për sigurinë tënde, mos e ndaj këtë kod.',
  [WhatsAppMtLanguage.AR]: 'للحفاظ على أمانك، تجنب مشاركة هذا الرمز',
  [WhatsAppMtLanguage.AZ]: '',
  [WhatsAppMtLanguage.BN]: 'আপনার নিরাপত্তার কারণেই এই কোডটা শেয়ার করবেন না।',
  [WhatsAppMtLanguage.BG]: 'С оглед на сигурността ви не споделяйте този код.',
  [WhatsAppMtLanguage.CA]: '',
  [WhatsAppMtLanguage.ZH_CN]: '为安全起见，请不要分享这组验证码。',
  [WhatsAppMtLanguage.ZH_HK]: '為了安全起見，請勿分享此驗證碼。',
  [WhatsAppMtLanguage.ZH_TW]: '為了安全起見，請勿分享此驗證碼。',
  [WhatsAppMtLanguage.HR]: 'Nemojte dijeliti kôd radi svoje sigurnosti.',
  [WhatsAppMtLanguage.CS]:
    'Z bezpečnostních důvodů tenhle kód s nikým nesdílejte.',
  [WhatsAppMtLanguage.DA]:
    'Af hensyn til din sikkerhed må du ikke dele denne kode.',
  [WhatsAppMtLanguage.NL]: 'Deel deze code om veiligheidsredenen niet.',
  [WhatsAppMtLanguage.EN]: 'For your security, do not share this code.',
  [WhatsAppMtLanguage.EN_GB]: 'For your security, do not share this code.',
  [WhatsAppMtLanguage.EN_US]: 'For your security, do not share this code.',
  [WhatsAppMtLanguage.ET]: 'Turvalisuse huvides ära jaga seda koodi.',
  [WhatsAppMtLanguage.FIL]:
    'Para sa iyong seguridad, huwag i-share ang code na ito.',
  [WhatsAppMtLanguage.FI]: 'Pidä koodi omana tietonasi turvallisuussyistä.',
  [WhatsAppMtLanguage.FR]: 'Pour votre sécurité, ne le partagez pas.',
  [WhatsAppMtLanguage.DE]:
    'Aus Sicherheitsgründen solltest du den Code nicht teilen.',
  [WhatsAppMtLanguage.EL]:
    'Για τη δική σας ασφάλεια, μην τον κοινοποιήσετε σε τρίτους.',
  [WhatsAppMtLanguage.GU]:
    'તમારી સુરક્ષા માટે, આ કોડ કોઈની સાથે શેર કરશો નહીં.',
  [WhatsAppMtLanguage.HA]: '',
  [WhatsAppMtLanguage.HE]: ' מטעמי אבטחה, אין לשתף את הקוד הז',
  [WhatsAppMtLanguage.HI]:
    'अपनी सुरक्षा के लिए, इस कोड को किसी के साथ शेयर न करें.',
  [WhatsAppMtLanguage.HU]:
    'A saját biztonságod érdekében mással ne oszd meg ezt a kódot.',
  [WhatsAppMtLanguage.ID]: 'Demi keamanan, jangan bagikan kode ini.',
  [WhatsAppMtLanguage.GA]: 'For your security, do not share this code.',
  [WhatsAppMtLanguage.IT]:
    'Per garantire la tua sicurezza, ti consigliamo di non condividere questo codice.',
  [WhatsAppMtLanguage.JA]:
    'セキュリティのため、このコードは他の人に共有しないでください。',
  [WhatsAppMtLanguage.KN]: 'ನಿಮ್ಮ ಭದ್ರತೆಗಾಗಿ, ಈ ಕೋಡ್ ಅನ್ನು ಹಂಚಿಕೊಳ್ಳಬೇಡಿ.',
  [WhatsAppMtLanguage.KK]: '',
  [WhatsAppMtLanguage.KO]: '보안을 위해 이 코드는 공유하지 마세요.',
  [WhatsAppMtLanguage.LO]: '',
  [WhatsAppMtLanguage.LV]: 'Jūsu drošībai nekopīgojiet šo kodu.',
  [WhatsAppMtLanguage.LT]: 'Saugos sumetimais nebendrinkite šio kodo.',
  [WhatsAppMtLanguage.MK]: 'За твоја безбедност, не го споделувај овој код.',
  [WhatsAppMtLanguage.MS]: 'Untuk keselamatan anda, jangan kongsikan kod ini.',
  [WhatsAppMtLanguage.ML]: 'നിങ്ങളുടെ സുരക്ഷയ്‌ക്ക്, ഈ കോഡ് പങ്കിടരുത്.',
  [WhatsAppMtLanguage.MR]: 'तुमच्या सुरक्षेसाठी, हा कोड शेअर करू नका.',
  [WhatsAppMtLanguage.NB]:
    'Av hensyn til sikkerheten din må du ikke dele denne koden.',
  [WhatsAppMtLanguage.FA]: '',
  [WhatsAppMtLanguage.PL]:
    'Ze względów bezpieczeństwa nie udostępniaj tego kodu.',
  [WhatsAppMtLanguage.PT_BR]: 'Para sua segurança, não o compartilhe.',
  [WhatsAppMtLanguage.PT_PT]: 'Para tua segurança, não partilhes este código.',
  [WhatsAppMtLanguage.PA]: 'ਆਪਣੀ ਸੁਰੱਖਿਆ ਲਈ, ਇਹ ਕੋਡ ਸਾਂਝਾ ਨਾ ਕਰੋ।',
  [WhatsAppMtLanguage.RO]: 'Pentru siguranţa ta, nu dezvălui acest cod.',
  [WhatsAppMtLanguage.RU]:
    'Из соображений безопасности не сообщайте никому этот код.',
  [WhatsAppMtLanguage.SR]:
    'Ради сопствене безбедности, немојте га делити ни са ким.',
  [WhatsAppMtLanguage.SK]: 'V záujme bezpečnosti tento kód nezdieľajte.',
  [WhatsAppMtLanguage.SL]: 'Zaradi varnosti ne deli te kode.',
  [WhatsAppMtLanguage.ES]: 'Por tu seguridad, no lo compartas.',
  [WhatsAppMtLanguage.ES_AR]: 'Por tu seguridad, no lo compartas.',
  [WhatsAppMtLanguage.ES_ES]: 'Por tu seguridad, no lo compartas.',
  [WhatsAppMtLanguage.ES_MX]: 'Por tu seguridad, no lo compartas.',
  [WhatsAppMtLanguage.SW]: 'Kwa usalama wako, usimpe mwingine msimbo huu.',
  [WhatsAppMtLanguage.SV]:
    'Av säkerhetsskäl ska du inte dela koden med någon annan.',
  [WhatsAppMtLanguage.TA]:
    'பாதுகாப்பிற்காக, இந்தக் குறியீட்டைப் பகிர வேண்டாம்.',
  [WhatsAppMtLanguage.TE]: 'మీ భద్రత కోసం ఈ కోడ్‌ను షేర్ చేయవద్దు.',
  [WhatsAppMtLanguage.TH]: 'โปรดอย่าบอกรหัสนี้แก่ผู้อื่นเพื่อความปลอดภัยของคุณ',
  [WhatsAppMtLanguage.TR]: 'Güvenliğin için bu kodu paylaşma.',
  [WhatsAppMtLanguage.UK]:
    'З міркувань безпеки не повідомляйте нікому цей код.',
  [WhatsAppMtLanguage.UR]: 'اپنی حفاظت کی خاطر یہ کوڈ شیئر نہ کریں۔',
  [WhatsAppMtLanguage.UZ]: '',
  [WhatsAppMtLanguage.VI]: 'Để bảo mật, bạn đừng chia sẻ mã này.',
  [WhatsAppMtLanguage.ZU]: '',
  [WhatsAppMtLanguage.KA]:
    'თქვენივე უსაფრთხოებისთვის, არავის გაუზიაროთ ეს კოდი.',
  [WhatsAppMtLanguage.RW_RW]: '',
  [WhatsAppMtLanguage.KY_KG]: '',
  [WhatsAppMtLanguage.NO_LANGUAGE]: '',
};
