import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash-es';

import {
  EmptySection,
  DataTable,
  IColumn,
  CellType,
  LabelTag,
  Box,
  IDataTableSorting,
  SortDirection,
} from '@src/common/components';
import { Channel } from '@src/common/types';
import { templateDetailsPath, sortTableItems } from '@src/common/utils';
import { t } from '@src/messages';
import {
  IWhatsAppMtOrMtCr,
  WhatsAppMtCategory,
  IWhatsAppMtTemplateContent,
} from '@shared/bff';

import { templateLabelType, crLabelType } from './constants';

interface IApprovedMessageTemplatesProps {
  items: IWhatsAppMtOrMtCr[];
  id: string;
}

type ITableItem = IWhatsAppMtOrMtCr & {
  id: string;
};

const LanguageContainer = styled(Box)({
  maxWidth: '100%',
});

const LanguageWrapper = styled.span({
  margin: '4px 0',
  minWidth: '190px',
});

export const ApprovedMessageTemplates = ({
  items,
  id,
}: IApprovedMessageTemplatesProps) => {
  const defaultSorting = {
    property: 'name',
    sortDirection: SortDirection.ASCENDING,
  };
  const [sorting, setSorting] = useState<IDataTableSorting>(defaultSorting);
  const [sortedItems, setSortedItems] = useState<ITableItem[]>(
    sortTableItems(sorting, items).map((item) => ({
      // SF table requires an ID on an item, but our templates are actually
      // templates grouped together to look like 1 item. To solve this we
      // create an ID based on other properties that should make it unique.
      id: `${item.aspClientId}_${item.name}`,
      ...item,
    }))
  );

  const columns = useMemo<IColumn[]>(
    () => [
      {
        label: t.whatsapp.messageTemplates.fields.templateName(),
        property: 'name',
        width: '20%',
        type: CellType.Link,
        getPath: ({ aspClientId, name }) =>
          templateDetailsPath(Channel.Whatsapp, aspClientId, name),
        sortable: true,
        isSorted: sorting.property === 'name',
        sortDirection:
          sorting.property === 'name' ? sorting.sortDirection : undefined,
        columnId: `${id}-template-name`,
        truncate: true,
      },
      {
        label: t.whatsapp.messageTemplates.fields.templateCategory(),
        property: 'category',
        width: '20%',
        type: CellType.Formatted,
        formatTitle: (category: WhatsAppMtCategory) =>
          t.whatsapp.messageTemplates.templateCategoryLabels[category](),
        format: (category: WhatsAppMtCategory) =>
          t.whatsapp.messageTemplates.templateCategoryLabels[category](),
        sortable: true,
        isSorted: sorting.property === 'category',
        sortDirection:
          sorting.property === 'category' ? sorting.sortDirection : undefined,
        columnId: `${id}-template-category`,
      },
      {
        label: t.whatsapp.messageTemplates.fields.languages(),
        property: 'contents',
        width: '60%',
        type: CellType.Languages,
        columnId: `${id}-template-languages`,
        formatTitle: (contents: IWhatsAppMtTemplateContent[]) =>
          contents
            .map((content) => {
              const language =
                t.whatsapp.messageTemplates.templateLanguageLabels[
                  content.language
                ]();
              const status =
                'templateStatus' in content
                  ? t.whatsapp.messageTemplates.templateStates[
                      content.templateStatus
                    ]()
                  : t.common.changeRequestStateLabels[content.crStatus]();

              return `${language} ${status}`;
            })
            .join(', '),
        format: (contents: IWhatsAppMtTemplateContent[]) => (
          <LanguageContainer display="flex" flexWrap="wrap">
            {sortBy(contents, [
              (c) =>
                t.whatsapp.messageTemplates.templateLanguageLabels[
                  c.language
                ](),
            ]).map((content) => {
              const language =
                t.whatsapp.messageTemplates.templateLanguageLabels[
                  content.language
                ]();
              const labelTag =
                'templateStatus' in content ? (
                  <LabelTag type={templateLabelType[content.templateStatus]}>
                    {t.whatsapp.messageTemplates.templateStates[
                      content.templateStatus
                    ]()}
                  </LabelTag>
                ) : (
                  <LabelTag type={crLabelType[content.crStatus]}>
                    {t.common.changeRequestStateLabels[content.crStatus]()}
                  </LabelTag>
                );

              return (
                <LanguageWrapper key={language}>
                  {language}
                  <Box mr="xx-small" display="inline" />
                  {labelTag}
                  <Box mr="medium" display="inline" />
                </LanguageWrapper>
              );
            })}
          </LanguageContainer>
        ),
      },
    ],
    [sorting]
  );

  return (
    <DataTable
      items={sortedItems}
      columns={columns}
      noDataComponent={
        <EmptySection
          type="no-templates"
          title={t.whatsapp.messageTemplates.list.noApprovedMessageTemplates.title()}
          subtitle={t.whatsapp.messageTemplates.list.noApprovedMessageTemplates.description()}
        />
      }
      handleSort={(sort: IDataTableSorting) => {
        setSorting(sort);
        setSortedItems(sortTableItems(sort, sortedItems));
      }}
      id={id}
    />
  );
};
