import React, { ReactNode } from 'react';
import styled from 'styled-components';

import {
  Box,
  Grid,
  GridItem,
  Image,
  Label,
  Text,
  UploadButton,
} from '@src/common/components';
import { t } from '@src/messages';
import { WhatsAppMtMediaType, IWhatsAppMtMediaVariable } from '@shared/bff';

import { ExistingFileSample } from './ExistingFileSample';

interface IExistingMediaVariableProps {
  mediaType: WhatsAppMtMediaType;
  mediaVariable: IWhatsAppMtMediaVariable;
}

const BoxWrapper = styled(Box)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const ExistingMediaVariable = ({
  mediaType,
  mediaVariable,
}: IExistingMediaVariableProps) => {
  const MediaSampleMapper: Record<WhatsAppMtMediaType, ReactNode | null> = {
    [WhatsAppMtMediaType.IMAGE]: (
      <Box mb="small">
        <Label
          id="header-media-sample-image-tooltip"
          tooltip={t.whatsapp.messageTemplates.tooltips.headerMediaSampleTooltip()}
          required
        >
          <Text color="weak">
            {t.whatsapp.messageTemplates.fields.headerMediaSample()}
          </Text>
        </Label>
        <Box mt="small" />
        <Grid>
          <GridItem cols={8}>
            <BoxWrapper pl="x-small" display="flex" alignItems="center">
              <Image src={mediaVariable.url} width="100px" height="100px" />
              <Box display="inline" ml="medium" />
              <Box display="inline-block">
                <div id="image-resolver">
                  <UploadButton
                    title={t.common.components.imageResolver.uploadButtonDescription()}
                    disabled
                    id="upload-image-button"
                  />
                </div>
              </Box>
            </BoxWrapper>
          </GridItem>
        </Grid>
      </Box>
    ),
    [WhatsAppMtMediaType.VIDEO]: (
      <ExistingFileSample mediaVariable={mediaVariable} />
    ),
    [WhatsAppMtMediaType.DOCUMENT]: (
      <ExistingFileSample mediaVariable={mediaVariable} />
    ),
    [WhatsAppMtMediaType.NONE]: null,
  };
  return <>{MediaSampleMapper[mediaType]}</>;
};
