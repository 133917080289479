import React from 'react';
import { IComboboxOption } from '@salesforce/design-system-react';

import {
  InputField,
  TextareaField,
  GridItem,
  Card,
  AspClientSelect,
  QueryHandler,
  IQueryHandlerResult,
} from '@src/common/components';
import { t } from '@src/messages';
import {
  ASP_CLIENTS_QUERY,
  IAspClientsQueryResponse,
} from '@src/modules/whatsapp/aspClients/graphql';
import { IWhatsAppAspClient, IWhatsAppBotInput } from '@shared/bff';
import { useFeatureFlags } from '@src/common/utils/hooks';

import { PhoneInput } from '../PhoneInput';
import { botFieldsMaxLength } from '../../../utils';

interface IConfigurationProps {
  isEditing: boolean;
  disablePhoneNumber: boolean;
}

const mapOnboardedToCombobox = (
  onboarded: IWhatsAppAspClient[]
): IComboboxOption[] => {
  return onboarded.map((account): IComboboxOption => {
    return { label: account.name, value: account.id };
  });
};

export const Configuration = ({
  isEditing,
  disablePhoneNumber,
}: IConfigurationProps) => {
  const { whatsAppChannelMakeCompanyDetailsOptional } = useFeatureFlags();

  return (
    <Card heading={t.common.channelConfiguration()}>
      <GridItem cols={8} pl="small" pr="small">
        <QueryHandler query={ASP_CLIENTS_QUERY} levitate>
          {({
            data: {
              whatsAppAspClients: { onboarded },
            },
          }: IQueryHandlerResult<IAspClientsQueryResponse>) => (
            <GridItem mb="small">
              <AspClientSelect
                type="channels"
                options={mapOnboardedToCombobox(onboarded)}
                singleInputDisabled={isEditing}
              />
            </GridItem>
          )}
        </QueryHandler>
        <GridItem mb="small">
          <InputField<IWhatsAppBotInput>
            id="channel-name-input"
            name="name"
            label={t.whatsapp.fieldLabels.channelName()}
            tooltip={t.whatsapp.tooltips.channelName()}
            maxLength={`${botFieldsMaxLength.name}`}
            required
          />
        </GridItem>
        <GridItem mb="small">
          <TextareaField<IWhatsAppBotInput>
            id="channel-description-input"
            name="description"
            label={t.whatsapp.fieldLabels.channelDescription()}
            maxLength={`${botFieldsMaxLength.description}`}
            tooltip={t.whatsapp.tooltips.channelDescription()}
            required={!whatsAppChannelMakeCompanyDetailsOptional}
          />
        </GridItem>
        <GridItem mb="small">
          <PhoneInput disabled={disablePhoneNumber} />
        </GridItem>
      </GridItem>
    </Card>
  );
};
