import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';

import {
  InputField,
  Grid,
  GridItem,
  Box,
  RadioGroup,
  Radio,
  CountryCodeSelector,
  ScopedNotification,
  SFIcon,
} from '@src/common/components';
import { t } from '@src/messages';
import { subscriberPhoneNumberMaxLength } from '@src/common/utils';
import { IWhatsAppBotInput, NumberProviderType } from '@shared/bff';
import { useFeatureFlags } from '@src/common/utils/hooks';

interface IPhoneInput {
  disabled?: boolean;
}

export const FIELD_NUMBER_PROVIDER_TYPE = 'numberProviderType';
const FIELD_COUNTRY_CODE = 'countryCode';
const FIELD_PHONE = 'phone';

export const PhoneInput = ({ disabled }: IPhoneInput) => {
  const [numberProviderTypeField] = useField(FIELD_NUMBER_PROVIDER_TYPE);
  const { setFieldValue } = useFormikContext();
  const { disabledCountryCodes } = useFeatureFlags();

  const showPhoneNumberField =
    numberProviderTypeField.value === NumberProviderType.CUSTOMER || disabled;
  const sinchProvidedNumber =
    numberProviderTypeField.value === NumberProviderType.SINCH;

  useEffect(() => {
    if (sinchProvidedNumber && !disabled) {
      setFieldValue(FIELD_PHONE, '');
    }
  }, [numberProviderTypeField.value]);

  return (
    <>
      <RadioGroup
        disabled={disabled}
        labels={{
          label: t.whatsapp.fieldLabels.whatsappPhoneNumber(),
        }}
        tooltip={t.whatsapp.tooltips.phoneNumberType()}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(FIELD_NUMBER_PROVIDER_TYPE, event.target.value);
        }}
      >
        <Radio
          key={NumberProviderType.SINCH.toString()}
          checked={sinchProvidedNumber}
          labels={{ label: t.whatsapp.botForm.phone.sinch() }}
          value={NumberProviderType.SINCH}
          id="phone-provider-sinch"
        />
        <div />
        <Radio
          key={NumberProviderType.CUSTOMER.toString()}
          labels={{ label: t.whatsapp.botForm.phone.personal() }}
          checked={
            numberProviderTypeField.value === NumberProviderType.CUSTOMER
          }
          value={NumberProviderType.CUSTOMER}
          id="phone-provider-customer"
        />
      </RadioGroup>
      <Grid>
        <GridItem cols={showPhoneNumberField ? 4 : 12}>
          <Box pr="medium" pl="medium">
            <CountryCodeSelector<IWhatsAppBotInput>
              id="country-code-input"
              name={FIELD_COUNTRY_CODE}
              labels={{
                label: t.whatsapp.fieldLabels.countryCode(),
                placeholder: t.whatsapp.botForm.placeholder.countryCode(),
              }}
              singleInputDisabled={disabled}
              inheritWidthOf={showPhoneNumberField ? 'none' : 'target'}
              disabledCountryCodes={sinchProvidedNumber && disabledCountryCodes}
              required
            />
          </Box>
        </GridItem>
        {showPhoneNumberField && (
          <GridItem cols={8}>
            <InputField<IWhatsAppBotInput>
              id="phone-input"
              name={FIELD_PHONE}
              label={t.whatsapp.fieldLabels.phone()}
              placeholder={t.whatsapp.botForm.placeholder.phoneNumber()}
              disabled={disabled}
              maxLength={`${subscriberPhoneNumberMaxLength}`}
              hideCharactersLimit
              regexConstraint={/[\D]/g}
              required
            />
          </GridItem>
        )}
        {!sinchProvidedNumber && !disabled && (
          <Box alignItems="center" mt="medium" display="flex">
            <ScopedNotification variant="info" icon={<SFIcon name="warning" />}>
              {t.whatsapp.fieldLabels.phoneNumberWarning()}
            </ScopedNotification>
          </Box>
        )}
      </Grid>
    </>
  );
};
