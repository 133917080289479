import { IViberAspClientInput } from '@shared/bff';

type aspClientFieldsKeys = keyof Pick<
  IViberAspClientInput,
  'name' | 'businessLegalName'
>;
export const aspClientFieldsMinLength: Record<aspClientFieldsKeys, number> = {
  name: 2,
  businessLegalName: 2,
};

export const aspClientFieldsMaxLength: Record<aspClientFieldsKeys, number> = {
  name: 255,
  businessLegalName: 100,
};
