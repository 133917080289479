import { gql } from '@apollo/client';

import { IViberHomeResponse } from '@shared/bff';

export interface IViberHomeQueryResponse {
  viberHome: IViberHomeResponse;
}

export const VIBER_HOME_QUERY = gql`
  query ViberHome {
    viberHome {
      aspClientStep
      channelStep
    }
  }
`;
