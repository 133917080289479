import React from 'react';

import {
  Box,
  EmptySection,
  InputField,
  Section,
  Text,
} from '@src/common/components';
import { t } from '@src/messages';
import { WhatsAppMtMediaType, WhatsAppMtUrlType } from '@shared/bff';
import { languageDirection } from '@src/common/utils';

import { ContentPayload } from '../../../types';

import { HeaderMediaSample } from './HeaderMediaSample';

interface ISamplesProps {
  selectedTabIndex: number;
  content?: ContentPayload;
}

export const Samples = ({ selectedTabIndex, content }: ISamplesProps) => {
  const isRtlLanguage =
    content && languageDirection(content.language) === 'rtl';

  const hasHeaderVariable = content && content.headerText?.includes('{{1}}');
  const hasMessageBodyVariable = content && content.bodyText.includes('{{1}}');
  const hasMediaHeader =
    content &&
    content.mediaType &&
    content.mediaType !== WhatsAppMtMediaType.NONE;
  const hasButtonUrlVariable =
    content &&
    content.callToActionButtons &&
    content.callToActionButtons?.find(
      (button) =>
        'urlType' in button && button.urlType === WhatsAppMtUrlType.DYNAMIC
    );
  const hasVaribles =
    hasHeaderVariable ||
    hasMessageBodyVariable ||
    hasMediaHeader ||
    hasButtonUrlVariable;
  return (
    <Section>
      <Text variant="body" mb="medium">
        {t.whatsapp.messageTemplates.register.helpTexts.samples()}
      </Text>
      {!hasVaribles && (
        <EmptySection
          type="no-templates"
          title={t.whatsapp.messageTemplates.noVariables()}
        />
      )}
      {hasMediaHeader && (
        <Box mb="small">
          <HeaderMediaSample
            mediaType={content!.mediaType}
            name={`contents[${selectedTabIndex}].mediaVariable`}
          />
        </Box>
      )}
      {hasHeaderVariable && (
        <Box mb="small">
          <InputField
            name={`contents[${selectedTabIndex}].headerVariable`}
            label={`${t.whatsapp.messageTemplates.fields.headerVariable()} {{1}}`}
            required
            id="header-variable-input"
            className={isRtlLanguage ? 'rtl' : ''}
          />
        </Box>
      )}
      {hasMessageBodyVariable &&
        content!.bodyText.match(/\{\{\d*\}\}/g)?.map((_, index) => (
          <Box mb="small">
            <InputField
              name={`contents[${selectedTabIndex}].bodyVariables[${index}]`}
              label={`${t.whatsapp.messageTemplates.fields.bodyVariable()} {{${
                index + 1
              }}}`}
              required
              id={`body-variable-input-${index}`}
              className={isRtlLanguage ? 'rtl' : ''}
            />
          </Box>
        ))}
      {hasButtonUrlVariable && (
        <Box mb="small">
          <InputField
            name={`contents[${selectedTabIndex}].buttonUrlVariable`}
            label={`${t.whatsapp.messageTemplates.fields.buttonDynamicUrlVariable()} {{1}}`}
            required
            id={`button-url-variable-input`}
          />
        </Box>
      )}
    </Section>
  );
};
