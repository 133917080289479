import React from 'react';
import styled from 'styled-components';
import { StandardPhoneFrame } from '@sinch/previewer';

import { Box } from '@src/common/components';

const PREVIEWER_WIDTH = '370px';

const PreviewerWrapper = styled(Box)({
  width: PREVIEWER_WIDTH,
  minWidth: PREVIEWER_WIDTH,
});

const CenterWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: PREVIEWER_WIDTH,
});

const PhoneContent = styled.iframe({
  width: '100%',
  height: '100%',
  border: 'none',
});

interface IChannelPreviewerProps {
  profileId: string;
}

export const ChannelPreviewer = ({ profileId }: IChannelPreviewerProps) => {
  return (
    <CenterWrapper>
      <PreviewerWrapper mt="x-large" ml="large" mr="large">
        <StandardPhoneFrame channelSwitcher={false}>
          {profileId && (
            <PhoneContent src={`https://pf.kakao.com/${profileId}`} />
          )}
        </StandardPhoneFrame>
      </PreviewerWrapper>
    </CenterWrapper>
  );
};
