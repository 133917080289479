import React, { useContext } from 'react';
import styled from 'styled-components';
import { PhoneFrame, ViberBusinessPreview } from '@sinch/viber-bot-preview';
import { useMutation } from '@apollo/client';

import {
  ActivityHistorySection,
  Card,
  Grid,
  GridItem,
  IQueryHandlerResult,
  QueryHandler,
  StickyGridItem,
  ToastContext,
} from '@src/common/components';
import { t } from '@src/messages';
import {
  ACTIVITIES_QUERY,
  CREATE_COMMENT_MUTATION,
  IActivitiesQueryResponse,
  ICreateCommentResponse,
} from '@src/common/graphql';
import {
  IViberAspClient,
  IMutationCreateCommentArgs,
  CrState,
  ActivityTargetType,
} from '@shared/bff';
import { useFeatureFlags } from '@src/common/utils/hooks';

import { AccountInformationSection } from '../AccountInformationSection';
import { ViberBusinessChannelProgress } from '../ViberBusinessChannelProgress';
import { ICrFragment, IViberSenderFragment } from '../../graphql';
import { getLogoToPreview } from '../../utils';

import { ChannelConfigurationSection } from './ChannelConfigurationSection';
import { ContactInformationSection } from './ContactInformationSection';
import { ChannelDesignSection } from './ChannelDesignSection';
import { ExtraDocumentsSection } from './ExtraDocumentsSection';
import { SampleMessagesSection } from './SampleMessagesSection';

interface IChangeRequestDetailsProps {
  aspClient: Pick<
    IViberAspClient,
    | 'name'
    | 'businessLegalName'
    | 'businessCategories'
    | 'businessRegisteredCountry'
  >;
  cr: ICrFragment;
  sender?: IViberSenderFragment;
  showCurrent: boolean;
}

const CardWrapper = styled(Card)({
  overflowX: 'auto',
});

export const ChangeRequestDetails = ({
  aspClient,
  cr,
  sender,
  showCurrent,
}: IChangeRequestDetailsProps) => {
  const { extraDocuments } = useFeatureFlags();

  const { sender: crSender } = cr;
  const hasCurrentValues = sender !== undefined;
  const { toast } = useContext(ToastContext);
  const [createCommentRequest] = useMutation<
    ICreateCommentResponse,
    IMutationCreateCommentArgs
  >(CREATE_COMMENT_MUTATION);

  return (
    <>
      {cr.state !== CrState.REJECTED && (
        <GridItem mb="small">
          <ViberBusinessChannelProgress state={cr.state} />
        </GridItem>
      )}
      <GridItem>
        <Grid>
          <GridItem cols={8} pr="small">
            <Grid>
              <GridItem mb="small">
                <AccountInformationSection aspClient={aspClient} />
              </GridItem>
              <GridItem mb="small">
                <ChannelConfigurationSection
                  crValues={{
                    name: crSender!.name,
                    destinationCountries: crSender!.destinationCountries,
                    businessLaunchDate: crSender!.businessLaunchDate,
                  }}
                  currentValues={
                    hasCurrentValues
                      ? {
                          name: sender!.name,
                          destinationCountries: sender!.destinationCountries,
                          businessLaunchDate: sender!.businessLaunchDate,
                        }
                      : undefined
                  }
                  crState={cr.state}
                  showCurrent={showCurrent}
                />
              </GridItem>
              <GridItem mb="small">
                <ChannelDesignSection
                  crValues={{
                    logoUrls: crSender!.logoUrls,
                  }}
                  currentValues={
                    hasCurrentValues
                      ? {
                          logoUrls: sender!.logoUrls,
                        }
                      : undefined
                  }
                  showCurrent={showCurrent}
                />
              </GridItem>
              <GridItem mb="small">
                <ContactInformationSection
                  crValues={{
                    description: crSender!.description,
                    address: crSender!.address,
                    phoneNumber: crSender!.phoneNumber,
                    website: crSender!.website,
                  }}
                  currentValues={
                    hasCurrentValues
                      ? {
                          description: sender!.description,
                          address: sender!.address,
                          phoneNumber: sender!.phoneNumber,
                          website: sender!.website,
                        }
                      : undefined
                  }
                  showCurrent={showCurrent}
                />
              </GridItem>
              <GridItem mb="small">
                <SampleMessagesSection
                  crValues={{ sampleMessage: crSender!.sampleMessage }}
                  currentValues={{ sampleMessage: sender?.sampleMessage }}
                  showCurrent={showCurrent}
                />
              </GridItem>
              {extraDocuments && (
                <GridItem mb="small">
                  <ExtraDocumentsSection
                    crValues={{
                      extraDocs: crSender!.extraDocs ?? [],
                    }}
                    currentValues={{
                      extraDocs: sender?.extraDocs ?? [],
                    }}
                    showCurrent={showCurrent}
                  />
                </GridItem>
              )}

              <GridItem>
                <QueryHandler
                  query={ACTIVITIES_QUERY}
                  variables={{
                    targetType: ActivityTargetType.VIBER_SENDER_CHANGE_REQUEST,
                    targetId: cr.id,
                  }}
                  levitate
                >
                  {({
                    data: { activities },
                  }: IQueryHandlerResult<IActivitiesQueryResponse>) => {
                    return (
                      <ActivityHistorySection
                        onSubmit={async (values) => {
                          try {
                            await createCommentRequest({
                              variables: {
                                comment: {
                                  targetId: cr.id,
                                  targetType:
                                    ActivityTargetType.VIBER_SENDER_CHANGE_REQUEST,
                                  comment: values.comment,
                                },
                              },

                              refetchQueries: [
                                {
                                  query: ACTIVITIES_QUERY,
                                  variables: {
                                    targetType:
                                      ActivityTargetType.VIBER_SENDER_CHANGE_REQUEST,
                                    targetId: cr.id,
                                  },
                                },
                              ],
                            });
                            toast('success', {
                              heading: t.common.toasts.commentCreatedSuccess(),
                            });
                          } catch (e) {
                            toast('error', {
                              heading: t.common.toasts.somethingWentWrong(),
                            });
                          }
                        }}
                        data={activities.map((activity) => ({
                          id: activity.activityId,
                          timestamp: activity.updated,
                          comment: activity.comment,
                          event: activity.notificationTemplate,
                        }))}
                      />
                    );
                  }}
                </QueryHandler>
              </GridItem>
            </Grid>
          </GridItem>
          <StickyGridItem cols={4}>
            <CardWrapper
              heading={t.common.components.businessProfilePreviewer.heading()}
            >
              <PhoneFrame>
                <ViberBusinessPreview
                  input={{
                    name: crSender!.name,
                    about: crSender!.description,
                    address: crSender!.address,
                    phoneNumber: crSender!.phoneNumber,
                    website: crSender!.website,
                    photoUrl: getLogoToPreview(crSender!.logoUrls, 360),
                  }}
                />
              </PhoneFrame>
            </CardWrapper>
          </StickyGridItem>
        </Grid>
      </GridItem>
    </>
  );
};
